import React from 'react';
import shortid from 'shortid';
import { Modal, Form, FormInstance, Select, Upload, Input, Switch, Row, Col, message, Alert } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload';
import { SelectValue } from 'antd/lib/select';

import { Currency } from 'models/currency';
import type { IClient } from 'models/client';
import type { IEntity } from 'models/entity';
import { PostalAddressDeliverability } from 'models/common/postalAddress';
import { MailClass, MailExtraService, MailAddressPlacement } from 'models/mail';
import { ISendMailToClientEntityCalculationPayload } from 'models/payloads/snailMail';
import { IFileData } from 'models/file';

import { EntityAddress } from 'components/entity/address';

import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

import { calculateMailPrice, sendMail } from 'api/mail';


interface IFormValues {
    entityId: string;
    color: boolean;
    doubleSided: boolean;
    addressPlacement: MailAddressPlacement;
    class: MailClass;
    extraService: MailExtraService | '';
    description: string;
}

interface ISendMailModalProps {
    isVisible: boolean;
    client?: IClient;
    entities: IEntity[];
    sendingFile?: IFileData;
    description?: string;
    close: (sent: boolean) => Promise<void>;
}

interface ISendMailModalState {
    valueChangeEventId: string;
    stillNeedsFile: boolean;
    sending: boolean;
    files: RcFile[];
    pageCount: number;
    pdfInvalidPageSizes: boolean;
    cost?: Currency;
}

export class SendMailModal extends React.PureComponent<ISendMailModalProps, ISendMailModalState> {
    state: Readonly<ISendMailModalState> = {
        valueChangeEventId: '',
        stillNeedsFile: false,
        sending: false,
        files: [],
        pageCount: -1,
        pdfInvalidPageSizes: false,
    };

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidUpdate() {
        if (!this.props.isVisible || !this.props.sendingFile) {
            return;
        }

        if (typeof this.props.sendingFile.mailablePages !== 'number' || !this.props.sendingFile.isMailable) {
            return;
        }

        if (this.state.pageCount === this.props.sendingFile.mailablePages) {
            return;
        }

        this.setState({ pageCount: this.props.sendingFile.mailablePages }, this.getSnailMailPricing);
    }

    onCancel = () => {
        this.props.close(false);
        this.reset();
    }

    reset = () => {
        this.setState({ stillNeedsFile: false, sending: false, files: [] });

        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }

    valuesChanged = (changedValues: any) => {
        if (changedValues.description) {
            return;
        }

        this.setState({ valueChangeEventId: shortid.generate() }, this.getSnailMailPricing);
    }

    onSend = async () => {
        try {
            const values = await this.formRef.current!.validateFields();

            if (this.state.files.length === 0 && !this.props.sendingFile) {
                this.setState({ stillNeedsFile: true });
                return;
            }

            this.setState({ sending: true }, async () => {
                const data = new FormData();
                data.append('entityId', values.entityId);
                data.append('description', values.description);
                data.append('color', `${ values.color }`);
                data.append('doubleSided', `${ values.doubleSided }`);
                data.append('addressPlacement', values.addressPlacement);
                data.append('class', values.class);

                if (values.extraService) {
                    data.append('extraService', values.extraService);
                }

                if (this.state.files.length >= 1) {
                    data.append('letter', this.state.files[0]);
                }

                if (this.props.sendingFile) {
                    data.append('uploadedFileId', this.props.sendingFile.id);
                }

                const client = this.props.client!;

                try {
                    await sendMail(client.organization.id, client.id, data);
                    trackUmami('Sent Snail Mail');

                    message.success('Mail sent successfully');

                    this.props.close(true);
                    this.reset();
                } catch (e) {
                    displayErrorNotification(e);
                } finally {
                    this.setState({ sending: false });
                }
            });
        } catch (e) {
            console.warn('send mail form validate fields failed:', e);

            // if they haven't selected a recipient or filled out the internal description
            // the form throws an error and we need to also indicate a file is still needed
            if (this.state.files.length === 0) {
                this.setState({ stillNeedsFile: true });
            }
        }
    }

    entityValidator = async (rule: Rule, value: string): Promise<void> => {
        if (!value) {
            throw new Error('A recipient must be selected.');
        }

        const entity = this.props.entities.find((e) => e.id === value);
        if (!entity) {
            throw new Error('Invalid recipient selected.');
        }

        if (!entity.addresses || entity.addresses.length === 0) {
            throw new Error('Selected recipient does not have an address.');
        }

        if (entity.addresses[0].deliverability !== PostalAddressDeliverability.Deliverable) {
            throw new Error('The address of the recipient is not deliverable.');
        }
    }

    get entitySelector() {
        if (!this.props.client) {
            return null;
        }

        const rules: Rule[] = [
            { required: true, validator: this.entityValidator },
        ];

        return (
            <Form.Item name="entityId" label="Recipient" rules={rules}>
                <Select disabled={this.state.sending}>
                    <Select.Option key="nothing" value="">&nbsp;</Select.Option>
                    {this.props.entities.map((e) => {
                        const noAddresses = !e.addresses || e.addresses.length === 0;
                        const undeliverable = !noAddresses && e.addresses![0].deliverability !== PostalAddressDeliverability.Deliverable;

                        return (
                            <Select.Option
                                value={e.id}
                                key={e.id}
                                disabled={noAddresses || undeliverable || this.state.sending}
                            >
                                { e.title ? `${ e.title } ` : null }{ e.fullName } { noAddresses ? '(no addresses)' : null} { !noAddresses && undeliverable ? '(undeliverable address)' : null}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    }

    get addressSelectedAndPlacement() {
        const form = this.formRef.current;
        const selectedId = form?.getFieldValue('entityId');

        let entity: IEntity | undefined;
        if (selectedId) {
            entity = this.props.entities.find((e) => e.id === selectedId);
        }

        const hasExtraService = !!form?.getFieldValue('extraService');

        return (
            <Row>
                <Col md={12}>
                    <Form.Item label="Selected Address" required>
                        {entity ? <EntityAddress entity={entity} showTags /> : '-'}
                    </Form.Item>
                </Col>

                <Col md={12}>
                    <Form.Item
                        name="addressPlacement"
                        label="Address Placement"
                        help={hasExtraService ? 'Certified/Registered mail automatically inserts a blank page.' : null}
                        rules={[{ required: true, message: 'Please select where and how the address should be placed.' }]}
                    >
                        <Select disabled={hasExtraService || this.state.sending}>
                            <Select.Option value={MailAddressPlacement.TopFirstPage}>Top First Page</Select.Option>
                            <Select.Option value={MailAddressPlacement.InsertBlankPage}>Insert Blank Page</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get colorAndSides() {
        return (
            <Row>
                <Col md={12}>
                    <Form.Item name="color" label="Color" required valuePropName="checked">
                        <Switch unCheckedChildren="Black & White" checkedChildren="Color Pages" disabled={this.state.sending} />
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item name="doubleSided" label="Single or Double Sided" required valuePropName="checked">
                        <Switch unCheckedChildren="Single Sided" checkedChildren="Double Sided" disabled={this.state.sending} />
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    onExtraServiceChange = (value: SelectValue) => {
        if (value && this.formRef.current) {
            this.formRef.current.setFieldsValue({ addressPlacement: MailAddressPlacement.InsertBlankPage });
        }
    }

    onClassChange = (value: SelectValue) => {
        if (value === MailClass.Standard && this.formRef.current) {
            this.formRef.current.setFieldsValue({ extraService: '' });
        }
    }

    get classAndExtraService() {
        const form = this.formRef.current;

        return (
            <Row gutter={16}>
                <Col md={12}>
                    <Form.Item name="class" label="Mail Class" rules={[{ required: true, message: 'Please select the mailing class which should be used.' }]}>
                        <Select disabled={this.state.sending} onChange={this.onClassChange}>
                            <Select.Option value={MailClass.FirstClass}>First Class</Select.Option>
                            <Select.Option value={MailClass.Standard}>Standard</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item name="extraService" label="Extra Service">
                        <Select onChange={this.onExtraServiceChange} disabled={this.state.sending || !form || form.getFieldValue('class') !== MailClass.FirstClass}>
                            <Select.Option value="">None</Select.Option>
                            <Select.Option value={MailExtraService.Certified}>Certified</Select.Option>
                            <Select.Option value={MailExtraService.CertifiedReturnReceipt}>Certified Return Receipt</Select.Option>
                            <Select.Option value={MailExtraService.Registered}>Registered</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get description() {
        return (
            <Form.Item name="description" label="Internal Description" rules={[{ required: true, message: 'Please provide a brief internal description about this mailing.' }]}>
                <Input.TextArea
                    disabled={this.state.sending || this.props.description !== undefined}
                />
            </Form.Item>
        );
    }

    beforeUpload = (file: RcFile) => {
        this.setState({ files: [file], stillNeedsFile: false });

        import('../pdfs/getPageCount').then(async (pdfPageCount) => {
            try {
                const pdfInfo = await pdfPageCount.getPageCount(file);

                this.setState({ pageCount: pdfInfo.pageCount, pdfInvalidPageSizes: pdfInfo.hasInvalidSizes }, this.getSnailMailPricing);
            } catch (e) {
                console.warn('failed to get the page count', e);
            }
        });

        return false;
    };

    onFileRemove = () => {
        this.setState({ files: [], stillNeedsFile: true, pageCount: -1, cost: undefined });
    }

    get fileUpload() {
        let help = 'Please upload the piece of mail to send.';
        let hasError = this.state.stillNeedsFile;
        if (!hasError && this.state.pdfInvalidPageSizes) {
            hasError = this.state.pdfInvalidPageSizes;
            help = 'At least one page of the PDF is not 8.5x11 inches in size. Please ensure the PDF is a USA Letter size and upload it again (landscape orientation is not accepted).';
        }

        return (
            <Form.Item label="Letter Upload" status={hasError ? 'error' : undefined} validateStatus={hasError ? 'error' : undefined} help={hasError ? help : null} required>
                <Upload.Dragger multiple={false} accept=".pdf" beforeUpload={this.beforeUpload} onRemove={this.onFileRemove} disabled={this.state.sending}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click this area to upload</p>
                    <p className="ant-upload-hint">Ensure the file is ready for print (8.5x11)</p>
                </Upload.Dragger>
            </Form.Item>
        );
    }

    get uploadedFileInfo() {
        if (!this.props.sendingFile) {
            throw new Error('Why did you try to send the uploaded file info when it is not there?');
        }

        return (
            <Alert
                message="Uploaded file Selected"
                description={`You have selected to mail the file: ${ this.props.sendingFile.name }`}
                type="info"
                showIcon
                style={{ marginBottom: '15px' }}
            />
        );
    }

    getSnailMailPricing = async () => {
        if (!this.formRef.current || !this.props.client || (this.state.stillNeedsFile && !this.props.sendingFile) || this.state.pageCount === -1) {
            return;
        }

        try {
            const client = this.props.client;
            const values = this.formRef.current.getFieldsValue();

            const payload: ISendMailToClientEntityCalculationPayload = {
                color: `${ values.color }`,
                doubleSided: `${ values.doubleSided }`,
                addressPlacement: values.addressPlacement,
                class: values.class,
                extraService: values.extraService,
                pages: `${ this.state.pageCount }`,
            };

            const res = await calculateMailPrice(client.organization.id, client.id, payload);

            this.setState({ cost: res.cost });
        } catch (e) {
            displayErrorNotification(e);
            return;
        }
    }

    get priceInfo() {
        if (!this.state.cost || !this.formRef.current) {
            return null;
        }

        let pageCount = this.state.pageCount;
        if (this.formRef.current.getFieldValue('addressPlacement') === MailAddressPlacement.InsertBlankPage) {
            pageCount++;
        }

        return (
            <Alert message={`The total cost for ${ pageCount } page${ pageCount === 1 ? '' : 's' } is: $${ this.state.cost }`} type="info" />
        );
    }

    render() {
        if (!this.props.client) {
            return null;
        }

        return (
            <Modal
                open={this.props.isVisible}
                title={`Send ${ this.props.client ? this.props.client.displayName : 'the client' } Snail Mail`}
                onCancel={this.onCancel}
                cancelButtonProps={{ disabled: this.state.sending }}
                onOk={this.onSend}
                okText="Send"
                okButtonProps={{ disabled: this.state.sending || this.state.pdfInvalidPageSizes, loading: this.state.sending }}
                closable={!this.state.sending}
                maskClosable={false}
            >
                <Form<IFormValues>
                    ref={this.formRef}
                    layout="vertical"
                    scrollToFirstError
                    onValuesChange={this.valuesChanged}
                    onFinish={this.onSend}
                    disabled={this.state.sending}
                    initialValues={{
                        color: true,
                        doubleSided: false,
                        addressPlacement: MailAddressPlacement.TopFirstPage,
                        class: MailClass.FirstClass,
                        extraService: '',
                        description: this.props.description || '',
                    }}
                >
                    {this.entitySelector}
                    {this.addressSelectedAndPlacement}
                    {this.colorAndSides}
                    {this.classAndExtraService}
                    {this.description}
                    {this.props.sendingFile ? this.uploadedFileInfo : this.fileUpload}
                    {this.priceInfo}
                </Form>
            </Modal>
        );
    }
}
