import React from 'react';

import { connect, DispatchProp } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Form, FormInstance, Button, Select, Switch, Alert, Radio, Input } from 'antd';
import { Rule } from 'antd/lib/form';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';
import { CommunicationPreference, OnlinePaymentPlatformFeePayee } from 'models';

import { INewLoanFormValues } from './formValues';

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    org: getSelectedOrg(state),
});

interface INewLoanFourthStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    goNext: () => void;
    goBack: () => void;
}

interface INewLoanFormFourthStepState {}

class NewLoanFormFourthStepBase extends React.PureComponent<INewLoanFourthStepProps, INewLoanFormFourthStepState> {
    state: Readonly<INewLoanFormFourthStepState> = {}

    get automatedCommunication() {
        return (
            <Form.Item
                name="automatedCommunication"
                label="Automated Communication"
                extra="Should the communication to the client be automated or manual?"
                valuePropName="checked"
                rules={[{ required: true, message: 'Do you want automated communications enabled or not?' }]}
            >
                <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
            </Form.Item>
        );
    }

    get communicationPreferences() {
        return (
            <Form.Item
                name="communicationPreferences"
                label="Communication Preferences"
                extra="How should automated information be sent to the Client?"
                rules={[{ required: true, message: 'Please select which route to communicate to the client.' }]}
            >
                <Select<CommunicationPreference> mode="multiple">
                    <Select.Option key={CommunicationPreference.SMS} value={CommunicationPreference.SMS}>SMS (text messages)</Select.Option>
                    <Select.Option key={CommunicationPreference.Email} value={CommunicationPreference.Email}>Email</Select.Option>
                    <Select.Option key={CommunicationPreference.Mail} value={CommunicationPreference.Mail} disabled>Snail Mail</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get communicationArea() {
        if (!this.props.org || !this.props.org.messaging) {
            return null;
        }

        if (!this.props.org.messaging.setupComplete) {
            return (
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={24} lg={18} xl={10}>
                        <Alert
                            type="info"
                            showIcon
                            message="Automated Communication"
                            description={
                                <span>If you would like to enable automated communication, please setup the <a href={`/${ this.props.org.shortId }/communications`} target="_blank" rel="noreferrer" title="Opens in a new tab.">Communication Portal</a>.</span>
                            }
                        />
                    </Col>
                </Row>
            );
        }

        return (
            <React.Fragment>
                {this.automatedCommunication}
                {this.communicationPreferences}
            </React.Fragment>
        );
    }

    get acceptOnlinePayments() {
        if (!this.props.org) {
            return null;
        }

        const { stripeConnect, payArc } = this.props.org;

        // no stripe account connected or payArc account
        if (!stripeConnect.accountId && !payArc.setupComplete) {
            return (
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={24} lg={18} xl={10}>
                        <Alert
                            type="info"
                            showIcon
                            message="Online Payments"
                            description={
                                <span>If you would like to accept online payments, please <a href="https://cal.com/bradleyhilton/payarc" target="_blank" rel="noreferrer" title="Opens in a new tab">schedule an appointment to setup PayArc</a>.</span>
                            }
                        />
                    </Col>
                </Row>
            );
        }

        // they have a stripe account id but their details have yet to be submitted
        if (stripeConnect.accountId && !stripeConnect.detailsSubmitted) {
            return (
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={24} lg={18} xl={10}>
                        <Alert
                            message="Online Payments"
                            description="To accept online payments, please finish connecting Stripe."
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            );
        }

        return (
            <Form.Item
                name="onlinePaymentsEnabled"
                label="Online Payments"
                extra="Should we set up this loan for online payments? You can always enable this in the future."
                rules={[{ required: true, message: 'Please state whether or not we should enabled online payments for the loan.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    get onlinePaymentPlatformFeePayeeConfig() {
        if (!this.props.org) {
            return null;
        }

        return (
            <Form.Item
                name="onlinePaymentPlatformFeePayee"
                label="Platform Fee Payee"
                extra="Who pays the online platform fees? The buyer, seller or split 50% between them both?"
                rules={[{ required: true, message: 'Please indicate who should pay the platform fees.' }]}
            >
                <Select<OnlinePaymentPlatformFeePayee>>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Buyer} value={OnlinePaymentPlatformFeePayee.Buyer}>Buyer</Select.Option>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Both} value={OnlinePaymentPlatformFeePayee.Both}>Both 50/50 Split</Select.Option>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Seller} value={OnlinePaymentPlatformFeePayee.Seller}>Seller</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get statementDescriptorExtra() {
        return (
            <React.Fragment>
                Extra information about a product which will appear on your customer's credit card statement. <a href="https://stripe.com/docs/statement-descriptors" target="_blank" rel="noopener noreferrer">Click for more information.</a>
            </React.Fragment>
        );
    }

    get statementDescriptorRules(): Rule[] {
        return [
            { required: true, message: 'Please provide a brief summary for the statement descriptor of the transaction.' },
            {
                type: 'string', whitespace: true, message: 'Invalid statement descriptor.',
                transform: (value: string) => value.trim().toUpperCase(),
            },
            { min: 5, max: 22, message: 'Statement descriptor must be between 5 and 22 characters long.' },
            { pattern: /[A-Z]+/gi, message: 'Statement descriptor must contain at least one letter.' },
            {
                message: `Statement descriptor must not contain: < > \\ ' " *`, validator: async (rule: any, value: string) => {
                    if (value.includes('>')) {
                        throw new Error('Can not include >');
                    } else if (value.includes('<')) {
                        throw new Error('Can not include <');
                    } else if (value.includes('\\')) {
                        throw new Error('Can not include \\');
                    } else if (value.includes('\'')) {
                        throw new Error('Can not include \'');
                    } else if (value.includes('"')) {
                        throw new Error('Can not include "');
                    } else if (value.includes('*')) {
                        throw new Error('Can not include *');
                    }
                },
            },
        ];
    }

    get statementDescriptor() {
        if (!this.props.org) {
            return null;
        }

        let initialValue = this.props.org.name.replace(/[^\w\s]/gi, '');
        if (initialValue.length > 22) {
            initialValue = initialValue.substring(0, 22).trim();
        }

        return (
            <Form.Item
                name="onlineStatementDescriptor"
                label="Statement Descriptor"
                extra={this.statementDescriptorExtra}
                initialValue={initialValue}
                rules={this.statementDescriptorRules}
            >
                <Input style={{ textTransform: 'uppercase' }} minLength={5} maxLength={22} />
            </Form.Item>
        );
    }

    get allowPrincipalOnlyPayments() {
        return (
            <Form.Item
                name="allowPrincipalOnly"
                label="Allow Principal Only"
                extra="Do you wish to allow the buyer to pay extra principal only payments online? These are separate transactions from payments which include extra on the regular payment."
                initialValue={true}
                rules={[{ required: true, message: 'Please state whether or not we should allow principal only payments online.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    get allowAutoDraft() {
        return (
            <Form.Item
                name="allowAutoDraft"
                label="Allow Auto Draft"
                extra="Do you wish to allow the buyer to set up auto drafting their payments?"
                initialValue={true}
                rules={[{ required: true, message: 'Please state whether or not we should allow auto drafting payments.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
            </Form.Item>
        );
    }

    get bottomButtons() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue, getFieldError }) => {
                    const automated = getFieldValue('automatedCommunication') as boolean;
                    const preferences = getFieldValue('communicationPreferences') as CommunicationPreference[];

                    let nextDisabled = typeof automated === 'undefined';

                    if (automated) {
                        // if the communication is automated, there needs to be at least one preference selected
                        if (!Array.isArray(preferences) || preferences.length === 0) {
                            nextDisabled = true;
                        } else {
                            // validate all of the selected values are valid
                            preferences.forEach((v) => {
                                if (!Object.values(CommunicationPreference).includes(v)) {
                                    nextDisabled = true;
                                }
                            });
                        }
                    }

                    if (getFieldValue('onlinePaymentsEnabled')) {
                        const descriptorErrors = getFieldError('onlineStatementDescriptor');
                        if (descriptorErrors && descriptorErrors.length > 0) {
                            nextDisabled = true;
                        }
                    }

                    if (getFieldValue('onlinePaymentPlatformFeePayee')) {
                        const feePayeeErrors = getFieldError('onlinePaymentPlatformFeePayee');
                        if (feePayeeErrors && feePayeeErrors.length > 0) {
                            nextDisabled = true;
                        }
                    } else {
                        nextDisabled = true;
                    }

                    return (
                        <Button.Group>
                            <Button icon={<LeftOutlined />} onClick={this.props.goBack}>Previous</Button>
                            <Button type="primary" onClick={this.props.goNext} disabled={nextDisabled}>
                                Next <RightOutlined />
                            </Button>
                        </Button.Group>
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.communicationArea}

                {this.acceptOnlinePayments}

                <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.onlinePaymentsEnabled !== curr.onlinePaymentsEnabled}>
                    {({ getFieldValue }) => {
                        if (!getFieldValue('onlinePaymentsEnabled')) {
                            return null;
                        }

                        return (
                            <React.Fragment>
                                {this.statementDescriptor}
                                {this.onlinePaymentPlatformFeePayeeConfig}
                                {this.allowPrincipalOnlyPayments}
                                {this.allowAutoDraft}
                            </React.Fragment>
                        );
                    }}
                </Form.Item>

                {this.bottomButtons}
            </React.Fragment>
        );
    }
}

export const NewLoanStepFour = connect(mapStateToProps)(NewLoanFormFourthStepBase);
