import React, { Suspense, lazy } from 'react';
import shortid from 'shortid';
import currency from 'currency.js';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import type { Dayjs } from 'dayjs';
import { isMobileOnly } from 'react-device-detect';

import { Modal, Form, Row, Col, Select, Input, DatePicker, Switch, Alert, FormInstance, Skeleton } from 'antd';
import type { DefaultOptionType, SelectValue } from 'antd/lib/select';
import type { CellRenderInfo, ValueDate } from 'rc-picker/lib/interface';
import type { Rule } from 'antd/es/form';
import type { EditorState } from 'braft-editor';

import { ILoan, ILoanTransaction, LoanTransactionType, LoanTermsInterestSchedule, LoanType, LoanTransactionExtraApplication, LoanTransactionAdjustmentType } from 'models/loan';
import { TransactionStatus, TransactionMethod } from 'models/transactions';
import { IPropertyTaxForTract } from 'models/propertyTax';
import type { ILoanSchedule, ILoanScheduleItem } from 'models/loanSchedule';
import { LateFeeChargeType, type ILateFeeTier } from 'models';
import { LoanDownPaymentSchedule, type ILoanDownPaymentIncrement } from 'models/loanDownPayment';

import { LateFeeTierString } from 'components/lateFees';
import { calculateAccruedInterestSinceLastPayment } from 'utils/paymentCalc';
import { displayErrorNotification } from 'utils/errors';
import { SimpleTooltip } from 'utils/tooltipWrapper';
import { trackUmami } from 'utils/umami';
import { sanitizeCurrency } from 'utils/numberFormatting';
import { displayConfirmModal } from 'utils/modals';

import { createLoanTransaction } from 'api/loans';

import './newTransactionModal.css';

const BraftEditorInput = lazy(() => import('components/misc/braftEditorInput'));

interface IFormValues {
    type: LoanTransactionType;
    forPaymentsIndexes: number[];
    downPaymentIncrement: number[];
    amount: string;
    adjustmentType?: LoanTransactionAdjustmentType;
    method: TransactionMethod;
    date: Dayjs | null;
    lateFeeWaived: boolean;
    status?: TransactionStatus;
    extraApplication?: LoanTransactionExtraApplication;
    lateFeeTier: number;
    comment: EditorState;
}

interface INewTransactionModalProps {
    loan?: Partial<ILoan>;
    schedule?: Partial<ILoanSchedule>;
    taxRecord?: IPropertyTaxForTract;
    type?: LoanTransactionType;
    isVisible: boolean;
    close: (saved: boolean) => Promise<void>;
}

interface INewTransactionModalState {
    isSaving: boolean;
    amountTouched: boolean;
    lastPaymentReceivedDate?: Dayjs;
}

export class NewTransactionModal extends React.PureComponent<INewTransactionModalProps, INewTransactionModalState> {
    state: Readonly<INewTransactionModalState> = {
        isSaving: false,
        amountTouched: false,
    }

    formRef = React.createRef<FormInstance<IFormValues>>();

    componentDidUpdate() {
        const { loan } = this.props;
        if (!loan || !loan.lastPaymentReceivedDate) {
            return;
        }

        const date = dayjs(loan.lastPaymentReceivedDate);
        if (!this.state.lastPaymentReceivedDate) {
            this.setState({ lastPaymentReceivedDate: date });
            return;
        }

        if (this.formRef.current && !this.state.amountTouched && this.props.taxRecord) {
            this.formRef.current.setFieldValue('amount', currency(this.props.taxRecord.amountOwed, { precision: 2 }).toString());
            this.formRef.current.setFieldValue('type', LoanTransactionType.PropertyTax);
        }

        if (this.state.lastPaymentReceivedDate.isSame(date)) {
            return;
        }

        this.setState({ lastPaymentReceivedDate: date });
    }

    //#region closing and saving logic
    onClose = () => {
        this.props.close(false);
        this.reset();
    };

    reset = () => {
        this.setState({ isSaving: false, amountTouched: false });

        if (this.formRef.current) {
            this.formRef.current.resetFields();
            this.formRef.current.setFieldValue('interestDue', '0.00');
        }
    }

    handleSave = async () => {
        const { loan } = this.props;

        if (!loan || !this.formRef.current) {
            return;
        }

        try {
            const values = await this.formRef.current.validateFields();

            if (values.lateFeeWaived && (!Array.isArray(values.forPaymentsIndexes) || values.forPaymentsIndexes.length === 0)) {
                Modal.error({
                    title: 'Cannot waive late fee',
                    content: 'You must select the payment(s) this transaction applies towards in order to waive the late fee.',
                });
                return;
            }

            if (this.state.lastPaymentReceivedDate?.isValid() && values.date)
            switch (values.type) {
                case LoanTransactionType.RegularPayment:
                case LoanTransactionType.PrincipalPayment:
                case LoanTransactionType.Adjustment:
                    if (values.date?.isBefore(this.state.lastPaymentReceivedDate)) {
                        Modal.error({
                            title: 'Invalid Date',
                            content: `The date of the transaction cannot be before the last payment received date of ${ this.state.lastPaymentReceivedDate.toDate().toLocaleDateString() }`,
                        });
                    }
                    break;
            }

            this.setState({ isSaving: true }, async () => {
                if (values.type === LoanTransactionType.RegularPayment && !values.lateFeeWaived && loan.balance?.lateFees && currency(loan.balance.lateFees, { precision: 2 }).intValue > 0) {
                    const res = await displayConfirmModal('Late Fee Warning', 'This loan currently has a late fee balance. Are you sure you want to proceed?', 'Proceed', 'Cancel');
                    if (!res) {
                        this.setState({ isSaving: false });
                        return;
                    }
                }

                const transaction: Partial<ILoanTransaction> = {
                    id: shortid.generate(),
                    amount: values.amount ? sanitizeCurrency(values.amount, true) : undefined,
                    method: values.method,
                    type: values.type,
                    forPayments: values.forPaymentsIndexes,
                    forDownPaymentIncrement: values.downPaymentIncrement,
                    date: values.date ? values.date.toJSON() : dayjs().toJSON(),
                    lateFeeWaived: values.lateFeeWaived,
                    lateFeeTier: values.lateFeeTier,
                    comment: values.comment.toHTML(),
                    commentText: values.comment.toText(),
                    status: TransactionStatus.Success,
                    extraApplication: values.extraApplication,
                    adjustmentType: values.adjustmentType,
                };

                if (values.status) {
                    transaction.status = values.status;
                }

                try {
                    await createLoanTransaction(loan.organization!.id, loan.id!, transaction);
                    trackUmami('Create Loan Transaction');

                    await this.props.close(true);
                    this.reset();
                } catch (e) {
                    displayErrorNotification(e);
                    this.setState({ isSaving: false });
                }
            });
        } catch (e) {
            console.warn('failed to validate the new loan transaction form:', e);
        }
    }
    //#endregion

    get transactionType() {
        let disabled = typeof this.props.taxRecord !== 'undefined' || this.state.isSaving;
        if (this.props.type) {
            disabled = true;
        }

        return (
            <Form.Item
                name="type"
                label={<SimpleTooltip content="Type" title="The type of transaction this is, how it applies to the loan." hasQuestion />}
                rules={[{ required: true, message: 'Please state the type of transaction!' }]}
            >
                <Select
                    disabled={disabled}
                    onChange={this.onTransactionTypeChange}>
                    <Select.OptGroup label="Principal">
                        <Select.Option key={LoanTransactionType.RegularPayment} value={LoanTransactionType.RegularPayment}>Regular Payment</Select.Option>
                        <Select.Option key={LoanTransactionType.PrincipalPayment} value={LoanTransactionType.PrincipalPayment}>Principal Payment</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Fees">
                        <Select.Option key={LoanTransactionType.LateFee} value={LoanTransactionType.LateFee}>Late Fee</Select.Option>
                        <Select.Option key={LoanTransactionType.OtherFee} value={LoanTransactionType.OtherFee}>Other Fee</Select.Option>
                        { this.props.loan?.type === LoanType.Tract && typeof this.props.taxRecord !== 'undefined' ? <Select.Option key={LoanTransactionType.PropertyTax} value={LoanTransactionType.PropertyTax}>Property Tax</Select.Option> : null }
                    </Select.OptGroup>
                    <Select.OptGroup label="Record Keeping">
                        <Select.Option key={LoanTransactionType.DownPayment} value={LoanTransactionType.DownPayment}>Down Payment</Select.Option>
                        <Select.Option key={LoanTransactionType.EarnestMoney} value={LoanTransactionType.EarnestMoney}>Earnest Money</Select.Option>
                        <Select.Option key={LoanTransactionType.ClosingFee} value={LoanTransactionType.ClosingFee}>Closing Fees</Select.Option>
                        <Select.Option key={LoanTransactionType.DocumentationFee} value={LoanTransactionType.DocumentationFee}>Documentation Fee</Select.Option>
                    </Select.OptGroup>
                    <Select.OptGroup label="Reconciliation">
                        <Select.Option key={LoanTransactionType.Adjustment} value={LoanTransactionType.Adjustment}>Adjustment</Select.Option>
                    </Select.OptGroup>
                </Select>
            </Form.Item>
        );
    }

    onTransactionTypeChange = (value: SelectValue) => {
        if (this.formRef.current?.isFieldTouched('amount')) {
            this.formRef.current.validateFields(['amount']);
        }

        if (!this.props.loan || !this.props.loan.terms || !this.formRef.current) {
            return;
        }

        if (value === LoanTransactionType.DownPayment && this.props.loan.terms.downPayment) {
            this.formRef.current.setFieldsValue({ amount: this.props.loan.terms.downPayment, forPaymentsIndexes: [], adjustmentType: undefined });
        }

        if (value === LoanTransactionType.RegularPayment && this.props.loan.terms.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            this.formRef.current.setFieldsValue({ amount: currency(this.props.loan.terms.payment!, { precision: 2 }).toString(), forPaymentsIndexes: [], adjustmentType: undefined });
        }
    }

    //#region payment for
    filterOption = (inputValue: string, option?: DefaultOptionType): boolean => {
        if (!option) {
            return false;
        }

        const value = parseInt(inputValue);
        if (isNaN(value)) {
            return false;
        }

        if (!option.props.children) {
            return false;
        }

        return value === option.props.value;
    }

    forPaymentsChange = (selectedPaymentIndexes: number[]) => {
        if (!this.formRef.current || this.state.amountTouched) {
            return;
        }

        if (!this.props.loan || !this.props.schedule || !this.props.schedule.payments) {
            return;
        }

        const transType = this.formRef.current.getFieldValue('type') as LoanTransactionType;
        if (transType !== LoanTransactionType.RegularPayment && transType !== LoanTransactionType.Adjustment) {
            return;
        }

        let suggestedAmount = currency(0, { precision: 2 });
        selectedPaymentIndexes.forEach((paymentIndex) => {
            const payment = this.props.schedule!.payments!.find((p) => p.paymentIndex === paymentIndex);
            if (!payment) {
                return;
            }

            const paid = currency(payment.paymentReceived, { precision: 2 });
            const needed = currency(payment.payment, { precision: 2 });

            let amount = currency(needed);
            if (needed.intValue > paid.intValue) {
                amount = needed.subtract(paid);
            }

            suggestedAmount = suggestedAmount.add(amount);
        });

        let multipleOf = 1;
        if (transType === LoanTransactionType.Adjustment) {
            multipleOf = -1;
        }

        this.formRef.current.setFieldsValue({ amount: suggestedAmount.multiply(multipleOf).toString(), adjustmentType: transType === LoanTransactionType.Adjustment && selectedPaymentIndexes.length > 0 ? LoanTransactionAdjustmentType.Payment : undefined});
    }

    get forPaymentsSelector() {
        // we don't need to show the payment selector when loans don't have a schedule
        if (!this.props.loan || !this.props.schedule || !this.props.loan.hasSchedule) {
            return null;
        }

        let payments: ILoanScheduleItem[] = [];
        if (this.props.schedule && this.props.schedule.payments) {
            payments = this.props.schedule.payments.filter((p) => !p.isFullyPaid);
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transactionType = getFieldValue('type');
                    if (transactionType === LoanTransactionType.DownPayment && this.props.loan?.downPayment) {
                        return null;
                    }

                    const isEnabled = transactionType === LoanTransactionType.RegularPayment || transactionType === LoanTransactionType.LateFee || transactionType === LoanTransactionType.Adjustment;
                    const isRequired = transactionType === LoanTransactionType.RegularPayment || transactionType === LoanTransactionType.LateFee;
                    const rules: Rule[] = [];

                    if (isRequired && this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
                        rules.push({ required: true, message: 'You must select which payments this transaction applies towards.' });
                    }

                    return (
                        <Form.Item name="forPaymentsIndexes" label="Payment For" rules={rules}>
                            <Select<number[]>
                                showSearch
                                mode="multiple"
                                placeholder="Select payment(s)"
                                optionFilterProp="children"
                                filterOption={this.filterOption}
                                disabled={!isEnabled || typeof this.props.taxRecord !== 'undefined' || this.state.isSaving}
                                onChange={this.forPaymentsChange}
                            >
                                {payments.map((p) =>
                                    <Select.Option key={p.paymentIndex} value={p.paymentIndex}>
                                        #{p.paymentNumber}{p.isLate ? '*' : null} (Due: {dayjs(p.dueDate).format('MM/DD/YYYY')})
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion payment for

    setDownPaymentAmount = debounce((value: string) => {
        if (!this.formRef.current || this.state.amountTouched) {
            return;
        }

        this.formRef.current.setFieldsValue({ amount: value });
    }, 300).bind(this);

    //#region down payment selector
    get downPaymentSelector() {
        // we don't need to show the down payment selector when loan doesn't have the down payment configured
        if (!this.props.loan || !this.props.loan.downPayment || this.props.loan.downPayment.paid) {
            return null;
        }

        let firstUnpaidIncrementIndex: number | undefined = undefined;
        let increments: ILoanDownPaymentIncrement[] = [];
        switch (this.props.loan.downPayment.schedule) {
            case LoanDownPaymentSchedule.OneTime:
                increments = [{ amount: this.props.loan.downPayment.amount, dueDate: this.props.loan.downPayment.dueDate! }];
                break;
            case LoanDownPaymentSchedule.Incremental:
                increments = this.props.loan.downPayment.increments!;
                firstUnpaidIncrementIndex = increments.findIndex((i) => !i.transactionId);
                break;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanTransactionType.DownPayment) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="downPaymentIncrement"
                            label="Down Payment Increment"
                            initialValue={firstUnpaidIncrementIndex ? [firstUnpaidIncrementIndex] : []}
                        >
                            <Select<number[]>
                                placeholder="Down payment selector"
                                mode="multiple"
                            >
                                {increments.map((i, index) =>
                                    <Select.Option key={index} value={index} disabled={!!i.transactionId}>
                                        {dayjs(i.dueDate).format('MM/DD/YYYY')} - ${i.amount}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion

    get interestDueInput() {
        if (!this.props.loan || this.props.loan.hasSchedule) {
            return null;
        }

        // we only show the interest due input when the interest schedule accrues daily and the loan doesn't have a schedule.
        if (this.props.loan.terms?.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') && getFieldValue('type') !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="interestDue"
                            label={<SimpleTooltip content="Interest Due" title="Calculated based on the last payment date plus the unpaid interest. Only goes out to 14 decimal places on the UI, so the actual decimal number may differ." hasQuestion />}
                        >
                            <Input prefix="$" disabled />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get amountInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transactionType = getFieldValue('type') as LoanTransactionType;

                    const rules: Rule[] = [
                        { required: true, message: 'Please state the amount for this transaction!' },
                    ];

                    let extra: React.ReactNode | null = null;
                    switch (transactionType) {
                        case LoanTransactionType.RegularPayment:
                            rules.push({
                                validator: async (rule, value: string) => {
                                    const principalBalance = currency(this.props.loan!.balance!.principal, { precision: 2 });
                                    const paying = currency(value, { precision: 2 });

                                    if (paying.intValue >= principalBalance.intValue) {
                                        throw new Error(`The amount is greater than, or equal to, the principal balance. Recording a pay off via this UI is not supported. Current principal balance is: $${ principalBalance.format() }`);
                                    }
                                },
                            });
                            break;
                        case LoanTransactionType.LateFee:
                            extra = "A positive value pays the late fee balance. A negative value adds to the late fee balance.";

                            rules.push({
                                validator: async (rule, value: string) => {
                                    const lateFeesDue = currency(this.props.loan!.balance!.lateFees, { precision: 2 });
                                    const fee = currency(value, { precision: 2 });
                                    if (fee.intValue > 0 && lateFeesDue.intValue <= 0) {
                                        throw new Error('There are currently no late fees due. Did you mean to create a late fee? If so, use negative numbers.');
                                    }

                                    if (fee.intValue > lateFeesDue.intValue) {
                                        throw new Error(`The amount is greater than the late fees due. Current late fee balance is: $${ lateFeesDue.format() }`);
                                    }
                                },
                            });
                            break;
                        case LoanTransactionType.OtherFee:
                            extra = "A positive value subtracts from the other fee balance. A negative value adds to the other fee balance."
                            break;
                        case LoanTransactionType.Adjustment:
                            rules.push({
                                validator: async (rule, value: string) => {
                                    const fee = currency(value, { precision: 2 });
                                    if (fee.intValue >= 0) {
                                        throw new Error('When creating an adjustment, the value must be negative as you are subtracting from whichever balance.');
                                    }
                                },
                            });
                            break;
                        default:
                            rules.push({
                                validator: async (rule, value: string) => {
                                    const fee = currency(value, { precision: 2 });
                                    if (fee.intValue <= 0) {
                                        throw new Error('When creating a transaction, the value must be positive as they are paying down an amount.');
                                    }
                                },
                            });
                            break;
                    }

                    return (
                        <Form.Item name="amount" label="Amount" rules={rules} extra={extra}>
                            <Input
                                prefix="$"
                                style={{ width: '100%' }}
                                onChange={() => {
                                    this.setState({ amountTouched: true });
                                }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get paymentMethod() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.amount !== curr.amount}>
                {({ getFieldValue }) => {
                    let disabled = false;

                    let extra: React.ReactNode | null = null;
                    switch (getFieldValue('type') as LoanTransactionType) {
                        case LoanTransactionType.LateFee:
                            const lateFeeAmt = currency(getFieldValue('amount'), { precision: 2 });
                            disabled = lateFeeAmt.intValue <= 0;

                            extra = disabled ? null : 'Payment method is required when paying a late fee.';
                            break;
                        case LoanTransactionType.OtherFee:
                            const amt = currency(getFieldValue('amount'), { precision: 2 });
                            disabled = amt.intValue <= 0;

                            extra = disabled ? 'Payment method is not required when adding to the other fee balance.' : 'Payment method is required when subtracting/paying the other fee balance.';
                            break;
                        case LoanTransactionType.Adjustment:
                            return null;
                    }

                    return (
                        <Form.Item name="method" label="Payment Method" extra={extra} rules={[{ required: !disabled, message: 'Please state the method of payment!' }]}>
                            <Select<TransactionMethod> disabled={disabled || this.state.isSaving}>
                                <Select.OptGroup label="Banking">
                                    <Select.Option key={TransactionMethod.Card}>Credit/Debit Card</Select.Option>
                                    <Select.Option key={TransactionMethod.Cash}>Cash</Select.Option>
                                    <Select.Option key={TransactionMethod.Check}>Check</Select.Option>
                                    <Select.Option key={TransactionMethod.ACH}>Bank Transfer</Select.Option>
                                    <Select.Option key={TransactionMethod.Wire}>Wire</Select.Option>
                                    <Select.Option key={TransactionMethod.MoneyOrder}>Money Order</Select.Option>
                                    <Select.Option key={TransactionMethod.CashierCheck}>Cashier's Check</Select.Option>
                                    <Select.Option key={TransactionMethod.Zelle}>Zelle</Select.Option>
                                </Select.OptGroup>
                                <Select.OptGroup label="Apps">
                                    <Select.Option key={TransactionMethod.CashApp}>Cash App</Select.Option>
                                    <Select.Option key={TransactionMethod.PayPal}>PayPal</Select.Option>
                                    <Select.Option key={TransactionMethod.Venmo}>Venmo</Select.Option>
                                </Select.OptGroup>
                                <Select.OptGroup label="Other">
                                    <Select.Option key={TransactionMethod.Barter}>Barter</Select.Option>
                                    <Select.Option key={TransactionMethod.Other}>Other</Select.Option>
                                </Select.OptGroup>
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get adjustmentType() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.forPaymentsIndexes !== curr.forPaymentsIndexes}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanTransactionType.Adjustment) {
                        return null;
                    }

                    const forPmts = getFieldValue('forPaymentsIndexes') as number[];

                    return (
                        <Form.Item name="adjustmentType" label="Adjustment Type" rules={[{ required: true, message: 'Please select the type of adjustment!' }]}>
                            <Select<LoanTransactionAdjustmentType> disabled={(Array.isArray(forPmts) && forPmts.length > 0) || this.state.isSaving}>
                                { this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Select.Option key={LoanTransactionAdjustmentType.Interest}>Unpaid Interest Balance</Select.Option> : null }
                                <Select.Option key={LoanTransactionAdjustmentType.Principal}>Principal</Select.Option>
                                <Select.Option key={LoanTransactionAdjustmentType.Payment} disabled>Payment</Select.Option>
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    disabledDate = (current: Dayjs | null): boolean => {
        const { loan } = this.props;
        const { lastPaymentReceivedDate } = this.state;

        if (!current || !loan || !lastPaymentReceivedDate || !lastPaymentReceivedDate.isValid()) {
            return false;
        }

        return current.isBefore(lastPaymentReceivedDate.subtract(1, 'day'));
    }

    renderDate = (current: string | number | Dayjs, info: CellRenderInfo<Dayjs>) => {
        if (typeof current === 'undefined' || current === null) {
            return current;
        }

        if (typeof current === 'number') {
            if (!info.subType) {
                return current;
            }

            switch (info.subType) {
                case 'meridiem':
                    return current === 0 ? 'am' : 'pm';
                case 'hour':
                    return current === 0 ? '12' : current;
                default:
                    return current;
            }
        }

        if (typeof current === 'number' || typeof current === 'string') {
            return current;
        }

        const { getFieldValue } = this.formRef.current!;
        const style: React.CSSProperties = {};

        const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
        const transactionType = getFieldValue('type') as LoanTransactionType;
        if (transactionType === LoanTransactionType.RegularPayment || transactionType === LoanTransactionType.LateFee) {
            if (Array.isArray(forPaymentsIndexes) && forPaymentsIndexes.length !== 0) {
                const payments = this.props.schedule!.payments!.filter((p) => forPaymentsIndexes.includes(p.paymentIndex));

                if (payments.length !== 0) {
                    payments.forEach((p) => {
                        let dueDate = dayjs(p.dueDate);

                        if (dueDate.isSame(current, 'day')) {
                            style.border = '1px solid #52c41a';
                        }

                        this.props.loan?.lateFeeConfig?.tiers.forEach((t) => {
                            const lateFeeDate = dueDate.add(t.days, 'days');
                            if (lateFeeDate.isSame(current, 'day')) {
                                style.border = '1px solid #ff4d4f';
                            }
                        });
                    });
                }
            } else if (this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
                let dueDate = dayjs(this.props.loan?.nextDueDate);

                if (dueDate.isSame(current, 'day')) {
                    style.border = '1px solid #52c41a';
                }

                this.props.loan?.lateFeeConfig?.tiers.forEach((t) => {
                    dueDate = dueDate.add(t.days, 'days');
                    if (dueDate.isSame(current, 'day')) {
                        style.border = '1px solid #ff4d4f';
                    }
                });
            }
        }

        return (
            <div className="ant-calendar-date ant-picker-cell-inner" style={style}>
                {current.date()}
            </div>
        );
    }

    onTransactionDateChange = (date: Dayjs | null) => {
        const { loan } = this.props;
        if (!this.formRef.current || !loan || !loan.terms || !date) {
            return;
        }

        if (loan.terms.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return;
        }

        if (this.formRef.current.getFieldValue('type') !== LoanTransactionType.RegularPayment) {
            return;
        }

        let interestDue = calculateAccruedInterestSinceLastPayment(date, dayjs(loan.lastPaymentReceivedDate), dayjs(loan.firstPaymentDate), loan.terms!, loan.balance!.principal);
        interestDue = interestDue.add(loan.balance?.interest || '0.00');

        this.formRef.current.setFieldValue('interestDue', currency(interestDue, { precision: 2 }).toString());
    }

    get transactionDate() {
        const presets: ValueDate<Dayjs>[] = [];

        if (this.props.loan && !isMobileOnly) {
            presets.push({ label: 'Today', value: dayjs() });
            presets.push({ label: 'Next Due Date', value: dayjs(this.props.loan.nextDueDate) });

            const prevDueDate = dayjs(this.props.loan.previousDueDate);
            if (prevDueDate.get('year') !== 0) {
                presets.push({ label: 'Last Due Date', value: prevDueDate });
            }

            if (this.props.loan.lastPaymentReceivedDate && dayjs(this.props.loan.lastPaymentReceivedDate).get('year') !== 0) {
                presets.push({ label: 'Last Payment Date', value: dayjs(this.props.loan.lastPaymentReceivedDate) });
            }

            if (this.props.loan.downPaymentDate && dayjs(this.props.loan.downPaymentDate).get('year') !== 0) {
                presets.push({ label: 'Down Payment Date', value: dayjs(this.props.loan.downPaymentDate) });
            }

            presets.push({ label: 'Closing Date', value: dayjs(this.props.loan.closingDate) });
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const isInterestAccruesDaily = this.props.loan?.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily;

                    let useDisabled = false;
                    switch (getFieldValue('type')) {
                        case LoanTransactionType.RegularPayment:
                        case LoanTransactionType.PrincipalPayment:
                        case LoanTransactionType.Adjustment:
                            useDisabled = true;
                            break;
                    }

                    return (
                        <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please set the date of this transaction!' }]}>
                            <DatePicker
                                format={ isInterestAccruesDaily ? 'MM/DD/YYYY h:mm a' : 'MM/DD/YYYY' }
                                disabledDate={useDisabled ? this.disabledDate : undefined}
                                cellRender={this.renderDate}
                                onChange={this.onTransactionDateChange}
                                presets={presets.length > 0 ? presets : undefined}
                                showNow={false}
                                showTime={isInterestAccruesDaily}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get lateFeeWaiveSwitch() {
        if (typeof this.props.taxRecord !== 'undefined') {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={this.shouldWeUpdateLateWarning}>
                {({ getFieldValue }) => {
                    switch (getFieldValue('type') as LoanTransactionType) {
                        case LoanTransactionType.OtherFee:
                        case LoanTransactionType.LateFee:
                        case LoanTransactionType.Adjustment:
                            return null;
                    }

                    const isLate = this.isLate();

                    return (
                        <Col offset={1} span={12}>
                            <Form.Item name="lateFeeWaived" label="Waive Late Fee" valuePropName="checked" rules={[{ required: isLate }]}>
                                <Switch
                                    disabled={!isLate || this.state.isSaving}
                                />
                            </Form.Item>
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    onLateFeeTierChange = () => {
        if (!this.formRef.current || !this.props.loan || !this.props.loan.lateFeeConfig) {
            return;
        }

        const { getFieldValue } = this.formRef.current;
        const tier = getFieldValue('lateFeeTier') as number;
        const tierConfig = this.props.loan.lateFeeConfig.tiers[tier - 1];

        if (!tierConfig || !tierConfig.chargeType || tierConfig.chargeType !== LateFeeChargeType.Fixed) {
            return;
        }

        this.formRef.current.setFieldsValue({ amount: currency(tierConfig.fixed || '0', { precision: 2 }).multiply(-1).toString() });
    };

    get lateFeeTierSelect() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;

                    if (transType !== LoanTransactionType.LateFee || !this.props.loan || !this.props.loan.lateFeeConfig) {
                        return null;
                    }

                    return (
                        <Col offset={1} span={12}>
                            <Form.Item
                                name="lateFeeTier"
                                label={<SimpleTooltip content="Late Fee Tier" title="Which late fee tier is this?" hasQuestion />}
                                rules={[{ required: true, message: 'Please the late fee you are applying.' }]}
                            >
                                <Select<ILateFeeTier> onChange={this.onLateFeeTierChange}>
                                    {this.props.loan.lateFeeConfig.tiers.map((t, i) =>
                                        <Select.Option key={i+1} value={i+1}><LateFeeTierString tier={t} /></Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get extraApplicationSelect() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;

                    if (transType !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    return (
                        <Col offset={1} span={12}>
                            <Form.Item
                                name="extraApplication"
                                label={<SimpleTooltip content="Extra Application" title="How should any amount over the regular payment be applied?" hasQuestion />}
                                rules={[{ required: true, message: 'Please select how the extra should be applied.' }]}
                            >
                                <Select<LoanTransactionExtraApplication>>
                                    <Select.Option key={LoanTransactionExtraApplication.NextPayment}>Next Payment</Select.Option>
                                    <Select.Option key={LoanTransactionExtraApplication.Principal}>Principal</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get statusSelect() {
        if (!this.props.loan) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;
                    switch (transType) {
                        case LoanTransactionType.RegularPayment:
                        case LoanTransactionType.PrincipalPayment:
                        case LoanTransactionType.DownPayment:
                            break;
                        default:
                            return null;
                    }

                    return (
                        <Col span={transType === LoanTransactionType.PrincipalPayment || transType === LoanTransactionType.DownPayment ? 24 : 11}>
                            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please state the status of the payment!' }]}>
                                <Select>
                                    <Select.Option key={TransactionStatus.Pending}>Pending</Select.Option>
                                    <Select.Option key={TransactionStatus.Success}>Success</Select.Option>
                                    <Select.Option key={TransactionStatus.Failure}>Failure</Select.Option>
                                    <Select.Option key={TransactionStatus.Reversed}>Reversed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    isLate = (): boolean => {
        if (!this.props.loan || !this.props.schedule || !this.props.schedule.payments || !this.props.loan.lateFeeConfig || !this.formRef.current) {
            return false;
        }

        const { getFieldValue } = this.formRef.current;

        // we only show a warning when the payment type is regular
        if (getFieldValue('type') !== LoanTransactionType.RegularPayment) {
            return false;
        }

        // and only if they've selected the expected payment it is for
        const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
        if (!Array.isArray(forPaymentsIndexes) || forPaymentsIndexes.length === 0) {
            return false;
        }

        const transDate = getFieldValue('date') as Dayjs;
        if (!transDate) {
            return false;
        }

        const payments = this.props.schedule.payments.filter((p) => forPaymentsIndexes.includes(p.paymentIndex));
        if (payments.length === 0) {
            return false;
        }

        return payments.some((p) => {
            if (p.isLate) {
                return true;
            }

            return this.props.loan!.lateFeeConfig!.tiers.some((tier) => {
                const dueDate = dayjs(p.dueDate).add(tier.days, 'days');

                return transDate.isAfter(dueDate);
            });
        });
    }

    shouldWeUpdateLateWarning = (prev: IFormValues, curr: IFormValues): boolean => {
        if (prev.forPaymentsIndexes.length !== curr.forPaymentsIndexes.length) {
            return true;
        }

        if (!prev.date || !prev.date.isSame(curr.date)) {
            return true;
        }

        if (prev.type !== curr.type) {
            return true;
        }

        return false;
    }

    get lateWarning() {
        return (
            <Form.Item noStyle shouldUpdate={this.shouldWeUpdateLateWarning}>
                {({ getFieldValue }) => {
                    const forPaymentsIndexes = getFieldValue('forPaymentsIndexes') as number[];
                    if (!this.isLate() || !forPaymentsIndexes || !Array.isArray(forPaymentsIndexes)) {
                        return null;
                    }

                    let message = 'Transaction date is past the due date (including the grace period).';
                    if (forPaymentsIndexes.length > 1) {
                        message = 'Transaction date is past the due date (including the grace period) for one or more of the selected payments.';
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={message}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get lateFeeBalanceWarning() {
        if (!this.props.loan || !this.props.loan.balance || this.props.loan.balance.lateFees === "0") {
            return null;
        }

        const lateFeesDue = currency(this.props.loan.balance.lateFees, { precision: 2 });

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.lateFeeWaived !== curr.lateFeeWaived || prev.forPaymentsIndexes !== curr.forPaymentsIndexes}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;
                    if (transType !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    if (getFieldValue('lateFeeWaived')) {
                        const forPayments = getFieldValue('forPaymentsIndexes') as number[];

                        return (
                            <Col span={24} style={{ marginBottom: '15px' }}>
                                <Alert
                                    showIcon
                                    type="info"
                                    message={`Any late fees associated with payment${ forPayments.length > 1 ? 's' : ''} ${ forPayments.map((v) => v+1).join(', ')} will be waived.`}
                                />
                            </Col>
                        );
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={`There is currently a late fee balance of ${lateFeesDue.format(true)} which will be paid first.`}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get unpaidInterestWarning() {
        if (!this.props.loan || !this.props.loan?.balance?.interest || this.props.loan.balance.interest === "0") {
            return null;
        }

        const unpaidInterestDue = currency(this.props.loan.balance.interest, { precision: 2 });

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;
                    if (transType !== LoanTransactionType.RegularPayment) {
                        return null;
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={`There is currently an unpaid interest balance of ${unpaidInterestDue.format(true)}, which will be paid after any late fees and before any recently accrued interest.`}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    get downPaymentAmountWarning() {
        if (!this.props.loan || !this.props.loan.downPayment || this.props.loan.downPayment.paid) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.amount !== curr.amount || prev.downPaymentIncrement !== curr.downPaymentIncrement}>
                {({ getFieldValue }) => {
                    const transType = getFieldValue('type') as LoanTransactionType;
                    if (transType !== LoanTransactionType.DownPayment || !this.props.loan?.downPayment) {
                        return null;
                    }

                    const amountPaying = currency(getFieldValue('amount'), { precision: 2 });
                    const increments = getFieldValue('downPaymentIncrement') as number[];
                    if (!Array.isArray(increments) || increments.length === 0) {
                        if (amountPaying.intValue === 0) {
                            return null;
                        }

                        return (
                            <Col span={24} style={{ marginBottom: '15px' }}>
                                <Alert
                                    showIcon
                                    type="info"
                                    message={`Please note, an online down payment is NOT selected. The down payment amount of ${ amountPaying.format(true) } will NOT be applied towards the amount expected to be collected online.`}
                                />
                            </Col>
                        );
                    }

                    let amountExpected = currency(this.props.loan.downPayment.amount || 0, { precision: 2 });
                    if (this.props.loan.downPayment.schedule === LoanDownPaymentSchedule.Incremental) {
                        amountExpected = increments.reduce((acc: currency, index: number) => {
                            const increment = this.props.loan!.downPayment!.increments![index];
                            if (!increment || increment.transactionId) {
                                return acc;
                            }

                            return acc.add(increment.amount);
                        }, currency(0, { precision: 2 }));
                    }

                    if (amountExpected.intValue <= amountPaying.intValue) {
                        return null;
                    }

                    return (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                showIcon
                                type="warning"
                                message={`The down payment expected amount is ${amountExpected.format(true)} which is greater than the amount paying (${ amountPaying.format(true) }), this will not mark the down payment as paid.`}
                            />
                        </Col>
                    );
                }}
            </Form.Item>
        );
    }

    //#region comment input
    get commentInput() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: IFormValues, curr: IFormValues) => prev.type !== curr.type || prev.method !== curr.method}>
                {({ getFieldValue }) => {
                    const rules: Rule[] = [];

                    switch (getFieldValue('type') as LoanTransactionType) {
                        case LoanTransactionType.OtherFee:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the transaction type is Other Fee, you must provide a comment on what or why.');
                                    }
                                },
                            });
                            break;
                        case LoanTransactionType.LateFee:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the transaction type is Late Fee, you must provide a comment.');
                                    }
                                },
                            });
                            break;
                        case LoanTransactionType.Adjustment:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the transaction type is Adjustment, you must provide a reason for the adjustment.');
                                    }
                                },
                            });
                            break;
                    }

                    switch (getFieldValue('method') as TransactionMethod) {
                        case TransactionMethod.Barter:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the payment method is a Barter, you must provide a comment with what was bartered.');
                                    }
                                },
                            });
                            break;
                        case TransactionMethod.Check:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the payment method is a Check, you must provide a comment with the check number.');
                                    }
                                },
                            });
                            break;
                        case TransactionMethod.CashierCheck:
                                rules.push({
                                    required: true,
                                    validator: async (rule, value: EditorState) => {
                                        if (!value || value.isEmpty()) {
                                            throw new Error('When the payment method is a Cashier\'s Check, you must provide a comment with the check number.');
                                        }
                                    },
                                });
                                break;
                        case TransactionMethod.MoneyOrder:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the payment method is a Money Order, you must provide a comment with the money order number.');
                                    }
                                },
                            });
                            break;
                        case TransactionMethod.Other:
                            rules.push({
                                required: true,
                                validator: async (rule, value: EditorState) => {
                                    if (!value || value.isEmpty()) {
                                        throw new Error('When the payment method is Other, you must provide a comment explaining the reason for selecting other.');
                                    }
                                },
                            });
                            break;
                    }

                    return (
                        <Suspense fallback={<Skeleton.Input block active />}>
                            <BraftEditorInput
                                name="comment"
                                label="Comment"
                                className="last-form-item"
                                height={75}
                                validateTrigger="onBlur"
                                rules={rules}
                            />
                        </Suspense>
                    );
                }}
            </Form.Item>
        );
    }
    //#endregion

    render() {
        const { isVisible, loan } = this.props;

        if (!loan) {
            return null;
        }

        let defaultTransactionDate = dayjs();
        if (this.state.lastPaymentReceivedDate && this.state.lastPaymentReceivedDate.isValid() && this.state.lastPaymentReceivedDate.year() !== 0 && defaultTransactionDate.isBefore(this.state.lastPaymentReceivedDate)) {
            defaultTransactionDate = dayjs(this.state.lastPaymentReceivedDate).add(1, 'day');
        }

        let interestDue = currency('0');
        if (loan.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily) {
            interestDue = calculateAccruedInterestSinceLastPayment(defaultTransactionDate, dayjs(loan.lastPaymentReceivedDate), dayjs(loan.firstPaymentDate), loan.terms!, loan.balance!.principal);
            interestDue = interestDue.add(loan.balance?.interest || '0.00');
        }

        let width = '600px';
        if (isMobileOnly) {
            width = '100vw';
        }

        return (
            <Modal
                open={isVisible}
                title="Add a Transaction"
                okText="Save"
                onCancel={this.onClose}
                onOk={this.handleSave}
                okButtonProps={{ disabled: this.state.isSaving, loading: this.state.isSaving }}
                cancelButtonProps={{ disabled: this.state.isSaving }}
                maskClosable={false}
                closable={!this.state.isSaving}
                width={width}
            >
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    disabled={this.state.isSaving}
                    initialValues={{
                        type: this.props.type || undefined,
                        forPaymentsIndexes: [],
                        date: defaultTransactionDate,
                        lateFeeWaived: false,
                        extraApplication: loan.terms?.extraApplication,
                        interestDue: currency(interestDue, { precision: 2 }).toString(),
                    }}
                >
                    <Row>
                        <Col span={11}>{this.transactionType}</Col>
                        <Col offset={1} span={12}>{this.forPaymentsSelector}{this.interestDueInput}{this.downPaymentSelector}</Col>
                    </Row>
                    <Row>
                        <Col span={11}>{this.amountInput}</Col>
                        <Col offset={1} span={12}>{this.paymentMethod}{this.adjustmentType}</Col>
                    </Row>
                    <Row>
                        <Col span={11}>{this.transactionDate}</Col>
                        {this.lateFeeWaiveSwitch}
                        {this.lateFeeTierSelect}
                        {this.statusSelect}
                        {this.extraApplicationSelect}
                        {this.lateWarning}
                        {this.lateFeeBalanceWarning}
                        {this.unpaidInterestWarning}
                        {this.downPaymentAmountWarning}
                    </Row>
                    <Row>
                        <Col span={24}>{this.commentInput}</Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
