import React, { useState } from 'react';
import currency from 'currency.js';
import { Alert, Checkbox, Divider, Form, FormInstance, Input, InputNumber, Select, Space, Switch } from 'antd';

import { states } from 'models/common/postalAddress';

import { INewInventoryFormValues } from 'views/inventory/new/newInventoryFormValues';
import { InventoryCategory, InventoryType, ResidentialDetailsLotAreaUnit } from 'models/inventory';

interface INewInventoryStepTwoProps {
    form: FormInstance;
    disabled?: boolean;
    editForm?: boolean;
    isExisting?: boolean;
}

export const InventoryDetailsFormStepTwo: React.FC<INewInventoryStepTwoProps> = (props) => {
    const [editTotal, setEditTotal] = useState(true);

    const totalCostToggle = (
        <Checkbox checked={editTotal} disabled={props.disabled} onChange={(e) => setEditTotal(e.target.checked)} />
    );

    const perAcreCostToggle = (
        <Checkbox checked={!editTotal} disabled={props.disabled} onChange={(e) => setEditTotal(!e.target.checked)} />
    );

    const onCostOrAcreChange = () => {
        const acres = props.form.getFieldValue('totalAcres');
        if (!acres) {
            return;
        }

        if (editTotal) {
            const totalCost = props.form.getFieldValue('totalCostOfDevelopment');
            if (!totalCost) {
                return;
            }

            const perAcreCost = currency(totalCost, { precision: 2 }).divide(acres);

            props.form.setFieldsValue({ perAcreCostOfDevelopment: perAcreCost.value });

            return;
        }

        const perAcreCost = props.form.getFieldValue('perAcreCostOfDevelopment');
        if (!perAcreCost) {
            return;
        }

        const totalCost = currency(perAcreCost, { precision: 2 }).multiply(acres);

        props.form.setFieldsValue({ totalCostOfDevelopment: totalCost.value });
    };

    const getCountyInput = () => (
        <Form.Item name="county" label="County" extra="The county where this is located." rules={[{ required: true, message: 'Please provide county where this item is located.' }]}>
            <Input placeholder="County" disabled={props.disabled} />
        </Form.Item>
    );

    const getStateInput = () => (
        <Form.Item name="state" label="State" extra="The state where this is located." rules={[{ required: true, message: 'Please provide state where this item is located.' }]}>
            <Select<string> showSearch placeholder="State" disabled={props.disabled}>
                {states.map((state) => (
                    <Select.Option key={state.abbreviation} value={state.abbreviation}>{state.name}</Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    const getParcelNumbersInput = () => (
        <Form.Item name="parcelNumbers" label="Parcel Numbers" extra="The parcel number(s) where this inventory is located. Input the parcel and then hit enter.">
            <Select
                placeholder="Enter parcel number"
                mode="tags"
                style={{ width: '100%' }}
                disabled={props.disabled}
            />
        </Form.Item>
    );

    const landFields = (
        <React.Fragment>
            {/* The name can only be changed from the "Rename" modal as it has side effects. Thus, we show this field only when creating a new land inventory. */}
            { !props.editForm ?
                <React.Fragment>
                    <Alert
                        showIcon
                        type="info"
                        message="Tracts"
                        description="Tracts are individual parcels of land that are part of the land development. You will add tracts after creating the land inventory!"
                        style={{ marginBottom: '25px' }}
                    />

                    <Form.Item name="name" label="Name" extra="Name is visible to the client." rules={[{ required: true, message: 'Please provide a name.' }]}>
                        <Input placeholder="Enter name here" />
                    </Form.Item>
                </React.Fragment>
            : null}

            { getCountyInput() }

            { getStateInput() }

            { getParcelNumbersInput() }

            <Form.Item name="totalAcres" label="Total Acres" extra="The total amount of acres, used in reports and calculating the development cost per acre." rules={[{ required: true, message: 'Please input the total number of acres for the land.' }]}>
                <InputNumber
                    min={0}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={onCostOrAcreChange}
                    disabled={props.disabled}
                />
            </Form.Item>

            <Divider orientation="left">Cost of Development</Divider>

            <Form.Item name="totalCostOfDevelopment" label="Total Cost" extra="The total cost of the development; sum of everything." rules={[{ required: editTotal, message: 'Please provide the total cost of development, you can update this later.' }]}>
                <InputNumber addonBefore={totalCostToggle} onChange={onCostOrAcreChange} disabled={!editTotal || !props.form.getFieldValue('totalAcres') || props.disabled} />
            </Form.Item>

            <Form.Item name="perAcreCostOfDevelopment" label="Per Acre Cost" extra="How much it cost for the development for each acre." rules={[{ required: !editTotal, message: 'Please provide the per acre cost of development, you can update this later.' }]}>
                <InputNumber addonBefore={perAcreCostToggle} onChange={onCostOrAcreChange} disabled={editTotal || !props.form.getFieldValue('totalAcres') || props.disabled} />
            </Form.Item>

            { props.isExisting ?
                <Form.Item name="updateTracts" label="Update Tracts?" extra="Should the development cost of each tract be updated? Overwrites the current entered value by setting the individual tract to zero.">
                    <Switch unCheckedChildren="No" checkedChildren="Yes" disabled={props.disabled} />
                </Form.Item>
            : null }
        </React.Fragment>
    );

    const onResidentialAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event || !event.target) {
            return;
        }

        props.form.setFieldValue('name', event.target.value);
    };

    const residentialFields = (
        <React.Fragment>
            <Form.Item
                name="address1"
                label="Street Address"
                extra="The street address of the property, it must be a valid address"
                rules={[{ required: true, message: 'The address is required.' }]}
            >
                <Input placeholder="Address Line 1" disabled={props.disabled} onChange={onResidentialAddress1Change} />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prev: INewInventoryFormValues, curr: INewInventoryFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== InventoryType.Rental) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="address2"
                            label="Street Address 2"
                            extra="The unit portion of the address. For example, if it is an apartment and the unit number is 302."
                        >
                            <Input placeholder="Address Line 2" disabled={props.disabled} />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item name="city" label="City" extra="The city where this residential inventory is located." rules={[{ required: true, message: 'The city is required.' }]}>
                <Input placeholder="City" disabled={props.disabled} />
            </Form.Item>

            { getCountyInput() }

            { getStateInput() }

            <Form.Item name="zip" label="Zip Code" extra="The 5 digit zip code of the address." rules={[{ required: true, message: 'The zip code is required.' }]}>
                <Input placeholder="31344" disabled={props.disabled} />
            </Form.Item>

            { getParcelNumbersInput() }

            <Divider orientation="left">Property Details</Divider>

            <Form.Item name="bedrooms" label="Bedrooms" extra="The number of bedrooms.">
                <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    disabled={props.disabled}
                />
            </Form.Item>

            <Form.Item name="bathrooms" label="Bathrooms" extra="The number of bathrooms.">
                <InputNumber
                    min={0}
                    step={0.5}
                    style={{ width: '100%' }}
                    disabled={props.disabled}
                />
            </Form.Item>

            <Form.Item name="livingSquareFootage" label="Square Footage" extra="The living area square footage.">
                <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    disabled={props.disabled}
                />
            </Form.Item>

            <Form.Item label="Lot Area" extra="The amount of acres or square footage the lot has.">
                <Space.Compact block>
                    <Form.Item noStyle name="lotArea">
                        <InputNumber
                            min={0}
                            style={{ width: '60%' }}
                            disabled={props.disabled}
                        />
                    </Form.Item>

                    <Form.Item noStyle name="lotAreaUnit">
                        <Select<ResidentialDetailsLotAreaUnit> style={{ width: '40%' }} disabled={props.disabled}>
                            <Select.Option value={ResidentialDetailsLotAreaUnit.Acres}>Acres</Select.Option>
                            <Select.Option value={ResidentialDetailsLotAreaUnit.SquareFeet}>Square Feet</Select.Option>
                        </Select>
                    </Form.Item>
                </Space.Compact>
            </Form.Item>

            <Divider orientation="left">Pricing Details</Divider>

            <Form.Item name="residentialPrice" label="Sales Price" extra="The sales price of this residential property." rules={[{ required: editTotal, message: 'Please provide the sales price of the property, you can update this later.' }]}>
                <InputNumber
                    prefix="$"
                    disabled={props.disabled}
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prev: INewInventoryFormValues, curr: INewInventoryFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== InventoryType.Rental) {
                        return null;
                    }

                    return (
                        <Form.Item name="rentAmount" label="Rent Amount" extra="How much is the monthly rent amount?" rules={[{ required: true, message: 'Please provide the monthly rent amount.' }]}>
                            <InputNumber
                                prefix="$"
                                disabled={props.disabled}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        </React.Fragment>
    );

    const multifamilyFields = (
        <React.Fragment>
            { !props.editForm ?
                <Form.Item name="name" label="Name" extra="This value is the name of the multifamily complex. This value is visible to the client." rules={[{ required: true, message: 'Please provide a name.' }]}>
                    <Input placeholder="Enter name here" />
                </Form.Item>
            : null }

            <Form.Item name="address1" label="Street Address" extra="The street address of the property, it must be a valid address. This is generally the office location or the main entrance." rules={[{ required: true, message: 'The address is required.' }]}>
                <Input placeholder="Address Line 1" disabled={props.disabled} />
            </Form.Item>

            <Form.Item name="address2" label="Street Address 2" extra="The unit portion of the address. For example, if the office has a unit address, then you can put that here.">
                <Input placeholder="Address Line 2" disabled={props.disabled} />
            </Form.Item>

            <Form.Item name="city" label="City" extra="The city where this multifamily inventory is located." rules={[{ required: true, message: 'The city is required.' }]}>
                <Input placeholder="City" disabled={props.disabled} />
            </Form.Item>

            { getCountyInput() }

            { getStateInput() }

            <Form.Item name="zip" label="Zip Code" extra="The 5 digit zip code of the address." rules={[{ required: true, message: 'The zip code is required.' }]}>
                <Input placeholder="31344" disabled={props.disabled} />
            </Form.Item>

            <Divider orientation="left">Property Details</Divider>

            { !props.editForm ?
                <Alert
                    showIcon
                    type="info"
                    message="Units"
                    description="Units are individual apartments or condos that are part of the multifamily property. You will add units after creating the multifamily inventory!"
                    style={{ marginBottom: '25px' }}
                />
            : null }

            <Form.Item name="units" label="Total Units" extra="The total number of units in the multifamily property." rules={[{ required: true, message: 'Please provide the total number of units at this complex.' }]}>
                <InputNumber
                    min={1}
                    precision={0}
                    inputMode="numeric"
                    style={{ width: '100%' }}
                    disabled={props.disabled}
                />
            </Form.Item>

            <Form.Item name="yearBuilt" label="Year Built" extra="The year the property was built." rules={[{ required: true, message: 'The year built is required.' }]}>
                <InputNumber
                    min={0}
                    precision={0}
                    inputMode="numeric"
                    style={{ width: '100%' }}
                    disabled={props.disabled}
                />
            </Form.Item>
        </React.Fragment>
    );

    return (
        <Form.Item noStyle shouldUpdate={(prev: INewInventoryFormValues, curr: INewInventoryFormValues) => prev.category !== curr.category || prev.livingSquareFootage !== curr.livingSquareFootage}>
            {({ getFieldValue }) => {
                const category: InventoryCategory = getFieldValue('category');

                switch(category) {
                    case InventoryCategory.Land:
                        return landFields;
                    case InventoryCategory.Residential:
                        return residentialFields;
                    case InventoryCategory.Multifamily:
                        return multifamilyFields;
                }
            }}
        </Form.Item>
    );
}
