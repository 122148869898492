import React from 'react';
import { NavLink } from 'react-router-dom';
import { AuditOutlined, CloudDownloadOutlined, CommentOutlined, CreditCardOutlined, DashboardOutlined, ExclamationCircleTwoTone, FileMarkdownOutlined, FilePdfOutlined, FileProtectOutlined, FileZipOutlined, HomeOutlined, InfoCircleOutlined, LockOutlined, PieChartOutlined, SettingOutlined, SnippetsOutlined, TeamOutlined, WalletOutlined } from '@ant-design/icons';

import { MenuDataItem } from '@ant-design/pro-layout';
import { Badge } from 'antd';

export function menuDataRender(orgId: string): () => MenuDataItem[] {
    return (): MenuDataItem[] => {
        return [
            {
                path: `/${orgId}/dashboard`,
                name: 'Dashboard',
                icon: <DashboardOutlined />,
            },
            {
                path: `/${orgId}/communications`,
                name: 'Communication',
                icon: <CommentOutlined />,
            },
            {
                path: `/${orgId}/inventories`,
                name: 'Inventory',
                icon: <SnippetsOutlined />,
            },
            {
                path: `/${orgId}/clients`,
                name: 'Clients',
                icon: <TeamOutlined />,
            },
            {
                path: `/${orgId}/loans`,
                name: 'Loans',
                icon: <AuditOutlined />,
            },
            {
                path: `/${orgId}/rentals`,
                name: 'Rentals',
                icon: <HomeOutlined />,
            },
            {
                path: `/${orgId}/documents`,
                name: 'Document Signing',
                icon: <FileProtectOutlined />,
            },
            {
                path: `/${orgId}/reports`,
                name: 'Reports',
                icon: <PieChartOutlined />,
            },
            {
                path: `/${orgId}/files`,
                name: 'Files',
                icon: <FileZipOutlined />,
            },
            {
                path: `/${orgId}/payarc`,
                name: 'PayArc',
                icon: <CreditCardOutlined />,
                children: [
                    {
                        path: `/${orgId}/payarc/merchant`,
                        name: 'Merchant Info',
                    },
                    {
                        path: `/${orgId}/payarc/transactions`,
                        name: 'Transactions',
                    },
                    {
                        path: `/${orgId}/payarc/deposits`,
                        name: 'Deposits',
                    },
                ],
            },
            {
                path: `/${orgId}/settings`,
                name: 'Org Settings',
                icon: <SettingOutlined />,
                children: [
                    {
                        path: `/${orgId}/settings/basic`,
                        name: 'Basic',
                        icon: <InfoCircleOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/billing`,
                        name: 'Billing',
                        icon: <WalletOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/roles`,
                        name: 'Roles',
                        icon: <LockOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/members`,
                        name: 'Members',
                        icon: <TeamOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/invoices`,
                        name: 'Invoices',
                        icon: <FilePdfOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/filleable-pdfs`,
                        name: 'Fillable PDFs',
                        icon: <FileMarkdownOutlined />,
                    },
                    {
                        path: `/${orgId}/settings/export`,
                        name: 'Backups/Exports',
                        icon: <CloudDownloadOutlined />,
                    },
                ],
            },
        ]
    }
}

export function menuItemRender(item: MenuDataItem, defaultDom: React.ReactNode): React.ReactNode {
    if (item.new) {
        return (
            <Badge count={<ExclamationCircleTwoTone twoToneColor="#52c41a" />}>
                <NavLink to={item.path!}>
                    {defaultDom}
                </NavLink>
            </Badge>
        )
    }

    return (
        <NavLink to={item.path!}>
            {defaultDom}
        </NavLink>
    );
}
