import { GlobalRentalState, RentalAction, RentalActionType, RentalsById } from "store/types/rentals";
import { displayErrorNotification } from "utils/errors";

const initialAuthState: GlobalRentalState = {
    isFetching: false,
    hasError: false,
    rentalsById: {},
};

export function rentalReducer(state = initialAuthState, action: RentalAction): GlobalRentalState {
    switch (action.type) {
        case RentalActionType.FETCHING_ONE:
        case RentalActionType.FETCHING_ONE_PENDING:
        case RentalActionType.FETCHING_RELATED_PENDING:
            return { ...state, isFetching: true, hasError: false };
        case RentalActionType.FETCHING_ONE_FAILURE:
        case RentalActionType.FETCHING_RELATED_FAILURE:
            displayErrorNotification(action.error);
            return { ...state, isFetching: false, hasError: true, error: action.error };
        case RentalActionType.PURGE:
            return { ...state, isFetching: false, hasError: false, rentalsById: {} };
        case RentalActionType.FETCHING_ONE_SUCCESS:
        case RentalActionType.EVENT_CREATED:
        case RentalActionType.EVENT_UPDATED:
            return {
                ...state,
                rentalsById: { ...state.rentalsById, [action.rental.id]: action.rental },
            };
        case RentalActionType.EVENT_DELETED:
            if (!action.lookup.id) {
                return state;
            }

            const rentalsById = { ...state.rentalsById };
            delete rentalsById[action.lookup.id];

            return {
                ...state,
                rentalsById,
            };
        case RentalActionType.FETCHING_RELATED_SUCCESS:
            return { ...state, isFetching: false, rentalsById: { ...state.rentalsById, ...action.rentals.reduce((v: RentalsById, p) => { v[p.id] = p; return v; }, {}) } };
        default:
            return state;
    }
}
