import { Action } from 'redux';
import type { IRental } from 'models/rental';
import type { IRestError } from 'models/common/restResult';
import type { ILookup } from 'models/common/lookup';

export type RentalsById = { [rentalId: string]: IRental };

export type GlobalRentalState = {
    isFetching: boolean;
    hasError: boolean;
    error?: IRestError;
    rentalsById: RentalsById;
};

export enum RentalActionType {
    PURGE = 'rentals/purge',
    EVENT_CREATED = 'rentals/event/created',
    EVENT_UPDATED = 'rentals/event/updated',
    EVENT_DELETED = 'rentals/event/deleted',
    FETCHING_ONE = 'rental/fetch',
    FETCHING_ONE_PENDING = 'rental/fetch/pending',
    FETCHING_ONE_SUCCESS = 'rental/fetch/success',
    FETCHING_ONE_FAILURE = 'rental/fetch/failure',
    FETCHING_RELATED_PENDING = 'rentals/fetch-related/pending',
    FETCHING_RELATED_SUCCESS = 'rentals/fetch-related/success',
    FETCHING_RELATED_FAILURE = 'rentals/fetch-related/failure',
}

export interface RentalsPurgeAction extends Action {
    type: RentalActionType.PURGE;
}

export interface RentalFetchAction extends Action {
    type: RentalActionType.FETCHING_ONE;
}

export interface RentalFetchPendingAction extends Action {
    type: RentalActionType.FETCHING_ONE_PENDING;
}

export interface RentalFetchSuccessAction extends Action {
    type: RentalActionType.FETCHING_ONE_SUCCESS;
    rental: IRental;
}

export interface RentalFetchFailureAction extends Action {
    type: RentalActionType.FETCHING_ONE_FAILURE;
    error?: IRestError;
}

export interface RentalCreatedEventReceivedAction extends Action {
    type: RentalActionType.EVENT_CREATED;
    rental: IRental;
}

export interface RentalUpdatedEventReceivedAction extends Action {
    type: RentalActionType.EVENT_UPDATED;
    rental: IRental;
}

export interface RentalDeletedEventReceivedAction extends Action {
    type: RentalActionType.EVENT_DELETED;
    lookup: Partial<ILookup>;
}

export interface RentalsFetchRelatedPendingAction extends Action {
    type: RentalActionType.FETCHING_RELATED_PENDING;
}

export interface RentalsFetchRelatedSuccessAction extends Action {
    type: RentalActionType.FETCHING_RELATED_SUCCESS;
    rentals: IRental[];
}

export interface RentalsFetchRelatedFailureAction extends Action {
    type: RentalActionType.FETCHING_RELATED_FAILURE;
    error?: IRestError;
}

export type RentalRelatedAction = RentalsFetchRelatedPendingAction | RentalsFetchRelatedSuccessAction | RentalsFetchRelatedFailureAction;
export type RentalEventAction = RentalCreatedEventReceivedAction | RentalUpdatedEventReceivedAction | RentalDeletedEventReceivedAction;
export type RentalAction = RentalFetchAction | RentalFetchPendingAction | RentalFetchSuccessAction | RentalFetchFailureAction | RentalsPurgeAction | RentalEventAction | RentalRelatedAction;
