import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Card, Descriptions } from 'antd';

import { IClient } from 'models/client';
import { IEntity } from 'models/entity';
import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { displayErrorNotification } from 'utils/errors';

import { EntityAddress } from './address';
import { EntityPhoneNumber } from './phoneNumber';
import { getClientByID, getClientEntity } from 'api/clients';

interface IEntityDescriptionCardProps {
    title: React.ReactNode;
    emptyContent: React.ReactNode;
    extraContent?: React.ReactNode;
    clientId?: string;
    vertical?: boolean;
}

export const EntityDescriptionCard: React.FC<IEntityDescriptionCardProps> = (props) => {
    const params = useParams<IOrgIdPathParams>();
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState<IClient | undefined>();
    const [primaryEntity, setPrimaryEntity] = useState<IEntity | undefined>();

    useEffect(() => {
        if (!props.clientId) {
            setLoading(false);
            setClient(undefined);
            setPrimaryEntity(undefined);
            return;
        }

        setLoading(true);
        getClientByID(params.orgId, props.clientId)
            .then((client) => {
                setClient(client);
                return getClientEntity(params.orgId, client.id, client.primaryEntity.id);
            })
            .then((entity) => {
                setPrimaryEntity(entity);
                setLoading(false);
            })
            .catch((err) => displayErrorNotification(err));
    }, [params.orgId, props.clientId]);

    const address = useMemo(() => {
        if (!props.clientId || !client || !primaryEntity) {
            return null;
        }

        return (
            <Descriptions.Item label="Address"><EntityAddress entity={primaryEntity} showTags={false} /></Descriptions.Item>
        );
    }, [client, primaryEntity, props.clientId]);

    return (
        <Card title={props.title} bordered={false} loading={loading} extra={props.extraContent}>
            <Descriptions size="small" column={props.vertical ? 2 : 3} layout={isMobileOnly || props.vertical ? 'vertical' : 'horizontal'} colon={false}>
                <Descriptions.Item label={props.clientId && client ? 'Name' : ''} span={props.clientId && client ? undefined : 3}>
                    {props.clientId && client
                        ? <Link to={`/${params.orgId}/clients/${client.id}`}>{client.displayName}</Link>
                        : props.emptyContent
                    }
                </Descriptions.Item>
                { props.vertical ? null : address }
                {props.clientId && client && primaryEntity ? <Descriptions.Item label="Phone Number"><EntityPhoneNumber entity={primaryEntity} /></Descriptions.Item> : null}
                { props.vertical ? address : null }
            </Descriptions>
        </Card>
    );
}
