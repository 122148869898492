import type { Dayjs } from 'dayjs';

import { get } from './index';

import { IRestRequest } from 'models/common/restRequest';
import { IRestResult } from 'models/common/restResult';
import { IExpectedLoanPaymentsInfo, IRecentTransaction, IUsageInfo, IUpcomingLoanPayment, ILateLoanInfo, ITotalActiveLoanBalances } from 'models/dashboard';

export const getRecentTransactions = (orgId: string, offset: number) => get<IRestResult<IRecentTransaction>>(`/organizations/${ orgId }/dashboard/loans/transactions?offset=${ offset }`, true);
export const getUpcomingPayments = (orgId: string) => get<IUpcomingLoanPayment[]>(`/organizations/${ orgId }/dashboard/loans/upcoming-payments`, true);
export const getExpectedPayments = (orgId: string, time: Dayjs) => get<IExpectedLoanPaymentsInfo>(`/organizations/${ orgId }/dashboard/loans/expected-payments?year=${ time.get('year') }&month=${ time.get('month')+1 }`, true);
export const getActiveBalances = (orgId: string) => get<ITotalActiveLoanBalances>(`/organizations/${ orgId }/dashboard/loans/active-balances`, true);
export const getUsageInfo = (orgId: string) => get<IUsageInfo>(`/organizations/${ orgId }/dashboard/usage`, true);
export const getLateLoans = (orgId: string, req?: IRestRequest) => get<IRestResult<ILateLoanInfo>>(`/organizations/${ orgId }/dashboard/loans/late`, true, req);
