export interface IRelatedTo {
    id: string;
    parentId?: string;
    label?: string;
    childId?: string;
    childLabel?: string;
    type: RelatedToType;
}

export enum RelatedToType {
    ORGANIZATION = 'organization',
    INVENTORY = 'inventory',
    TRACT = 'tract',
    CLIENT = 'client',
    CLIENT_ENTITY = 'client/entity',
    NOTE = 'note',
    LOAN = 'loan',
    RENTAL = 'rental',
    MAIL = 'mail',
    FILLABLE_PDF = 'fillable-pdf',
    REPORT = 'report',
}
