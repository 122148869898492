import { Currency } from './currency';

import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { ILookup } from './common/lookup';
import { IDescription } from './common/description';
import { LoanTermsInterestSchedule } from './loan';
import { IPostalAddress } from './common/postalAddress';

export interface ITract extends IObjectDates, IOrganizational, IDescription {
    id: string;
    inventoryId?: string;
    number: number;
    label: string;
    acres: number;
    status: TractStatus;
    owner?: ILookup;
    address?: IPostalAddress;
    loan: ILookup;
    paymentOption: ITractPricing;
    costTotal?: Currency;
    costPerAcre?: Currency;
    taxReimbursementEligibility: boolean;
    parcelNumbers: string[];
}

export interface ITractPricing {
    isFilledOut: boolean;
    isFinanced: boolean;
    pricePerAcre: Currency;
    downPayment: Currency;
    years: number;
    interestRate: Currency;
    interestSchedule?: LoanTermsInterestSchedule;
    salesPrice: Currency;
    monthlyPayment: Currency;
    totalOfPayments: Currency;
    totalOfInterest: Currency;
    updatedAt: Date;
}

export enum TractStatus {
    Available = 'available',
    UnderContract = 'under-contract',
    Sold = 'sold',
    PaidOff = 'paid-off',
    InDefault = 'in-default',
    Pending = 'pending',
    ResendContract = 'resend-contract',
    Late = 'late',
}
