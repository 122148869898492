import React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { GlobalState } from 'store';
import { hasValidToken, getToken, getUserId } from 'store/selectors/auth';
import { notificationEventRecieved } from 'store/actions/notifications';
import { inventoryCreatedEventReceived, inventoryUpdatedEventReceived, inventoryDeletedEventReceived } from 'store/actions/inventories';
import { clientCreatedEventRecieved, clientUpdatedEventRecieved, clientDeletedEventRecieved } from 'store/actions/clients';
import { loanCreatedEventRecieved, loanUpdatedEventRecieved, loanDeletedEventRecieved } from 'store/actions/loans';
import { orgCreatedEventRecieved, orgUpdatedEventRecieved, orgDeletedEventRecieved } from 'store/actions/org';
import { rentalCreatedEventReceived, rentalDeletedEventReceived, rentalUpdatedEventReceived } from 'store/actions/rentals';

import { ISSEvent, SSEventType, SSEventAction } from 'models/sse/event';
import { IOrganization } from 'models/organization';
import { INotification } from 'models/notification';
import { IInventory } from 'models/inventory';
import { IClient } from 'models/client';
import { ILoan } from 'models/loan';
import type { IRental } from 'models/rental';

import { BASE_URL } from 'api';

const mapStateToProps = (state: GlobalState) => ({
    hasValidToken: hasValidToken(state),
    token: getToken(state),
    userId: getUserId(state),
});

interface IServerEventSourceProps extends ReturnType<typeof mapStateToProps>, DispatchProp {}

interface IServerEventSourceState {}

class ServerEventSourceBase extends React.PureComponent<IServerEventSourceProps, IServerEventSourceState> {
    state: Readonly<IServerEventSourceState> = {};
    es: EventSource | undefined;

    componentDidMount() {
        this.attemptLoading();
    }

    componentDidUpdate(prevProps: IServerEventSourceProps) {
        //if we haven't loaded it before, let's go ahead and try
        if (typeof this.es === 'undefined') {
            this.attemptLoading();
            return;
        }

        //handle them logging out: where the current token is no longer valid and it used to be
        if (!this.props.hasValidToken && prevProps.hasValidToken) {
            this.es.close();
            this.es = undefined;
            return;
        }

        if (this.es.readyState === EventSource.OPEN) {
            return;
        }

        console.log('Got a ServerEventSource update. Previous props:', prevProps, 'Current props:', this.props, 'And the event source:', this.es);
    }

    componentWillUnmount() {
        if (!this.es) {
            return;
        }

        this.es.close();
    }

    attemptLoading = () => {
        if (this.props.hasValidToken && this.props.userId) {
            this.es = new EventSource(`${ BASE_URL }/events/${ this.props.userId }?token=${ this.props.token }`);
            this.es.onmessage = this.onEventSourceMessage;
        } else {
            console.log('did not load');
        }
    }

    onEventSourceMessage = (event: MessageEvent) => {
        if (event.type === 'message' && typeof event.data === 'string') {
            try {
                const sse: ISSEvent<any> = JSON.parse(event.data);
                switch (sse.type) {
                    case SSEventType.Notification:
                        this.handleNotificationEvent(sse as ISSEvent<INotification>);
                        return;
                    case SSEventType.Inventory:
                        this.handleInventoryEvent(sse as ISSEvent<IInventory>);
                        return;
                    case SSEventType.Client:
                        this.handleClientEvent(sse as ISSEvent<IClient>);
                        return;
                    case SSEventType.Loan:
                        this.handleLoanEvent(sse as ISSEvent<ILoan>);
                        return;
                    case SSEventType.Organization:
                        this.handleOrganizationEvent(sse as ISSEvent<IOrganization>);
                        return;
                    case SSEventType.Rental:
                        this.handleRentalEvent(sse as ISSEvent<IRental>);
                        return;
                    default:
                        console.warn('unhandled sse:', sse);
                        break;
                }
            } catch (e) {
                console.error('error while parsing the event source data:', e);
            }
        } else {
            console.warn('unknown message type from the server:', event);
        }
    }

    handleNotificationEvent = (event: ISSEvent<INotification>) => {
        switch (event.action) {
            case SSEventAction.Created:
            case SSEventAction.Updated:
                this.props.dispatch(notificationEventRecieved(event.data));
                return;
            default:
                console.log('unhandled notification event:', event);
        }
    }

    handleInventoryEvent = (event: ISSEvent<IInventory>) => {
        switch (event.action) {
            case SSEventAction.Created:
                this.props.dispatch(inventoryCreatedEventReceived(event.data));
                return;
            case SSEventAction.Updated:
                this.props.dispatch(inventoryUpdatedEventReceived(event.data));
                return;
            case SSEventAction.Removed:
                this.props.dispatch(inventoryDeletedEventReceived({ id: event.data.id })); //TODO: Technical debt :/
                return;
        }
    }

    handleClientEvent = (event: ISSEvent<IClient>) => {
        switch (event.action) {
            case SSEventAction.Created:
                this.props.dispatch(clientCreatedEventRecieved(event.data));
                return;
            case SSEventAction.Updated:
                this.props.dispatch(clientUpdatedEventRecieved(event.data));
                return;
            case SSEventAction.Removed:
                this.props.dispatch(clientDeletedEventRecieved({ id: event.data.id })); //TODO: Technical debt :/
                return;
        }
    }

    handleLoanEvent = (event: ISSEvent<ILoan>) => {
        switch (event.action) {
            case SSEventAction.Created:
                this.props.dispatch(loanCreatedEventRecieved(event.data));
                return;
            case SSEventAction.Updated:
                this.props.dispatch(loanUpdatedEventRecieved(event.data));
                return;
            case SSEventAction.Removed:
                this.props.dispatch(loanDeletedEventRecieved({ id: event.data.id })); //TODO: Technical debt :/
                return;
        }
    }

    handleRentalEvent = (event: ISSEvent<IRental>) => {
        switch (event.action) {
            case SSEventAction.Created:
                this.props.dispatch(rentalCreatedEventReceived(event.data));
                return;
            case SSEventAction.Updated:
                this.props.dispatch(rentalUpdatedEventReceived(event.data));
                return;
            case SSEventAction.Removed:
                this.props.dispatch(rentalDeletedEventReceived({ id: event.data.id })); //TODO: Technical debt :/
                return;
        }
    }

    handleOrganizationEvent = (event: ISSEvent<IOrganization>) => {
        switch (event.action) {
            case SSEventAction.Created:
                this.props.dispatch(orgCreatedEventRecieved(event.data));
                return;
            case SSEventAction.Updated:
                this.props.dispatch(orgUpdatedEventRecieved(event.data));
                return;
            case SSEventAction.Removed:
                this.props.dispatch(orgDeletedEventRecieved({ id: event.data.id, shortId: event.data.shortId })); //TODO: Technical debt :/
                return;
        }
    }

    render() {
        return <span></span>;
    }
}

export const ServerEventSource = connect(mapStateToProps)(ServerEventSourceBase);
