import React from 'react';
import debounce from 'lodash.debounce';
import { AutoComplete } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { LabelInValueType, RawValueType } from 'rc-select/lib/Select';

import type { IInventory, InventoryCategory, InventoryType } from 'models/inventory';
import type { IRestRequestFilter } from 'models/common/restRequest';
import { getInventories, getInventoryByID } from 'api/inventories';

export interface IInventoryAutoCompleteValue {
    id: string;
    name?: string;
    category?: InventoryCategory;
}

interface IInventoryAutoCompleteProps {
    orgId: string;
    disabled?: boolean;
    category?: InventoryCategory;
    type?: InventoryType;
    value?: IInventoryAutoCompleteValue;
    withLoansDisabled?: boolean; //disables the inventory from being selected if it has a loan
    withRentalsDisabled?: boolean; //disables the inventory from being selected if it has a rental already attached
    onChange?: (value: IInventoryAutoCompleteValue) => void;
}

interface IInventoryAutoCompleteState {
    isSearching: boolean;
    isSelecting: boolean;
    value: IInventoryAutoCompleteValue;
    inventories: IInventory[];
}

export class InventoryAutoComplete extends React.PureComponent<IInventoryAutoCompleteProps, IInventoryAutoCompleteState> {
    static getDerivedStateFromProps(props: IInventoryAutoCompleteProps, state: IInventoryAutoCompleteState): Partial<IInventoryAutoCompleteState> | null {
        if (props.value && props.value.id !== state.value.id && !state.isSearching) {
            return { value: props.value };
        }

        return null;
    }

    state: Readonly<IInventoryAutoCompleteState> = {
        isSearching: false,
        isSelecting: false,
        value: { id: '', name: '' },
        inventories: [],
    }

    async componentDidMount() {
        if (this.props.value && this.props.value.id) {
            const inventory = await getInventoryByID(this.props.orgId, this.props.value.id);

            this.setState({ value: this.generateValue(inventory) });
        }
    }

    onSelect = (selectedValue: RawValueType | LabelInValueType): void => {
        if (!selectedValue) {
            return;
        }

        this.setState({ isSelecting: true }, async () => {
            const inventory = await getInventoryByID(this.props.orgId, selectedValue.toString());
            const value = this.generateValue(inventory);

            if (this.props.onChange) {
                this.props.onChange(value);
            }

            this.setState({
                value,
                isSelecting: false,
                isSearching: false,
                inventories: [],
            });
        });
    }

    private generateValue(inventory: IInventory): IInventoryAutoCompleteValue {
        return {
            id: inventory.id,
            name: inventory.name,
            category: inventory.category,
        }
    }

    searchFor = debounce(async (term: string) => {
        const filters: IRestRequestFilter[] = [];

        if (this.props.category) {
            filters.push({ key: 'category', value: this.props.category });
        }

        if (this.props.type) {
            filters.push({ key: 'type', value: this.props.type });
        }

        const result = await getInventories(this.props.orgId, {
            search: term,
            filters: filters.length !== 0 ? filters : undefined,
        });

        this.setState({ isSearching: false, inventories: result.data });
    }, 200);

    onSearch = async (searchValue: string): Promise<void> => {
        let inventories = this.state.inventories;
        if (searchValue) {
            this.searchFor(searchValue);
        } else {
            inventories = [];
        }

        const value: IInventoryAutoCompleteValue = { id: '', name: searchValue };

        if (this.props.onChange) {
            this.props.onChange(value);
        }

        this.setState({
            value,
            isSearching: searchValue !== '',
            inventories,
        });
    }

    render() {
        return (
            <AutoComplete
                onSelect={this.onSelect}
                onSearch={this.onSearch}
                value={this.state.value.name}
                placeholder="Search for the inventory"
                style={{width: '100%'}}
                disabled={this.state.isSelecting || this.props.disabled}
                allowClear
            >
                {this.state.inventories.map((inv) => {
                    let disabled = false;
                    if (this.props.withLoansDisabled && inv.residentialDetails?.loan?.id) {
                        disabled = true;
                    }

                    if (this.props.withRentalsDisabled && inv.residentialDetails?.rental?.id) {
                        disabled = true;
                    }

                    return (
                        <AutoComplete.Option key={inv.id} disabled={disabled}>
                            <span>{inv.name}</span>
                            <span style={{ position: 'absolute', right: '16px' }}><ArrowRightOutlined /></span>
                        </AutoComplete.Option>
                    );
                })}
            </AutoComplete>
        );
    }
}
