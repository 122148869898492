export enum TransactionStatus {
    Pending = 'pending',
    Success = 'success',
    Failure = 'failure',
    Reversed = 'reversed',
}

export enum TransactionMethod {
    Barter = 'barter',
    Cash = 'cash',
    Check = 'check',
    Card = 'card',
    ACH = 'ach',
    Wire = 'wire',
    MoneyOrder = 'money-order',
    CashierCheck = 'cashier-check',
    CashApp = 'cash-app',
    PayPal = 'paypal',
    Venmo = 'venmo',
    Zelle = 'zelle',
    Other = 'other',
}

export enum TransactionReversalReason {
    InsufficientFunds = 'insufficient-funds',
    CheckBounced = 'check-bounced',
    ClericalError = 'clerical-error',
    BuyerRequested = 'buyer-requested',
    Other = 'other',
}
