import { RelatedToType } from "models/common/relatedTo";
import { PermissionFeature } from "models/permissions/features";
import type { PermissionAction } from "models/permissions/features";

export function serializeEnforceRequest(orgId: string, userId: string, feature: PermissionFeature, action: PermissionAction) {
    return `${ orgId }-${ userId }-${ feature }-${ action }`;
}

export function canAccess(enforcementCache: Record<string, boolean>, orgId: string, userId: string, feature: PermissionFeature, action: PermissionAction) {
    const serialized = serializeEnforceRequest(orgId, userId, feature, action);

    return enforcementCache[serialized];
}

export function relatedToFeature(relatedTo: RelatedToType): PermissionFeature {
    switch (relatedTo) {
        case RelatedToType.CLIENT:
            return PermissionFeature.Client;
        case RelatedToType.CLIENT_ENTITY:
            return PermissionFeature.ClientEntities;
        case RelatedToType.INVENTORY:
            return PermissionFeature.Inventory;
        case RelatedToType.LOAN:
            return PermissionFeature.Loan;
        case RelatedToType.ORGANIZATION:
            return PermissionFeature.Organization;
        case RelatedToType.TRACT:
            return PermissionFeature.Tract;
        case RelatedToType.RENTAL:
            return PermissionFeature.Rental;
        default:
            return PermissionFeature.All;
    }
}
