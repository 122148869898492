import { notification } from 'antd';
import type { NotificationInstance } from 'antd/es/notification/interface';
import { displayUnauthorizedModal } from 'components/misc/authorizationModal';
import { UnauthorizedError } from 'models/common/restResult';

export function displayErrorNotification(e: any, refresh?: () => void, n?: NotificationInstance) {
    console.error('Displayed the error notification:', e);

    const notifier = n || notification;

    if (typeof e === 'undefined') {
        notifier.error({ message: 'Unknown error' });
    } else if (typeof e === 'string') {
        notifier.error({ message: e });
    } else if (typeof e === 'number') {
        notifier.error({ message: `Unknown error with a status code of: ${ e }`});
    } else if (e instanceof UnauthorizedError) {
        displayUnauthorizedModal(e, refresh);
    } else if (typeof e.message === 'undefined') {
        notifier.error({ message: `${ e.error } (${ e.code })` });
    } else {
        notifier.error({ message: e.message });
    }
}
