import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Alert, Button, Drawer, Form, Input, notification, Space, Switch } from 'antd';
import { useHistory, useParams} from 'react-router';
import { Link } from 'react-router-dom';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { IPropertyTax } from 'models/propertyTax';
import { displayErrorNotification } from 'utils/errors';

import { finalizePropertyTax } from 'api/propertyTaxes';

interface IFinalizeValues {
    sendSms: boolean;
    sendMail: boolean;
    signature: string;
}

interface IFinalizePropertyTaxProps {
    visible: boolean;
    tax: IPropertyTax;
    close: () => void;
}

export const FinalizePropertyTax: React.FC<IFinalizePropertyTaxProps> = (props) => {
    const history = useHistory();
    const params = useParams<IOrgIdPathParams>();
    const [form] = Form.useForm<IFinalizeValues>();
    const [finalizing, setFinalizing] = useState(false);

    const onFinalizeClick = async () => {
        let values: IFinalizeValues;

        try {
            values = await form.validateFields();
        } catch (e) {
            console.log('invalid form, missing something?', e);
            return;
        }

        setFinalizing(true);

        try {
            await finalizePropertyTax(props.tax.organization.id, props.tax.inventoryId, props.tax.id, values);

            notification.success({ message: `Success! The ${ props.tax.taxYear } is now being finalized.`});

            history.push(`/${ props.tax.organization.shortId }/inventories/${ props.tax.inventoryId }`);
        } catch (e) {
            displayErrorNotification(e);
            setFinalizing(false);
        }
    };

    let width: string = '500px';
    if (isMobileOnly) {
        width = '100vw';
    }

    return (
        <Drawer
            open={props.visible}
            width={width}
            title={`Finalize: ${props.tax.taxYear} Property Tax`}
            onClose={props.close}
            maskClosable={false}
            closable={!finalizing}
            extra={
                <Space>
                    <Button onClick={props.close} disabled={finalizing}>Cancel</Button>
                    <Button type="primary" loading={finalizing} disabled={finalizing} onClick={onFinalizeClick}>Finalize</Button>
                </Space>
            }
        >
            <Form<IFinalizeValues>
                form={form}
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    sendSms: true,
                    sendMail: true,
                }}
            >
                <Alert
                    type="info"
                    style={{ marginBottom: '15px' }}
                    message="Finalizing a property tax prevents any further edits to it and optionally sends out either text messages, notice letters via mail or both. Once finalized, clients will have the option to pay online via their Lendiom Pay portal."
                />

                <Form.Item
                    name="sendSms"
                    label="Send SMS"
                    valuePropName="checked"
                    extra="Would you like to send a SMS notification? Should any client have SMS disabled or not have a valid mobile number, they will not receive a text message."
                    rules={[{ required: true, message: 'Please tell us whether or not you want to send text messages.' }]}
                >
                    <Switch
                        checkedChildren="Yes, send SMS"
                        unCheckedChildren="Don't send"
                        disabled={finalizing}
                    />
                </Form.Item>

                <Form.Item
                    name="sendMail"
                    label="Send Mail"
                    valuePropName="checked"
                    extra="Would you like to send snail mail to inform the clients of their Property Tax due? Only clients which have validated addresses will be sent a letter. It will cost $1.35 per letter."
                    rules={[{ required: true, message: 'Please let us know whether to send mail or not.' }]}
                >
                    <Switch
                        checkedChildren="Yes, send mail"
                        unCheckedChildren="Don't send"
                        disabled={finalizing}
                    />
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev: IFinalizeValues, curr: IFinalizeValues) => prev.sendMail !== curr.sendMail}>
                    {({ getFieldValue }) => {
                        if (!getFieldValue('sendMail')) {
                            return null;
                        }

                        return (
                            <React.Fragment>
                                <Alert
                                    type="warning"
                                    message="Notice Return Address"
                                    style={{ marginBottom: '15px' }}
                                    description={<React.Fragment>The return address will be the value provided in your organization configuration. Please ensure it is the correct address before Finalizing. You can do that via <Link to={`/${ params.orgId }/settings/basic`}>Org Settings -&gt; Basic</Link>.</React.Fragment>}
                                />
                                <Form.Item name="signature" label="Name in Signature" extra="What is the name in the signature position of the notice letters we are sending by mail?" rules={[{ required: true, message: 'Name in the signature is required when sending mail.' }]}>
                                    <Input
                                        placeholder="Name goes here"
                                        disabled={finalizing}
                                    />
                                </Form.Item>
                            </React.Fragment>
                        );
                    }}
                </Form.Item>
            </Form>
        </Drawer>
    );
}
