import React, { useState } from 'react';
import { Alert, Button, Drawer, Form, Space } from 'antd';
import { isMobileOnly } from 'react-device-detect';

import { InventoryCategory, InventoryType, IInventory } from 'models/inventory';
import { ISetInventoryDetailsPayload } from 'models/payloads/inventories';

import { AddressDeliverabilityWarning } from 'components/misc/address';
import { displayErrorNotification } from 'utils/errors';

import { setInventoryDetails } from 'api/inventories';

import { InventoryDetailsFormStepTwo } from './inventoryDetailsStep';
import { Link } from 'react-router-dom';
import { sanitizeCurrency } from 'utils/numberFormatting';

interface IFormValues {
    category: InventoryCategory; // this value should not be changed in this drawer
    type: InventoryType;
    county: string;
    state: string;
    totalAcres: number;
    totalCostOfDevelopment: number;
    perAcreCostOfDevelopment: number;
    parcelNumbers: string[];
    units?: number;
    yearBuilt?: number;
}

interface IEditInventoryDetailsProps {
    visible: boolean;
    inventory: IInventory;
    close: (update: boolean) => Promise<void>;
}

export const EditInventoryDetailsDrawer: React.FC<IEditInventoryDetailsProps> = (props) => {
    const [form] = Form.useForm<IFormValues>();
    const [saving, setSaving] = useState(false);

    const onCancelClick = () => {
        props.close(false);
        form.resetFields();
    };

    const onSaveClick = () => {
        setSaving(true);

        form.validateFields().then(async (values) => {
            try {
                const payload: ISetInventoryDetailsPayload = {
                    county: values.county,
                    state: values.state,
                    parcelNumbers: values.parcelNumbers || [],
                };

                switch (props.inventory.category) {
                    case InventoryCategory.Land:
                        payload.totalAcres = values.totalAcres;
                        payload.costTotal = sanitizeCurrency(form.getFieldValue('totalCostOfDevelopment')) || '0';
                        payload.costPerAcre = sanitizeCurrency(form.getFieldValue('perAcreCostOfDevelopment')) || '0';
                        payload.updateTracts = form.getFieldValue('updateTracts') || false;
                        break;
                    case InventoryCategory.Residential:
                        payload.residentialDetails = {
                            streetAddresses: [form.getFieldValue('address1')],
                            city: form.getFieldValue('city'),
                            zipCode: form.getFieldValue('zip'),
                            bedrooms: form.getFieldValue('bedrooms'),
                            bathrooms: form.getFieldValue('bathrooms'),
                            livingSquareFootage: form.getFieldValue('livingSquareFootage'),
                            lotArea: form.getFieldValue('lotArea'),
                            lotAreaUnit: form.getFieldValue('lotAreaUnit'),
                            price: sanitizeCurrency(form.getFieldValue('residentialPrice')) || '0',
                            rent: sanitizeCurrency(form.getFieldValue('rentAmount')) || '0',
                        };
                        break;
                    case InventoryCategory.Multifamily:
                        payload.multifamilyDetails = {
                            streetAddresses: [form.getFieldValue('address1')],
                            city: form.getFieldValue('city'),
                            zipCode: form.getFieldValue('zip'),
                            units: form.getFieldValue('units'),
                            yearBuilt: form.getFieldValue('yearBuilt'),
                        };
                }

                await setInventoryDetails(props.inventory.organization.id, props.inventory.id, payload);

                await props.close(true);
                form.resetFields();
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                setSaving(false);
            }
        }).catch((e) => {
            console.log('failed to validate the form:', e);
            setSaving(false);
        });
    };

    let width: string = '500px';
    if (isMobileOnly) {
        width = '100vw';
    }

    let hasLoan = false;
    let loanId = '';
    if (props.inventory.category === InventoryCategory.Residential && props.inventory.residentialDetails?.loan?.id) {
        hasLoan = true;
        loanId = props.inventory.residentialDetails?.loan?.id || '';
    }

    let defaultParcelNumbers: string[] = [];
    switch (props.inventory.category) {
        case InventoryCategory.Residential:
            defaultParcelNumbers = props.inventory.residentialDetails?.parcelNumbers || [];
            break;
        case InventoryCategory.Land:
            defaultParcelNumbers = props.inventory.landDetails?.parcelNumbers || [];
            break;
    }

    return (
        <Drawer
            title={`Edit: ${ props.inventory.name }`}
            open={props.visible}
            onClose={onCancelClick}
            maskClosable={!saving}
            closable={!saving}
            width={width}
            extra={
                <Space>
                    <Button onClick={onCancelClick} style={{ marginRight: 8 }} disabled={saving}>Cancel</Button>
                    <Button onClick={onSaveClick} type="primary" disabled={saving} loading={saving}>Save</Button>
                </Space>
            }
        >
            { hasLoan ? <Alert message="Active Loan" description={<React.Fragment>As this inventory has an <Link to={`/${ props.inventory.organization.shortId }/loans/${ loanId }`}>active loan associated</Link>, the details can not be changed.</React.Fragment>} type="warning" showIcon style={{ marginBottom: '16px' }} /> : null }
            { !hasLoan ? <AddressDeliverabilityWarning label={props.inventory.name} address={props.inventory.address} /> : null }
            <Form<IFormValues>
                form={form}
                layout="vertical"
                initialValues={{
                    category: props.inventory.category,
                    type: props.inventory.type,
                    address1: props.inventory.address.streetAddresses.length > 0 ? props.inventory.address.streetAddresses[0] : '',
                    address2: props.inventory.address.streetAddresses.length >= 2 ? props.inventory.address.streetAddresses[1] : '',
                    city: props.inventory.address.city,
                    county: props.inventory.address.county,
                    state: props.inventory.address.state,
                    zip: props.inventory.address.zipCode,
                    totalAcres: props.inventory.landDetails ? props.inventory.landDetails.totalAcres : undefined,
                    totalCostOfDevelopment: props.inventory.landDetails ? props.inventory.landDetails.costTotal : undefined,
                    perAcreCostOfDevelopment: props.inventory.landDetails ? props.inventory.landDetails.costPerAcre : undefined,
                    bedrooms: props.inventory.residentialDetails ? props.inventory.residentialDetails.bedrooms : undefined,
                    bathrooms: props.inventory.residentialDetails ? props.inventory.residentialDetails.bathrooms : undefined,
                    livingSquareFootage: props.inventory.residentialDetails ? props.inventory.residentialDetails.livingSquareFootage : undefined,
                    lotArea: props.inventory.residentialDetails ? props.inventory.residentialDetails.lotArea : undefined,
                    lotAreaUnit: props.inventory.residentialDetails ? props.inventory.residentialDetails.lotAreaUnit : undefined,
                    residentialPrice: props.inventory.residentialDetails ? props.inventory.residentialDetails.price : undefined,
                    parcelNumbers: defaultParcelNumbers,
                    rentAmount: props.inventory.residentialDetails?.rent || undefined,
                    units: props.inventory.multifamilyDetails?.units || undefined,
                    yearBuilt: props.inventory.multifamilyDetails?.yearBuilt || undefined,
                }}
            >
                <InventoryDetailsFormStepTwo form={form} disabled={saving || hasLoan} editForm isExisting />
            </Form>
        </Drawer>
    );
}
