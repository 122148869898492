import React, { lazy, Suspense } from 'react';
import { CheckCircleTwoTone, DeleteOutlined, EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Table, Button, Typography } from 'antd';
import type { ColumnProps } from 'antd/lib/table';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';

import { IMember } from 'models/member';
import { getMembers } from 'api/members';

import { displayErrorNotification } from 'utils/errors';
import { SimpleDate } from 'utils/formatting';

const MemberInviteModal = lazy(() => import('components/members/inviteModal'));
const MemberRemoveModal = lazy(() => import('components/members/removeModal'));
const MemberUpdateModal = lazy(() => import('components/members/updateModal'));

interface IMembersProps {
    orgId: string;
    userId: string;
    canCreate: boolean;
}

interface IMembersState {
    isLoading: boolean;
    inviteModalVisible: boolean;
    removeModalVisible: boolean;
    updateModalVisible: boolean;
    selectedMember?: IMember;
    members: IMember[];
}

export class MembersView extends React.PureComponent<IMembersProps, IMembersState> {
    state: Readonly<IMembersState> = {
        isLoading: true,
        inviteModalVisible: false,
        removeModalVisible: false,
        updateModalVisible: false,
        members: [],
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IMembersProps) {
        if (this.props.orgId === prevProps.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = async () => {
        try {
            const members = await getMembers(this.props.orgId);
            this.setState({ members, isLoading: false });
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    columns: ColumnProps<IMember>[] = [
        {
            title: 'Name', key: 'name',
            render: (name: any, record: IMember) => record.firstName + ' ' + record.lastName,
        },
        {
            title: 'Email', key: 'email', dataIndex: 'email',
            render: (email: string, member: IMember) => <React.Fragment>{ email }&nbsp;{ member.emailVerified ? <CheckCircleTwoTone twoToneColor="#52c41a" title="Email is verified" /> : '' }</React.Fragment>
        },
        {
            title: 'Role', key: 'role', dataIndex: 'role', className: 'title-caps',
            render: (role: string) => role.replace('role::', '').replace('-', ' '),
        },
        {
            title: 'Status', key: 'status', dataIndex: 'status', className: 'title-caps',
        },
        {
            title: 'Invited By', key: 'invitedBy', dataIndex: 'invitedBy.label',
        },
        {
            title: 'Last Access Date', key: 'lastAccessedAt', dataIndex: 'lastAccessedAt',
            render: (date: string) => <SimpleDate date={date} />,
        },
        {
            title: '', key: 'actions',
            render: (nothing: any, record: IMember) => <ActionsMenu<IMember> record={record} actions={this.actions} onClick={this.onActionMenuClick} />,
        },
    ]

    onActionMenuClick = async (member: IMember, actionKey: string) => {
        switch (actionKey) {
            case 'edit':
                this.setState({ selectedMember: member, updateModalVisible: true });
                return;
            case 'delete':
                this.setState({ selectedMember: member, removeModalVisible: true });
                return;
            default:
                return;
        }
    }

    actions: ActionMenuItemMap<IMember> = {
        edit: { icon: <EditOutlined />, text: 'Edit', disabled: (m) => m.userId === this.props.userId },
        'delete': { icon: <DeleteOutlined />, text: 'Remove', disabled: () => this.state.members.length <= 1 },
    };

    onInviteClick = () => {
        this.setState({ inviteModalVisible: true });
    }

    onInviteClosed = async (sent: boolean) => {
        if (sent) {
            await this.loadData();
        }

        this.setState({ inviteModalVisible: false });
    }

    onRemoveClose = async (removed: boolean) => {
        if (removed) {
            await this.loadData();
        }

        this.setState({ removeModalVisible: false, selectedMember: undefined });
    }

    onUpdateClose = async (updated: boolean) => {
        if (updated) {
            await this.loadData();
        }

        this.setState({ updateModalVisible: false, selectedMember: undefined });
    }

    tableHeader = () => {
        return (
            <div className="table-header-with-button">
                <Typography.Title level={3}>Members</Typography.Title>
                <Button size="large" type="primary" icon={<UsergroupAddOutlined />} onClick={this.onInviteClick} disabled={!this.props.canCreate}>Invite</Button>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Table<IMember>
                    dataSource={this.state.members}
                    loading={this.state.isLoading}
                    columns={this.columns}
                    rowKey="userId"
                    pagination={{ hideOnSinglePage: true }}
                    bordered={false}
                    title={this.tableHeader}
                    scroll={{ x: 'max-content' }}
                />

                <Suspense fallback={null}>
                    <MemberInviteModal orgId={this.props.orgId} visible={this.state.inviteModalVisible} close={this.onInviteClosed} />
                </Suspense>
                <Suspense fallback={null}>
                    <MemberRemoveModal orgId={this.props.orgId} visible={this.state.removeModalVisible} member={this.state.selectedMember} close={this.onRemoveClose} />
                </Suspense>
                <Suspense fallback={null}>
                    <MemberUpdateModal
                        orgId={this.props.orgId}
                        open={this.state.updateModalVisible}
                        member={this.state.selectedMember}
                        close={this.onUpdateClose}
                    />
                </Suspense>
            </React.Fragment>
        );
    }
}
