import type { IOrganization } from 'models/organization';
import type { IUser } from 'models/user';

export function openHelpScoutToChat(user?: IUser, org?: IOrganization) {
    if (typeof window.Beacon !== 'function') {
        return;
    }

    window.Beacon('session-data', {
        'App Version': `v${process.env.REACT_APP_VERSION}`,
    });

    if (org) {
        window.Beacon('session-data', {
            'App Version': `v${process.env.REACT_APP_VERSION}`,
            'Selected Org': `${ org.name } (${ org.shortId })`,
        });
    }

    if (user) {
        console.log({
            name: `${ user.firstName } ${ user.lastName }`,
            email: user.email,
            signature: user.helpScoutSignature || undefined,
        });

        window.Beacon('identify', {
            name: `${ user.firstName } ${ user.lastName }`,
            email: user.email,
            signature: user.helpScoutSignature || undefined,
        });
    }

    window.Beacon('navigate', '/ask/');
    window.Beacon('open');
}

export function hideHelpScout() {
    if (typeof window.Beacon !== 'function') {
        return;
    }

    window.Beacon('destroy');
}
