import { Currency } from './currency';
import { LoanStatus } from './loan';

export interface ILoanDownPayment {
	schedule: LoanDownPaymentSchedule;
	amount: Currency;
	increments?: ILoanDownPaymentIncrement[];

	dueDate?: string;
    expiredStatus: LoanStatus;

    transactionId?: string;
    paid?: boolean;
}

export enum LoanDownPaymentSchedule {
	OneTime = 'one',
	Incremental = 'incremental',
}

export interface ILoanDownPaymentIncrement {
	amount: Currency;
	dueDate: string;

    transactionId?: string;
}
