import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { Form, Input, InputNumber, Modal, Select, Typography } from 'antd';

import { ILateFeeTier, LateFeeApplication, LateFeeChargeType } from 'models';
import type { Currency } from 'models/currency';

import { useResetFormOnCloseModal } from 'hooks/resetFormOnCloseModal';

interface ILateFeeTierModalProps {
    open: boolean;
    type: 'loan' | 'rental';
    onAdd: (tier?: ILateFeeTier) => void;
}

interface ILateFeeTierValues {
    days: number;
    application: LateFeeApplication;
    chargeType: LateFeeChargeType;
    fixed?: Currency;
    percentage?: string;
    minimumAmount?: Currency;
    maximumAmount?: Currency;
}

export const LateFeeTierModal: FC<ILateFeeTierModalProps> = ({ type, open, onAdd }) => {
    const [form] = Form.useForm<ILateFeeTierValues>();

    useResetFormOnCloseModal({ open, form });

    const onSubmit = async () => {
        try {
            form.submit();

            const values = await form.validateFields();

            const minimumLateFeeAmount = currency(values.minimumAmount!, { precision: 2 });
            const maximumLateFeeAmount = currency(values.maximumAmount!, { precision: 2 });

            if ((!values.days && values.days !== 0) || values.days < 0
                || !values.application || (values.application !== LateFeeApplication.First && values.application !== LateFeeApplication.Principal && values.application !== LateFeeApplication.Balance)
                || !values.chargeType || (values.chargeType !== LateFeeChargeType.Fixed && values.chargeType !== LateFeeChargeType.Percent)
                || (values.chargeType === LateFeeChargeType.Fixed && !values.fixed)
                || (values.chargeType === LateFeeChargeType.Percent && !values.percentage)
                || minimumLateFeeAmount.intValue < 0 || maximumLateFeeAmount.intValue < 0 || minimumLateFeeAmount.intValue > maximumLateFeeAmount.intValue
            ) {
                return;
            }


            onAdd(values);
        } catch (e) {
            console.log('error on submit:', e);
        }
    };

    const onClose = useCallback(() => onAdd(), [onAdd]);

    const daysExtra = useMemo(() => {
        return (
            <Typography.Text className="ant-form-item-extra">
                How long the payee has after the due date until the payment has this late fee tier applied?&nbsp;
                <Typography.Text className="ant-form-item-extra" strong>Note:</Typography.Text> when there are multiple tiers, the days are not inclusive. Meaning, the last tier does <strong>NOT</strong> replace the previous tiers, it adds to.
            </Typography.Text>
        );
    }, []);

    return (
        <Modal
            title="New Late Fee Tier"
            open={open}
            okText="Add Tier"
            onOk={onSubmit}
            onCancel={onClose}
            maskClosable={false}
            keyboard={false}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    days: 10,
                    application: '',
                    chargeType: '',
                    fixed: '35.00',
                    percentage: '4',
                    minimumAmount: '10.00',
                    maximumAmount: '50.00',
                }}
            >
                <Form.Item
                    name="days"
                    label="Days Until Applied"
                    extra={daysExtra}
                    rules={[
                        { required: true, message: 'Please input the amount of days before the late fee tier is applied.' },
                        { type: 'number', min: 1, message: 'Days must be greater than or equal to 1.' },
                    ]}
                >
                    <InputNumber
                        min={0}
                        step={1}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name="application"
                    label="Application"
                    extra={`How should the late fee be applied to the ${type}? When the application is "Balance" then the late fee will never be automatically paid.`}
                    rules={[{ required: true, message: 'Please select how the late fee should be applied.' }]}
                >
                    <Select<LateFeeApplication>>
                        <Select.Option key={LateFeeApplication.First} value={LateFeeApplication.First}>First Part of Next Payment</Select.Option>
                        {type === 'loan' ? <Select.Option key={LateFeeApplication.Principal} value={LateFeeApplication.Principal}>Added to Principal</Select.Option> : null}
                        <Select.Option key={LateFeeApplication.Balance} value={LateFeeApplication.Balance}>Added to Late Fee Balance</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="chargeType"
                    label="Charge Type"
                    extra="How should the late fee be calculated?"
                    rules={[{ required: true, message: 'Please select how the late fee should be calculated.' }]}
                >
                    <Select<LateFeeChargeType>>
                        <Select.Option key={LateFeeChargeType.Fixed} value={LateFeeChargeType.Fixed}>Fixed Amount</Select.Option>
                        <Select.Option key={LateFeeChargeType.Percent} value={LateFeeChargeType.Percent}>Percentage</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev: ILateFeeTierValues, curr: ILateFeeTierValues) => prev.chargeType !== curr.chargeType}>
                    {({ getFieldValue }) => {
                        const chargeType = getFieldValue('chargeType') as LateFeeChargeType;

                        if (!chargeType) {
                            return null;
                        }

                        if (chargeType === LateFeeChargeType.Fixed) {
                            return (
                                <Form.Item
                                    name="fixed"
                                    label="Fixed Amount"
                                    extra="How much the payee will be charged for being late."
                                    rules={[{ required: true, message: 'Please provide how much the fixed late fee amount is.' }]}
                                >
                                    <Input
                                        prefix="$"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            );
                        }

                        return (
                            <React.Fragment>
                                <Form.Item
                                    name="percentage"
                                    label="Percentage"
                                    extra="How much the payee will be charged for being late."
                                    rules={[{ required: true, message: 'Please provide how much the late fee percentage is.' }]}
                                >
                                    <Input
                                        suffix="%"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="minimumAmount"
                                    label="Minimum Fee"
                                    extra="What is the lowest amount that the late fee can be?"
                                    rules={[{ required: true, message: 'Please provide how the minimum late fee to charge the payee.' }]}
                                >
                                    <Input
                                        prefix="$"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="maximumAmount"
                                    label="Maximum Fee"
                                    extra="What is the highest amount that the late fee can be?"
                                    rules={[{ required: true, message: 'Please provide how the maximum late fee to charge the payee.' }]}
                                >
                                    <Input
                                        prefix="$"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </React.Fragment>
                        );
                    }}
                </Form.Item>
            </Form>
        </Modal>
    );
}
