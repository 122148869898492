export interface IPaymentMethod {
    id: string;
    disabled: boolean;
    default: boolean;
    type: 'card' | 'bank' | 'us-bank';
    source: PaymentMethodSource;

    name: string;
    brand: string;
    last4: string;
    expirationMonth: number;
    expirationYear: number;

    bankName: string;
    routingNumber: string;
    bankStatus: string;
    bankAccountType?: PaymentMethodBankType;

    createdAt: string;
}

export enum PaymentMethodSource {
    PayArc = 'payArc',
    Stripe = 'stripe',
}

export enum PaymentMethodBankType {
    PersonalChecking = 'personal-checking',
    PersonalSavings = 'personal-savings',
    BusinessChecking = 'business-checking',
    BusinessSavings = 'business-savings',
}

export interface IBankInfo {
    valid: boolean;
    value: string;
    status: string;
    bankName: string;
    phoneNumber: string;
}
