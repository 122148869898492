import React from 'react';
import { Link } from 'react-router-dom';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

export const breadCrumbItemRender = (route: ItemType, params: any, routes: ItemType[], paths: string[]) => {
    const last = routes.indexOf(route) === routes.length - 1;

    if (last) {
        return (
            <span>{route.breadcrumbName}</span>
        );
    }

    return (
        <Link to={route.path!}>{route.breadcrumbName}</Link>
    );
};
