import React, { useCallback, useMemo, useState } from 'react';
import { App, Button, Card, List } from 'antd';
import { FileTextTwoTone, HddTwoTone, LayoutTwoTone } from '@ant-design/icons';

import type { IOrganization } from 'models/organization';
import { displayErrorNotification } from 'utils/errors';

import { addAddon, removeAddon } from 'api/billing';

interface IBillingAddonsCardProps {
    org: IOrganization;
}

export const BillingAddonsCard: React.FC<IBillingAddonsCardProps> = ({ org }) => {
    const { modal, notification } = App.useApp();
    const [workingSign, setWorkingSign] = useState(false);

    const clickSign = useCallback(() => {
        if (org.billing?.addons?.documentSignature?.enabled) {
            modal.confirm({
                title: 'Disable Document Signatures',
                content: 'Are you sure you want to disable document signatures? You will no longer be able to access them.',
                okText: 'Yes, disable',
                okButtonProps: { danger: true },
                onOk: async () => {
                    setWorkingSign(true);

                    try {
                        await removeAddon(org.id, 'sign');
                    } catch (e) {
                        displayErrorNotification(e, undefined, notification);
                    } finally {
                        setWorkingSign(false);
                    }
                },
                onCancel: () => setWorkingSign(false),
            });

            return;
        }

        modal.confirm({
            title: 'Enable Document Signatures',
            content: 'Are you sure you want to enable document signatures? It costs $20 per month.',
            okText: 'Yes, enable!',
            onOk: async () => {
                setWorkingSign(true);

                try {
                    await addAddon(org.id, 'sign');
                } catch (e) {
                    displayErrorNotification(e, undefined, notification);
                } finally {
                    setWorkingSign(false);
                }
            },
            onCancel: () => setWorkingSign(false),
        });
    }, [modal, notification, org]);

    const documentSignExtra = useMemo(() => {
        const enabled = org.billing?.addons?.documentSignature?.enabled || false;

        return (
            <Button
                disabled={workingSign}
                loading={workingSign}
                onClick={clickSign}
            >
                { enabled ? 'Disable' : 'Enable' }
            </Button>
        );
    }, [workingSign, clickSign, org.billing?.addons?.documentSignature?.enabled]);

    const websiteExtra = (
        <Button disabled>{ org.billing?.addons?.customWebsite.enabled ? 'Disable' : 'Enable' }</Button>
    );

    const mailExtra = (
        <Button disabled>Enable</Button>
    );

    return (
        <Card title="Addons" size="small" bordered style={{ marginTop: '45px' }}>
            <List>
                <List.Item
                    extra={documentSignExtra}
                >
                    <List.Item.Meta
                        avatar={<HddTwoTone twoToneColor="#52c41a" style={{ fontSize: '2em' }} />}
                        title="Document Signing"
                        description="The document signing addon enables you to upload documents and request signatures on them."
                        style={{ paddingRight: '16px' }}
                    />
                </List.Item>

                <List.Item
                    extra={websiteExtra}
                >
                    <List.Item.Meta
                        avatar={<LayoutTwoTone twoToneColor="#52c41a" style={{ fontSize: '2em' }} />}
                        title="Custom Website"
                        description="The custom website addon enables you to build a website via lendiom, with automatic content updates of the inventory."
                        style={{ paddingRight: '16px' }}
                    />
                </List.Item>

                <List.Item
                    extra={mailExtra}
                >
                    <List.Item.Meta
                        avatar={<FileTextTwoTone twoToneColor="#52c41a" style={{ fontSize: '2em' }} />}
                        title="Mail Editor"
                        description="A mail/letter creation inside of Lendiom which allows using and inserting data from Lendiom."
                        style={{ paddingRight: '16px' }}
                    />
                </List.Item>
            </List>
        </Card>
    );
}
