import React from 'react';
import shortid from 'shortid';
import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Col, Form, FormInstance, Input, Button, Spin, notification, Modal } from 'antd';
import { SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';

import { GlobalState } from 'store';
import { getAuthState, getUser } from 'store/selectors/auth';
import { authLogin, authRefreshUser } from 'store/actions/auth';
import { orgSelect } from 'store/actions/org';

import { IMember } from 'models/member';
import { IUser } from 'models/user';
import { getMemberInvite, acceptMemberInvite } from 'api/members';
import { displayErrorNotification } from 'utils/errors';
import { IMemberAcceptInvitePayload } from 'models/payloads/members';
import { IUserLoginPayload } from 'models/payloads/userLogin';

interface IFormValues {
    email: string;
    inviteCode: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

const mapStateToProps = (state: GlobalState) => ({
    auth: getAuthState(state),
    loggedInUser: getUser(state),
});

interface IAcceptInvitePathParams {
    code: string;
    email: string;
}

interface IAcceptInviteProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<IAcceptInvitePathParams> { }

interface IAcceptInviteState {
    autoCompleteId: string;
    isLoading: boolean;
    isProcessing: boolean;
    member?: IMember;
    user?: IUser;
}

class AcceptInviteBase extends React.PureComponent<IAcceptInviteProps, IAcceptInviteState> {
    state: Readonly<IAcceptInviteState> = {
        autoCompleteId: shortid.generate(),
        isLoading: true,
        isProcessing: false,
    };
    formRef = React.createRef<FormInstance<IFormValues>>();

    async componentDidMount() {
        try {
            const result = await getMemberInvite(this.props.match.params.code, this.props.match.params.email);
            console.log('member invite get result', result);

            this.setState({ member: result.member, user: result.user }, () => {
                this.formRef.current!.setFieldsValue({
                    firstName: result.member.firstName,
                    lastName: result.member.lastName,
                });

                this.checkForLoggedInUser();
            });
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    checkForLoggedInUser = async () => {
        // no logged in user, so let them continue
        if (!this.props.loggedInUser) {
            this.setState({ isLoading: false });
            return;
        }

        if (!this.state.member || !this.state.user) {
            Modal.error({
                title: 'Unknown Error',
                content: 'An unknown error has occurred which is preventing you from accepting an invite. Try again in a few minutes OR contact support.',
                okButtonProps: { style: { display: 'none' }},
            });

            return;
        }

        if (this.props.loggedInUser.id !== this.state.user.id) {
            Modal.error({
                title: 'Invite for Someone Else',
                content: `The invite you attempting to accept is for someone other than you. The invite is for ${ this.state.user.firstName } ${ this.state.user.lastName } and you're logged in as ${ this.props.loggedInUser.firstName } ${ this.props.loggedInUser.lastName }.`,
                okText: 'Whoopies!',
                onOk: () => this.props.history.push('/'),
            });

            return;
        }

        try {
            const payload: IMemberAcceptInvitePayload = {
                code: this.props.match.params.code,
            };

            await acceptMemberInvite(payload);

            await this.props.dispatch(authRefreshUser() as any);
            await this.props.dispatch(orgSelect(this.state.member.shortOrgId) as any);
            this.props.history.push(`/${this.state.member.shortOrgId}/inventories`);
        } catch (e) {
            displayErrorNotification(e);
            return;
        }
    }

    handleResetSubmit = async () => {
        try {
            const values = await this.formRef.current!.validateFields();

            this.setState({ isProcessing: true }, async () => {
                if (!this.state.user || !this.state.member) {
                    return;
                }

                const payload: IMemberAcceptInvitePayload = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    code: values.inviteCode,
                };

                if (this.state.user.createdViaInvite) {
                    payload.password = values.password;
                }

                try {
                    await acceptMemberInvite(payload);
                } catch (e) {
                    displayErrorNotification(e);
                    return;
                }

                const loginPayload: IUserLoginPayload = {
                    email: values.email,
                    password: values.password,
                };

                const result = await this.props.dispatch(authLogin(loginPayload) as any);
                if (result.error) {
                    if (result.error.code === 88) {
                        notification.error({ message: 'Invalid password.' });
                        return;
                    } else if (result.error.code === 1) {
                        notification.error({ message: 'Unknown email provided, no account found.' });
                        return;
                    }

                    notification.error({ message: 'Internal error while logging in.' });
                    return;
                }

                await this.props.dispatch(orgSelect(this.state.member.shortOrgId) as any);
                this.props.history.push(`/${this.state.member.shortOrgId}/inventories`);
            });
        } catch (e) {
            console.warn('error while validating form fields', e);
        }
    }

    get emailInput() {
        return (
            <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to sign up.' }]}>
                <Input
                    size="large"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled
                />
            </Form.Item>
        );
    }

    get inviteCodeInput() {
        return (
            <Form.Item name="inviteCode" rules={[{ required: true, message: 'A valid invite code is required to sign up via an invite.' }]}>
                <Input
                    size="large"
                    placeholder="Invite Code goes here"
                    autoComplete={this.state.autoCompleteId}
                    prefix={<SafetyCertificateOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    disabled
                />
            </Form.Item>
        );
    }

    get firstNameInput() {
        return (
            <Form.Item name="firstName" rules={[{ required: true, message: 'Your first name is required'}]}>
                <Input
                    size="large"
                    placeholder="First name"
                    autoComplete="given-name"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get lastNameInput() {
        return (
            <Form.Item name="lastName" rules={[{ required: true, message: 'Your last name is required'}]}>
                <Input
                    size="large"
                    placeholder="Last name"
                    autoComplete="family-name"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get passwordInput() {
        return (
            <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required.' }]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get confirmPasswordInput() {
        if (this.state.user && !this.state.user.createdViaInvite) {
            return null;
        }

        const rules: Rule[] = [
            { required: true, message: 'A non-empty password is required.' },
            {
                message: 'Passwords must match.',
                validator: async (rule, value) => {
                    if (value === this.formRef.current!.getFieldValue('password')) {
                        return;
                    }

                    throw new Error('Passwords must match.');
                },
            },
        ];

        return (
            <Form.Item name="confirmPassword" rules={rules}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    loginClick = () => {
        this.props.history.push('/auth/login');
    }

    get loginOrRegister() {
        return (
            <Form.Item>
                <div className="action-buttons">
                    <Button size="large" type="primary" htmlType="submit" className="submit" disabled={this.state.isProcessing} loading={this.state.isProcessing}>Sign Up</Button>
                    <Button size="large" className="sign-up" onClick={this.loginClick} disabled={this.state.isProcessing}>Log In</Button>
                </div>
            </Form.Item>
        );
    }

    render() {
        return (
            <Layout className="login-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={6}>
                        <Layout.Content className="login">
                            <Layout className="branding">
                                <img src={`${process.env.PUBLIC_URL}/logo-name.png`} height="80" alt="Lendiom App logo" />
                            </Layout>

                            <h3>Accept Invite</h3>
                            <Form<IFormValues>
                                ref={this.formRef}
                                onFinish={this.handleResetSubmit}
                                initialValues={{
                                    email: decodeURIComponent(this.props.match.params.email || ''),
                                    inviteCode: decodeURIComponent(this.props.match.params.code || ''),
                                }}
                            >
                                <Spin key="loading-accept-invite" tip="Loading..." spinning={this.state.isLoading}>
                                    {this.emailInput}
                                    {this.inviteCodeInput}
                                    <Row gutter={16}>
                                        <Col xs={24} sm={12}>{this.firstNameInput}</Col>
                                        <Col xs={24} sm={12}>{this.lastNameInput}</Col>
                                    </Row>
                                    {this.passwordInput}
                                    {this.confirmPasswordInput}
                                    {this.loginOrRegister}
                                </Spin>
                            </Form>
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>
            </Layout>
        );
    }
}

export const UserAcceptInvite = connect(mapStateToProps)(withRouter(AcceptInviteBase));
