import downloadjs from 'downloadjs';

import { IRestRequest } from 'models/common/restRequest';
import { UnauthorizedError } from 'models/common/restResult';
import { getBearerToken } from 'utils/auth';

export const BASE_URL = `${process.env.REACT_APP_API_URL}/v1`;

export interface IDownloadResult {
    url: string;
}

const handleError = async (response: Response) => {
    //TODO: better error handling; actually throw an error and not random status codes when there is no message
    if (!response.ok) {
        console.log('handling error w/ status code:', response.status);

        if (response.status === 401) {
            throw new UnauthorizedError();
        }

        let message;
        try {
            message = await response.json();
        } catch (e) {
            throw response.status;
        }

        if (message) {
            throw message;
        } else {
            throw response.status;
        }
    }
}

const addAuthentication = (authenticationNeeded: boolean): { Authorization: string } => {
    if (authenticationNeeded) {
        return getBearerToken();
    }

    return { Authorization: '' };
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
const download = async (response: Response) => {
    const disposition = response.headers.get('content-disposition');
    if (!disposition) {
        throw new Error('Invalid download request');
    }

    if (disposition.indexOf('attachment') === -1) {
        throw new Error('Invalid content disposition; must be attachment');
    }


    const matches = filenameRegex.exec(disposition);
    if (!matches || matches.length <= 2) {
        throw new Error('Invalid content disposition; no file name')
    }

    const filename = matches[1].replace(/['"]/g, '');
    const blob = await response.blob();

    let contentType = response.headers.get('content-type');
    if (!contentType) {
        contentType = 'application/octet-stream';
    }

    downloadjs(blob, filename, contentType);
}

const mapRequestToQuery = (req?: IRestRequest): string => {
    if (!req) {
        return '';
    }

    const query = new URLSearchParams();

    Object.keys(req).forEach((k) => {
        const val = req[k];
        if (!k || !val) {
            return;
        }

        if (typeof val === 'string' || typeof val === 'number') {
            query.append(k, `${ val }`);
        } else {
            val.forEach((f) => query.append(k, `${ f.key },${ f.value }`));
        }
    });

    return query.toString();
}

const get = async <TResult>(url: string, authenticated = false, req?: IRestRequest, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomUI',
        },
    };

    let fullUrl = BASE_URL + url;
    const query = mapRequestToQuery(req);
    if (query) {
        fullUrl = `${ fullUrl }?${ query }`;
    }

    const response = await fetch(fullUrl, config);

    await handleError(response);

    if (response.headers.get('content-disposition')) {
        await download(response);
        return {} as TResult;
    }

    return response.json();
}

const post = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'POST',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomUI',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const postForm = async <TResult>(url: string, body: FormData, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'POST',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomUI',
        },
        body,
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const put = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts?: { [key: string]: any }): Promise<TResult> => {
    const config = {
        method: 'PUT',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomUI',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    return response.json();
}

const del = async (url: string, authenticated = false, opts?: { [key: string]: any }) => {
    const config = {
        method: 'DELETE',
        headers: {
            ...addAuthentication(authenticated),
            'X-Requested-With': 'LendiomUI',
            'Content-Type': 'application/json'
        },
    };

    const response = await fetch(BASE_URL + url, config);

    await handleError(response);

    if (response.status === 204) {
        return;
    }

    return response.json();
}

export { get, post, postForm, put, del };
