import React, { useMemo } from 'react';
import { LateFeeChargeType, type ILateFeeTier, LateFeeApplication } from 'models';

interface ILateFeeTierStringProps {
    tier: ILateFeeTier;
}

export const LateFeeTierString = React.memo<ILateFeeTierStringProps>(({ tier }) => {
    const content = useMemo(() => {
        switch (tier.chargeType) {
            case LateFeeChargeType.Fixed:
                return (
                    <React.Fragment>
                        ${tier.fixed} after {tier.days} days
                    </React.Fragment>
                );
            case LateFeeChargeType.Percent:
                return (
                    <React.Fragment>
                        {tier.percentage}% after {tier.days} days<br />
                        ${tier.minimumAmount} min, ${tier.maximumAmount} max
                    </React.Fragment>
                );
        }
    }, [tier]);

    const application = useMemo(() => {
        switch (tier.application) {
            case LateFeeApplication.First:
                return 'First Part of Next Payment';
            case LateFeeApplication.Principal:
                return 'Added to Principal';
            case LateFeeApplication.Balance:
                return 'Added to Late Fee Balance';
        }
    }, [tier]);

    if (!content && !tier) {
        return (
            <React.Fragment>
                Invalid tier
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {content}<br />{application}<br />
        </React.Fragment>
    );
});
