import React, { Suspense, lazy } from 'react';
import currency from 'currency.js';

import { connect, DispatchProp } from 'react-redux';
import { CheckCircleTwoTone, DollarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, FormInstance, Button, Input, InputNumber, Select, Switch, DatePicker, Collapse, Alert, Space, Typography } from 'antd';

import { Currency } from 'models/currency';
import { LengthUnit } from 'models';
import { LoanTermsInterestAccruesDailyFirstPaymentBasis, LoanTermsInterestFormula, LoanTermsInterestSchedule, LoanTransactionExtraApplication, LoanType } from 'models/loan';
import type { ILoanDownPayment } from 'models/loanDownPayment';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import { displayConfirmModal } from 'utils/modals';
import { displayErrorNotification } from 'utils/errors';
import { sanitizeCurrency } from 'utils/numberFormatting';

import { getPaymentAmount } from 'api/helpers';

import { INewLoanFormValues } from './formValues';

const LoanDownPaymentCollectionModal = lazy(() => import('components/loans/downPaymentCollectionModal'));

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    orgId: getSelectedOrgShortId(state),
});

interface INewLoanHowMuchStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    goNext: () => void;
    goBack: () => void;
}

interface INewLoanFormHowMuchStepState {
    loadingPayment: boolean;
    downPaymentOpen: boolean;
}

class NewLoanFormHowMuchStepBase extends React.PureComponent<INewLoanHowMuchStepProps, INewLoanFormHowMuchStepState> {
    state: Readonly<INewLoanFormHowMuchStepState> = {
        loadingPayment: false,
        downPaymentOpen: false,
    }

    fieldChangedDate: Date | undefined = undefined;
    lastCalculatedDate: Date | undefined = undefined;
    lastCalculatedAmount: Currency | undefined = undefined;

    get salesPrice() {
        return (
            <Form.Item
                name="salesPrice"
                label="Sales Price"
                extra="Sales price refers to how much they agreed to pay prior to putting any money down."
                rules={[{ required: true, message: 'Please input the sales price amount' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} min={0} inputMode="numeric" />
            </Form.Item>
        );
    }

    get downPaymentAmount() {
        return (
            <Form.Item
                label="Down Payment"
                extra="Amount of money the Buyer is putting down against the principal."
                required
            >
                <Space.Compact block>
                    <Form.Item name="downPayment" noStyle preserve rules={[{ required: true, message: 'Please input the down payment amount for the loan.' }]}>
                        <Input prefix="$" style={{ width: '100%' }} min={0} inputMode="numeric" />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.downPayment !== curr.downPayment || prev.downPaymentConfig !== curr.downPaymentConfig}>
                        {({ getFieldValue }) => {
                            const dpConfig = getFieldValue('downPaymentConfig') as ILoanDownPayment;
                            const downPayment = getFieldValue('downPayment') as Currency;
                            const disabled = !downPayment || downPayment.length === 0 || currency(downPayment).intValue === 0;

                            return (
                                <Button
                                    type="primary"
                                    icon={typeof dpConfig === 'undefined' ? <DollarOutlined /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                    onClick={() => this.setState({ downPaymentOpen: true })}
                                    disabled={disabled}
                                >Collect via Lendiom</Button>
                            );
                        }}
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
        );
    }

    get totalAdjustmentAmount() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.type !== curr.type}>
                {({ getFieldValue }) => {
                    if (getFieldValue('type') !== LoanType.Residential) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="totalAdjustments"
                            label="Total Adjustments"
                            extra="When closing a new house loan there are usually several adjustments made before the financed amount is calculated. These include, but are not limited to, taxes, escrow, etc. When the value is positive, it adds to the financed amount. When the value is negative, it subtracts from the financed amount."
                        >
                            <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get amountFinanced() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.salesPrice !== curr.salesPrice || prev.downPayment !== curr.salesPrice || prev.totalAdjustments !== curr.totalAdjustments}>
                {({ getFieldValue }) => {
                    const salesPrice = getFieldValue('salesPrice') as Currency;
                    const downPayment = getFieldValue('downPayment') as Currency;

                    let financed = currency(salesPrice).subtract(downPayment);
                    if (getFieldValue('totalAdjustments')) {
                        financed = financed.add(getFieldValue('totalAdjustments'));
                    }

                    return (
                        <Form.Item label="Amount Financed" extra="This is the amount which will be financed and the starting principal amount.">
                            <Input
                                prefix="$"
                                disabled
                                value={financed.format()}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get length() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    const isAccruesDaily = getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily;

                    return (
                        <Form.Item
                            label={
                                isAccruesDaily
                                    ? "Based on Length"
                                    : "Length"
                            }
                            extra={
                                isAccruesDaily
                                    ? "What term/duration is the loan payment amount based on? As the interest schedule is accrues daily, there is no actual length for the loan."
                                    : "How long will the loan be financed for?"
                            }
                            required
                        >
                            <Space.Compact block>
                                <Form.Item noStyle name="length" rules={[{ required: true, message: 'Please input the length of the loan.' }]}>
                                    <InputNumber
                                        min={1}
                                        step={1}
                                        style={{ width: '60%' }}
                                    />
                                </Form.Item>

                                <Form.Item noStyle name="lengthUnit" rules={[{ required: true, message: 'Please state whether the length is months or years.' }]}>
                                    <Select<LengthUnit> style={{ width: '40%' }}>
                                        <Select.Option value={LengthUnit.Months}>Months</Select.Option>
                                        <Select.Option value={LengthUnit.Years}>Years</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Space.Compact>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get interestRate() {
        return (
            <Form.Item
                name="interestRate"
                label="Interest Rate"
                extra="The rate at which the loan is charged interest (only fixed interest rate is supported, for now)."
                rules={[{ required: true, message: 'Please input the interest rate for the loan.' }]}
            >
                <Input suffix="%" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }

    onInterestScheduleChange = (val: LoanTermsInterestSchedule) => {
        switch (val) {
            case LoanTermsInterestSchedule.AccruesDaily:
                this.props.form.setFieldValue('extraApplication', LoanTransactionExtraApplication.Principal);
                break;
        }
    }

    get interestSchedule() {
        return (
            <Form.Item
                name="interestSchedule"
                label="Interest Schedule"
                extra="What schedule does the interest calculation follow? Generally, if your contract has length or duration then the interest follows the payments schedule (amorization schedule)."
                rules={[{ required: true, message: 'You must select the how the interest is calculated.' }]}
                tooltip={
                    <Typography.Link href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-loan#step3-interest-schedule`} target="_blank" rel="noopener noreferrer">
                        Click here for detailed information.
                    </Typography.Link>
                }
            >
                <Select<LoanTermsInterestSchedule> onChange={this.onInterestScheduleChange}>
                    <Select.Option key={LoanTermsInterestSchedule.AccruesDaily}>Accrues Daily</Select.Option>
                    <Select.Option key={LoanTermsInterestSchedule.FollowsPayments}>Follows Payment Schedule</Select.Option>
                </Select>
            </Form.Item>
        );
    }

    get interestFormula() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    if (getFieldValue('interestSchedule') !== LoanTermsInterestSchedule.AccruesDaily) {
                        return null;
                    }

                    return (
                        <React.Fragment>
                            <Form.Item
                                name="interestFormula"
                                label="Interest Formula"
                                extra={(
                                    <React.Fragment>What formula do we use for the daily interest accrual? <a title="How interest accrual method works" href={`${ process.env.REACT_APP_DOCS_URL }/app/how-it-works/interest-accrues-daily`} target="_blank" rel="noopener noreferrer">Click here for a detailed explanation.</a></React.Fragment>
                                )}
                                rules={[{ required: true, message: 'Because the interest accrues daily, you must select the accrual method.' }]}
                            >
                                <Select<LoanTermsInterestFormula>>
                                    <Select.Option key={LoanTermsInterestFormula.ThirtyDaysBy360}>30 / 360</Select.Option>
                                    <Select.Option key={LoanTermsInterestFormula.ActualBy360}>Actual / 360</Select.Option>
                                    <Select.Option key={LoanTermsInterestFormula.ActualBy365}>Actual / 365</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="paymentFrequency"
                                label="Payment Frequency"
                                extra="How often are payments supposed to be made? Currently we only support Monthly, in the future we will support other options."
                            >
                                <Select disabled>
                                    <Select.Option key="monthly">Monthly</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="firstPaymentBasis"
                                label="Finance Start"
                                extra="What date is the basis, or reference point, for calculating how much interest to charge on the first payment?"
                                rules={[{ required: true, message: 'Please provide the finance start so the first payment P&I is correct.' }]}
                            >
                                <Select>
                                    <Select.Option key={LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate}>Down Payment Date</Select.Option>
                                    <Select.Option key={LoanTermsInterestAccruesDailyFirstPaymentBasis.ClosingDate}>Closing Date</Select.Option>
                                    <Select.Option key={LoanTermsInterestAccruesDailyFirstPaymentBasis.FullMonth}>Full Month</Select.Option>
                                </Select>
                            </Form.Item>
                        </React.Fragment>
                    );
                }}
            </Form.Item>
        );
    }

    get downPaymentDate() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule || prev.firstPaymentBasis !== curr.firstPaymentBasis}>
                {({ getFieldValue }) => {
                    if (getFieldValue('interestSchedule') !== LoanTermsInterestSchedule.AccruesDaily) {
                        return null;
                    }

                    if (getFieldValue('firstPaymentBasis') !== LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate) {
                        return null;
                    }

                    return (
                        <Form.Item name="downPaymentDate" label="Down Payment Date" extra="The date when the down payment was paid." rules={[{ required: true, message: 'Please select the down payment date.' }]}>
                            <DatePicker
                                format="MM/DD/YYYY h:mm a"
                                showToday={false}
                                style={{width: '100%'}}
                                showTime
                                changeOnBlur
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    callPaymentCalculator = () => {
        const salesPrice = this.props.form.getFieldValue('salesPrice') as Currency;
        const interestRate = this.props.form.getFieldValue('interestRate') as Currency;
        const length = this.props.form.getFieldValue('length') as number;
        let downPayment = this.props.form.getFieldValue('downPayment') as Currency;
        let adjustments = this.props.form.getFieldValue('totalAdjustments') as Currency;

        if (!salesPrice || !length) {
            return;
        }

        if (!downPayment) {
            downPayment = '0';
        }

        if (!adjustments) {
            adjustments = '0';
        }

        let months = length;
        if (this.props.form.getFieldValue('lengthUnit') === LengthUnit.Years) {
            months = length * 12;
        }

        this.setState({ loadingPayment: true }, async () => {
            try {
                const res = await getPaymentAmount(sanitizeCurrency(salesPrice), sanitizeCurrency(downPayment), sanitizeCurrency(adjustments), months, interestRate ? interestRate : '0');
                this.lastCalculatedDate = new Date();
                this.lastCalculatedAmount = res.paymentRounded;

                this.props.form.setFieldValue('paymentAmount', currency(res.paymentRounded).format());
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ loadingPayment: false });
            }
        });
    }

    callOverwritePaymentCalculator = () => {
        this.props.form.setFieldValue('overwritePaymentAmount', true);
    }

    shouldMonthlyPaymentUpdate = (prev: INewLoanFormValues, curr: INewLoanFormValues) => {
        if (prev.paymentAmount !== curr.paymentAmount) {
            return true;
        }

        let changed = false;

        if (prev.salesPrice !== curr.salesPrice) {
            changed = true;
        } else if (prev.downPayment !== curr.downPayment) {
            changed = true;
        } else if (prev.interestRate !== curr.interestRate) {
            changed = true;
        } else if (prev.length !== curr.length) {
            changed = true;
        } else if (prev.lengthUnit !== curr.lengthUnit) {
            changed = true;
        } else if (prev.interestSchedule !== curr.interestSchedule) {
            changed = true;
        } else if (prev.overwritePaymentAmount !== curr.overwritePaymentAmount) {
            changed = true;
        } else if (prev.totalAdjustments !== curr.totalAdjustments) {
            changed = true;
        }

        if (!changed) {
            return false;
        }

        this.fieldChangedDate = new Date();

        return true;
    }

    get monthlyPayment() {
        return (
            <Form.Item noStyle shouldUpdate={this.shouldMonthlyPaymentUpdate}>
                {({ getFieldValue }) => {
                    const overwritePayment = getFieldValue('overwritePaymentAmount');
                    const paymentAmount = getFieldValue('paymentAmount');
                    const salesPrice = currency(getFieldValue('salesPrice') as Currency, { precision: 14 });
                    const length = currency(getFieldValue('length') as number, { precision: 0 });
                    const interestRate = this.props.form.getFieldValue('interestRate') as Currency;

                    let notEnoughData = false;
                    if (salesPrice.intValue === 0 || length.intValue === 0 || !interestRate) {
                        notEnoughData = true;
                    }

                    let value = paymentAmount;
                    if (notEnoughData) {
                        value = 'Missing data';
                    } else if (!paymentAmount) {
                        value = 'Press calculate';
                    }

                    return (
                        <Form.Item label="Monthly Payment" extra="How much the monthly payment will be based upon the provided terms.">
                            <Space.Compact block>
                                {
                                    notEnoughData || !overwritePayment
                                        ?
                                        <Input
                                            prefix="$"
                                            disabled
                                            value={value}
                                            style={{ width: 'calc(100% - 92px)' }}
                                        />
                                        :
                                        <Form.Item name="paymentAmount" noStyle preserve>
                                            <Input
                                                prefix="$"
                                                disabled={this.state.loadingPayment}
                                                style={{ width: 'calc(100% - 92px)' }}
                                            />
                                        </Form.Item>
                                }
                                <Button
                                    disabled={notEnoughData || this.state.loadingPayment}
                                    loading={this.state.loadingPayment}
                                    onClick={this.callPaymentCalculator}
                                >Calculate</Button>
                                <Button
                                    disabled={notEnoughData || this.state.loadingPayment || !this.lastCalculatedAmount}
                                    loading={this.state.loadingPayment}
                                    onClick={this.callOverwritePaymentCalculator}
                                >Overwrite</Button>
                            </Space.Compact>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get extraPaymentApplication() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    const disabled = getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily;

                    return (
                        <Form.Item
                            name="extraApplication"
                            label="Extra Payment Application"
                            extra="When extra is paid on a payment, where should the extra amount be applied to?"
                            rules={[{ required: true, message: 'Please select how the extra amount should be applied.' }]}
                        >
                            <Select<LoanTransactionExtraApplication> disabled={disabled}>
                                <Select.Option key={LoanTransactionExtraApplication.Principal} value={LoanTransactionExtraApplication.Principal}>Principal</Select.Option>
                                <Select.Option key={LoanTransactionExtraApplication.NextPayment} value={LoanTransactionExtraApplication.NextPayment}>Next Payment</Select.Option>
                            </Select>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get isExistingLoan() {
        return (
            <Form.Item name="isExistingLoan" label="New or Existing Loan" extra="Is this loan a pre-existing one? If so, we require a few more details." required valuePropName="checked">
                <Switch checkedChildren="Existing" unCheckedChildren="New" />
            </Form.Item>
        );
    }

    get principalPaid() {
        let extra = "How much principal they have paid down (excluding partial payments).";
        if (this.props.form.getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily) {
            extra = "How much principal they have paid down (including partial payments)."
        }

        return (
            <Form.Item
                name="principalPaid"
                label="Principal Paid*"
                extra={extra}
                rules={[{ required: this.props.form.getFieldValue('isExistingLoan'), message: 'Please input the amount of principal already paid.' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }

    get interestPaid() {
        let extra = "How much interest they have paid (excluding partial payments) all time (including this year)?";
        if (this.props.form.getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily) {
            extra = "How much interest they have paid (including partial payments) all time (including this year)?"
        }

        return (
            <Form.Item
                name="interestPaid"
                label="Interest Paid*"
                extra={extra}
                rules={[{ required: this.props.form.getFieldValue('isExistingLoan'), message: 'Please input the amount of interest already paid, including this year.' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }

    get interestPaidYearToDate() {
        return (
            <Form.Item
                name="interestPaidYtd"
                label="Interest Paid YTD"
                extra="How much interest has been paid year to date? We will use this amount if you generate a 1098INT form."
                rules={[{ required: this.props.form.getFieldValue('isExistingLoan'), message: 'Please input the amount of interest paid year to date.' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }

    get interestBalanceUnpaid() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    if (getFieldValue('interestSchedule') !== LoanTermsInterestSchedule.AccruesDaily) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="interestBalanceUnpaid"
                            label="Unpaid Interest Balance"
                            extra="How much unpaid interest balance do they have?"
                            dependencies={[['interestSchedule']]}
                            rules={[{ required: getFieldValue('isExistingLoan'), message: 'The unpaid interest balance is required when interest accrues daily and it is an existing loan.' }]}
                        >
                            <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get lastPaidInFullPaymentNumber() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.length !== curr.length || prev.lengthUnit !== curr.lengthUnit || prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    if (getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="lastPaidInFullPayment"
                            label="Last Full Payment Number"
                            extra="What payment number did they last pay in full? You will enter any partial payments after the loan is created."
                            dependencies={[['length'], ['lengthUnit']]}
                            rules={[{ required: getFieldValue('isExistingLoan'), message: 'The last paid in full payment number is required for an existing loan.' }]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                inputMode="numeric"
                                min={1}
                                max={getFieldValue('lengthUnit') === LengthUnit.Years ? getFieldValue('length') * 12 : getFieldValue('length')}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get existingLastPaymentDate() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.interestSchedule !== curr.interestSchedule}>
                {({ getFieldValue }) => {
                    if (getFieldValue('interestSchedule') !== LoanTermsInterestSchedule.AccruesDaily) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="lastPaymentDate"
                            label="Last Payment Date"
                            extra="The date the last payment was made."
                            rules={[{ required: getFieldValue('isExistingLoan'), message: 'Please select the day the last payment was made on.' }]}
                            style={{ width: '100%' }}
                        >
                            <DatePicker
                                format="MM/DD/YYYY h:mm a"
                                showToday={false}
                                showTime
                                changeOnBlur
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get existingNextPaymentDate() {
        return (
            <Form.Item
                name="nextPaymentDate"
                label="Next Payment Date"
                extra="The date the next payment is due on."
                rules={[{ required: true, message: 'Please select the day the next payment is due.' }]}
                style={{ width: '100%' }}
            >
                <DatePicker format="MM/DD/YYYY" showToday={false} />
            </Form.Item>
        );
    }

    get existingLoanAlert() {
        let description = (
            <React.Fragment>
                Starting a loan's amortization schedule in the middle of it is complex. As such, when putting in the amounts for Principal Paid and Interest Paid, please <strong>exclude</strong> any partial amount paid towards the next payment due. Should you include the partial amounts, then the amorization schedule will be incorrect.
                For more information, please see the documentation here: <a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-pre-existing-loan`} target="_blank" rel="noopener noreferrer">Creating a Pre-Existing Loan</a>
            </React.Fragment>
        );

        if (this.props.form.getFieldValue('interestSchedule') === LoanTermsInterestSchedule.AccruesDaily) {
            description = (
                <React.Fragment>
                    Importing an existing loan requires some additional information. When putting in the amounts for Principal Paid and Interest Paid, please <strong>include</strong> any partial amount paids.
                    For more information, please see the documentation here: <a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-pre-existing-loan`} target="_blank" rel="noopener noreferrer">Creating a Pre-Existing Loan</a>
                </React.Fragment>
            )
        }

        return (
            <Alert
                message="Existing Loan Requirements"
                description={description}
                type="warning"
                showIcon
                style={{ marginBottom: '25px' }}
            />
        );
    }

    get existingLoanInformation() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.isExistingLoan !== curr.isExistingLoan}>
                {({ getFieldValue }) => {
                    const isExisting = getFieldValue('isExistingLoan');

                    return (
                        <Collapse activeKey={isExisting ? ['info'] : []} style={{ marginBottom: '25px' }}>
                            <Collapse.Panel header="Existing Loan Information" key="info" collapsible={!isExisting ? 'disabled' : undefined}>
                                {this.existingLoanAlert}

                                {this.principalPaid}
                                {this.interestPaid}
                                {this.interestPaidYearToDate}
                                {this.interestBalanceUnpaid}
                                {this.lastPaidInFullPaymentNumber}
                                {this.existingLastPaymentDate}
                                {this.existingNextPaymentDate}
                            </Collapse.Panel>
                        </Collapse>
                    );
                }}
            </Form.Item>
        );
    }

    closeDownPaymentModal = (dpConfig?: ILoanDownPayment) => {
        if (dpConfig) {
            this.props.form.setFieldValue('downPaymentConfig', dpConfig);
        }

        this.setState({ downPaymentOpen: false });
    }

    get downPaymentModal() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.downPayment !== curr.downPayment || prev.downPaymentConfig !== curr.downPaymentConfig}>
                {({ getFieldValue }) => {
                    return (
                        <Suspense fallback={null}>
                            <LoanDownPaymentCollectionModal
                                open={this.state.downPaymentOpen}
                                downPaymentAmount={currency(getFieldValue('downPayment'), { precision: 2 })}
                                initial={getFieldValue('downPaymentConfig') as ILoanDownPayment}
                                close={this.closeDownPaymentModal}
                            />
                        </Suspense>
                    );
                }}
            </Form.Item>
        );
    }

    onNextClick = async () => {
        const overwritePayment = this.props.form.getFieldValue('overwritePaymentAmount');
        const overwritten = currency(this.props.form.getFieldValue('paymentAmount'), { precision: 2 });

        if (!this.lastCalculatedDate && !(await displayConfirmModal('Payment not Calculated', 'The payment has not been calculated, are you sure you want to continue?', 'Yes', 'Whoops!'))) {
            return;
        }

        if (overwritePayment) {
            if (overwritten.intValue <= 0) {
                displayConfirmModal('Overwrite Payment Amount', 'The payment amount cannot be less than or equal to zero. If you are looking to do a down payment loan, the "Monthly Payment" must be equal to the amount required.', null, 'Let me fix it.');
                return;
            }

            const calculated = currency(this.lastCalculatedAmount || '0', { precision: 2 });
            if (overwritten.intValue < calculated.intValue) {
                const goOn = await displayConfirmModal('Overwrite Payment Amount', `The payment amount (${ overwritten.format(true) }) is less than the calculated payment amount (${ calculated.format(true) }). Are you sure you want to continue? Doing so could result in an infinite loan.`, 'Yes', 'No');

                if (!goOn) {
                    return;
                }
            }

            this.props.goNext();
            return;
        }

        if (this.fieldChangedDate && this.lastCalculatedDate && this.fieldChangedDate.getTime() > this.lastCalculatedDate.getTime()) {
            const goOn = await displayConfirmModal('Details Changed', 'The data which goes into calculating the payment has changed but the payment has not been calculated, would you like to continue anyway?', 'Yes', 'Whoops, no!');

            if (!goOn) {
                return;
            }
        }

        this.props.goNext();
    }

    get bottomButtons() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue }) => {
                    const salesPrice = currency(getFieldValue('salesPrice'));
                    const downPayment = getFieldValue('downPayment');
                    const length = parseInt(getFieldValue('length'));
                    const lengthUnit = getFieldValue('lengthUnit');
                    const interestRate = getFieldValue('interestRate');
                    const interestSchedule = getFieldValue('interestSchedule');
                    const isExisting = getFieldValue('isExistingLoan') as boolean;

                    let nextDisabled = !salesPrice || salesPrice.value <= 0 || (typeof downPayment !== 'string' || downPayment.length <= 0)
                        || !length || length <= 0 || !lengthUnit
                        || (typeof interestRate !== 'string' || interestRate.length <= 0)
                        || !interestSchedule || (interestSchedule === LoanTermsInterestSchedule.AccruesDaily && !getFieldValue('interestFormula') && !getFieldValue('firstPaymentBasis'))
                        || (interestSchedule === LoanTermsInterestSchedule.AccruesDaily && getFieldValue('firstPaymentBasis') === LoanTermsInterestAccruesDailyFirstPaymentBasis.DownPaymentDate && !getFieldValue('downPaymentDate'))
                        || (isExisting && (!getFieldValue('principalPaid') || !getFieldValue('interestPaid') || !getFieldValue('nextPaymentDate')))
                        || !getFieldValue('paymentAmount') || this.state.loadingPayment;

                    // if it is existing and next is not disabled, let's check additional information
                    // based on the interest schedule selected
                    if (isExisting && !nextDisabled) {
                        switch (interestSchedule) {
                            case LoanTermsInterestSchedule.AccruesDaily:
                                nextDisabled = !getFieldValue('interestBalanceUnpaid') || !getFieldValue('lastPaymentDate');
                                break;
                            case LoanTermsInterestSchedule.FollowsPayments:
                                nextDisabled = !getFieldValue('lastPaidInFullPayment');
                                break;
                        }
                    }

                    if (salesPrice.subtract(downPayment).intValue <= 0) {
                        nextDisabled = true;
                    }

                    return (
                        <Button.Group>
                            <Button icon={<LeftOutlined />} onClick={this.props.goBack}>Previous</Button>
                            <Button type="primary" onClick={this.onNextClick} disabled={nextDisabled}>
                                Next <RightOutlined />
                            </Button>
                        </Button.Group>
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.salesPrice}
                {this.downPaymentAmount}
                {this.totalAdjustmentAmount}
                {this.amountFinanced}
                {this.interestRate}
                {this.interestSchedule}
                {this.interestFormula}
                {this.downPaymentDate}
                {this.length}
                {this.monthlyPayment}
                {this.extraPaymentApplication}
                {this.isExistingLoan}
                {this.existingLoanInformation}

                {this.bottomButtons}

                {this.downPaymentModal}
            </React.Fragment>
        );
    }
}

export const NewLoanHowMuchStep = connect(mapStateToProps)(NewLoanFormHowMuchStepBase);
