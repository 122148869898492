export * from './lateFee';

export enum CommunicationPreference {
    Email = 'email',
    SMS = 'sms',
    Mail = 'mail',
}

export enum LengthUnit {
    Months = 'months',
    Years = 'years',
}

export enum OnlinePaymentPlatformFeePayee {
    Buyer = 'buyer',
    Both = 'both',
    Seller = 'seller',
}

export enum PaymentFrequency {
    Weekly = 'weekly',
    Biweekly = 'biweekly',
    SemiMonthly = 'semimonthly',
    Monthly = 'monthly',
    Bimonthly = 'bimonthly',
    Quarterly = 'quarterly',
    SemiAnnually = 'semiannually',
    Annually = 'annually',
}

export interface ICommunicationConfig {
    automated: boolean;
    preferences: CommunicationPreference[];
}
