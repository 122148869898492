import React from 'react';
import dayjs from 'dayjs';
import { isMobileOnly } from 'react-device-detect';
import { Modal, Row, Col, Form, DatePicker, Button, Divider, Descriptions } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';

import { LoanTermsInterestSchedule, type ILoan, type ILoanPayOff } from 'models/loan';
import { LongCurrency } from 'utils/formatting';

import { getPayOffInfo } from 'api/loans';

interface IViewPayOffModalProps {
    loan: Partial<ILoan>;
    isVisible: boolean;
    close: () => void;
}

interface IViewPayOffModalState {
    isLoading: boolean;
    payOffDate: Dayjs;
    payOff?: ILoanPayOff;
    generatingLetter: boolean;
}

export class ViewPayOffModal extends React.PureComponent<IViewPayOffModalProps, IViewPayOffModalState> {
    state: Readonly<IViewPayOffModalState> = {
        isLoading: true,
        payOffDate: dayjs(),
        generatingLetter: false,
    }

    now = dayjs();

    componentDidUpdate(prevProps: Readonly<IViewPayOffModalProps>): void {
        if (prevProps.isVisible) {
            return;
        }

        if (this.props.isVisible) {
            this.loadData();
        }
    }

    loadData = () => {
        this.setState({ isLoading: true }, async () => {
            const { loan } = this.props;

            if (!loan || !loan.id || !loan.organization || !loan.organization.id) {
                return;
            }

            const payoff = await getPayOffInfo(loan.organization.id, loan.id, this.state.payOffDate.toJSON());
            this.setState({ isLoading: false, payOff: payoff });
        });
    }

    disabledDate = (current: Dayjs | null): boolean => {
        if (!current) {
            return false;
        }

        return current.isBefore(this.now);
    }

    onDateChange = (value: Dayjs | null) => {
        if (!value) {
            return;
        }

        this.setState({ payOffDate: value }, this.loadData);
    }

    get datePicker() {
        return (
            <Form.Item label="Select Pay Off Date">
                <DatePicker
                    format="MM/DD/YYYY"
                    defaultValue={dayjs()}
                    disabledDate={this.disabledDate}
                    onChange={this.onDateChange}
                    showNow
                    disabled={this.state.generatingLetter || this.state.isLoading}
                />
            </Form.Item>
        );
    }

    get payOffDetails() {
        const { loan } = this.props;
        const { payOff } = this.state;

        if (!payOff || !loan) {
            return null;
        }

        let columns = 2;
        if (isMobileOnly) {
            columns = 1;
        }

        return (
            <Row>
                <Col>
                    <Divider orientation="left">Pay Off Details</Divider>
                    <Descriptions bordered column={columns}>
                        <Descriptions.Item label="Total"><LongCurrency value={payOff.total} /></Descriptions.Item>
                        <Descriptions.Item label="Principal"><LongCurrency value={payOff.toPrincipal} /></Descriptions.Item>
                        <Descriptions.Item label="Interest"><LongCurrency value={payOff.toInterest} /></Descriptions.Item>
                        { loan.terms?.interestSchedule === LoanTermsInterestSchedule.AccruesDaily ? <Descriptions.Item label="Unpaid Interest"><LongCurrency value={payOff.toUnpaidInterest} /></Descriptions.Item> : null }
                        <Descriptions.Item label="Fees"><LongCurrency value={payOff.toFees} /></Descriptions.Item>
                        <Descriptions.Item label="Other Fees"><LongCurrency value={payOff.toOtherFees} /></Descriptions.Item>
                        <Descriptions.Item label="Property Tax"><LongCurrency value={payOff.toPropertyTax} /></Descriptions.Item>
                        { loan.escrow ? <Descriptions.Item label="Escrow"><LongCurrency value={payOff.toEscrow} /></Descriptions.Item> : null }
                    </Descriptions>
                </Col>
            </Row>
        );
    }

    onGenerateClick = () => {
        this.setState({ generatingLetter: true }, async () => {
            const { loan } = this.props;

            if (!loan || !loan.id || !loan.organization || !loan.organization.id) {
                return;
            }

            const payOff = await getPayOffInfo(loan.organization.id, loan.id, this.state.payOffDate.toJSON(), true);
            if (payOff.letterUrl) {
                window.open(payOff.letterUrl, '_blank');
            }

            this.setState({ isLoading: false, payOff, generatingLetter: false });
        });
    }

    get generateLetter() {
        return (
            <Button
                key="generate-payoff"
                onClick={this.onGenerateClick}
                disabled={this.state.generatingLetter || this.state.isLoading}
                loading={this.state.generatingLetter}
            >Generate Letter</Button>
        );
    }

    render() {
        const { isVisible } = this.props;

        if (!isVisible) {
            return null;
        }

        let width = '600px';
        if (isMobileOnly) {
            width = '100vw';
        }

        return (
            <Modal
                open={isVisible}
                width={width}
                title={<span><CalculatorOutlined /> Pay Off Calculator</span>}
                footer={[
                    this.generateLetter,
                    <Button key="close" type="primary" loading={this.state.isLoading} disabled={this.state.generatingLetter || this.state.isLoading} onClick={this.props.close}>
                        Close
                    </Button>
                ]}
                onCancel={this.props.close}
                closable={!this.state.isLoading && !this.state.generatingLetter}
            >
                <Row>
                    <Col>
                        {this.datePicker}
                    </Col>
                </Row>
                {this.payOffDetails}
            </Modal>
        );
    }
}
