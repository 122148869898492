import React from 'react';
import { Tag } from 'antd';

import { LoanStatus } from 'models/loan';

interface ILoanStatusTagProps {
    status: LoanStatus
};

export const LoanStatusTag: React.FC<ILoanStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case LoanStatus.Draft:
        case LoanStatus.Pending:
            color = 'gold';
            break;
        case LoanStatus.Current:
            color = 'green';
            break;
        case LoanStatus.GracePeriod:
            color = 'orange';
            break;
        case LoanStatus.InDefault:
        case LoanStatus.Late:
            color = 'red';
            break;
        case LoanStatus.Defaulted:
        case LoanStatus.Repossessed:
            color = 'magenta';
            break;
        case LoanStatus.Inactive:
        case LoanStatus.Canceled:
            color = 'volcano';
            break;
        case LoanStatus.PendingPayoff:
        case LoanStatus.PaidOff:
            color = 'geekblue';
            break;
        default:
            color = 'purple';
            break;
    }

    let tagLabel = `${ props.status.replaceAll('-', ' ') }`;
    switch(props.status) {
        case LoanStatus.Draft:
            tagLabel = 'Draft';
            break;
        case LoanStatus.Pending:
            tagLabel = 'Pending';
            break;
        case LoanStatus.Current:
            tagLabel = 'Current';
            break;
        case LoanStatus.GracePeriod:
            tagLabel = 'Grace Period';
            break;
        case LoanStatus.Late:
            tagLabel = 'Late';
            break;
        case LoanStatus.InDefault:
            tagLabel = 'In Default';
            break;
        case LoanStatus.Defaulted:
            tagLabel = 'Defaulted';
            break;
        case LoanStatus.PendingPayoff:
            tagLabel = 'Pending Payoff';
            break;
        case LoanStatus.PaidOff:
            tagLabel = 'Paid Off';
            break;
        case LoanStatus.Repossessed:
            tagLabel = 'Repossessed';
            break;
        case LoanStatus.Inactive:
            tagLabel = 'Inactive';
            break;
        case LoanStatus.Canceled:
            tagLabel = 'Canceled';
            break;
        default:
            tagLabel = 'Unknown';
            break;
    }

    return (
        <Tag color={color}>{tagLabel}</Tag>
    );
});
