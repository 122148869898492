import { post, postForm, get, put, del } from './index';

import type { IOrganization, IOrganizationStripeConnect, IOrganizationUnveilSite } from 'models/organization';
import type {
    IOrganizationCreationPayload,
    IOrganizationPaymentMethodAddPayload,
    IOrganizationSubscribePayload,
    IOrganizationUpdateBasicDetailsPayload,
    IOrganizationUpdateBillingDetailsPayload,
    IOrganizationStripeConnectStart,
    IOrganizationSubscriptionUpdatePayload,
    IOrganizationLoanDefaultingPreferences,
    IOrganizationLoanLateFeePreferences,
    IOrganizationCommunicationPreferences,
} from 'models/payloads/organization';
import type { IOrganizationLegalPayload, IOrganizationOfficePayload, IOrganizationSupportPayload } from 'models/payloads/organizationLegal';
import type { IOrganizationPayArcApplicationFinalizePayload, IOrganizationPayArcDocumentsPayload, IOrganizationPayArcDocumentsResults, IOrganizationPayArcFinancialPayload } from 'models/payloads/organizationPayArc';
import type { IOrganizationLegalOwner } from 'models/organizationLegal';
import type { ISetDefaultPaymentMethodPayload } from 'models/payloads/billing';

import type { FileMap, IFile } from 'models/file';
import type { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import type { IRestResult } from 'models/common/restResult';
import type { IPayArcDepositBatchDetail, IPayArcMonthlyDeposit, IPayArcCharge } from 'models/payarc';

export const createOrganization = (payload: IOrganizationCreationPayload) => post<IOrganizationCreationPayload, IOrganization>('/organizations', payload, true);
export const updateBasicOrganizationInfo = (orgId: string, payload: IOrganizationUpdateBasicDetailsPayload) => put<IOrganizationUpdateBasicDetailsPayload, IOrganization>(`/organizations/${ orgId }`, payload, true);
export const updateBillingOrganizationInfo = (orgId: string, payload: IOrganizationUpdateBillingDetailsPayload) => put<IOrganizationUpdateBillingDetailsPayload, IOrganization>(`/organizations/${ orgId }/billing/address`, payload, true);

export const getOrganization = (orgId: string) => get<IOrganization>(`/organizations/${ orgId }`, true);

export const getOrganizationFilesAsVFS = (orgId: string) => get<FileMap>(`/organizations/${ orgId }/files?as=vfs`, true);
export const uploadOrganizationFile = (orgId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/files`, formData, true);
export const createNewOrganizationFileFolder = (orgId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const updateOrganizationFile = (orgId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/files/${ fileId }`, payload, true);
export const deleteOrganizationFile = (orgId: string, fileId: string) => del(`/organizations/${ orgId }/files/${ fileId }`, true);
export const getOrganizationFile = (orgId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/files/${ fileId }`, true);

export const createPaymentMethod = (orgId: string, payload: IOrganizationPaymentMethodAddPayload) => post<IOrganizationPaymentMethodAddPayload, any>(`/organizations/${ orgId }/billing/paymentMethods`, payload, true);
export const setPaymentMethodAsDefault = (orgId: string, payload: ISetDefaultPaymentMethodPayload) => post<ISetDefaultPaymentMethodPayload, any>(`/organizations/${ orgId }/billing/paymentMethods/set-default`, payload, true);
export const getPaymentIntentSecret = (orgId: string) => get<{ secret: string }>(`/organizations/${ orgId }/billing/paymentMethods/add`, true);
export const getPaymentIntentActionSecret = (orgId: string, invoiceId: string) => get<{ secret: string }>(`/organizations/${ orgId }/billing/invoices/${ invoiceId }/action`, true);
export const deletePaymentMethod = (orgId: string, paymentMethodId: string) => del(`/organizations/${ orgId }/billing/paymentMethods/${ paymentMethodId }`, true);

export const createSubscription = (orgId: string, payload: IOrganizationSubscribePayload) => post<IOrganizationSubscribePayload, IOrganization>(`/organizations/${ orgId }/billing/subscriptions`, payload, true);
export const updateSubscription = (orgId: string, payload: IOrganizationSubscriptionUpdatePayload) => put<IOrganizationSubscriptionUpdatePayload, void>(`/organizations/${ orgId }/billing/subscriptions`, payload, true);

//stripe connect
export const createStripeConnectLink = (orgId: string) => get<IOrganizationStripeConnectStart>(`/organizations/${ orgId }/stripe/start-link`, true);
export const syncStripeConnectAccount = (orgId: string) => get<IOrganizationStripeConnect>(`/organizations/${ orgId }/stripe`, true);

// organization preferences
export const updateOrganizationLoanDefaultingPreferences = (orgId: string, payload: IOrganizationLoanDefaultingPreferences) => post<IOrganizationLoanDefaultingPreferences, void>(`/organizations/${ orgId }/preferences/loans/defaulting`, payload, true);
export const updateOrganizationLoanLateFeePreferences = (orgId: string, payload: IOrganizationLoanLateFeePreferences) => post<IOrganizationLoanLateFeePreferences, void>(`/organizations/${ orgId }/preferences/loans/late-fees`, payload, true);
export const updateOrganizationLoanCommunicationPreferences = (orgId: string, payload: IOrganizationCommunicationPreferences) => post<IOrganizationCommunicationPreferences, void>(`/organizations/${ orgId }/preferences/loans/communication`, payload, true);

//#region legal
export const setOrganizationLegalInfo = (orgId: string, payload: IOrganizationLegalPayload) => post<IOrganizationLegalPayload, void>(`/organizations/${ orgId }/legal`, payload, true);
export const setOrganizationOwners = (orgId: string, owners: IOrganizationLegalOwner[]) => post<{ owners: IOrganizationLegalOwner[] }, void>(`/organizations/${ orgId }/legal/owners`, { owners }, true);
export const getOrganizationOwners = (orgId: string) => get<IOrganizationLegalOwner[]>(`/organizations/${ orgId }/legal/owners`, true);
export const setOrganizationOwnersDriversLicense = (orgId: string, data: FormData) => postForm(`/organizations/${ orgId }/legal/owners/drivers-license`, data, true);
export const setOrganizationOffice = (orgId: string, payload: IOrganizationOfficePayload) => post<IOrganizationOfficePayload, void>(`/organizations/${ orgId }/legal/office`, payload, true);
export const setOrganizationSupport = (orgId: string, payload: IOrganizationSupportPayload) => post<IOrganizationSupportPayload, void>(`/organizations/${ orgId }/legal/support`, payload, true);
//#endregion

//#region payarc
export const getOrganizationPayArcFinancial = (orgId: string) => get<IOrganizationPayArcFinancialPayload>(`/organizations/${ orgId }/payarc/card-financial`, true);
export const setOrganizationPayArcFinancial = (orgId: string, payload: IOrganizationPayArcFinancialPayload) => post<IOrganizationPayArcFinancialPayload, void>(`/organizations/${ orgId }/payarc/card-financial`, payload, true);
export const getOrganizationPayArcFiles = (orgId: string) => get<IOrganizationPayArcDocumentsResults>(`/organizations/${ orgId }/payarc/documents`, true);
export const putOrganizationPayArcFiles = (orgId: string, payload: IOrganizationPayArcDocumentsPayload) => put<IOrganizationPayArcDocumentsPayload, void>(`/organizations/${ orgId }/payarc/documents`, payload, true);
export const postOrganizationPayArcApplication = (orgId: string, payload: IOrganizationPayArcApplicationFinalizePayload) => post<IOrganizationPayArcApplicationFinalizePayload, void>(`/organizations/${ orgId }/payarc/application`, payload, true);
export const getOrganizationCardCharges = (orgId: string, limit: number, offset: number) => get<IRestResult<IPayArcCharge>>(`/organizations/${ orgId }/payarc/charges/cards?limit=${ limit }&offset=${ offset }`, true);
export const getOrganizationBankCharges = (orgId: string, limit: number, offset: number) => get<IRestResult<IPayArcCharge>>(`/organizations/${ orgId }/payarc/charges/banks?limit=${ limit }&offset=${ offset }`, true);
export const getOrganizationPayArcDeposit = (orgId: string, month: number, year: number) => get<IPayArcMonthlyDeposit>(`/organizations/${ orgId }/payarc/deposits?month=${ year }-${ month }-01`, true);
export const getOrganizationPayArcDepositDetails = (orgId: string, depositId: number) => get<IPayArcDepositBatchDetail[]>(`/organizations/${ orgId }/payarc/deposits/${ depositId }`, true);
//#endregion

//#region unveil site
export const getOrganizationUnveilSite = (orgId: string) => get<IOrganizationUnveilSite>(`/organizations/${ orgId }/site`, true);
export const postOrganizationUnveilSite = (orgId: string) => post<{}, IOrganizationUnveilSite>(`/organizations/${ orgId }/site`, {}, true);
//#endregion
