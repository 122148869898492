import { Currency } from './currency';

export interface ILateFeeConfig {
    disabled: boolean;
    tiers: ILateFeeTier[];
}

export interface ILateFeeTier {
    days: number;
    application: LateFeeApplication;
    chargeType: LateFeeChargeType;

    fixed?: Currency;
    percentage?: Currency;

    minimumAmount?: Currency;
    maximumAmount?: Currency;
}

export enum LateFeeApplication {
    First = 'first',
    Principal = 'principal',
    Balance = 'balance',
}

export enum LateFeeChargeType {
    Fixed = 'fixed',
    Percent = 'percent',
}
