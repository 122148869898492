import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';

export const ConnectivityBanner: React.FC = () => {
    const [isOffline, setOffline] = useState(false);

    const offlineHandler = () => {
        setOffline(true);
    };

    const onlineHandler = () => {
        setOffline(false);
    };

    useEffect(() => {
        window.addEventListener('offline', offlineHandler);
        window.addEventListener('online', onlineHandler);

        return function removeOfflineEventListener() {
            window.removeEventListener('offline', offlineHandler);
            window.removeEventListener('online', onlineHandler);
        }
    }, []);

    if (isOffline) {
        return (
            <Alert
                message="Your connection is offline. Saving is currently disabled."
                type="warning"
                banner
                style={{ zIndex: 100000 }}
            />
        );
    }

    return null;
}
