import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import ProLayout, { ProSettings } from '@ant-design/pro-layout';
import { notification } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import type { HeaderProps } from '@ant-design/pro-layout';
import { isMobileOnly } from 'react-device-detect';

import { getSelectedOrgShortId, getSelectedOrg, isFetchingOrg } from 'store/selectors/org';
import { getUser } from 'store/selectors/auth';
import { orgSelect, orgCreateSuccess } from 'store/actions/org';
import { getPermissionsLoading, getArePermissionsLoaded, getArePermissionLoadedForOrg } from 'store/selectors/permissions';
import { permissionLoadForOrg, permissionsLoad } from 'store/actions/permissions';
import { authLogout } from 'store/actions/auth';

import { FullScreenLoading } from 'components/misc/loading';
import { useUserHeaderProps } from 'components/layout/headerRight/userHeaderMenu';
import { getHeaderActions } from 'components/layout/headerRight/headerActions';
import { menuItemRender, menuDataRender } from 'components/layout/menu';

import { ServerEventSource } from 'components/sse/eventSource';

// Views
import { Dashboard } from 'views/dashboard';
import { Inventories } from 'views/inventories';
import { Inventory } from 'views/inventory';
import { NewInventory } from 'views/inventory/new';
import { PropertyTax } from 'views/inventory/tax';
import { Tract } from 'views/tract';
import { Client } from 'views/client';
import { Clients } from 'views/clients';
import { NewClient } from 'views/clients/new';

import { NewLoan } from 'views/loans/new';
import { Loan } from 'views/loan';

import { OrganizationSettings } from 'views/organization/settings';

import { OrgSelectView } from './orgSelect';

//TODO: see about removing this
import { hasValidToken } from 'utils/auth';

const AccountSettings = lazy(() => import('views/account/settings'));
const Loans = lazy(() => import('views/loans'));
const RentalsNew = lazy(() => import('views/rentals/new'));
const Rentals = lazy(() => import('views/rentals'));
const RentalView = lazy(() => import('views/rental'));
const Communications = lazy(() => import('views/communication'));
// const CustomizeWebsite = lazy(() => import('views/customize/website'));
// const CustomizeMail = lazy(() => import('views/customize/mail'));
const DocumentListView = lazy(() => import('views/documents'));
const DocumentBuilderView = lazy(() => import('views/documents/builder/builder'));
const ReportsView = lazy(() => import('views/reports'));
const FilesView = lazy(() => import('views/files'));
const PayArcMerchantInfo = lazy(() => import('views/payarc/merchant'));
const PayArcTransactions = lazy(() => import('views/payarc/transactions'));
const PayArcDeposits = lazy(() => import('views/payarc/deposits'));

const SentryRoute = Sentry.withSentryRouting(Route);

const settings: Partial<ProSettings> = {
    fixSiderbar: true,
    layout: 'mix',
    siderMenuType: 'sub',
    splitMenus: false,
    fixedHeader: true,
};

export const MainLayout: FC = () => {
    // const [showSupportAlert, setShowSupportAlert] = useState(window.localStorage.getItem('lendiom.support.email.alert') !== 'false');
    const [collapsed, setCollapsed] = useState(isMobileOnly);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const loggedInUser = useSelector(getUser);
    const orgId = useSelector(getSelectedOrgShortId);
    const isFetching = useSelector(isFetchingOrg);
    const selectedOrg = useSelector(getSelectedOrg);
    const arePermissionsLoading = useSelector(getPermissionsLoading);
    const arePermissionsLoaded = useSelector(getArePermissionsLoaded);
    const arePermissionsLoadedForSelectedOrg = useSelector(getArePermissionLoadedForOrg);
    const userHeaderProps = useUserHeaderProps();

    const callOrgToActionAsItNeedsFinished = useCallback(() => {
        console.log('setup not completed');

        dispatch(orgCreateSuccess(selectedOrg!));
        history.push('/org/new');
    }, [dispatch, history, selectedOrg]);

    useEffect(() => {
        if (!selectedOrg) {
            return;
        }

        if (selectedOrg && selectedOrg.setupComplete) {
            return;
        }

        callOrgToActionAsItNeedsFinished();
    }, [selectedOrg, callOrgToActionAsItNeedsFinished]);

    useEffect(() => {
        const previous = `?previous=${ encodeURIComponent(location.pathname+location.search) }`;

        if (!hasValidToken()) {
            console.log('has INVALID token');
            dispatch(authLogout());
            history.push(`/auth/login${ previous }`);
            return;
        }

        if (!loggedInUser) {
            console.log('no user found, redirecting to the login page (but they have a valid token?)');
            window.localStorage.clear();
            history.push(`/auth/login${ previous }`);
            return;
        }

        if (!loggedInUser.emailVerified) {
            console.log('user has not verified their email address');
            history.push(`/auth/verify-email${ previous }`);
            return;
        }

        if (loggedInUser.organizations.length === 0) {
            console.log('User has no organizations');
            notification.info({ message: 'Let\'s set you up with your first organization.' });
            history.push('/org/new');
            return;
        }

        let orgIdToSelect = '';

        const pathSplit = location.pathname.split('/');
        if (pathSplit.length >= 2) {
            const potentialId = pathSplit[1];

            loggedInUser.organizations.forEach((o) => {
                if (o.shortId === potentialId) {
                    orgIdToSelect = o.shortId;
                }
            });
        }

        if (!orgId && !orgIdToSelect && loggedInUser.organizations.length >= 1) {
            console.log('no organization selected and no organization in the url, so we are selecting the first org');
            dispatch(orgSelect(loggedInUser.organizations[0].shortId!) as any);
            return;
        }

        if (orgIdToSelect && !orgId) {
            console.log('found an org in the url but no org has been selected yet');
            dispatch(orgSelect(orgIdToSelect) as any);
            return;
        }
    }, [callOrgToActionAsItNeedsFinished, dispatch, history, location.pathname, location.search, loggedInUser, orgId, selectedOrg]);

    useEffect(() => {
        if (arePermissionsLoading) {
            return;
        }

        if (arePermissionsLoaded || !loggedInUser) {
            return;
        }

        dispatch(permissionsLoad());
    }, [arePermissionsLoaded, arePermissionsLoading, dispatch, loggedInUser]);

    useEffect(() => {
        if (arePermissionsLoading || !arePermissionsLoaded || arePermissionsLoadedForSelectedOrg) {
            return;
        }

        if (!selectedOrg || !loggedInUser) {
            return;
        }

        dispatch(permissionLoadForOrg(selectedOrg.id));
    }, [arePermissionsLoading, arePermissionsLoadedForSelectedOrg, selectedOrg, dispatch, loggedInUser, arePermissionsLoaded]);

    // const supportAlert = useMemo(() => {
    //     if (!showSupportAlert) {
    //         return null;
    //     }

    //     return (
    //         <Row justify="center" style={{ marginTop: '16px', marginBottom: '8px' }}>
    //             <Col xs={22} md={16}>
    //                 <Alert
    //                     showIcon
    //                     type="info"
    //                     message="Support Email Changed"
    //                     description={
    //                         <Typography.Text>
    //                             <Typography.Text strong>Our support email has changed!</Typography.Text> If you have submitted or responded to a support ticket in the last few days, please send it to the new email:&nbsp;
    //                             <Typography.Link target="_blank" rel="noreferrer" href={`mailto:support@lendiom.com?subject=Hello%20from%20${ loggedInUser?.firstName }%20(${ encodeURIComponent(selectedOrg?.shortId || 'no-org') })`}>support@lendiom.com</Typography.Link>.
    //                             Additionally, in the lower right side, there is a new button to get support.
    //                         </Typography.Text>}
    //                     closable
    //                     onClose={() => {
    //                         setShowSupportAlert(false);

    //                         trackUmami('Alert Closed: Support Email Changed');
    //                         window.localStorage.setItem('lendiom.support.email.alert', 'false');
    //                     }}
    //                 />
    //             </Col>
    //         </Row>
    //     );
    // }, [loggedInUser?.firstName, selectedOrg?.shortId, showSupportAlert]);

    if (!orgId || isFetching || arePermissionsLoading) {
        return (
            <FullScreenLoading />
        );
    }

    return (
        <React.Fragment>
            <ProLayout
                title="Lendiom"
                logo={<img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Lendiom Logo" />}
                locale="en-US"
                breakpoint="md"
                collapsed={collapsed}
                onCollapse={setCollapsed}
                layout="side"
                actionsRender={(props?: HeaderProps) => {
                    if (props?.isMobile) return [];

                    return getHeaderActions(loggedInUser);
                }}
                avatarProps={userHeaderProps}
                menuDataRender={menuDataRender(orgId)}
                menuItemRender={menuItemRender}
                style={{ minHeight: '100vh' }}
                {...settings}
                navTheme={userHeaderProps.navTheme}
                className={userHeaderProps.navTheme === 'realDark' ? 'realDark' : 'light'}
            >
                <Switch>
                    <SentryRoute exact path="/account/settings/:tab?" render={() => <Suspense fallback={null}><AccountSettings /></Suspense>} />

                    <SentryRoute exact path="/" render={() => <OrgSelectView />} />
                    <Redirect exact from="/:orgId" to="/:orgId/dashboard" />
                    <SentryRoute exact path="/:orgId/dashboard" render={() => <Dashboard />} />

                    <SentryRoute exact path="/:orgId/communications" render={() => <Suspense fallback={null}><Communications /></Suspense>} />

                    <SentryRoute exact path="/:orgId/inventory/new" render={() => <NewInventory />} />
                    <SentryRoute exact path="/:orgId/inventories" render={() => <Inventories />} />
                    <SentryRoute exact path="/:orgId/inventories/:inventoryId" render={() => <Inventory />} />
                    <SentryRoute exact path="/:orgId/inventories/:inventoryId/property-taxes/:propertyTaxId" render={() => <PropertyTax />} />
                    <SentryRoute exact path="/:orgId/inventories/:inventoryId/tracts/:tractId" render={() => <Tract />} />

                    {/* Redirects for previous routes */}
                    <Redirect from="/:orgId/projects" to="/:orgId/inventories" exact />
                    <Redirect from="/:orgId/project/new" to="/:orgId/inventory/new" exact />
                    <Redirect from="/:orgId/projects/:projectId" to="/:orgId/inventories/:inventoryId" exact />
                    <Redirect from="/:orgId/projects/:projectId/property-taxes/:propertyTaxId" to="/:orgId/inventories/:inventoryId/property-taxes/:propertyTaxId" exact />
                    <Redirect from="/:orgId/projects/:projectId/tracks/:trackId" to="/:orgId/inventories/:inventoryId/tracts/:tractId" exact />

                    <SentryRoute exact path="/:orgId/client/new" component={NewClient} />
                    <SentryRoute exact path="/:orgId/clients" component={Clients} />
                    <SentryRoute exact path="/:orgId/clients/:clientId" component={Client} />

                    <SentryRoute exact path="/:orgId/loan/new" component={NewLoan} />
                    <SentryRoute exact path="/:orgId/loans" render={() => <Suspense fallback={null}><Loans /></Suspense>} />
                    <SentryRoute exact path="/:orgId/loans/:loanId" component={Loan} />

                    <SentryRoute exact path="/:orgId/rental/new" render={() => <Suspense fallback={null}><RentalsNew /></Suspense>} />
                    <SentryRoute exact path="/:orgId/rentals" render={() => <Suspense fallback={null}><Rentals /></Suspense>} />
                    <SentryRoute exact path="/:orgId/rentals/:rentalId" render={() => <Suspense fallback={null}><RentalView /></Suspense>} />

                    {/* <SentryRoute exact path="/:orgId/customize/website" render={() => <Suspense fallback={null}><CustomizeWebsite /></Suspense>} />
                    <SentryRoute exact path="/:orgId/customize/mail" render={() => <Suspense fallback={null}><CustomizeMail /></Suspense>} /> */}

                    <SentryRoute exact path="/:orgId/documents" render={() => <Suspense fallback={null}><DocumentListView /></Suspense>} />
                    <SentryRoute exact path="/:orgId/documents/builder/:signatureId?" render={() => <Suspense fallback={null}><DocumentBuilderView /></Suspense>} />

                    <SentryRoute exact path="/:orgId/reports" render={() => <Suspense fallback={null}><ReportsView /></Suspense>} />

                    <SentryRoute exact path="/:orgId/files" render={() => <Suspense fallback={null}><FilesView /></Suspense>} />

                    <SentryRoute exact path="/:orgId/payarc/merchant" render={() => <Suspense fallback={null}><PayArcMerchantInfo /></Suspense>} />
                    <SentryRoute exact path="/:orgId/payarc/transactions" render={() => <Suspense fallback={null}><PayArcTransactions /></Suspense>} />
                    <SentryRoute exact path="/:orgId/payarc/deposits" render={() => <Suspense fallback={null}><PayArcDeposits /></Suspense>} />
                    <Redirect from="/:orgId/payments/provider-stripe" to="/:orgId/settings/stripe" exact />

                    <SentryRoute exact path="/:orgId/settings/:tab?" render={() => <OrganizationSettings />} />
                </Switch>
            </ProLayout>

            <ServerEventSource />
        </React.Fragment>
    );
}
