import React, { lazy, Suspense } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Layout, Row, Col, Form, FormInstance, Input, Select, Button, Alert, notification } from 'antd';
import { Rule } from 'antd/lib/form';

import { GlobalState } from 'store';
import { getAuthState } from 'store/selectors/auth';
import { authRegister } from 'store/actions/auth';

import { IUserRegistrationPayload } from 'models/payloads/userProfile';
import { displayErrorNotification } from 'utils/errors';

import './register.css';

const ClientConfirmRegisterModal = lazy(() => import('./clientConfirmModal'));
const MaskedInput = lazy(() => import('components/misc/maskedInput'));

interface IFormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    mobile: string;
}

const mapStateToProps = (state: GlobalState) => ({
    auth: getAuthState(state),
});

interface IUserRegistrationProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps {}

interface IUserRegistrationState {
    isSaving: boolean;
    showClientPayRedirectModal: boolean;
    confirmCreateEvenIfClient: boolean;
}

class UserRegistrationBase extends React.PureComponent<IUserRegistrationProps, IUserRegistrationState> {
    state: Readonly<IUserRegistrationState> = {
        isSaving: false,
        showClientPayRedirectModal: false,
        confirmCreateEvenIfClient: false,
    };

    formRef = React.createRef<FormInstance<IFormValues>>();

    handleRegistrationSubmit = async () => {
        try {
            const values = await this.formRef.current!.validateFields();

            if (values.mobile.includes('_')) {
                notification.error({ message: 'Mobile phone number is not valid.' });
                return;
            }

            this.setState({ isSaving: true }, async() => {
                const payload: IUserRegistrationPayload = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    password: values.password,
                    phoneNumber: {
                        isCellular: true,
                        label: 'Mobile',
                        number: values.mobile.replace(/\D/g, ''),
                    },
                    confirmCreateEvenIfClient: this.state.confirmCreateEvenIfClient,
                };

                await this.props.dispatch(authRegister(payload) as any);

                if (this.props.auth.hasError) {
                    // 118 is returned when the user is a client of an organization
                    if (this.props.auth.error?.code === 118) {
                        this.setState({showClientPayRedirectModal: true});
                        return;
                    }

                    displayErrorNotification(this.props.auth.error!);
                    return;
                }

                this.props.history.push('/auth/verify-email');
            });
        } catch (e) {
            console.warn('error while validating form fields', e);
        }
    }

    handleGotoPayCancel = async () => {
        this.setState({showClientPayRedirectModal: false, confirmCreateEvenIfClient: true}, this.handleRegistrationSubmit);
    }

    //#region inputs
    get firstNameInput() {
        return (
            <Form.Item name="firstName" rules={[{ required: true, message: 'Your first name is required'}]}>
                <Input
                    size="large"
                    placeholder="First name"
                    autoComplete="given-name"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get lastNameInput() {
        return (
            <Form.Item name="lastName" rules={[{ required: true, message: 'Your last name is required'}]}>
                <Input
                    size="large"
                    placeholder="Last name"
                    autoComplete="family-name"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get emailInput() {
        return (
            <Form.Item name="email" rules={[{ required: true, message: 'A valid email is required to sign up.'}]}>
                <Input
                    size="large"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    inputMode="email"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get passwordInput() {
        return (
            <Form.Item name="password" rules={[{ required: true, message: 'A non-empty password is required.'}]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get confirmPasswordInput() {
        const rules: Rule[] = [
            { required: true, message: 'A non-empty password is required.'},
            {
                message: 'Passwords must match.',
                validator: async (rule, value) => {
                    if (value === this.formRef.current!.getFieldValue('password')) {
                        return;
                    }

                    throw new Error('Passwords must match.');
                },
            },
        ];

        return (
            <Form.Item name="confirmPassword" rules={rules}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    disabled={this.props.auth.isLoggingIn}
                />
            </Form.Item>
        );
    }

    get phoneNumberAddon() {
        return (
            <Select size="large" value="+1" disabled>
                <Select.Option value="+1">+1</Select.Option>
            </Select>
        );
    }

    get phoneNumberInput() {
        const rules: Rule[] = [
            { required: true, message: 'Mobile phone number is required to help secure your account.'},
        ];

        return (
            <Suspense fallback={null}>
                <MaskedInput
                    name="mobile"
                    mask="(###) ###-####"
                    formProps={{ rules }}
                    inputProps={{
                        size: 'large',
                        name: 'mobilePhone',
                        placeholder: 'Mobile Phone Number',
                        type: 'tel',
                        addonBefore: this.phoneNumberAddon,
                    }}
                />
            </Suspense>
        );
    }

    get registerOrSignIn() {
        const linkProps = {
            to: '/auth/login',
            className: 'login',
            disabled: this.props.auth.isLoggingIn,
        };

        return (
            <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="submit" disabled={this.props.auth.isLoggingIn} loading={this.props.auth.isLoggingIn}>Register</Button>
                <Link {...linkProps}>Already have an account?</Link>
            </Form.Item>
        );
    }
    //#endregion

    render() {
        return (
            <Layout className="register-layout container">
                <Row justify="center" className="content">
                    <Col xs={22} sm={16} md={10} lg={6}>
                        <Layout.Content className="register">
                            <Layout className="branding">
                                <img src={`${process.env.PUBLIC_URL}/logo-name.png`} height="80" alt="Lendiom App logo" />
                            </Layout>

                            <Alert
                                type="info"
                                showIcon
                                message="Looking for the payment portal?"
                                description={
                                    <React.Fragment>
                                        If you are looking to make a payment, such as rental or owner financing, then you are looking for Lendiom Pay.
                                        This registration page is only for businesses and their employees.
                                        <br />
                                        <Button
                                            ghost
                                            type="primary"
                                            href={process.env.REACT_APP_PAY_URL}
                                            style={{ marginTop: '15px' }}
                                        >Go to Lendiom Pay</Button>
                                    </React.Fragment>
                                }
                            />

                            <h3>Register</h3>
                            <Form
                                ref={this.formRef}
                                onFinish={this.handleRegistrationSubmit}
                                disabled={this.props.auth.isLoggingIn}
                            >
                                <Row gutter={16}>
                                    <Col xs={24} sm={12}>{this.firstNameInput}</Col>
                                    <Col xs={24} sm={12}>{this.lastNameInput}</Col>
                                </Row>
                                {this.emailInput}
                                {this.passwordInput}
                                {this.confirmPasswordInput}
                                {this.phoneNumberInput}
                                {this.registerOrSignIn}
                            </Form>
                        </Layout.Content>
                    </Col>
                </Row>

                <Layout.Footer>
                    <div className="footer">Copyright &copy; {new Date().getFullYear()}</div>
                </Layout.Footer>

                <Suspense fallback={null}>
                    <ClientConfirmRegisterModal open={this.state.showClientPayRedirectModal} onCancel={this.handleGotoPayCancel} />
                </Suspense>
            </Layout>
        );
    }
}

export const UserRegistration = connect(mapStateToProps)(withRouter(UserRegistrationBase));
