import React, { Suspense, lazy } from 'react';
import currency from 'currency.js';
import { isMobileOnly } from 'react-device-detect';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { AuditOutlined, ContactsOutlined, CreditCardOutlined, DollarOutlined, EditOutlined, EllipsisOutlined, FormOutlined, HistoryOutlined, PicRightOutlined, UploadOutlined } from '@ant-design/icons';
import { Row, Col, Card, Empty, Descriptions, Button, Collapse, Dropdown, Result, Skeleton } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { ILoadingFailureState, ILoadingState } from 'models/props-or-state/states';

import { Loading } from 'components/misc/loading';

import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { INote } from 'models/notes';
import { IClient } from 'models/client';
import { ITimelineEntry } from 'models/timelineEntry';
import { RelatedToType } from 'models/common/relatedTo';
import { ILoan, LoanTermsInterestSchedule } from 'models/loan';
import type { ILoanSchedule } from 'models/loanSchedule';

import { SimpleDate } from 'utils/formatting';
import { breadCrumbItemRender } from 'utils/breadCrumbs';

import { EditTractDrawer } from 'components/tracts/editTractDrawer';
import { NotesCard } from 'components/notes';
import { FilesCard } from 'components/files';
import { TimelineView } from 'components/timeline';
import { TractStatusTag } from 'components/tracts/tractStatusTag';
import { TractRenameModal } from 'components/tracts/relabelModal';
import { EntityDescriptionCard } from 'components/entity/entityDescriptionCard';
import { NewTransactionModal } from 'components/loans/newTransactionModal';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';

import { getInventoryByID } from 'api/inventories';
import { getTractByID, getTractNotes, getTractTimelineEntries } from 'api/tracts';
import { getTractOwner } from 'api/tracts';
import { getAmortizationSchedule } from 'api/helpers';
import { getLoanAmortizationSchedule, getLoanByID } from 'api/loans';

import './tract.css';

const LoanScheduleTable = lazy(() => import('components/loans/scheduleTable'));
const DescriptionCard = lazy(() => import('components/misc/descriptionCard'));
const TractAdjustCostModal = lazy(() => import('components/tracts/adjustCost'));
const TractAdjustAcresModal = lazy(() => import('components/tracts/adjustAcres'));
const MapPreviewButton = lazy(() => import('components/misc/mapPreviewBtn'));

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
});

interface ITractPathParams extends IOrgIdPathParams {
    inventoryId: string;
    tractId: string;
};

interface ITractProps extends ReturnType<typeof mapStateToProps>, RouteComponentProps<ITractPathParams> {}

interface ITractState extends ILoadingFailureState, ILoadingState {
    inventory?: IInventory;
    tract?: ITract;
    owner?: IClient;
    schedule?: ILoanSchedule;
    loan?: ILoan;
    notes: INote[];
    timelineEntries: ITimelineEntry[];
    description: string;
    isEditDrawerOpen: boolean;
    isTimelineVisible: boolean;
    isAddTransVisible: boolean;
    isRenameVisible: boolean;
    isEditCostVisible: boolean;
    isEditAcresVisible: boolean;
}

class TractBase extends React.PureComponent<ITractProps, ITractState> {
    state: Readonly<ITractState> = {
        notFound: false,
        otherError: false,
        error: '',
        isLoading: true,
        notes: [],
        timelineEntries: [],
        description: '',
        isEditDrawerOpen: false,
        isTimelineVisible: false,
        isAddTransVisible: false,
        isRenameVisible: false,
        isEditCostVisible: false,
        isEditAcresVisible: false,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: ITractProps) {
        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true, notFound: false, otherError: false });
        const { orgId, inventoryId, tractId } = this.props.match.params;

        try {
            const [inventory, tract, notes, timelineEntries] = await Promise.all([
                getInventoryByID(orgId, inventoryId),
                getTractByID(orgId, inventoryId, tractId),
                getTractNotes(orgId, inventoryId, tractId),
                getTractTimelineEntries(orgId, inventoryId, tractId),
            ]);

            let owner: IClient | undefined;
            if (tract.owner && tract.owner.id) {
                owner = await getTractOwner(orgId, inventory.id, tract.id);
            }

            this.setState({
                inventory: inventory,
                tract: tract,
                owner,
                notes,
                timelineEntries,
            });

            let loan: ILoan | undefined = undefined;
            let schedule: ILoanSchedule | undefined = undefined;
            if (tract.paymentOption.isFilledOut) {
                if (tract.loan.id) {
                    loan = await getLoanByID(orgId, tract.loan.id);
                    schedule = await getLoanAmortizationSchedule(orgId, loan.id);
                } else if (tract.paymentOption.isFinanced) {
                    schedule = await getAmortizationSchedule(tract.paymentOption.downPayment, tract.paymentOption.salesPrice, 12*tract.paymentOption.years, tract.paymentOption.interestRate, new Date().toJSON());
                }
            }

            this.setState({
                isLoading: false,
                loan,
                schedule,
            });
        } catch (e: any) {
            console.error('Error while loading the tract:', e);

            if (e.code === 73) {
                this.setState({ notFound: true, isLoading: false });
            } else {
                this.setState({
                    notFound: false,
                    otherError: true,
                    error: typeof e.error === 'string' ? e.error : 'Unknown error',
                    isLoading: false,
                });
            }
        }
    }

    toggleEditDrawer = () => {
        this.setState({ isEditDrawerOpen: !this.state.isEditDrawerOpen });
    }

    closeEditDrawer = async (saved: boolean) => {
        if (saved) {
            await this.loadData();
        }

        this.toggleEditDrawer();
    }

    toggleTimeline = () => {
        this.setState({ isTimelineVisible: !this.state.isTimelineVisible });
    }

    openAddTransaction = () => {
        this.setState({ isAddTransVisible: true });
    }

    closeAddTransaction = async (saved: boolean) => {
        if (saved) {
            await this.loadData();
        }

        this.setState({ isAddTransVisible: false });
    }

    openRenameModal = () => {
        this.setState({ isRenameVisible: true });
    }

    closeRenameModal = async (saved: boolean) => {
        this.setState({ isRenameVisible: false });

        if (saved) {
            await this.loadData();
        }
    }

    //#region ellipsis menu
    onEllipsisMenuClick = async (e: MenuInfo) => {
        const { tract } = this.state;
        if (!tract) {
            return;
        }

        switch (e.key) {
            case 'rename':
                this.openRenameModal();
                return;
            case 'add-transaction':
                this.openAddTransaction();
                return;
            case 'details-loan':
                this.props.history.push(`/${this.props.match.params.orgId}/loans/${ tract.loan.id }`);
                return;
            case 'details-owner':
                this.props.history.push(`/${this.props.match.params.orgId}/clients/${ tract.owner!.id }`);
                return;
            case 'adjust-cost':
                this.setState({ isEditCostVisible: true });
                return;
            case 'adjust-acres':
                this.setState({ isEditAcresVisible: true });
                return;
            default:
                return;
        }
    }

    get extraMenuItems(): Array<ItemType> | undefined {
        const { tract } = this.state;
        if (!tract) {
            return undefined;
        }

        return [
            { key: 'rename', icon: <FormOutlined />, label: 'Rename' },
            tract.loan.id && isMobileOnly ? { key: 'add-transaction', icon: <CreditCardOutlined />, label: 'Add Transaction' } : null,
            { key: 'details-loan', icon: <AuditOutlined />, label: 'View Loan', disabled: !tract.loan.id },
            { key: 'details-owner', icon: <ContactsOutlined />, label: 'View Owner', disabled: !!!tract.owner || !tract.owner.id },
            { key: 'adjust-cost', icon: <DollarOutlined />, label: 'Adjust Cost' },
            { key: 'adjust-acres', icon: <PicRightOutlined />, label: 'Adjust Acres' },
        ];
    }
    //#endregion ellipsis menu

    get headerButtons() {
        const { tract } = this.state;
        if (!tract) {
            return null;
        }

        return (
            <React.Fragment>
                <Button.Group>
                    <Button type="default" icon={<HistoryOutlined />} onClick={this.toggleTimeline}>Timeline</Button>
                    { tract.loan.id && !isMobileOnly ? <Button type="default" icon={<CreditCardOutlined />} onClick={this.openAddTransaction}>Add Transaction</Button> : null }

                    <Dropdown menu={{ items: this.extraMenuItems, onClick: this.onEllipsisMenuClick }}>
                        <Button disabled><EllipsisOutlined /></Button>
                    </Dropdown>
                </Button.Group>
                <Button className="edit-button" type={tract.loan.id ? 'default' : 'primary'} onClick={this.toggleEditDrawer} icon={<EditOutlined />}>Edit</Button>
            </React.Fragment>
        );
    }

    get tractInfo() {
        const { tract, loan } = this.state;
        if (!tract) {
            return null;
        }

        let years = 0;
        let paymentAmount = '0';
        let totalOfPayments = '0';
        let salesPrice = '0';

        if (tract.paymentOption.isFilledOut) {
            years = tract.paymentOption.years
            paymentAmount = currency(tract.paymentOption.monthlyPayment, { precision: 2 }).format(true);
            totalOfPayments = currency(tract.paymentOption.totalOfPayments, { precision: 2 }).format(true);
            salesPrice = currency(tract.paymentOption.salesPrice, { precision: 2 }).format(true);
        }

        return (
            <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
                <Descriptions.Item label="Acres">{tract.acres}</Descriptions.Item>
                <Descriptions.Item label="Status" className="title-caps">{tract.status.replace('-', ' ')}</Descriptions.Item>
                <Descriptions.Item label="Sales Price">
                    {tract.paymentOption.isFilledOut ? <span>{ salesPrice }</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Down Payment">
                    {tract.paymentOption.isFilledOut && tract.paymentOption.isFinanced ? <span>{ currency(tract.paymentOption.downPayment, { precision: 0 }).format(true) }</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Years">
                    {tract.paymentOption.isFilledOut && tract.paymentOption.isFinanced ? <span>{years}</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Interest Rate">
                    {tract.paymentOption.isFilledOut && tract.paymentOption.isFinanced ? <span>{ currency(tract.paymentOption.interestRate, { precision: 2 }).format() }%</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Price per Acre">
                    {tract.paymentOption.isFilledOut ? <span>{ currency(tract.paymentOption.pricePerAcre, { precision: 0 }).format(true) }</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Payment">
                    {tract.paymentOption.isFilledOut && tract.paymentOption.isFinanced ? <span>{ paymentAmount }</span> : <span>-</span> }
                </Descriptions.Item>
                <Descriptions.Item label="Total of Payments">
                    {tract.paymentOption.isFilledOut && tract.paymentOption.isFinanced ? <span>{ totalOfPayments } </span> : <span>-</span> }
                </Descriptions.Item>
                { loan ? <Descriptions.Item label="Balance Due">{ currency(loan.balance.due, { precision: 2 }).format(true) }</Descriptions.Item> : null}
                { loan ? <Descriptions.Item label="Next Due Date"><SimpleDate date={loan.nextDueDate} /></Descriptions.Item> : null }
                { loan ? <Descriptions.Item label="Last Paid Date"><SimpleDate date={loan.lastPaymentReceivedDate} /></Descriptions.Item> : null }
                <Descriptions.Item label="Parcel Numbers">{ Array.isArray(tract.parcelNumbers) && tract.parcelNumbers.length !== 0 ? tract.parcelNumbers.join(', ') : '-' }</Descriptions.Item>
                <Descriptions.Item label="Location">
                    <Suspense fallback={<Skeleton.Button />}>
                        <MapPreviewButton
                            latitude={tract.address?.location?.latitude || 0}
                            longitude={tract.address?.location?.longitude || 0}
                            zoom={tract.address?.location?.zoomLevel || 0}
                            btnProps={{ size: 'small' }}
                        />
                    </Suspense>
                </Descriptions.Item>
            </Descriptions>
        );
    }

    get pictures() {
        return (
            <Card title="Featured Photos" bordered={false} extra={<Button type="dashed" size="small" icon={<UploadOutlined />} disabled>Upload</Button>}>
                <Empty description="Coming soon" />
                {/* <Carousel>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/thumbs/IMG_0455.jpg'} alt="" />
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/thumbs/IMG_0456.jpg'} alt="" />
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/thumbs/IMG_0457.jpg'} alt="" />
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/thumbs/IMG_0458.jpg'} alt="" />
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/thumbs/IMG_0460.jpg'} alt="" />
                    </div>
                </Carousel> */}
            </Card>
        );
    }

    refreshNotes = async () => {
        const { params } = this.props.match;

        const notes = await getTractNotes(params.orgId, params.inventoryId, params.tractId);
        const timelineEntries = await getTractTimelineEntries(params.orgId, params.inventoryId, params.tractId);

        this.setState({ notes, timelineEntries });
    }

    get notes() {
        return (
            <NotesCard orgId={this.props.match.params.orgId} isLoading={this.state.isLoading} tract={this.state.tract} notes={this.state.notes} refresh={this.refreshNotes} />
        );
    }

    get description() {
        return (
            <Suspense fallback={<Skeleton.Input block active />}>
                <DescriptionCard orgId={this.props.match.params.orgId} tract={this.state.tract!} refresh={this.loadData} />
            </Suspense>
        );
    }

    get files() {
        if (!this.state.tract) {
            return null;
        }

        return (
            <FilesCard
                orgId={this.props.match.params.orgId}
                relatedTo={{
                    type: RelatedToType.TRACT,
                    id: this.state.tract.id,
                    parentId: this.state.tract.inventoryId,
                }}
                relatedLabel={this.state.tract.label}
            />
        );
    }

    get loanInfo() {
        if (!this.state.tract || !this.state.tract.paymentOption.isFilledOut || !this.state.schedule) {
            return null;
        }

        const { paymentOption } = this.state.tract;
        const { schedule } = this.state;

        return (
            <Collapse defaultActiveKey={[]} bordered={false}>
                <Collapse.Panel key="1" header="Amortization Schedule">
                    <Descriptions>
                        <Descriptions.Item label="Sales Price">{ currency(paymentOption.salesPrice).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Down Payment">{ currency(paymentOption.downPayment).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Loan Amount">{ currency(paymentOption.salesPrice).subtract(paymentOption.downPayment).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Payment Frequency">Monthly</Descriptions.Item>
                        <Descriptions.Item label="Payment Terms">{ paymentOption.years * 12 }</Descriptions.Item>
                        <Descriptions.Item label="Payment Amount">{ currency(paymentOption.monthlyPayment).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Interest Rate">{ currency(paymentOption.interestRate).format() }%</Descriptions.Item>
                        <Descriptions.Item label="Total of Payments">{ currency(paymentOption.totalOfPayments).format(true) }</Descriptions.Item>
                        <Descriptions.Item label="Total Interest">{ currency(paymentOption.totalOfInterest).format(true) }</Descriptions.Item>
                    </Descriptions>

                    <Suspense fallback={null}>
                        <LoanScheduleTable interestSchedule={paymentOption.interestSchedule || LoanTermsInterestSchedule.FollowsPayments} schedule={schedule} showDate={!!this.state.tract.loan.id} />
                    </Suspense>
                </Collapse.Panel>
            </Collapse>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg || !this.state.inventory || !this.state.tract) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${ this.props.selectedOrg.shortId }`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories`,
                    breadcrumbName: `${this.props.selectedOrg.name}'s Portfolio`,
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories/${ this.state.inventory.id }`,
                    breadcrumbName: `Inventory ${ this.state.inventory.name }`,
                },
                {
                    path: `/${ this.props.selectedOrg.shortId }/inventories/${ this.state.inventory.id }/tracts/${ this.state.tract.id }`,
                    breadcrumbName: `${ this.state.tract.label }`,
                },
            ],
        };
    }

    onBack = () => this.props.history.push(`/${this.props.match.params.orgId}/inventories/${this.props.match.params.inventoryId}`)

    render() {
        //TODO: change this loading so it doesn't refresh the entire page
        if (this.state.isLoading) {
            return <Loading />;
        }

        if (this.state.notFound) {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="We could not find this tract. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Go back</Button>}
                />
            );
        }

        if (this.state.otherError) {
            return (
                <Result
                    status="error"
                    title="Unknown Error"
                    subTitle={`All we know is: ${ this.state.error }`}
                    extra={<Button type="primary" onClick={this.onBack}>Go back</Button>}
                />
            );
        }

        const headerProps: PageContainerProps = {
            title: this.state.tract ? this.state.tract.label : 'Loading...',
            subTitle: `of ${this.state.inventory?.name}`,
            tags: <TractStatusTag status={this.state.tract?.status} />,
            onBack: this.onBack,
            extra: this.headerButtons,
            content: this.tractInfo,
            // extraContent: this.ownerInfo,
            breadcrumb: this.breadcrumbProps,
        };

        return (
            <PageContainer {...headerProps}>
                <Row gutter={16} style={{ marginTop: 24 }}>
                    <Col xs={24} lg={12}>
                        {/* {this.pictures} */}
                        <EntityDescriptionCard
                            vertical
                            title="Buyer Information"
                            emptyContent="No current owner"
                            clientId={this.state.owner?.id || undefined}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        {this.notes}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 24 }}>
                    <Col xs={24} lg={12}>
                        {this.description}
                    </Col>
                    <Col xs={24} lg={12}>
                        {this.files}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 24 }}>
                    <Col span={24}>
                        {this.loanInfo}
                    </Col>
                </Row>

                <TimelineView view="modal" visible={this.state.isTimelineVisible} close={this.toggleTimeline} related={this.state.tract!} relatedType={RelatedToType.TRACT} entries={this.state.timelineEntries} refresh={this.refreshNotes} />
                <EditTractDrawer orgId={this.props.match.params.orgId} inventory={this.state.inventory!} tract={this.state.tract} owner={this.state.owner} isVisible={this.state.isEditDrawerOpen} close={this.closeEditDrawer} />
                <NewTransactionModal loan={this.state.loan} schedule={this.state.schedule} isVisible={this.state.isAddTransVisible} close={this.closeAddTransaction} />
                <TractRenameModal orgId={this.props.match.params.orgId} inventoryId={this.state.inventory!.id} tractId={this.state.tract!.id} initialLabel={this.state.tract!.label} initialNumber={this.state.tract!.number} visible={this.state.isRenameVisible} close={this.closeRenameModal} />
                <Suspense fallback={null}>
                    <TractAdjustCostModal
                        orgId={this.props.match.params.orgId}
                        tract={this.state.tract}
                        open={this.state.isEditCostVisible}
                        close={() => {
                            this.setState({ isEditCostVisible: false });
                            this.loadData();
                        }}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <TractAdjustAcresModal
                        orgId={this.props.match.params.orgId}
                        tract={this.state.tract}
                        open={this.state.isEditAcresVisible}
                        close={() => {
                            this.setState({ isEditAcresVisible: false });
                            this.loadData();
                        }}
                    />
                </Suspense>
            </PageContainer>
        );
    }
}

export const Tract = connect(mapStateToProps)(withRouter(TractBase));
