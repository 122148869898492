import { useCallback, useEffect, useLayoutEffect, useState } from "react";

export const useIsScrollable = (dependencies: any[]) => {
    const [node, setNode] = useState<HTMLDivElement>();
    const ref = useCallback((node: HTMLDivElement) => {
        setNode(node);
    }, []);

    const [isScrollable, setIsScrollable] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (!node) return;

        setIsScrollable(node.scrollHeight > node.clientHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, node]);

    useLayoutEffect(() => {
        if (!node) return;

        const handleWindowResize = () => {
            setIsScrollable(node.scrollHeight > node.clientHeight);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, [node]);

    return [isScrollable, ref, node] as const;
};

function getStorageValue<T>(key: string, defaultValue: T) {
    // getting stored value
    const saved = localStorage.getItem(key);
    if (!saved) {
        return defaultValue;
    }

    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

export function useLocalStorage<T>(key: string, defaultValue: T) {
    const [value, setValue] = useState<T>(() => {
        return getStorageValue<T>(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue] as const;
};
