import React from 'react';
import type { Dayjs } from 'dayjs';

import { connect, DispatchProp } from 'react-redux';
import { LeftOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Form, FormInstance, Button, DatePicker, InputNumber, Popconfirm, Space } from 'antd';

import { LateFeeInputs } from 'components/forms';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';

import type { ILateFeeTier } from 'models';

import { INewLoanFormValues } from './formValues';

const mapStateToProps = (state: GlobalState) => ({
    globalState: state,
    orgId: getSelectedOrgShortId(state),
});

interface INewLoanTermsStepProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    form: FormInstance<INewLoanFormValues>;
    goNext: () => void;
    goBack: () => void;
}

interface INewLoanFormTermsStepState {
    addLateFeeTierVisible: boolean;
}

class NewLoanFormTermsStepBase extends React.PureComponent<INewLoanTermsStepProps, INewLoanFormTermsStepState> {
    state: Readonly<INewLoanFormTermsStepState> = {
        addLateFeeTierVisible: false,
    }

    get closingDate() {
        return (
            <Form.Item name="closingDate" label="Closing Date" extra="The date when the papers were signed." rules={[{ required: true, message: 'Please select closing date.' }]}>
                <DatePicker
                    format="MM/DD/YYYY"
                    showNow={false}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        );
    }

    get firstPaymentDate() {
        return (
            <Form.Item name="firstPaymentDate" label="First Payment Date" extra="The date the first payment is due." rules={[{ required: true, message: 'Please select the day the first payment is due.' }]}>
                <DatePicker
                    format="MM/DD/YYYY"
                    showNow={false}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        );
    }

    get defaultingEnabled() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue, setFieldValue }) => {
                    const isEnabled = getFieldValue('defaultingAutomaticallyEnabled') as boolean;

                    return (
                        <Form.Item name="defaultingAutomaticallyEnabled" noStyle preserve>
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title="Enable auto-defaulting?"
                                description={<React.Fragment>
                                    Enabling automatic defaulting will move the loan to<br />
                                    in-default status after the specified number of days<br />
                                    of no payment (<a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-loan#step2-days-until-default`} target="_blank" rel="noopener noreferrer">click for more info</a>).<br />
                                    Are you sure you want to enable feature?
                                </React.Fragment>}
                                onConfirm={() => setFieldValue('defaultingAutomaticallyEnabled', true)}
                                onCancel={() => setFieldValue('defaultingAutomaticallyEnabled', false)}
                                okText="Yes, enable"
                                okButtonProps={{ danger: true }}
                                cancelText="No, disable"
                                disabled={isEnabled}
                            >
                                <Button
                                    type={isEnabled ? 'primary' : 'default'}
                                    danger={isEnabled}
                                    onClick={() => isEnabled ? setFieldValue('defaultingAutomaticallyEnabled', false) : undefined}
                                >Auto: {isEnabled ? 'Enabled' : 'Disabled'}</Button>
                            </Popconfirm>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get daysUntilDefault() {
        return (
            <Form.Item
                label="Days Until In-Default"
                required
                extra={
                    <React.Fragment>
                        The number of consecutive days after the due date, excluding the due date itself, during which no payment has been made that the loan is considered in-default is specified above.
                        By enabling automatic defaulting, the loan will be moved to in-default status after the designated time frame has passed without any payment being received.
                        Currently, notices are not automatically sent when a loan enters in-default status and must still be manually distributed.
                        For more information on this process, please <a href={`${process.env.REACT_APP_DOCS_URL}/app/guides/creating-a-loan#step2-days-until-default`} target="_blank" rel="noopener noreferrer">click here</a>.
                    </React.Fragment>
                }
            >
                <Space.Compact block>
                    <Form.Item name="daysUntilInDefault" noStyle rules={[{ required: true, message: 'Please enter the number of days until it is moved to in-default.' }]}>
                        <InputNumber
                            min={1}
                            max={365}
                            step={1}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    {this.defaultingEnabled}
                </Space.Compact>
            </Form.Item>
        );
    }

    get defaultsAfter() {
        return (
            <Form.Item noStyle shouldUpdate={(prev: INewLoanFormValues, curr: INewLoanFormValues) => prev.defaultingAutomaticallyEnabled !== curr.defaultingAutomaticallyEnabled}>
                {({ getFieldValue }) => {
                    if (!getFieldValue('defaultingAutomaticallyEnabled')) {
                        return null;
                    }

                    return (
                        <Form.Item
                            name="daysUntilDefaulted"
                            label="Defaults After"
                            extra="When you enable automatic defaulting, specify how many days after being marked as in-default that the loan should be escalated to defaulted status. Important note: Repossessing the property (which marks the loan as repossessed) is a manual process and will not occur automatically."
                            rules={[{ required: true, message: 'Value is required when automatic defaulting is enabled.' }]}
                            preserve
                        >
                            <InputNumber
                                min={1}
                                max={365}
                                step={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }

    get bottomButtons() {
        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue }) => {
                    const closingDate = getFieldValue('closingDate') as Dayjs;
                    const firstPaymentDate = getFieldValue('firstPaymentDate') as Dayjs;
                    const daysUntilInDefault = getFieldValue('daysUntilInDefault') as number;
                    const defaultingAutomaticallyEnabled = getFieldValue('defaultingAutomaticallyEnabled') as boolean;
                    const daysUntilDefaulted = getFieldValue('daysUntilDefaulted') as number | undefined;
                    const lateFeeDisabled = getFieldValue('lateFeeDisabled') as boolean;
                    const lateFeeTiers = getFieldValue('lateFeeTiers') as ILateFeeTier[];

                    const nextDisabled = !closingDate || !closingDate.isValid()
                        || !firstPaymentDate || !firstPaymentDate.isValid()
                        || firstPaymentDate.isBefore(closingDate) // the payment date can not be before the closing date
                        || typeof daysUntilInDefault !== 'number' || daysUntilInDefault < 1 || daysUntilInDefault > 365
                        || (defaultingAutomaticallyEnabled && (typeof daysUntilDefaulted === 'number' && daysUntilInDefault < 1 && daysUntilInDefault > 365))
                        || (typeof lateFeeDisabled !== 'boolean')
                        || !lateFeeTiers || !Array.isArray(lateFeeTiers) || lateFeeTiers.length === 0;

                    return (
                        <Button.Group>
                            <Button icon={<LeftOutlined />} onClick={this.props.goBack}>Previous</Button>
                            <Button type="primary" onClick={this.props.goNext} disabled={nextDisabled}>
                                Next <RightOutlined />
                            </Button>
                        </Button.Group>
                    );
                }}
            </Form.Item>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.closingDate}
                {this.firstPaymentDate}

                <LateFeeInputs form={this.props.form} type="loan" />

                {this.daysUntilDefault}
                {this.defaultsAfter}

                {this.bottomButtons}
            </React.Fragment>
        );
    }
}

export const NewLoanTermsStep = connect(mapStateToProps)(NewLoanFormTermsStepBase);
