import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Tag } from 'antd';

import type { ILateFeeTier } from 'models';
import { LateFeeTierString } from './toString';

interface ILateFeeTierTagProps {
    value: ILateFeeTier;
    closable?: boolean;
    onClose?: (tier: ILateFeeTier) => void;
}

export const LateFeeTierTag: FC<ILateFeeTierTagProps> = React.memo(({ value, closable, onClose }) => {
    const closedClick = useCallback(() => {
        if (typeof onClose !== 'function') {
            return;
        }

        onClose(value);
    }, [onClose, value]);

    return (
        <Tag style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} closable={closable} onClose={closedClick}>
            <div className="content">
                <LateFeeTierString tier={value} />
            </div>
        </Tag>
    );
});
