import React from 'react';
import currency from 'currency.js';
import { Link } from 'react-router-dom';
import { Card, Table, Row, Col, Statistic, DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import type { CardTabListType } from 'antd/lib/card';
import type { ColumnProps } from 'antd/lib/table';
import type { Dayjs } from 'dayjs';

import { IExpectedLoanPaymentsInfo, IExpectedLoanPayment } from 'models/dashboard';
import { getExpectedPayments } from 'api/dashboard';

import { displayErrorNotification } from 'utils/errors';
import { ShortDate } from 'utils/formatting';
import { SimpleTooltip } from 'utils/tooltipWrapper';

interface IExpectedPaymentsProps {
    orgId: string;
    orgShortId: string;
}

type tabKeys = 'summary' | 'payments';

interface IExpectedPaymentsState {
    loading: boolean;
    activeTabKey: tabKeys;
    monthSelected: Dayjs;
    info?: IExpectedLoanPaymentsInfo;
}

const pageSize = 5;

export class ExpectedPaymentsCard extends React.PureComponent<IExpectedPaymentsProps, IExpectedPaymentsState> {
    state: Readonly<IExpectedPaymentsState> = {
        loading: false,
        activeTabKey: 'summary',
        monthSelected: dayjs(),
    };

    componentDidMount() {
        if (this.props.orgId) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps: IExpectedPaymentsProps) {
        if (prevProps.orgId === this.props.orgId) {
            return;
        }

        this.loadData();
    }

    loadData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const res = await getExpectedPayments(this.props.orgId, this.state.monthSelected);

                this.setState({ info: res });
            } catch (e) {
                displayErrorNotification(e, this.loadData);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    tabs: CardTabListType[] = [
        { key: 'summary', tab: 'Summary' },
        { key: 'payments', tab: 'Payments' },
    ];

    onTabChange = (key: string) => {
        this.setState({ activeTabKey: key as tabKeys });
    }

    get summaryTabContent() {
        if (!this.state.info) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip hasQuestion title="The total amount expected for this month" content="Expected" />}
                            value={this.state.info.expectedAmount}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip hasQuestion title="The total amount received for this month (excluding any over payments)" content="Received" />}
                            value={this.state.info.receivedAmount}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip hasQuestion title="The total amount remaining regular payments for this month" content="Remaining" />}
                            value={this.state.info.remainingAmount}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip hasQuestion title="The total amount of extra payments received for this month" content="Extra" />}
                            value={this.state.info.extraAmount}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={12} md={8}>
                        <Statistic
                            title="Collected %"
                            value={currency(this.state.info.collectedPercentage, { precision: 4 }).multiply(100).toString()} precision={2} suffix="%" />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    columns: ColumnProps<IExpectedLoanPayment>[] = [
        {
            title: 'Due Date', dataIndex: ['payment', 'dueDate'], key: 'dueDate',
            render: (date: string) => <ShortDate value={date} />,
        },
        {
            title: 'Loan', dataIndex: ['loan', 'label'], key: 'loanLabel',
            render: (loanLabel: string, trans) => <Link to={`/${ this.props.orgShortId }/loans/${ trans.loan.id }`}>{ loanLabel }</Link>
        },
        {
            title: 'Fully Paid', dataIndex: ['payment', 'isFullyPaid'], key: 'isFullyPaid', align: 'center',
            render: (isFullyPaid: boolean) => isFullyPaid ? 'Yes' : 'No',
            filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
            onFilter: (value, record) => record.payment.isFullyPaid === value,
        },
        {
            title: 'Late', dataIndex: ['payment', 'isLate'], key: 'isLate', align: 'center',
            render: (isLate: boolean) => isLate ? <span style={{ color: 'red' }}>Yes</span> : 'No',
            filters: [{ text: 'Yes', value: true }, { text: 'No', value: false }],
            onFilter: (value, record) => record.payment.isLate === value,
        },
        {
            title: 'Bal Due', dataIndex: ['payment', 'payment'], key: 'paymentAmount', align: 'right',
            render: (amount: string, payment) => {
                let amtDue = currency(amount, { precision: 2 }).subtract(payment.payment.paymentReceived);
                if (amtDue.value < 0) {
                    amtDue = currency(0, { precision: 2 });
                }

                const overlay = (
                    <React.Fragment>
                        Payment amount: { currency(amount, { precision: 2 }).format(true) }<br />
                        Received amount: { currency(payment.payment.paymentReceived, { precision: 2 }).format(true) }
                    </React.Fragment>
                );

                return (
                    <Tooltip overlay={overlay}>
                        { amtDue.format(true) }
                    </Tooltip>
                );
            },
        }
    ];

    get paymentsContent() {
        if (!this.state.info) {
            return null;
        }

        return (
            <Table<IExpectedLoanPayment>
                size="small"
                columns={this.columns}
                dataSource={this.state.info.payments}
                rowKey={(r) => r.loan.id}
                scroll={{ x: 'max-content' }}
                pagination={{
                    style: { marginBottom: 0 },
                    pageSize,
                }}
            />
        );
    }

    onMonthPickChange = (date: Dayjs | null) => {
        this.setState({ monthSelected: date ? date : dayjs() }, this.loadData);
    }

    get monthPicker() {
        return (
            <DatePicker.MonthPicker
                value={this.state.monthSelected}
                onChange={this.onMonthPickChange}
                disabled={this.state.loading}
            />
        );
    }

    render() {
        let title = 'Expected Payments*';
        if (this.state.monthSelected.isValid()) {
            title = `Expected ${ this.state.monthSelected.format('MMM YYYY') } Payments*`;
        }

        return (
            <Card
                loading={this.state.loading}
                bordered={false}
                title={title}
                extra={this.monthPicker}
                tabList={this.tabs}
                activeTabKey={this.state.activeTabKey}
                onTabChange={this.onTabChange}
                style={{ height: '100%' }}
            >
                { this.state.activeTabKey === 'summary' ? this.summaryTabContent : null }
                { this.state.activeTabKey === 'payments' ? this.paymentsContent : null }

                <em style={{ position: 'relative', top: this.state.activeTabKey === 'payments' ? '-25px' : '0', paddingLeft: '8px', fontSize: '12px' }}>*excludes draft and in-active loans</em>
            </Card>
        );
    }
}
