import cloneDeep from 'lodash/cloneDeep';

import { get, post, postForm, put, del } from './index';

import { ITimelineEntry } from 'models/timelineEntry';
import { IFile, FileMap } from 'models/file';
import { ITract } from 'models/tract';
import { INote } from 'models/notes';
import { IClient } from 'models/client';
import { ILookup } from 'models/common/lookup';
import { IDescription } from 'models/common/description';
import { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import { Currency } from 'models/currency';

export const createTract = (orgId: string, tract: Partial<ITract>) => post<Partial<ITract>, ITract>(`/organizations/${ orgId }/inventories/${ tract.inventoryId }/tracts`, tract, true);
export const updateTract = (orgId: string, inventoryId: string, tract: Partial<ITract>) => put<Partial<ITract>, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tract.id }`, tract, true);
export const duplicateTract = (orgId: string, inventoryId: string, tract: Partial<ITract>) => {
    const cloned = cloneDeep(tract);

    delete cloned.id;
    delete cloned.owner;
    delete cloned.loan;

    cloned.inventoryId = inventoryId;

    return createTract(orgId, cloned)
};

export const getTracts = (orgId: string, inventoryId: string) => get<ITract[]>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts`, true);
export const getTractByID = (orgId: string, inventoryId: string, tractId: string) => get<ITract>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }`, true);
export const deleteTractByID = (orgId: string, inventoryId: string, tractId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }`, true);

export const setTractLabelAndNumber = (orgId: string, inventoryId: string, tractId: string, label: string, number: number) => put<{ label: string, number: number }, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/label`, { label, number }, true);
export const updateTractCost = (orgId: string, inventoryId: string, tractId: string, costTotal: Currency, costPerAcre: Currency) => put<{ costTotal: Currency, costPerAcre: Currency }, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/cost`, { costTotal, costPerAcre }, true);
export const updateTractDescription = (orgId: string, inventoryId: string, tractId: string, description: string) => put<{ description: string }, IDescription>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/description`, { description }, true);
export const updateTractAcres = (orgId: string, inventoryId: string, tractId: string, acres: number) => put<{ acres: number }, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/acres`, { acres }, true);

export const getTractNotes = (orgId: string, inventoryId: string, tractId: string) => get<INote[]>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/notes`, true);
export const addTractNote = (orgId: string, inventoryId: string, tractId: string, note: Partial<INote>) => post<Partial<INote>, INote>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/notes`, note, true);

export const updateTractNote = (orgId: string, inventoryId: string, tractId: string, note: Partial<INote>) => put<Partial<INote>, INote>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/notes/${ note.id }`, note, true);
export const deleteTractNote = (orgId: string, inventoryId: string, tractId: string, noteId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/notes/${ noteId }`, true);
export const getTractOwner = (orgId: string, inventoryId: string, tractId: string) => get<IClient>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/owner`, true);
export const setTractOwner = (orgId: string, inventoryId: string, tractId: string, owner: Partial<ILookup>) => post<Partial<ILookup>, ITract>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/owner`, owner, true);
export const removeTractOwner = (orgId: string, inventoryId: string, tractId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/owner`, true);

export const getTractFilesAsVFS = (orgId: string, inventoryId: string, tractId: string) => get<FileMap>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files?as=vfs`, true);
export const getTractFile = (orgId: string, inventoryId: string, tractId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files/${ fileId }`, true);
export const uploadTractFile = (orgId: string, inventoryId: string, tractId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files`, formData, true);
export const updateTractFile = (orgId: string, inventoryId: string, tractId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files/${ fileId }`, payload, true);
export const createNewTractFileFolder = (orgId: string, inventoryId: string, tractId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const deleteTractFile = (orgId: string, inventoryId: string, tractId: string, fileId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/files/${ fileId }`, true);

export const getTractTimelineEntries = (orgId: string, inventoryId: string, tractId: string) => get<ITimelineEntry[]>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/timelineEntries`, true);
export const addTractTimelineEntry = (orgId: string, inventoryId: string, tractId: string, timelineEntry: Partial<ITimelineEntry>) => post<Partial<ITimelineEntry>, ITimelineEntry>(`/organizations/${ orgId }/inventories/${ inventoryId }/tracts/${ tractId }/timelineEntries`, timelineEntry, true);
