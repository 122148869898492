import React from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { BulbOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { List, Row, Col, Statistic, Button } from 'antd';

import { ILoanTransaction, LoanTransactionType } from 'models/loan';
import { TransactionStatus } from 'models/transactions';
import { SimpleDate } from 'utils/formatting';

interface ITransactionsListProps {
    loading: boolean;
    transactions: ILoanTransaction[];
    empty: JSX.Element;
    actionsDisabled: boolean;
    reversedColor: string;
    pendingColor: string;
    openDetailsModal: (trans: ILoanTransaction) => void;
    openStatusChangeModal: (trans: ILoanTransaction) => void;
}

export const TransactionsList: FC<ITransactionsListProps> = (props) => {

    return (
        <List<ILoanTransaction>
            itemLayout="vertical"
            loading={props.loading}
            dataSource={props.transactions}
            locale={{ emptyText: props.empty }}
            pagination={{ pageSize: 8, hideOnSinglePage: true }}
            renderItem={(trans: ILoanTransaction) => {
                const actions = [
                    <Button key={trans.id+'_view'} icon={<InfoCircleOutlined />} onClick={() => props.openDetailsModal(trans)}>Details</Button>,
                ];

                if (trans.status === TransactionStatus.Pending && !trans.processor) {
                    actions.push(<Button key={trans.id+'_status'} icon={<BulbOutlined />} onClick={() => props.openStatusChangeModal(trans)} disabled={props.actionsDisabled}>Change Status</Button>);
                }

                let transTitle = 'Unknown';
                switch (trans.type) {
                    case LoanTransactionType.RegularPayment:
                        if (Array.isArray(trans.toPayments)) {
                            transTitle = `${ trans.type.replace('-', ' ') } for ${ trans.toPayments.map((t) => t.paymentNumber).join(', ') }`;
                        } else {
                            transTitle = trans.type.replace('-', ' ');
                        }

                        break;
                    default:
                        transTitle = trans.type.replace('-', ' ');
                        break;
                }

                let style: React.CSSProperties = {};
                switch (trans.status) {
                    case TransactionStatus.Reversed:
                    case TransactionStatus.Failure:
                        style = {
                            backgroundColor: props.reversedColor,
                            textDecoration: 'line-through',
                        };
                        break;
                    case TransactionStatus.Pending:
                        style = {
                            backgroundColor: props.pendingColor,
                        };
                        break;
                }

                return (
                    <List.Item
                        key={trans.id}
                        className={trans.status === TransactionStatus.Reversed ? 'payment-reversed' : ''}
                        style={style}
                        actions={actions}
                    >
                        <List.Item.Meta
                            className="title-caps"
                            title={transTitle}
                            description={`${ trans.status ? trans.status.replaceAll('-', ' ') : '-' }`}
                        />

                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic title="Payment" prefix="$" precision={2} value={currency(trans.totalAmount, { precision: 2 }).value} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="Date" value={trans.date} formatter={(value: any) => <SimpleDate date={value} simplier />} />
                            </Col>
                        </Row>
                    </List.Item>
                );
            }}
        />
    );
}
