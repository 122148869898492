import React, { useMemo } from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { Button, Form, Input, Select } from 'antd';
import type { FormInstance } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { EscrowApplicationStep } from 'models/escrow';

interface INewLoanFormEscrowDetailsStepProps {
    form: FormInstance;
    editForm?: boolean; // editForm means we are editing the values, not creating a new loan
    goNext?: () => void;
    goBack?: () => void;
}

export const NewLoanFormEscrowDetailsStep: FC<INewLoanFormEscrowDetailsStepProps> = (props) => {
    const collectEscrow = useMemo(() => {
        if (props.editForm) {
            return null;
        }

        return (
            <Form.Item
                name="collectEscrow"
                label="Collect Escrow"
                extra="Do you want to collect escrow? Escrow includes anything such as taxes, insurance, or other fees that are paid on a regular basis. If property taxes are paid in a lump sum, you may not need to collect escrow."
                rules={[{ required: true, message: 'Please select if you want to collect escrow' }]}
            >
                <Select>
                    <Select.Option key="yes" value={true}>Yes</Select.Option>
                    <Select.Option key="no" value={false}>No</Select.Option>
                </Select>
            </Form.Item>
        );
    }, [props.editForm]);

    const startingBalance = useMemo(() => {
        if (props.editForm) {
            return null;
        }

        return (
            <Form.Item
                name="escrowStartingBalance"
                label="Escrow Starting Balance"
                extra="How much money is escrow starting with?"
                rules={[{ required: true, message: 'Please input the starting balance' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }, [props.editForm]);

    const amount = useMemo(() => {
        return (
            <Form.Item
                name="escrowPaymentAmount"
                label="Escrow Payment Amount"
                extra="How much escrow is due each month? This can be changed later."
                rules={[{ required: true, message: 'Please provide a value for the escrow amount' }]}
            >
                <Input prefix="$" style={{ width: '100%' }} inputMode="numeric" />
            </Form.Item>
        );
    }, []);

    const application = useMemo(() => {
        return (
            <Form.Item
                name="escrowApplicationStep"
                label="Escrow Application Step"
                extra="At what point does the escrow get took out of regular payments and applied to the escrow balance? Currently we only support after principal."
                rules={[{ required: true, message: 'Please select when escrow gets took out of the payment' }]}
            >
                <Select>
                    <Select.Option key={EscrowApplicationStep.BeforeFees} disabled>Before Fees</Select.Option>
                    <Select.Option key={EscrowApplicationStep.BeforeInterest}>Before Interest</Select.Option>
                    <Select.Option key={EscrowApplicationStep.AfterPrincipal}>After Principal</Select.Option>
                </Select>
            </Form.Item>
        );
    }, []);

    const bottomButtons = useMemo(() => {
        if (props.editForm) {
            return null;
        }

        return (
            <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue }) => {
                    const doWeCollectEscrow = getFieldValue('collectEscrow') as boolean;
                    const escrowStartingBalanceValue = currency(getFieldValue('escrowStartingBalance'), { precision: 2 });
                    const escrowPaymentAmountValue = currency(getFieldValue('escrowPaymentAmount'), { precision: 2 });
                    const escrowApplicationStep = getFieldValue('escrowApplicationStep') as EscrowApplicationStep;

                    const nextDisabled = (typeof doWeCollectEscrow !== 'boolean' || doWeCollectEscrow) && (
                        escrowStartingBalanceValue.intValue < 0
                        || escrowPaymentAmountValue.intValue <= 0
                        || !escrowApplicationStep
                    );

                    return (
                        <Button.Group>
                            <Button icon={<LeftOutlined />} onClick={props.goBack}>Previous</Button>
                            <Button type="primary" onClick={props.goNext} disabled={nextDisabled}>
                                Next <RightOutlined />
                            </Button>
                        </Button.Group>
                    );
                }}
            </Form.Item>
        );
    }, [props.editForm, props.goBack, props.goNext]);

    return (
        <React.Fragment>
            {collectEscrow}

            <Form.Item noStyle shouldUpdate={(prev: { collectEscrow: boolean }, curr: { collectEscrow: boolean }) => prev.collectEscrow !== curr.collectEscrow}>
                {({ getFieldValue }) => {
                    const doWeCollectIt = getFieldValue('collectEscrow') as boolean;
                    if (!doWeCollectIt) {
                        return null;
                    }

                    return (
                        <React.Fragment>
                            {startingBalance}
                            {amount}
                            {application}
                        </React.Fragment>
                    );
                }}
            </Form.Item>

            {bottomButtons}
        </React.Fragment>
    );
}
