import React from 'react';
import { Modal } from 'antd';
import type { ModalStaticFunctions } from 'antd/lib/modal/confirm';

type ModalType = Omit<ModalStaticFunctions, 'warn'>;

interface IModalProps {
    title: React.ReactNode;
    content: React.ReactNode;
    okText: React.ReactNode;
    cancelText: React.ReactNode;
}

export function displayConfirmInModal(modal: ModalType, args: IModalProps): Promise<boolean> {
    return new Promise((resolve) => {
        function onOk() {
            resolve(true);
        }

        function onCancel() {
            resolve(false);
        }

        if (!args.okText) {
            modal.warning({
                title: args.title,
                content: args.content,
                onOk,
                onCancel,
                okText: args.cancelText,
            });

            return;
        }

        modal.confirm({
            title: args.title,
            content: args.content,
            okText: args.okText,
            onOk,
            onCancel,
            cancelText: args.cancelText,
            autoFocusButton: 'ok',
        });
    });
}

export function displayConfirmModal(title: React.ReactNode, content: React.ReactNode, okText: React.ReactNode, cancelText: React.ReactNode): Promise<boolean> {
    return new Promise((resolve) => {
        function onOk() {
            resolve(true);
        }

        function onCancel() {
            resolve(false);
        }

        if (!okText) {
            Modal.warning({
                title,
                content,
                onOk,
                onCancel,
                okText: cancelText,
            });

            return;
        }

        Modal.confirm({
            title,
            content,
            okText,
            onOk,
            onCancel,
            cancelText,
            autoFocusButton: 'ok',
        });
    });
}
