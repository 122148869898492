import { GlobalState } from "store";
import type { IRental } from "models/rental";

export const getRentalsForSelectedOrg = (state: GlobalState): IRental[] => Object.values(state.rentals.rentalsById).filter((c) => c.organization.id === state.org.selectedOrgLongId);
export const getRentalById = (state: GlobalState, orgId: string, rentalId: string): IRental | undefined => {
    const c = state.rentals.rentalsById[rentalId];
    if (!c) {
        return c;
    }

    if (c.organization.shortId !== orgId) {
        return undefined;
    }

    return c;
};
