import { get, post, postForm, put, del } from './index';

import { IRestRequest } from 'models/common/restRequest';
import { IRestResult } from 'models/common/restResult';

import { IClient, ClientStatus, IClientChangeRequest } from 'models/client';
import { IEntity } from 'models/entity';
import { INote } from 'models/notes';
import { ITimelineEntry } from 'models/timelineEntry';
import { IClientCreationPayload, IClientSetupBillingPayload } from 'models/payloads/clients';
import { ISetDefaultPaymentMethodPayload } from 'models/payloads/billing';
import { IFileUpdatePayload } from 'models/payloads/fileUpdate';
import { IFile, FileMap } from 'models/file';
import { IMail } from 'models/mail';
import type { ITaxInfo } from 'models/common/taxId';
import type { IUserConfirmPasswordPayload } from 'models/payloads/userLogin';

export const createClient = (orgId: string, payload: IClientCreationPayload) => post<IClientCreationPayload, IClient>(`/organizations/${ orgId }/clients`, payload, true);
export const updateClient = (orgId: string, client: Partial<IClient>) => put<Partial<IClient>, void>(`/organizations/${ orgId }/clients/${ client.id }`, client, true);
export const getClients = (orgId: string, req?: IRestRequest) => get<IRestResult<IClient>>(`/organizations/${ orgId }/clients`, true, req);
export const getClientByID = (orgId: string, clientId: string) => get<IClient>(`/organizations/${ orgId }/clients/${ clientId }`, true);
export const deleteClientByID = (orgId: string, clientId: string) => del(`/organizations/${ orgId }/clients/${ clientId }`, true);
export const setClientStatus = (orgId: string, clientId: string, status: ClientStatus) => put<{ status: ClientStatus }, IClient>(`/organizations/${ orgId }/clients/${ clientId }/status`, { status }, true);

export const getClientNotes = (orgId: string, clientId: string) => get<INote[]>(`/organizations/${ orgId }/clients/${ clientId }/notes`, true);
export const addClientNote = (orgId: string, clientId: string, note: Partial<INote>) => post<Partial<INote>, INote>(`/organizations/${ orgId }/clients/${ clientId }/notes`, note, true);
export const updateClientNote = (orgId: string, clientId: string, note: Partial<INote>) => put<Partial<INote>, INote>(`/organizations/${ orgId }/clients/${ clientId }/notes/${ note.id }`, note, true);
export const deleteClientNote = (orgId: string, clientId: string, noteId: string) => del(`/organizations/${ orgId }/clients/${ clientId }/notes/${ noteId }`, true);

export const getClientEntities = (orgId: string, clientId: string) => get<IEntity[]>(`/organizations/${ orgId }/clients/${ clientId }/entities`, true);
export const getClientEntity = (orgId: string, clientId: string, entityId: string) => get<IEntity>(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entityId }`, true);
export const createClientEntity = (orgId: string, clientId: string, entity: Partial<IEntity>) => post<Partial<IEntity>, IEntity>(`/organizations/${ orgId }/clients/${ clientId }/entities`, entity, true);
export const updateClientEntity = (orgId: string, clientId: string, entity: Partial<IEntity>) => put<Partial<IEntity>, IEntity>(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entity.id }`, entity, true);
export const deleteClientEntity = (orgId: string, clientId: string, entityId: string) => del(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entityId }`, true);
export const setPrimaryEntity = (orgId: string, clientId: string, entityId: string) => post<{ entityId: string}, void>(`/organizations/${ orgId }/clients/${ clientId }/primary-entity`, { entityId }, true);
export const verifyClientEntityAddress = (orgId: string, clientId: string, entityId: string) => post<{}, void>(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entityId }/verify-address`, {}, true);
export const downloadClientEntityVCard = (orgId: string, clientId: string, entityId: string) => get<void>(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entityId }/vcard`, true);
export const getClientEntityTaxInfo = (orgId: string, clientId: string, entityId: string, password: string) => post<IUserConfirmPasswordPayload, ITaxInfo>(`/organizations/${ orgId }/clients/${ clientId }/entities/${ entityId }/taxId`, { password }, true);

export const getClientTimelineEntries = (orgId: string, clientId: string) => get<ITimelineEntry[]>(`/organizations/${ orgId }/clients/${ clientId }/timelineEntries`, true);
export const addClientTimelineEntry = (orgId: string, clientId: string, timelineEntry: Partial<ITimelineEntry>) => post<Partial<ITimelineEntry>, ITimelineEntry>(`/organizations/${ orgId }/clients/${ clientId }/timelineEntries`, timelineEntry, true);

export const getClientFilesAsVFS = (orgId: string, clientId: string) => get<FileMap>(`/organizations/${ orgId }/clients/${ clientId }/files?as=vfs`, true);
export const getClientFile = (orgId: string, clientId: string, fileId: string) => get<IFile>(`/organizations/${ orgId }/clients/${ clientId }/files/${ fileId }`, true);
export const uploadClientFile = (orgId: string, clientId: string, formData: FormData) => postForm<IFile>(`/organizations/${ orgId }/clients/${ clientId }/files`, formData, true);
export const updateClientFile = (orgId: string, clientId: string, fileId: string, payload: Partial<IFileUpdatePayload>) => put<Partial<IFileUpdatePayload>, void>(`/organizations/${ orgId }/clients/${ clientId }/files/${ fileId }`, payload, true);
export const createNewClientFileFolder = (orgId: string, clientId: string, folderName: string, parentId?: string) => post<{}, IFile>(`/organizations/${ orgId }/clients/${ clientId }/files?folder=${ folderName }&parentId=${ parentId }`, {}, true);
export const deleteClientFile = (orgId: string, clientId: string, fileId: string) => del(`/organizations/${ orgId }/clients/${ clientId }/files/${ fileId }`, true);

export const getClientMails = (orgId: string, clientId: string) => get<IMail[]>(`/organizations/${ orgId }/clients/${ clientId }/mail`, true);

export const getClientLendiomPayGraphicUrl = (orgId: string, clientId: string) => get<{ url: string }>(`/organizations/${ orgId }/clients/${ clientId }/lendiom-pay-graphic`, true);
export const getClientImpersonationUrl = (orgId: string, clientId: string) => get<{ url: string }>(`/organizations/${ orgId }/clients/${ clientId }/billing/impersonation-url`, true);
export const setupClientBilling = (orgId: string, clientId: string, payload: Partial<IClientSetupBillingPayload>) => post<Partial<IClientSetupBillingPayload>, undefined>(`/organizations/${ orgId }/clients/${ clientId }/billing/setup`, payload, true);
export const getClientPaymentIntentSecret = (orgId: string, clientId: string) => get<{ secret: string }>(`/organizations/${ orgId }/clients/${ clientId }/billing/paymentMethods/add`, true);
export const deleteClientPaymentMethod = (orgId: string, clientId: string, paymentMethodId: string) => del(`/organizations/${ orgId }/clients/${ clientId }/billing/paymentMethods/${ paymentMethodId }`, true);
export const setClientPaymentMethodAsDefault = (orgId: string, clientId: string, payload: ISetDefaultPaymentMethodPayload) => post<ISetDefaultPaymentMethodPayload, any>(`/organizations/${ orgId }/clients/${ clientId }/billing/paymentMethods/set-default`, payload, true);

export const getClientChangeRequests = (orgId: string, clientId: string) => get<IClientChangeRequest[]>(`/organizations/${ orgId }/clients/${ clientId }/change-requests`, true);
export const approveClientChangeRequest = (orgId: string, clientId: string, changeReqId: string) => post<{}, IClientChangeRequest>(`/organizations/${ orgId }/clients/${ clientId }/change-requests/${ changeReqId }/approve`, {}, true);
