import { Language } from './common/language';
import { IPaymentMethod } from './billing/paymentMethod';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { ILookup } from './common/lookup';
import { IRelatedTo } from './common/relatedTo';

export interface IClient extends IObjectDates, IOrganizational {
    id: string;
    accountNumber: string;
    displayName: string;
    type: ClientType;
    status: ClientStatus;
    primaryEntity: ILookup;
    entities: ILookup[];
    preferences?: IClientPreferences;

    billing: IClientBilling;
    portal: IClientPortal;

    hasPendingUpdate?: boolean;

    associated?: IRelatedTo[];
}

export enum ClientType {
    Family = 'family',
    Individual = 'individual',
    Company = 'company',
    Unidentified = 'unidentified',
}

export enum ClientStatus {
    Active = 'active',
    Inactive = 'inactive',
    Prospect = 'prospect',
    DoNotContact = 'do-not-contact',
    Unknown = 'unknown',
}

export interface IClientPreferences {
    language: Language;
}

export enum ClientBillingAllowedPaymentMethods {
    BankAccounts = 'bank',
    CreditDebitCards = 'card',
}

export interface IClientBilling {
    payArcIsSetup?: boolean;
    payArcCustomerId?: string;
    setup?: boolean;
    hasPaymentMethods?: boolean;
    paymentMethods?: IPaymentMethod[];
    allowedPaymentMethods?: ClientBillingAllowedPaymentMethods[];
}

export interface IClientPortal {
    lastActivity: string;
    accessCode: IClientPortalAccessCode;
}

export interface IClientPortalAccessCode {
    requestedAt: string;
    redeemedAt: string;
}

export interface IClientChangeRequest extends IObjectDates, IOrganizational {
	id: string;
	client: ILookup;
	entity: ILookup;

	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;

	status: ClientChangeRequestStatus;
	statusUpdatedBy?: ILookup;
	statusUpdateReason?: string;
}

export enum ClientChangeRequestStatus {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
}
