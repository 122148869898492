import { PlanInterval } from './payment/plan';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { IPhoneNumber } from './common/phoneNumber';
import { IOrganizationAddresses } from './organizationAddresses';
import { IOrganizationLegal } from './organizationLegal';
import { IInvoice } from './billing/invoices';
import { IPaymentMethod } from './billing/paymentMethod';
import { Currency } from './currency';
import { ILateFeeConfig } from './lateFee';
import { ILoanDefaulting } from './loan';
import { ICommunicationConfig } from './';

export interface IOrganization extends IOrganizational, IObjectDates {
    id: string;
    shortId: string;
    name: string;
    phoneNumber: IPhoneNumber;
    email: string;
    website?: string;
    addresses: IOrganizationAddresses;
    tosAgreedAt: Date;
    setupComplete: boolean;

    billing: IOrganizationBilling;
    legal: IOrganizationLegal;

    stripeConnect: IOrganizationStripeConnect;
    payArc: IOrganizationPayArc;

    messaging?: IOrganizationMessaging;
    preferences?: IOrganizationPreferences;
}

export interface IOrganizationBilling {
    subscriptionId: string;
    plan: PlanInterval;
    status: OrganizationBillingStatus;
    periodStartsAt: string;
    periodEndsAt: string;

    latestInvoice: IInvoice;
    paymentMethods: IPaymentMethod[];

    addons: IOrganizationBillingAddons;

    cancelsAtEndOfPeriod: boolean;
    cancelsAt: string;
    canceledAt: string;
}

export enum OrganizationBillingStatus {
    Active = 'active',
    Cancelled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Unpaid = 'unpaid',
    Trialing = 'trialing',
}

export interface IOrganizationBillingAddons {
    customWebsite: IOrganizationBillingAddon;
    rentals: IOrganizationBillingAddon;
    documentSignature?: IOrganizationBillingAddon;
}

export interface IOrganizationBillingAddon {
    enabled: boolean;
    dateEnabled: string;
}

export interface IOrganizationStripeConnect {
    accountId: string;
    availableBalance: Currency;
    pendingBalance: Currency;
    detailsSubmitted: boolean;
    chargesEnabled: boolean;
    payoutsEnabled: boolean;
}

export interface IOrganizationPayArc {
    setupComplete: boolean;
    setupCompletedAt: string;
    achEnabled: boolean;

    merchantId: string;

    rates: IOrganizationPayArcRates;
    info?: IOrganizationPayArcInfo;
}

export interface IOrganizationPayArcRates {
    card: string;
    bank: string;
    cardTransactionFee: string;
}

export interface IOrganizationPayArcInfo {
    reserveAmount: string;
    settlementDays: number;
}

export interface IOrganizationMessaging {
    setupComplete: boolean;

    accountId: string;

    brandId: string;
    brandStatus: string;
    campaignId: string;
    campaignStatus: string;
    numberId: string
    number: string;

    purchaseError?: boolean;
}

export interface IOrganizationPreferences {
    loanLateFee?: ILateFeeConfig;
    loanDefaulting?: ILoanDefaulting;
    loanCommunication?: ICommunicationConfig;

    loanLateFeeUpdatedAt: string;
    loanDefaultingUpdatedAt: string;
    loanCommunicationUpdatedAt: string;
}

export interface IOrganizationUnveilSite extends IOrganizational, IObjectDates {
    id: string;
    subdomain: string;
}
