import React from 'react';
import { Tag } from 'antd';

import { RentalStatus } from 'models/rental';

interface IRentalStatusTagProps {
    status?: RentalStatus
};

export const RentalStatusTag: React.FC<IRentalStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case RentalStatus.Current:
            color = 'green';
            break;
        case RentalStatus.Eviction:
        case RentalStatus.Late:
            color = 'red';
            break;
        case RentalStatus.Evicted:
            color = 'magenta';
            break;
        case RentalStatus.Terminated:
            color = 'volcano';
            break;
        case RentalStatus.Draft:
            color = 'gold';
            break;
        default:
            color = 'purple';
            break;
    }

    let tagLabel = `${ props.status ? props.status.replaceAll('-', ' ') : '' }`;
    switch(props.status) {
        case RentalStatus.Draft:
            tagLabel = 'Draft';
            break;
        case RentalStatus.Current:
            tagLabel = 'Current';
            break;
        case RentalStatus.Late:
            tagLabel = 'Late';
            break;
        case RentalStatus.Eviction:
            tagLabel = 'Eviction';
            break;
        case RentalStatus.Evicted:
            tagLabel = 'Evicted';
            break;
        case RentalStatus.Terminated:
            tagLabel = 'Terminated';
            break;
        default:
            tagLabel = 'Unknown';
            break;
    }

    return (
        <Tag color={color}>{tagLabel}</Tag>
    );
});
