import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Button, Result, notification } from 'antd';

import { IClient } from 'models/client';
import { IOrganization } from 'models/organization';
import { IEntity } from 'models/entity';

import { AddPaymentMethodModal } from './addPaymentMethodModal';
import { PaymentMethodTable } from './paymentMethods';

import { displayErrorNotification } from 'utils/errors';
import { setupClientBilling, getClientEntity } from 'api/clients';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { orgSetStripeAccount, orgUnsetStripeAccount } from 'store/actions/org';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
});

interface IPaymentMethodCardProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    for: IClient | IOrganization;
    style?: React.CSSProperties;
}

interface IPaymentMethodCardState {
    isAddPaymentMethodVisible: boolean;
    isSettingUp: boolean;
    primaryEntity?: IEntity;
}

class PaymentMethodCardBase extends React.PureComponent<IPaymentMethodCardProps, IPaymentMethodCardState> {
    state: Readonly<IPaymentMethodCardState> = {
        isAddPaymentMethodVisible: false,
        isSettingUp: false,
    };

    componentDidMount() {
        this.fetchClientEntity();
    }

    componentDidUpdate(prevProps: IPaymentMethodCardProps) {
        const what = this.props.for;
        if ('stripeConnect' in what || 'stripeConnect' in prevProps.for) {
            return;
        }

        if (new Date(what.modifiedAt).getTime() > new Date(prevProps.for.modifiedAt).getTime()) {
            this.fetchClientEntity();
        }
    }

    async fetchClientEntity() {
        const what = this.props.for;
        if ('stripeConnect' in what) {
            return;
        }

        const entity = await getClientEntity(what.organization.id, what.id, what.primaryEntity.id);

        this.setState({ primaryEntity: entity });
    }

    onAddPaymentMethodClick = () => {
        if (!this.props.selectedOrg) {
            return;
        }

        if ('primaryEntity' in this.props.for) {
            this.props.dispatch(orgSetStripeAccount(this.props.selectedOrg.stripeConnect.accountId));
        } else {
            this.props.dispatch(orgUnsetStripeAccount());
        }

        this.setState({ isAddPaymentMethodVisible: true });
    }

    onAddClose = () => {
        this.props.dispatch(orgUnsetStripeAccount());

        this.setState({ isAddPaymentMethodVisible: false });
    }

    get canAddPaymentMethod(): boolean {
        if (this.state.isSettingUp) {
            return false;
        }

        // always allow organizations to add payment methods
        if ('stripeConnect' in this.props.for) {
            return true;
        }

        // only can add for organizations now
        return false;
    }

    get addPaymentMethodButton() {
        if ('primaryEntity' in this.props.for) {
            return null;
        }

        return (
            <Button
                size="small"
                type="dashed"
                icon={<PlusOutlined />}
                onClick={this.onAddPaymentMethodClick}
                disabled={!this.canAddPaymentMethod}
            >Add Payment Method</Button>
        );
    }

    get paymentMethods() {
        if (!this.props.selectedOrg) {
            return null;
        }

        return (
            <React.Fragment>
                <PaymentMethodTable for={this.props.for} size="small" />

                <AddPaymentMethodModal
                    visible={this.state.isAddPaymentMethodVisible}
                    close={this.onAddClose}
                    for={this.props.for}
                />
            </React.Fragment>
        );
    }

    get orgBillingLayout() {
        return (
            <React.Fragment>
                <div className="view-right-header">
                    <div className="sub-title">Payment Methods</div>
                    {this.addPaymentMethodButton}
                </div>

                { this.paymentMethods }
            </React.Fragment>
        );
    }

    onSetupClick = () => {
        this.setState({ isSettingUp: true }, async () => {
            const client = this.props.for as IClient;

            try {
                await setupClientBilling(client.organization.id, client.id, { entityId: client.primaryEntity.id });

                notification.success({ message: `${ client.displayName } has been successfully set up in the billing system.` });
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ isSettingUp: false });
            }
        });
    }

    get clientSetupRequired() {
        if (!this.state.primaryEntity) {
            return (
                <Result
                    title="Primary Entity not Found"
                    subTitle="The primary entity for this client was not found. Why? Not entirely sure."
                />
            );
        }

        const pe = this.state.primaryEntity;

        if (!pe.email) {
            return (
                <Result
                    title="Email Missing"
                    subTitle="In order to setup the client for billing and to add a payment method, an email address is required for the primary entity."
                />
            );
        }

        if (!pe.phoneNumbers || pe.phoneNumbers.length === 0) {
            return (
                <Result
                    title="Missing Phone Number"
                    subTitle="In order to setup the client for billing and to add a payment method, a phone number is required for the primary entity."
                />
            );
        }

        if (typeof pe.phoneNumbers.find((p) => typeof p.isCellular === 'boolean' ? p.isCellular : false) === 'undefined') {
            return (
                <Result
                    title="Missing Cell Phone Number"
                    subTitle="The client's primary entity must have a cellular phone number in order to setup the client for billing."
                />
            );
        }

        if (!pe.addresses || pe.addresses.length === 0) {
            return (
                <Result
                    title="Missing an Address"
                    subTitle="In order to setup the client for billing and to add a payment method, an address is required for the primary entity."
                />
            );
        }

        return (
            <Result
                title="Billing Setup Required"
                subTitle="The client has yet to be setup as a Customer inside of the billing system. Clicking setup will configure the customer with the Primary Entity's information."
                extra={
                    <Button
                        type="primary"
                        size="large"
                        icon={<SettingOutlined />}
                        onClick={this.onSetupClick}
                        disabled={this.state.isSettingUp}
                        loading={this.state.isSettingUp}
                    >Setup</Button>
                }
            />
        );
    }

    render() {
        if ('stripeConnect' in this.props.for) {
            return this.orgBillingLayout;
        }

        if (!this.props.selectedOrg) {
            return null;
        }

        if (this.props.selectedOrg?.payArc?.setupComplete) {
            return (
                <Card title="Payment Methods" bordered={false} style={this.props.style}>
                    { this.props.for.billing.payArcIsSetup ? this.paymentMethods : this.clientSetupRequired }
                </Card>
            );
        }

        if (this.props.selectedOrg.stripeConnect && this.props.selectedOrg.stripeConnect.detailsSubmitted) {
            return (
                <Card title="Payment Methods" bordered={false} style={this.props.style}>
                    { this.props.for.billing.setup ? this.paymentMethods : this.clientSetupRequired }
                </Card>
            );
        }

        return null;
    }
}

export const PaymentMethodCard = connect(mapStateToProps)(PaymentMethodCardBase);
