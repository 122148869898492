import React from 'react';
import { MobileTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { IEntity } from 'models/entity';

import { formatPhoneNumberForLink, formatPhoneNumberForDisplay } from 'utils/numberFormatting';

interface IEntityPhoneNumberProps {
    entity: IEntity,
}

interface IEntityPhoneNumberState {}

export class EntityPhoneNumber extends React.PureComponent<IEntityPhoneNumberProps> {
    state: Readonly<IEntityPhoneNumberState> = {}

    get isCellularIcon() {
        const phoneNumber = this.props.entity!.phoneNumbers![0];

        if (!phoneNumber.isCellular) {
            return null;
        }

        return (
            <Tooltip overlay="Is a cellular number">
                <MobileTwoTone />
            </Tooltip>
        );
    }

    render() {
        const person = this.props.entity;

        if (!person.phoneNumbers || person.phoneNumbers.length <= 0) {
            return (
                <span style={{color: '#ccc'}}>No phone numbers.</span>
            );
        }

        const phoneNumber = person.phoneNumbers[0];

        return (
            <span style={{ textDecoration: phoneNumber.optedOut ? 'line-through' : '' }}>
                <Tooltip overlay={phoneNumber.optedOut ? 'Phone number has opted out of messages.' : `${ person.fullName }'s ${ phoneNumber.label } number`}>
                    <a href={'tel:' + formatPhoneNumberForLink(phoneNumber.number)}>{formatPhoneNumberForDisplay(phoneNumber.number)}</a>
                </Tooltip>

                { this.isCellularIcon }
            </span>
        );
    }
}
