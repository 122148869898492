import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Row, Col, Alert, Form, Radio, Select, Input } from 'antd';
import type { Rule } from 'antd/lib/form';

import type { IOrganization } from 'models/organization';
import { OnlinePaymentPlatformFeePayee } from 'models';

interface IOnlinePaymentInputsProps {
    org: IOrganization;
    type: 'loan' | 'rental';
    width?: 'full';
}

export const OnlinePaymentInputs: FC<IOnlinePaymentInputsProps> = ({ org, type, width }) => {
    const initialValue = useMemo(() => {
        let initialValue = org.name.replace(/[^\w\s]/gi, '');
        if (initialValue.length > 22) {
            initialValue = initialValue.substring(0, 22).trim();
        }

        return initialValue;
    }, [org]);

    const statementDescriptorRules: Rule[] = useMemo(() => [
        { required: true, message: 'Please provide a brief summary for the statement descriptor of the transaction.' },
        {
            type: 'string', whitespace: true, message: 'Invalid statement descriptor.',
            transform: (value: string) => value.trim().toUpperCase(),
        },
        { min: 5, max: 22, message: 'Statement descriptor must be between 5 and 22 characters long.' },
        { pattern: /[A-Z]+/gi, message: 'Statement descriptor must contain at least one letter.' },
        {
            message: `Statement descriptor must not contain: < > \\ ' " *`, validator: async (rule: any, value: string) => {
                if (value.includes('>')) {
                    throw new Error('Can not include >');
                } else if (value.includes('<')) {
                    throw new Error('Can not include <');
                } else if (value.includes('\\')) {
                    throw new Error('Can not include \\');
                } else if (value.includes('\'')) {
                    throw new Error('Can not include \'');
                } else if (value.includes('"')) {
                    throw new Error('Can not include "');
                } else if (value.includes('*')) {
                    throw new Error('Can not include *');
                }
            },
        },
    ], []);

    return (
        <React.Fragment>
            {!org || !org.stripeConnect || !org.stripeConnect.accountId ?
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={24} lg={width === 'full' ? 25 : 18} xl={width === 'full' ? 25 : 10}>
                        <Alert
                            message="Online Payments"
                            description="If you would like to accept payments online, please connect Stripe."
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            : null}

            {org.stripeConnect.accountId && !org.stripeConnect.detailsSubmitted ?
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={24} lg={18} xl={10}>
                        <Alert
                            message="Online Payments"
                            description="To accept online payments, please finish connecting Stripe."
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            : null}

            {org.stripeConnect.accountId && org.stripeConnect.detailsSubmitted ?
                <Form.Item
                    name="onlinePaymentsEnabled"
                    label="Online Payments"
                    extra={`Should we set up this ${type} for online payments? You can always enable this in the future.`}
                    rules={[{ required: true, message: 'Please state whether or not we should enabled online payments.' }]}
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={true}>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            : null}

            <Form.Item
                name="onlinePaymentPlatformFeePayee"
                label="Platform Fee Payee"
                extra={`Who pays the online platform fees? The ${type === 'rental' ? 'renter' : 'buyer'}, ${type === 'rental' ? 'owner' : 'seller'} or split 50%?`}
                rules={[{ required: true, message: 'Please indicate who should pay the platform fees.' }]}
            >
                <Select<OnlinePaymentPlatformFeePayee>>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Buyer} value={OnlinePaymentPlatformFeePayee.Buyer}>{type === 'rental' ? 'Renter' : 'Buyer'}</Select.Option>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Both} value={OnlinePaymentPlatformFeePayee.Both}>Both 50/50 Split</Select.Option>
                    <Select.Option key={OnlinePaymentPlatformFeePayee.Seller} value={OnlinePaymentPlatformFeePayee.Seller}>{type === 'rental' ? 'Owner' : 'Seller'}</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                name="onlineStatementDescriptor"
                label="Statement Descriptor"
                initialValue={initialValue}
                rules={statementDescriptorRules}
                extra={
                    <React.Fragment>
                        Extra information about a product which will appear on your customer's credit card statement. <a href="https://stripe.com/docs/statement-descriptors" target="_blank" rel="noopener noreferrer">Click for more information.</a>
                    </React.Fragment>
                }
            >
                <Input style={{ textTransform: 'uppercase' }} minLength={5} maxLength={22} />
            </Form.Item>

            {
                type === 'loan' ?
                    <Form.Item
                        name="allowAutoDraft"
                        label="Allow Auto Draft"
                        extra="Do you wish to allow the buyer to set up auto drafting their payments?"
                        initialValue={true}
                        rules={[{ required: true, message: 'Please state whether or not we should allow auto drafting payments.' }]}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={true}>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                :
                    <Form.Item
                        name="allowAutoPay"
                        label="Allow Auto Pay"
                        extra="Do you wish to allow the buyer to set up automatic payments?"
                        initialValue={true}
                        rules={[{ required: true, message: 'Please state whether or not we should allow automatic payments.' }]}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={true}>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
            }
        </React.Fragment>
    );
}
