import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { hasAccessTo } from 'store/selectors/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
    userCanView: hasAccessTo(PermissionFeature.OrganizationBilling, PermissionAction.Read)(state),
});

interface IStripeConnectButtonProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    size?: SizeType;
}

interface IStripeConnectButtonState {
    isLoadingConnect: boolean;
}

class StripeConnectButtonBase extends React.PureComponent<IStripeConnectButtonProps, IStripeConnectButtonState> {
    state: IStripeConnectButtonState = {
        isLoadingConnect: false,
    };

    //#region when they are connected already
    get stripeConnectDashboardPopover() {
        return (
            <div>View your account information<br />in the Stripe Connect Dashboard.</div>
        );
    }
    //#endregion when they are connected already

    render() {
        if (!this.props.selectedOrg || !this.props.selectedOrg.stripeConnect || !this.props.userCanView) {
            return null;
        }

        if (this.props.selectedOrg.payArc?.setupComplete || !this.props.selectedOrg.stripeConnect.accountId || !this.props.selectedOrg.stripeConnect.detailsSubmitted) {
            return null;
        }

        const url = `https://dashboard.stripe.com/${ process.env.NODE_ENV === 'production' ? '' : 'test/' }dashboard`;

        return (
            <Popover placement="bottom" title="Stripe Connect Dashboard" content={this.stripeConnectDashboardPopover}>
                <Button type="default" icon={<LinkOutlined />} href={url} target="_blank" size={this.props.size || 'middle'}>Stripe Dashboard</Button>
            </Popover>
        );
    }
}

export const StripeConnectButton = connect(mapStateToProps)(StripeConnectButtonBase);
