import React from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { ILoanTransaction, ILoanTransactionTo, LoanTransactionType } from 'models/loan';
import { LongCurrency } from 'utils/formatting';

interface ITransactionToTableProps {
    transaction: ILoanTransaction;
}

export const TransactionToTable: React.FC<ITransactionToTableProps> = (props) => {
    const columns: ColumnProps<ILoanTransactionTo>[] = [
        { title: 'Payment Number', dataIndex: 'paymentNumber', key: 'paymentNumber' },
        { title: 'To Interest', dataIndex: 'interest', key: 'paymentToInterest', render: (value: string) => <LongCurrency value={value} tooltip /> },
        { title: 'To Principal', dataIndex: 'principal', key: 'paymentToPrincipal', render: (value: string) => <LongCurrency value={value} tooltip /> },
    ];

    if (props.transaction.totalToEscrow && props.transaction.totalToEscrow !== '0') {
        columns.push({
            title: 'To Escrow',
            dataIndex: 'escrow',
            key: 'paymentToEscrow',
            render: (value: string) => <LongCurrency value={value} tooltip />,
        });
    }

    if (props.transaction.type === LoanTransactionType.Adjustment) {
        columns.splice(1, 2);
        columns.push({ title: 'Adjustments', dataIndex: 'adjustments', key: 'paymentToAdjustments', render: (value: string) => <LongCurrency value={value} tooltip /> });
    }

    if (props.transaction.toUnpaidInterest !== '0') {
        columns.push({ title: 'To Unpaid Interest', dataIndex: 'unpaidInterest', key: 'paymentToUnpaidInterest', render: (value: string) => <LongCurrency value={value} tooltip /> });
    }

    return (
        <Table<ILoanTransactionTo>
            title={(rows) => `Transaction Applied to ${ rows.length } Payments`}
            columns={columns}
            dataSource={props.transaction.toPayments}
            rowKey="paymentNumber"
            pagination={false}
            size="small"
            bordered={false}
        />
    );
}
