import React, { useMemo } from 'react';
import type { FC } from 'react';
import currency from 'currency.js';
import { isMobileOnly } from 'react-device-detect';
import { Descriptions, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import type { ILoan } from 'models/loan';
import { LoanStatus, LoanTermsInterestFormula, LoanTermsInterestSchedule } from 'models/loan';
import { LongCurrency, SimpleDate } from 'utils/formatting';

interface ILoanTabOverviewProps {
    loan: ILoan;
}

export const LoanTabOverview: FC<ILoanTabOverviewProps> = React.memo(({ loan }) => {
    const totalDueTooltip = useMemo(() => <Tooltip overlay="Total due is a sum of the late fees and other fees due plus the balance due (does NOT include property tax).">Total Due <InfoCircleOutlined /></Tooltip>, []);
    const length = useMemo(() => currency(loan.terms.length, { precision: 0 }).format(), [loan]);
    const interest = useMemo(() => currency(loan.terms.rate).format(), [loan]);
    const paymentAmountTooltip = useMemo(() => {
        if (!loan.escrow || !loan.escrow.paymentAmount) {
            return (
                <React.Fragment>Payment Amount</React.Fragment>
            );
        }

        return (
            <Tooltip overlay="Includes the escrow payment.">Payment Amount <InfoCircleOutlined /></Tooltip>
        );
    }, [loan]);

    const interestAccruesDaily = useMemo(() => {
        if (loan.terms.interestSchedule !== LoanTermsInterestSchedule.AccruesDaily) {
            return null;
        }

        let val = currency(loan.terms.rate, { precision: 14 }).divide(100);
        switch (loan.terms.interestFormula) {
            case LoanTermsInterestFormula.ActualBy360:
            case LoanTermsInterestFormula.ThirtyDaysBy360:
                val = val.divide(360).multiply(loan.balance.principal);
                break;
            case LoanTermsInterestFormula.ActualBy365:
                val = val.divide(365).multiply(loan.balance.principal);
                break;
        }

        const dailyInterest = val.format();

        return (
            <React.Fragment>
                <Descriptions.Item label="Interest per Day"><LongCurrency value={dailyInterest} tooltip /></Descriptions.Item>
                <Descriptions.Item label="Accrued Interest"><LongCurrency value={loan.balance.accruedInterest} /></Descriptions.Item>
                <Descriptions.Item label="Unpaid Interest"><LongCurrency value={loan.balance.interest} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    const escrow = useMemo(() => {
        if (typeof loan.escrow === 'undefined') {
            return null;
        }

        return (
            <React.Fragment>
                <Descriptions.Item label="Escrow Balance"><LongCurrency value={loan.escrow.balance} /></Descriptions.Item>
                <Descriptions.Item label="Escrow Payment Amount"><LongCurrency value={loan.escrow.paymentAmount} /></Descriptions.Item>
            </React.Fragment>
        );
    }, [loan]);

    return (
        <Descriptions size="small" column={isMobileOnly ? 2 : 3} layout={isMobileOnly ? 'vertical' : 'horizontal'}>
            <Descriptions.Item label={paymentAmountTooltip}><LongCurrency value={loan.terms.payment!} /></Descriptions.Item>
            <Descriptions.Item label="Remaining Principal"><LongCurrency value={loan.balance.principal} tooltip /></Descriptions.Item>
            <Descriptions.Item label="Interest Rate">{ interest }%</Descriptions.Item>
            <Descriptions.Item label="Balance Due"><LongCurrency value={loan.balance.due} /></Descriptions.Item>
            <Descriptions.Item label="Late Fees Due"><LongCurrency value={loan.balance.lateFees} /></Descriptions.Item>
            { loan.balance.otherFees !== '0' ? <Descriptions.Item label="Other Fees Due"><LongCurrency value={loan.balance.otherFees} /></Descriptions.Item> : null }
            { loan.balance.propertyTax !== '0' ? <Descriptions.Item label="Property Tax Due"><LongCurrency value={loan.balance.propertyTax} /></Descriptions.Item> : null}
            { interestAccruesDaily }
            <Descriptions.Item label={totalDueTooltip}><LongCurrency value={loan.balance.totalDue || '0'} /></Descriptions.Item>
            <Descriptions.Item label="Last Payment Date"><SimpleDate date={loan.lastPaymentReceivedDate} /></Descriptions.Item>
            <Descriptions.Item label="Next Due Date"><SimpleDate date={loan.nextDueDate} /></Descriptions.Item>
            <Descriptions.Item label="Payment Terms">{ length } { loan.terms.lengthUnit }</Descriptions.Item>
            { loan.status === LoanStatus.Repossessed ? <Descriptions.Item label="Repossessed Date"><SimpleDate date={loan.repossessedDate} /></Descriptions.Item> : null }
            { escrow }
        </Descriptions>
    );
});
