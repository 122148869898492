import { authReducer } from './auth';
import { orgReducer } from './org';
import { inventoryReducer } from './inventories';
import { tractReducer } from './tracts';
import { notificationReducer } from './notifications';
import { clientReducer } from './clients';
import { loanReducer } from './loans';
import { permissionReducer } from './permissions';
import { rentalReducer } from './rentals';

export const reducers = {
    auth: authReducer,
    org: orgReducer,
    inventories: inventoryReducer,
    tracts: tractReducer,
    notifications: notificationReducer,
    clients: clientReducer,
    loans: loanReducer,
    rentals: rentalReducer,
    permission: permissionReducer,
};

