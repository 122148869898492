import { ILookup } from './common/lookup';
import { ILateFeeConfig } from './lateFee';
import { Currency } from './currency';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { CommunicationPreference, LengthUnit, OnlinePaymentPlatformFeePayee, PaymentFrequency } from './';

export interface IRental extends IObjectDates, IOrganizational {
    id: string;
    label: string;
    inventory: ILookup;
    unit?: ILookup;
    client: ILookup;

    status: RentalStatus;
    communication: IRentalCommunication;
    onlinePayment: IRentalOnlinePaymentConfiguration;

    terms: IRentalTerms;
    balance: IRentalBalance;
    lateFeeConfig: ILateFeeConfig;
    recurringFees: IRentalRecurringFee[];

    startDate: string;
    renewalDate: string;
    firstPaymentDate: string;
    lastPaymentReceivedDate: string;
    lastTransactionDate: string;
    lastTransactionModifiedDate?: string;
    previousDueDate: string;
    nextDueDate: string;
    evictedDate: string;
    terminatedDate: string;
}

export enum RentalStatus {
    Draft = 'draft',
    Current = 'current',
    Late = 'late',
    Eviction = 'eviction',
    Evicted = 'evicted',
    Terminated = 'terminated',
}

export interface IRentalCommunication {
    automated: boolean;
    preference?: CommunicationPreference[];
}

export interface IRentalOnlinePaymentConfiguration {
    enabled: boolean;
    statementDescriptor: string;
    platformFeePayee?: OnlinePaymentPlatformFeePayee;
    allowAutoPay: boolean;
    autoPay: IRentalAutoPayConfiguration;
}

export interface IRentalAutoPayConfiguration {
    enabled: boolean;
    paymentMethodId: string;
    upToAmount: Currency;
    nextDate: string;
    previousDate: string;
    authorizationDate: string;
}

export interface IRentalTerms {
    length: number;
    lengthUnit: LengthUnit;
    payment: Currency;

    wasExisting: boolean;
    existing: IRentalTermsExisting;
}

export interface IRentalTermsExisting {
    balance: Currency;
    lateFees: Currency;
    otherFees: Currency;
    nextPaymentDate: string;
}

export interface IRentalBalance {
    due: Currency;
    lateFees: Currency;
    otherFees: Currency;
    totalDue: Currency;
    deposit: Currency;
}

export interface IRentalRecurringFee {
    name: string;
    description: string;
    amount: Currency;
    frequency: PaymentFrequency;
    firstChargePeriod: 'contract-start' | 'specific-date';
    startDate?: string;
}
