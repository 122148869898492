import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Checkbox, Col, Result, Row, Statistic, Typography } from 'antd';

import type { ITotalActiveLoanBalances } from 'models/dashboard';

import { SimpleTooltip } from 'utils/tooltipWrapper';
import { displayErrorNotification } from 'utils/errors';

import { getActiveBalances } from 'api/dashboard';
import { useLocalStorage } from 'utils/hooks';

interface IActiveBalanceVisible {
    visible: boolean;
}

interface IActiveBalancesCardProps {
    orgId: string;
}

export const ActiveBalancesCard: React.FC<IActiveBalancesCardProps> = ({ orgId }) => {
    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);
    const [contentVisible, setContentVisible] = useLocalStorage<IActiveBalanceVisible>('activeBalancesVisible', { visible: true });
    const [activeBalance, setActiveBalance] = useState<ITotalActiveLoanBalances | undefined>();

    useEffect(() => {
        if (!orgId || !loading) {
            return;
        }

        getActiveBalances(orgId)
            .then((data) => {
                setActiveBalance(data);
                setLoading(false);
                setDidError(false);
            })
            .catch((e) => {
                displayErrorNotification(e);
                setDidError(true);
                setLoading(false);
            });
    }, [orgId, loading]);

    const content = useMemo(() => {
        if (didError || !contentVisible.visible) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Sum of everything outstanding: fees, interest and principal" hasQuestion content="Total" />}
                            value={activeBalance?.total}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Sum of the outstanding principal and remaining interest." hasQuestion content="Total Balance" />}
                            value={activeBalance?.totalBalance}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Sum of the late fees and other fees." hasQuestion content="Total Fees" />}
                            value={activeBalance?.totalFees}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Total sum of the remaining principal balances." hasQuestion content="Principal" />}
                            value={activeBalance?.principal}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Estimated unpaid interest; when loan's interest schedule accrues daily, this contains the total unpaid accrued interest." hasQuestion content="Interest" />}
                            value={activeBalance?.interest}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Total sum of the unpaid late fees which are currently due." hasQuestion content="Late Fees" />}
                            value={activeBalance?.lateFees}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Statistic
                            title={<SimpleTooltip title="Total sum of the unpaid other fees." hasQuestion content="Other Fees" />}
                            value={activeBalance?.otherFees}
                            precision={2}
                            prefix="$"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <em style={{ position: 'relative', top: '0', paddingLeft: '8px', fontSize: '12px' }}>*is calculated in real time; assumes payments are made on time</em>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }, [didError, contentVisible, activeBalance]);

    const contentHidden = useMemo(() => {
        if (contentVisible.visible) {
            return null;
        }

        return (
            <Typography.Text type="secondary">Content is hidden</Typography.Text>
        );
    }, [contentVisible]);

    const didErrorContent = useMemo(() => {
        if (!didError) {
            return null;
        }

        return (
            <Result
                status="error"
                title="An error occurred"
                extra={<Button onClick={() => setLoading(true)}>Retry</Button>}
            />
        );
    }, [didError]);

    return (
        <Card
            loading={loading}
            bordered={false}
            title="Active Loan Balances*"
            extra={<Checkbox checked={contentVisible.visible} onChange={() => setContentVisible({ visible: !contentVisible.visible })}>Visible</Checkbox>}
            style={{ height: '100%' }}
        >
            { didErrorContent }
            { content }
            { contentHidden }
        </Card>
    );
};
