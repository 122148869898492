import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { GridContent, PageContainer } from '@ant-design/pro-layout';
import { Tabs } from 'antd';
import type { BreadcrumbProps } from 'antd/lib/breadcrumb';
import type { TabPosition } from 'rc-tabs/lib/interface';

import { AccessControlledWrapper } from 'components/permissions';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';

import { Loading } from 'components/misc/loading';
import { breadCrumbItemRender } from 'utils/breadCrumbs';

import { getUser } from 'store/selectors/auth';
import { isSelectedOrgAllowedToCreate, getSelectedOrg } from 'store/selectors/org';

import { BasicSettingsView } from './tabs/basic';
import { BillingView } from './tabs/billing';
import { MembersView } from './tabs/members';
import { InvoicesView } from './tabs/invoices';
import { StripeView } from './tabs/stripe';

const ExportView = lazy(() => import('./tabs/export'));
const FillablePDFsView = lazy(() => import('./tabs/fillablePdfs'));
const OrganizationRoles = lazy(() => import('./tabs/roles'));

enum OrganizationSettingsMenuKey {
    Basic = 'basic',
    Billing = 'billing',
    Invoices = 'invoices',
    Export = 'export',
    Members = 'members',
    Roles = 'roles',
    Stripe = 'stripe',
    FillablePDFs = 'filleable-pdfs',
}

const OrganizationSettingsBase: FC = () => {
    const [tabPosition, setTabPosition] = useState<TabPosition>('left');
    const [selectedTab, setSelectedTab] = useState<OrganizationSettingsMenuKey>(OrganizationSettingsMenuKey.Basic);
    const history = useHistory();
    const { tab } = useParams<{ tab: string }>();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const selectedOrg = useSelector(getSelectedOrg);
    const canCreate = useSelector(isSelectedOrgAllowedToCreate);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const resizeCallback = useCallback(() => {
        requestAnimationFrame(() => {
            if (!containerRef || !containerRef.current) {
                return;
            }

            let position: TabPosition = 'left';
            const { offsetWidth } = containerRef.current;
            if (offsetWidth < 641) {
                position = 'top';
            }

            if (window.innerWidth < 768 && offsetWidth > 400) {
                position = 'top';
            }

            setTabPosition(position);
        });
    }, [containerRef]);

    useEffect(() => {
        window.addEventListener('resize', resizeCallback);

        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
    }, [resizeCallback]);

    useEffect(() => {
        setTimeout(resizeCallback, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handling the tab selection and syncing it with the url
    useEffect(() => {
        if (tab) {
            setSelectedTab(tab as OrganizationSettingsMenuKey);
            return;
        }

        if (!selectedOrg) {
            return;
        }

        history.push(`/${ selectedOrg.shortId }/settings/${ OrganizationSettingsMenuKey.Basic }`);
    }, [tab, selectedOrg, history]);

    // on click of the tab, navigate to the tab's url
    const onTabClick = useCallback((key: string) => {
        if (!selectedOrg) {
            setSelectedTab(key as OrganizationSettingsMenuKey);
            return;
        }

        history.push(`/${ selectedOrg.shortId }/settings/${ key }`);
    }, [history, selectedOrg]);


    if (!user || !selectedOrg) {
        return <Loading />;
    }

    const breadcrumbProps: BreadcrumbProps = {
        itemRender: breadCrumbItemRender,
        items: [
            {
                path: `/${ selectedOrg.shortId }`,
                breadcrumbName: 'Dashboard',
            },
            {
                path: `/${selectedOrg.shortId}/settings/basic`,
                breadcrumbName: `${selectedOrg.name}'s Settings`,
            }
        ],
    };

    return (
        <PageContainer breadcrumb={breadcrumbProps}>
            <GridContent>
                <div ref={containerRef}>
                    <Tabs
                        tabPosition={tabPosition}
                        activeKey={selectedTab}
                        onTabClick={onTabClick}
                        destroyInactiveTabPane
                        items={[
                            {
                                key: OrganizationSettingsMenuKey.Basic,
                                label: 'Basic Settings',
                                children: (
                                    <BasicSettingsView org={selectedOrg} dispatch={dispatch} />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Billing,
                                label: 'Billing',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationBilling}
                                        action={PermissionAction.Read}
                                        children={<BillingView org={selectedOrg} dispatch={dispatch} />}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Stripe,
                                label: 'Stripe',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationBilling}
                                        action={PermissionAction.Read}
                                        children={<StripeView org={selectedOrg} dispatch={dispatch} />}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Roles,
                                label: 'Roles',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationMember}
                                        action={PermissionAction.Read}
                                        children={(
                                            <Suspense fallback={null}>
                                                <OrganizationRoles org={selectedOrg} />
                                            </Suspense>
                                        )}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Members,
                                label: 'Members',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationMember}
                                        action={PermissionAction.Read}
                                        children={<MembersView orgId={selectedOrg.shortId} userId={user.id} canCreate={canCreate} />}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Invoices,
                                label: 'Invoices',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationBilling}
                                        action={PermissionAction.Read}
                                        children={<InvoicesView orgId={selectedOrg.shortId} />}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.FillablePDFs,
                                label: 'Fillable PDFs',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationSetting}
                                        action={PermissionAction.Update}
                                        children={(
                                            <Suspense fallback={null}>
                                                <FillablePDFsView orgId={selectedOrg.shortId} />
                                            </Suspense>
                                        )}
                                    />
                                ),
                            },
                            {
                                key: OrganizationSettingsMenuKey.Export,
                                label: 'Backups/Exports',
                                children: (
                                    <AccessControlledWrapper
                                        feature={PermissionFeature.OrganizationSetting}
                                        action={PermissionAction.Update}
                                        children={(
                                            <Suspense fallback={null}>
                                                <ExportView orgId={selectedOrg.shortId} />
                                            </Suspense>
                                        )}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </GridContent>
        </PageContainer>
    );
};

export const OrganizationSettings: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Organization}
        action={PermissionAction.Read}
        children={<OrganizationSettingsBase />}
    />
);
