import { get, post, put, del } from './index';

import type { IPropertyTax } from 'models/propertyTax';
import type { IPropertyTaxFinalizePayload, IPropertyTaxPayPayload, IPropertyTaxSendReminderPayload, IPropertyTaxTractVoidPayload } from 'models/payloads/propertyTax';

export const getPropertyTaxes = (orgId: string, inventoryId: string) => get<IPropertyTax[]>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes`, true);
export const createPropertyTax = (orgId: string, inventoryId: string, payload: Partial<IPropertyTax>) => post<Partial<IPropertyTax>, IPropertyTax>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes`, payload, true);
export const getPropertyTaxById = (orgId: string, inventoryId: string, taxRecordId: string) => get<IPropertyTax>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }`, true);
export const updatePropertyTaxById = (orgId: string, inventoryId: string, taxRecordId: string, payload: Partial<IPropertyTax>) => put<Partial<IPropertyTax>, IPropertyTax>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }`, payload, true);
export const deletePropertyTax = (orgId: string, inventoryId: string, taxRecordId: string) => del(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }`, true);
export const finalizePropertyTax = (orgId: string, inventoryId: string, taxRecordId: string, payload: IPropertyTaxFinalizePayload) => post<IPropertyTaxFinalizePayload, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }/finalize`, payload, true);
export const payPropertyTax = (orgId: string, inventoryId: string, taxRecordId: string, payload: IPropertyTaxPayPayload) => post<IPropertyTaxPayPayload, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }/pay`, payload, true);
export const sendPropertyTaxReminder = (orgId: string, inventoryId: string, taxRecordId: string, payload: IPropertyTaxSendReminderPayload) => post<IPropertyTaxSendReminderPayload, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }/remind`, payload, true);
export const voidPropertyTaxTract = (orgId: string, inventoryId: string, taxRecordId: string, payload: IPropertyTaxTractVoidPayload) => post<IPropertyTaxTractVoidPayload, void>(`/organizations/${ orgId }/inventories/${ inventoryId }/propertyTaxes/${ taxRecordId }/void`, payload, true);
