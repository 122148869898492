import { ThunkAction } from 'redux-thunk';

import { GlobalState } from '../';

import { OrgSelectSuccessAction, OrgSelectFailureAction, OrgSelectPendingAction, OrgActionType, OrgCreate, OrgCreatePendingAction, OrgCreateFailureAction, OrgCreateSuccessAction, OrgRefresh, OrgCreateClearAction, OrgCreatePaymentMethodPendingAction, OrgDeletedEventRecievedAction, OrgCreatedEventRecievedAction, OrgUpdatedEventRecievedAction, OrgSetStripeAccount, OrgUnsetStripeAccount } from './../types/org';
import { getOrganization, createOrganization } from 'api/organizations';

import { IRestError } from 'models/common/restResult';
import { IOrganization } from 'models/organization';
import { ILookup } from 'models/common/lookup';
import { IOrganizationCreationPayload } from 'models/payloads/organization';

import { authRefreshUser } from './auth';
import { permissionLoadForOrg } from './permissions';

export const orgSelect = (orgId: string): ThunkAction<Promise<OrgSelectSuccessAction | OrgSelectFailureAction>, GlobalState, null, OrgSelectPendingAction | OrgSelectSuccessAction | OrgSelectFailureAction> => {
    return async (dispatch) => {
        dispatch(orgSelectPending());
        await dispatch(orgRefresh(orgId));

        localStorage.setItem('payarc-registering', 'false');

        try {
            return dispatch(orgSelectSuccess(await getOrganization(orgId)));
        } catch (e) {
            return dispatch(orgSelectFailure(e as IRestError));
        }
    }
}

export const orgCreate = (payload: IOrganizationCreationPayload): ThunkAction<Promise<OrgCreate>, GlobalState, null, OrgCreate> => {
    return async (dispatch) => {
        dispatch(orgCreatePending());

        try {
            const result = await createOrganization(payload);

            await dispatch(authRefreshUser());
            await dispatch(permissionLoadForOrg(result.id));

            return dispatch(orgCreateSuccess(result));
        } catch (e) {
            return dispatch(orgCreateFailure(e as IRestError));
        }
    }
}

export const orgRefresh = (orgId: string): ThunkAction<Promise<OrgRefresh>, GlobalState, null, OrgRefresh> => {
    return async (dispatch) => {
        try {
            const org = await getOrganization(orgId);
            await dispatch(permissionLoadForOrg(org.id));

            return dispatch({ type: OrgActionType.REFRESH, org });
        } catch (e) {
            return dispatch({ type: OrgActionType.REFRESH_FAILURE, error: e as IRestError });
        }
    }
}

export const orgClearCreated = (): ThunkAction<Promise<void>, GlobalState, null, OrgCreateClearAction> => {
    return async (dispatch) => {
        dispatch({ type: OrgActionType.CREATE_CLEAR });

        //TODO: MAJOR TECH DEBT
        window.setTimeout(() => window.location.reload(), 2000);
    };
}

export function orgSelectPending(): OrgSelectPendingAction {
    return {
        type: OrgActionType.SELECT_PENDING,
    };
}

export function orgSelectSuccess(org: IOrganization): OrgSelectSuccessAction {
    return {
        type: OrgActionType.SELECT_SUCCESS,
        org,
    }
}

export function orgSelectFailure(error?: IRestError): OrgSelectFailureAction {
    return {
        type: OrgActionType.SELECT_FAILURE,
        error,
    };
}

export function orgCreatePending(): OrgCreatePendingAction {
    return {
        type: OrgActionType.CREATE_PENDING,
    };
}

export function orgCreatePaymentMethodPending(): OrgCreatePaymentMethodPendingAction {
    return {
        type: OrgActionType.CREATE_PAYMENT_METHOD_PENDING,
    };
}

export function orgCreateFailure(error?: Partial<IRestError>): OrgCreateFailureAction {
    return {
        type: OrgActionType.CREATE_FAILURE,
        error,
    };
}

export function orgCreateSuccess(org: IOrganization): OrgCreateSuccessAction {
    return {
        type: OrgActionType.CREATE_SUCCESS,
        org,
    }
}

export function orgCreatedEventRecieved(org: IOrganization): OrgCreatedEventRecievedAction {
    return {
        type: OrgActionType.EVENT_CREATED,
        org,
    };
}

export function orgUpdatedEventRecieved(org: IOrganization): OrgUpdatedEventRecievedAction {
    return {
        type: OrgActionType.EVENT_UPDATED,
        org,
    };
}

export function orgDeletedEventRecieved(lookup: Partial<ILookup>): OrgDeletedEventRecievedAction {
    return {
        type: OrgActionType.EVENT_DELETED,
        lookup,
    };
}

export function orgSetStripeAccount(stripeAccountId: string): OrgSetStripeAccount {
    return {
        type: OrgActionType.SET_STRIPEACCOUNT,
        accountId: stripeAccountId,
    };
}

export function orgUnsetStripeAccount(): OrgUnsetStripeAccount {
    return {
        type: OrgActionType.UNSET_STRIPEACCOUNT,
    };
}
