import { ILookup } from './common/lookup';
import { IDescription } from './common/description';
import { IObjectDates } from './common/objectDates';
import { IOrganizational } from './common/organizational';
import { IPostalAddress } from './common/postalAddress';
import { Currency } from './currency';

export interface IInventory extends IObjectDates, IOrganizational {
    id: string;
    name: string;
    category: InventoryCategory;
    type: InventoryType;
    status: InventoryStatus;
    address: IPostalAddress;

    pinned: boolean;

    landDetails?: IInventoryLandDetails;
    residentialDetails?: IResidentialDetails;
    multifamilyDetails?: IMultifamilyDetails;
}

export enum InventoryCategory {
    Land = 'land',
    Residential = 'residential',
    Multifamily = 'multifamily',
    Commercial = 'commercial',
    Other = 'other',
}

export enum InventoryType {
    Finance = 'finance',
    Rental = 'rental',
    Other = 'other',
}

export enum InventoryStatus {
    Idea = 'idea',
    Ready = 'ready',
    InProgress = 'in-progress',
    Completed = 'completed',
    //shared between non-land financed and rentals
    Late = 'late',
    Eviction = 'eviction',
    Evicted = 'evicted',
    //rental
    Available = 'available',
    Rented = 'rented',
}

export interface IInventoryLandDetails {
    parcelNumbers: string[];

    totalAcres: number;
    costTotal: string;
    costPerAcre: string;
}

export interface IResidentialDetails extends IDescription {
    parcelNumbers: string[];

    bedrooms: number;
    bathrooms: number;
    livingSquareFootage: number;
    lotArea: number;
    lotAreaUnit: ResidentialDetailsLotAreaUnit;

    price: Currency;
    rent?: Currency;

    owner?: ILookup;
    loan?: ILookup;
    rental?: ILookup;
}

export enum ResidentialDetailsLotAreaUnit {
    Acres = 'acres',
    SquareFeet = 'sqft',
}

export interface IMultifamilyDetails {
    units: number;
    yearBuilt: number;

    office?: InventoryMultifamilyOffice;
}

//TODO: not really used yet, but here for future usage!
// if you are reading this and want something to do,
// then create the UI on the new inventory page which
export interface InventoryMultifamilyOffice {
	phone: string;
	email: string;
	hours: InventoryMultifamilyOfficeHour[];
}

export enum DayOfWeek {
	Sunday = "sunday",
	Monday = "monday",
	Tuesday = "tuesday",
	Wednesday = "wednesday",
	Thursday = "thursday",
	Friday = "friday",
	Saturday = "saturday",
}

export interface InventoryMultifamilyOfficeHour {
	day: DayOfWeek;
	open: string;
	close: string;
}
