import React from 'react';
import { TableOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Descriptions, Button, Table } from 'antd';
import type { ColumnProps } from 'antd/lib/table';

import { IMail, IMailTrackingEvent, MailAddressPlacement, MailClass } from 'models/mail';

import { LongCurrency, SimpleDate } from 'utils/formatting';
import { Address } from 'components/misc/address';

interface IViewMailDetailsModalProps {
    mail?: IMail;
    isVisible: boolean;
    close: () => void;
}

interface IViewMailDetailsModalState { }

export class ViewMailDetailsModal extends React.PureComponent<IViewMailDetailsModalProps, IViewMailDetailsModalState> {
    state: Readonly<IViewMailDetailsModalState> = {};

    get mailDetails() {
        const { mail } = this.props;

        if (!mail) {
            return null;
        }

        return (
            <Row>
                <Col>
                    <Descriptions column={2}>
                        <Descriptions.Item label="Description" span={2}>{ mail.description }</Descriptions.Item>
                        <Descriptions.Item label="Class">{ mail.class === MailClass.FirstClass ? 'First Class' : 'Standard Class' }</Descriptions.Item>
                        <Descriptions.Item label="Extra Services" className="title-caps">{ mail.extraService ? mail.extraService.replaceAll('_', ' ') : '-' }</Descriptions.Item>
                        <Descriptions.Item label="Color">{ mail.color ? 'Yes' : 'No, B&W' }</Descriptions.Item>
                        <Descriptions.Item label="Double Sided">{ mail.doubleSided ? 'Yes' : 'No' }</Descriptions.Item>
                        <Descriptions.Item label="Page Count">{ mail.addressPlacement === MailAddressPlacement.InsertBlankPage ? mail.pageCount+1 : mail.pageCount }</Descriptions.Item>
                        <Descriptions.Item label="Cost"><LongCurrency value={mail.cost} /></Descriptions.Item>
                        <Descriptions.Item label="Sent Date"><SimpleDate date={mail.externalInfo.sentDate} /></Descriptions.Item>
                        <Descriptions.Item label="Expected Delivery"><SimpleDate date={mail.externalInfo.expectedDeliveryDate} /></Descriptions.Item>
                        { mail.relatedTo.label ? <Descriptions.Item label="Client">{ mail.relatedTo.label }</Descriptions.Item> : null }
                        { mail.relatedTo.childLabel ? <Descriptions.Item label="Entity">{ mail.relatedTo.childLabel }</Descriptions.Item> : null }
                        <Descriptions.Item label="To Address"><Address value={mail.to} showCopy={false} /></Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        );
    }

    get trackingEvents() {
        if (!this.props.mail || !this.props.mail.externalInfo || !Array.isArray(this.props.mail.externalInfo.trackingEvents) || this.props.mail.externalInfo.trackingEvents.length === 0) {
            return null;
        }

        const columns: ColumnProps<IMailTrackingEvent>[] = [
            {
                title: 'Date', dataIndex: 'when', key: 'when',
                render: (date) => <SimpleDate date={date} />,
            },
            {
                title: 'Location', dataIndex: 'location', key: 'location',
            },
            {
                title: 'Status', dataIndex: 'status', key: 'status', className: 'title-caps',
                render: (status) => status.replaceAll('_', ' '),
            },
        ];

        if (this.props.mail.extraService) {
            columns.push({ title: 'Certified Notes', dataIndex: 'certifiedNotes', key: 'certifiedNotes' });
        }

        return (
            <Table<IMailTrackingEvent>
                dataSource={this.props.mail.externalInfo.trackingEvents}
                rowKey="eventId"
                pagination={false}
                scroll={{ x: 'max-content' }}
                columns={columns}
            ></Table>
        );
    }

    render() {
        const { isVisible } = this.props;

        if (!isVisible || !this.props.mail) {
            return null;
        }

        return (
            <Modal
                open={isVisible}
                title={<React.Fragment><TableOutlined /> View Mail Details</React.Fragment>}
                footer={
                    <Button type="primary" onClick={this.props.close}>
                        Close
                    </Button>
                }
                onCancel={this.props.close}
            >
                {this.mailDetails}
                {this.trackingEvents}
            </Modal>
        );
    }
}
