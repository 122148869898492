import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import shortid from 'shortid';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { isMobileOnly } from 'react-device-detect';
import { Drawer, Row, Col, Form, Button, Input, DatePicker, Divider, Switch, Select, Space, App } from 'antd';
import { LockOutlined, SaveOutlined, UnlockOutlined } from '@ant-design/icons';
import type { FieldData } from 'rc-field-form/lib/interface';

import { AccessControlledButton } from 'components/permissions';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { ClientType } from 'models/client';
import { ITaxInfo, TaxIDType } from 'models/common/taxId';
import type { IEntity } from 'models/entity';

import { IPostalAddress, states } from 'models/common/postalAddress';
import { AddressDeliverabilityWarning } from 'components/misc/address';

import { createClientEntity, updateClientEntity } from 'api/clients';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

import './addEditDrawer.css';

const MaskedInput = lazy(() => import('components/misc/maskedInput'));
const ViewEntityTaxInfoModal = lazy(() => import('./viewTaxInfo'));

interface IFormValues {
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    birthDate: Dayjs;
    taxId: string;
    taxIdType: TaxIDType;
    phone: {
        number: string;
        isCellular: boolean;
    };
    address: {
        street1: string;
        street2: string;
        city: string;
        state: string;
        zipCode: string;
    };
    [key: string]: any;
}

interface IAddEditEntityDrawerProps {
    orgId?: string;
    clientId?: string;
    clientType: ClientType;
    entity?: IEntity;
    save: boolean;
    isVisible: boolean;
    close: (entity?: Partial<IEntity>) => Promise<void>;
}

export const AddEditEntityDrawer: React.FC<IAddEditEntityDrawerProps> = (props) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm<IFormValues>();
    const [confirmPasswordOpen, setConfirmPasswordOpen] = useState(false);
    const [canUpdateTaxId, setCanUpdateTaxId] = useState(false);
    const [taxIdMask, setTaxIdMask] = useState<'###-##-####' | '##-#######' | '####'>('####');
    const [isSaving, setSaving] = useState(false);
    const [autocompleteIds, setAutocompleteIds] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const autocompleteIds: { [key: string]: string } = {};

        const values = form.getFieldsValue();
        Object.keys(values).forEach((k) => {
            if (typeof values[k] === 'object') {
                Object.keys(values[k]).forEach((kv: string) => autocompleteIds[k + kv] = shortid.generate());
                return;
            }

            autocompleteIds[k] = shortid.generate();
        });

        setAutocompleteIds(autocompleteIds);
    }, [form]);

    useEffect(() => {
        if (!props.entity) {
            return;
        }

        const entity = props.entity;

        let birthDate: Dayjs | undefined = dayjs(entity.birthDate);
        if (birthDate.year() === 0 || birthDate.year() === 1) {
            birthDate = undefined;
        }

        const fieldsValues: FieldData[] = [];
        fieldsValues.push({ name: 'title', value: entity.title });
        fieldsValues.push({ name: 'firstName', value: entity.firstName });
        fieldsValues.push({ name: 'middleName', value: entity.middleName });
        fieldsValues.push({ name: 'lastName', value: entity.lastName });
        fieldsValues.push({ name: 'email', value: entity.email });
        fieldsValues.push({ name: 'birthDate', value: birthDate });

        if (entity.taxInfo && entity.taxInfo.short) {
            fieldsValues.push({ name: 'taxIdType', value: entity.taxInfo.type });
            fieldsValues.push({ name: 'taxId', value: `***${entity.taxInfo.short}` });
        } else {
            setCanUpdateTaxId(true);
        }

        if (entity.phoneNumbers && entity.phoneNumbers.length >= 1) {
            const num = entity.phoneNumbers[0];
            fieldsValues.push({ name: ['phone', 'label'], value: num.label });
            fieldsValues.push({ name: ['phone', 'number'], value: num.number });
            fieldsValues.push({ name: ['phone', 'isCellular'], value: num.isCellular });
        }

        if (entity.addresses && entity.addresses.length >= 1) {
            const prim = entity.addresses[0];
            fieldsValues.push({ name: ['address', 'city'], value: prim.city });
            fieldsValues.push({ name: ['address', 'state'], value: prim.state });
            fieldsValues.push({ name: ['address', 'zipCode'], value: prim.zipCode });

            const lines = prim.streetAddresses;
            if (lines.length >= 1) {
                fieldsValues.push({ name: ['address', 'street1'], value: lines[0] });
            }

            if (lines.length >= 2) {
                fieldsValues.push({ name: ['address', 'street2'], value: lines[1] });
            }
        }

        form.setFields(fieldsValues);
    }, [form, props.entity]);

    const onViewTaxClose = (taxInfo?: ITaxInfo) => {
        setConfirmPasswordOpen(false);

        if (!taxInfo) {
            return;
        }

        form.setFieldValue('taxId', taxInfo.id);
        onTaxIdTypeChange(taxInfo.type);
        setCanUpdateTaxId(true);
    };

    const onCancelClick = async () => {
        await props.close();
        form.resetFields();
        setSaving(false);
        setCanUpdateTaxId(false);
        setTaxIdMask('####');
        setConfirmPasswordOpen(false);
    };

    const onSaveClick = () => {
        form
            .validateFields()
            .then(async (values) => {
                setSaving(true);

                let p: Partial<IEntity> = {};
                p.title = values.title;
                p.firstName = values.firstName;
                p.middleName = values.middleName;
                p.lastName = values.lastName;
                p.email = values.email;

                if (values.birthDate) {
                    p.birthDate = values.birthDate.toJSON();
                }

                //TODO: validate
                if (values.taxId && (typeof props.entity === 'undefined' || canUpdateTaxId)) {
                    if (values.taxId.includes('_')) {
                        notification.error({ message: 'Tax ID field must contain a valid value.' });
                        setSaving(false);
                        return;
                    }

                    p.taxInfo = {
                        short: values.taxId.length >= 4 ? values.taxId.slice(-4) : values.taxId,
                        type: values.taxIdType,
                        id: values.taxId,
                    };
                }

                if (values.phone && values.phone.number) {
                    p.phoneNumbers = [{
                        label: values.phone.isCellular ? 'Mobile' : 'Unknown',
                        number: values.phone.number,
                        isCellular: values.phone.isCellular,
                    }];
                }

                p.addresses = [];
                if (values.address) {
                    const { address } = values;

                    const addressToAdd: Partial<IPostalAddress> = {
                        streetAddresses: [],
                        city: address.city,
                        state: address.state,
                        zipCode: address.zipCode,
                    };

                    if (address.street1) {
                        const lines = [address.street1];
                        if (address.street2) {
                            lines.push(address.street2);
                        }

                        addressToAdd.streetAddresses = lines;
                    }

                    p.addresses.push(addressToAdd as IPostalAddress);
                }

                if (props.save) {
                    if (!props.orgId) {
                        throw new Error('invalid props; orgId is required when updating an existing client\'s entity');
                    }

                    if (!props.clientId) {
                        throw new Error('invalid props; clientId is required when updating an existing client\'s entity');
                    }
                }

                try {
                    if (props.entity && props.save) {
                        await updateClientEntity(props.orgId!, props.clientId!, Object.assign({}, props.entity, p));
                        trackUmami('Update Entity');
                    } else if (props.save) {
                        await createClientEntity(props.orgId!, props.clientId!, p);
                        trackUmami('Create Entity');
                    } else if (!props.entity && !props.save) {
                        //when the entity instance is not provided, we generate a provide id
                        p.id = shortid.generate();
                        trackUmami('Create Entity');
                    } else if (props.entity && !props.save) {
                        p = Object.assign({}, props.entity, p);
                        trackUmami('Update Entity');
                    }
                } catch (e) {
                    displayErrorNotification(e);
                    setSaving(false);
                    return;
                }

                await props.close(p);
                setSaving(false);
                setCanUpdateTaxId(false);
                setTaxIdMask('####');
                setConfirmPasswordOpen(false);
                form.resetFields();
            })
            .catch((e) => {
                console.warn('failed to validate the add/edit entity drawer fields:', e);
            });
    };

    const getAddressVerifiedWarning = () => {
        if (!props.entity || !props.entity.addresses || props.entity.addresses.length === 0) {
            return null;
        }

        return (
            <AddressDeliverabilityWarning label={props.entity.fullName} address={props.entity.addresses[0]} />
        );
    };

    const onTaxIdUnlockClick = useCallback(() => {
        setConfirmPasswordOpen(true);
    }, []);

    let width: string = '500px';
    let columnSpan: number = 12;
    if (isMobileOnly) {
        width = '100vw';
        columnSpan = 24;
    }

    const getDrawerTitle = (): string => {
        let title: string;
        if (props.entity) {
            title = `Editing: ${props.entity.firstName} ${props.entity.lastName}`;
        } else {
            title = 'Add a new Entity';

            switch (props.clientType) {
                case ClientType.Individual:
                    title = 'Individual Details';
                    break;
                case ClientType.Family:
                    title = 'Add a new Family Member';
                    break;
                case ClientType.Company:
                    title = 'Add a new Company Member';
                    break;
            }
        }

        return title;
    };

    const tinViewButton = (
        // when there is no entity (creating), they can enter the value
        // when there is an entity (updating), then they need to click the button to edit it
        <AccessControlledButton
            type="text"
            icon={typeof props.entity === 'undefined' || canUpdateTaxId ? <UnlockOutlined /> : <LockOutlined />}
            onClick={onTaxIdUnlockClick}
            disabled={typeof props.entity === 'undefined' || canUpdateTaxId}
            feature={PermissionFeature.ClientSecrets}
            action={PermissionAction.Update}
            prevent="disable"
        />
    );

    const onTaxIdTypeChange = (val: TaxIDType) => {
        switch (val) {
            case TaxIDType.SSN:
            case TaxIDType.ITIN:
                setTaxIdMask('###-##-####');
                break;
            case TaxIDType.EIN:
                setTaxIdMask('##-#######');
                break;
        }
    }

    const tinTypeSelector = (
        <Form.Item
            name="taxIdType"
            noStyle
            rules={[{ required: typeof form.getFieldValue('taxId') === 'string' && form.getFieldValue('taxId') !== '', message: 'Tax ID type is required when providing their tax information.' }]}
        >
            <Select<TaxIDType>
                style={{ width: 90 }}
                disabled={isSaving || (!canUpdateTaxId && typeof props.entity !== 'undefined')}
                onChange={onTaxIdTypeChange}
            >
                <Select.Option value={TaxIDType.SSN}>SSN</Select.Option>
                <Select.Option value={TaxIDType.EIN}>EIN</Select.Option>
                <Select.Option value={TaxIDType.ITIN}>ITIN</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <Drawer
            title={getDrawerTitle()}
            width={width}
            onClose={onCancelClick}
            open={props.isVisible}
            closable={!isSaving}
            maskClosable={false}
            extra={
                <Space>
                    <Button onClick={onCancelClick} style={{ marginRight: 8 }} disabled={isSaving}>Cancel</Button>
                    <Button onClick={onSaveClick} type="primary" disabled={isSaving} loading={isSaving} icon={<SaveOutlined />}>Save</Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                colon={false}
                onFinish={onSaveClick}
                disabled={isSaving}
                autoComplete="off"
                initialValues={{
                    taxId: '',
                    phone: {
                        isCellular: true,
                    },
                }}
            >
                <Row gutter={16}>
                    <Col span={columnSpan}>
                        <Form.Item name="title" label="Title">
                            <Input
                                name="title"
                                placeholder="Entity's title"
                                autoComplete={autocompleteIds['title']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: 'First name is required.' }]}>
                            <Input
                                name="firstName"
                                placeholder="Entity's first name"
                                autoComplete={autocompleteIds['firstName']}
                                autoFocus
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name="middleName" label="Middle Name(s)">
                            <Input
                                name="middle-name"
                                placeholder="Entity's middle name(s)"
                                autoComplete={autocompleteIds['middleName']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Last name is required.' }]}>
                            <Input
                                name="lastName"
                                placeholder="Entity's last name"
                                autoComplete={autocompleteIds['lastName']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name="birthDate" label="Birth Date">
                            <DatePicker
                                name="birthDate"
                                format="MM/DD/YYYY"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name="email" label="Email">
                            <Input
                                name="email"
                                type="email"
                                placeholder="mail@gmail.com"
                                autoComplete={autocompleteIds['email']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Tax ID Number"
                            extra="Taxpayer Identification Number is either the social security number (SSN), employer identification number (EIN), or individual taxpayer identification number (ITIN). The value is encrypted and can only be viewed after confirming your password."
                        >
                            <Suspense fallback={null}>
                                <MaskedInput
                                    name="taxId"
                                    mask={taxIdMask}
                                    inputProps={{
                                        name: 'taxId',
                                        disabled: isSaving || taxIdMask === '####' || (!canUpdateTaxId && typeof props.entity !== 'undefined'),
                                        autoComplete: autocompleteIds['taxId'],
                                        addonBefore: tinTypeSelector,
                                        addonAfter: typeof props.entity !== 'undefined' ? tinViewButton : null,
                                    }}
                                />
                            </Suspense>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Divider orientation="left">Phone Number</Divider>
                    </Col>
                    <Col span={14}>
                        <Form.Item name={['phone', 'number']} label="Phone Number">
                            <Input
                                name="phone-number"
                                type="tel"
                                placeholder="(901) 347-3475"
                                autoComplete={autocompleteIds['phonenumber']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={['phone', 'isCellular']} label="Is Cellular" colon={false} required valuePropName="checked">
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Divider orientation="left">Address</Divider>
                    </Col>

                    {getAddressVerifiedWarning()}

                    <Col span={columnSpan}>
                        <Form.Item name={['address', 'street1']} label="Street">
                            <Input
                                name="address-street1"
                                placeholder="777 Street Name"
                                autoComplete={autocompleteIds['addressstreet1']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name={['address', 'street2']} label="Street 2">
                            <Input
                                name="address-line2"
                                placeholder="Suite 301"
                                autoComplete={autocompleteIds['addressstreet2']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={columnSpan}>
                        <Form.Item name={['address', 'city']} label="City">
                            <Input
                                name="address-city"
                                placeholder="Memphis"
                                autoComplete={autocompleteIds['addresscity']}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={['address', 'state']} label="State">
                            <Select<string> showSearch placeholder="State">
                                {states.map((state) => (
                                    <Select.Option key={state.abbreviation} value={state.abbreviation}>{state.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={['address', 'zipCode']} label="Zip Code">
                            <Input
                                name="address-zipCode"
                                placeholder="38133"
                                min={0}
                                style={{ width: '100%' }}
                                autoComplete={autocompleteIds['addresszipCode']}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Suspense fallback={null}>
                <ViewEntityTaxInfoModal
                    open={confirmPasswordOpen}
                    close={onViewTaxClose}
                    entity={props.entity}
                    autoClose
                />
            </Suspense>
        </Drawer>
    );
}
