import React, { useCallback, useState } from 'react';
import type { FC } from 'react';
import { Button, Form, Radio, Space } from 'antd';
import type { FormInstance } from 'antd';

import type { ILateFeeTier } from 'models';
import { LateFeeTierTag, LateFeeTierModal } from 'components/lateFees';

interface ILateFeeInputsProps {
    type: 'loan' | 'rental';
    hideTiers?: boolean;
    form: FormInstance;
}

export const LateFeeInputs: FC<ILateFeeInputsProps> = ({ form, type, hideTiers }) => {
    const [addTierOpen, setAddTierOpen] = useState(false);

    const removeTier = useCallback((tier: ILateFeeTier) => {
        const tiers: ILateFeeTier[] = form.getFieldValue('lateFeeTiers') || [];
        form.setFieldsValue({ lateFeeTiers: tiers.filter((t) => t !== tier) });
    }, [form]);

    const addTier = useCallback((tier?: ILateFeeTier) => {
        if (!tier) {
            setAddTierOpen(false);
            return;
        }

        const tiers = form.getFieldValue('lateFeeTiers') || [];
        form.setFieldsValue({ lateFeeTiers: [...tiers, tier] });

        setAddTierOpen(false);
    }, [form]);

    return (
        <React.Fragment>
            <Form.Item
                name="lateFeeDisabled"
                label="Late Fees Applied"
                extra="Do you want Lendiom to automatically apply late fees?"
                rules={[{ required: true, message: 'Please indicate whether or not to automatically apply late fees.' }]}
            >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={false}>Automatically</Radio.Button>
                    <Radio.Button value={true}>Manually</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.lateFeeTiers !== curr.lateFeeTiers}>
                {({ getFieldValue }) => {
                    const tiers: ILateFeeTier[] = getFieldValue('lateFeeTiers') || [];

                    return (
                        <Form.Item
                            label="Late Fee Tiers"
                            required
                            extra="What are the formulas for late fees? More than one late fee can be applied, which is why there are tiers."
                        >
                            <Space.Compact>
                                {
                                    tiers.length > 0
                                    ? tiers.map((t, i) => <LateFeeTierTag value={t} key={`tier-${ i }`} closable onClose={removeTier} />)
                                    : null
                                }

                                <Button size="small" onClick={() => setAddTierOpen(true)}>Add tier</Button>
                            </Space.Compact>
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <LateFeeTierModal open={addTierOpen} type={type} onAdd={addTier} />
        </React.Fragment>
    );
}
