export enum PermissionFeature {
    All = '*',
    Organization = 'organization',
    Client = 'client',
    ClientEntities = 'client::entities',
    ClientFiles = 'client::files',
    ClientNotes = 'client::notes',
    ClientTimeline = 'client::timeline',
    ClientSecrets = 'client::secrets',
    Files = 'file',
    Inventory = 'inventory',
    InventoryFiles = 'inventory::files',
    InventoryNotes = 'inventory::notes',
    InventoryPropertyTax = 'inventory::propertyTax',
    Tract = 'tract',
    TractFiles = 'tract::files',
    TractNotes = 'tract::notes',
    TractTimeline = 'tract::timeline',
    OrganizationSetting = 'setting',
    OrganizationBilling = 'billing',
    OrganizationMember = 'member',
    Loan = 'loan',
    LoanTransaction = 'loan::transactions',
    LoanEscrow = 'loan::escrow',
    LoanNotes = 'loan::notes',
    LoanFiles = 'loan::files',
    Communication = 'communication',
    Rental = 'rental',
    RentalTransaction = 'rental::transactions',
    RentalNotes = 'rental::notes',
    RentalFiles = 'rental::files',
}

export enum PermissionAction {
    Create = 'create',
    Read = 'read',
    Update = 'update',
    Delete = 'delete',
}

export interface IPermission {
    feature: PermissionFeature;
    action: PermissionAction;
}

export interface IFeatureAccessPermissions {
    [PermissionAction.Create]: boolean;
    [PermissionAction.Read]: boolean;
    [PermissionAction.Update]: boolean;
    [PermissionAction.Delete]: boolean;
}

export interface IRoleDetails {
    role: string;
    permissions: Record<string, boolean>;
}

export const permissionsList: string[] = [
    ...Object.values(PermissionFeature).filter((v) => v !== '*').flatMap((f) => {
        if (f === PermissionFeature.Organization) {
            return [`${ f }_${ PermissionAction.Update }`];
        }

        return Object.values(PermissionAction).map((a) => `${ f }_${ a }`);
    }),
];
