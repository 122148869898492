import React from 'react';
import { Tag } from 'antd';

import { InventoryStatus } from 'models/inventory';

interface IInventoryStatusTagProps {
    status: InventoryStatus
};

export const InventoryStatusTag: React.FC<IInventoryStatusTagProps> = React.memo((props) => {
    let color = 'purple';
    switch(props.status) {
        case InventoryStatus.Idea:
            color = 'gold';
            break;
        case InventoryStatus.Ready:
        case InventoryStatus.Available:
            color = 'purple';
            break;
        case InventoryStatus.InProgress:
        case InventoryStatus.Rented:
            color = 'green';
            break;
        case InventoryStatus.Completed:
            color = 'cyan';
            break;
        case InventoryStatus.Late:
        case InventoryStatus.Eviction:
            color = 'red';
            break;
        case InventoryStatus.Evicted:
            color = 'volcano';
            break;
        default:
            color = 'purple';
            break;
    }

    return (
        <Tag color={color} className="title-caps">{props.status.replace('-', ' ')}</Tag>
    );
});
