import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { App, Dropdown } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined, LogoutOutlined, PlusOutlined, SelectOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import type { AvatarProps } from 'antd';
import type { ItemType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import type { MenuInfo } from 'rc-menu/lib/interface';

import { authLogout } from 'store/actions/auth';
import { orgSelect } from 'store/actions/org';
import { getUser } from 'store/selectors/auth';
import { getSelectedOrg } from 'store/selectors/org';

type AvatarHeaderProps = AvatarProps & {
    navTheme?: 'light' | 'realDark',
    title?: React.ReactNode;
    render?: (props: AvatarProps, defaultDom: React.ReactNode) => React.ReactNode;
};

function getDarkThemePreference(): boolean {
    const storedDefault = window.localStorage.getItem('useDarkTheme');
    if (storedDefault) {
        return storedDefault === 'true';
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function useUserHeaderProps(): AvatarHeaderProps {
    const { modal } = App.useApp();
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [useDarkTheme, setUseDarkTheme] = useState<boolean>(getDarkThemePreference());
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const selectedOrg = useSelector(getSelectedOrg);

    useEffect(() => {
        // set the account settings as active
        if (location && (location as any).pathname.includes('/account/settings')) {
            setSelectedKeys(['settings']);
            return;
        }

        // clear the active ones
        if (selectedKeys.length > 0) {
            setSelectedKeys([]);
        }
    }, [location, selectedKeys.length]);

    const onMenuClick = async (e: MenuInfo) => {
        switch (e.key) {
            case 'logout':
                dispatch(authLogout() as any);
                window.localStorage.clear();
                history.push('/auth/login');
                return;
            case 'settings':
                //this only happens when they're already on the settings page
                return;
            case 'new-org':
                return;
            case 'about':
                modal.info({
                    title: 'About Lendiom',
                    content: (
                        <div>
                            <p>
                                Selected organization:
                                <ul>
                                    <li>Name: { selectedOrg?.name || 'Unknown' }</li>
                                    <li>Long ID: <span style={{ fontFamily: 'monospace' }}>{ selectedOrg?.id || '-' }</span></li>
                                    <li>Short ID: <span style={{ fontFamily: 'monospace' }}>{ selectedOrg?.shortId || '-' }</span></li>
                                    <li>Status: { selectedOrg?.billing?.status || '-' }</li>
                                </ul>
                            </p>
                            <p>Current version is: v{process.env.REACT_APP_VERSION}</p>
                            <p>Documentation url: <a href={`${process.env.REACT_APP_DOCS_URL}/app`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_DOCS_URL}/app</a></p>
                        </div>
                    ),
                });
                return;
            case 'theme':
                const toSetValue = !useDarkTheme;
                setUseDarkTheme(toSetValue);
                window.localStorage.setItem('useDarkTheme', `${ toSetValue }`);
                break;
            default:
                console.log('top menu clicked:', e.key);
                await dispatch(orgSelect(e.key) as any);

                const sp = location.pathname.split('/');

                if (sp[1] === 'account') {
                    history.push(`/${e.key}/dashboard`);
                } else {
                    sp[1] = e.key;
                    history.push(sp.join('/'));
                }
        }
    }

    const getOrganizationsSubMenuItems = (): SubMenuType | undefined => {
        if (!user || !selectedOrg?.shortId) {
            return undefined;
        }

        return {
            key: 'organizations',
            label: (
                <React.Fragment>
                    <TeamOutlined /> Organizations
                </React.Fragment>
            ),
            children: [
                ...user.organizations.map((v) => ({
                    key: v.shortId!,
                    label: (
                        <React.Fragment>
                            <SelectOutlined /> {v.label}
                        </React.Fragment>
                    ),
                    disabled: selectedOrg.shortId === v.shortId!,
                })),
                {
                    key: 'new-org',
                    label: (
                        <Link to="/org/new">
                            <PlusOutlined /> &nbsp;New Organization
                        </Link>
                    ),
                },
            ],
        };
    }

    const getMenuHeaderDropdownItems = () => {
        const items: Array<ItemType> = [
            {
                key: 'settings',
                label: (
                    <Link to="/account/settings">
                        <SettingOutlined /> &nbsp;Account Settings
                    </Link>
                ),
            },
            {
                key: 'theme',
                label: useDarkTheme ? 'Use Light Theme' : 'Use Dark Theme',
                icon: useDarkTheme ? <EyeInvisibleOutlined /> : <EyeOutlined />,
            },
            {
                key: 'about',
                label: 'About',
                icon: <InfoCircleOutlined />,
            },
            { type: 'divider' },
            {
                key: 'logout',
                label: 'Log out',
                icon: <LogoutOutlined />,
            },
        ];

        const orgSubMenu = getOrganizationsSubMenuItems();
        if (orgSubMenu) {
            items.unshift(orgSubMenu);
        }

        return items;
    }

    return {
        navTheme: useDarkTheme ? 'realDark' : 'light',
        icon: <UserOutlined />,
        size: 'small',
        title: user ? `${user.firstName} ${user.lastName}` : 'Loading',
        render: (props: AvatarProps, defaultDom: React.ReactNode) => {
            return (
                <Dropdown
                    menu={{
                        disabled: typeof user === 'undefined' || !user,
                        items: getMenuHeaderDropdownItems(),
                        onClick: onMenuClick,
                        selectedKeys,
                    }}
                >
                    {defaultDom}
                </Dropdown>
            );
        }
    };
}
