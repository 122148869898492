import { ThunkAction } from 'redux-thunk';

import type { IRestError } from 'models/common/restResult';
import type { IRental } from 'models/rental';
import type { ILookup } from 'models/common/lookup';
import type { IRelatedTo } from 'models/common/relatedTo';

import { GlobalState } from 'store';
import { getSelectedOrgShortId } from 'store/selectors/org';
import { RentalActionType, RentalCreatedEventReceivedAction, RentalDeletedEventReceivedAction, RentalFetchFailureAction, RentalFetchPendingAction, RentalFetchSuccessAction, RentalRelatedAction, RentalUpdatedEventReceivedAction, RentalsFetchRelatedFailureAction, RentalsFetchRelatedPendingAction, RentalsFetchRelatedSuccessAction } from 'store/types/rentals';

import { getRentalByID } from 'api/rentals';

export const fetchRentalById = (rentalId: string): ThunkAction<Promise<RentalFetchSuccessAction | RentalFetchFailureAction>, GlobalState, null, RentalFetchPendingAction | RentalFetchSuccessAction | RentalFetchFailureAction> => {
    return async (dispatch, getState) => {
        dispatch(rentalFetchingPending());

        try {
            const orgId = getSelectedOrgShortId(getState());
            const rental = await getRentalByID(orgId, rentalId);

            return dispatch(rentalFetchingSuccess(rental));
        } catch (e) {
            return dispatch(rentalFetchingFailure(e as IRestError));
        }
    }
}

export const fetchRelatedRentals = (relatedTo: IRelatedTo[]): ThunkAction<Promise<RentalRelatedAction>, GlobalState, null, RentalRelatedAction> => {
    return async (dispatch, getState) => {
        dispatch(rentalsFetchingRelatedPending());

        const orgId = getSelectedOrgShortId(getState());
        const toFetch = relatedTo.map((r) => getRentalByID(orgId, r.id));

        try {
            const rentals = await Promise.all(toFetch);
            return dispatch(rentalsFetchingRelatedSuccess(rentals));
        } catch (e) {
            return dispatch(rentalsFetchingRelatedFailure(e as IRestError));
        }
    }
}

export function rentalFetchingPending(): RentalFetchPendingAction {
    return {
        type: RentalActionType.FETCHING_ONE_PENDING,
    };
}

export function rentalFetchingSuccess(rental: IRental): RentalFetchSuccessAction {
    return {
        type: RentalActionType.FETCHING_ONE_SUCCESS,
        rental,
    };
}

export function rentalFetchingFailure(error?: IRestError): RentalFetchFailureAction {
    return {
        type: RentalActionType.FETCHING_ONE_FAILURE,
        error,
    };
}

export function rentalsFetchingRelatedPending(): RentalsFetchRelatedPendingAction {
    return {
        type: RentalActionType.FETCHING_RELATED_PENDING,
    };
}

export function rentalsFetchingRelatedSuccess(rentals: IRental[]): RentalsFetchRelatedSuccessAction {
    return {
        type: RentalActionType.FETCHING_RELATED_SUCCESS,
        rentals,
    };
}

export function rentalsFetchingRelatedFailure(error?: IRestError): RentalsFetchRelatedFailureAction {
    return {
        type: RentalActionType.FETCHING_RELATED_FAILURE,
        error,
    };
}

export function rentalCreatedEventReceived(rental: IRental): RentalCreatedEventReceivedAction {
    return {
        type: RentalActionType.EVENT_CREATED,
        rental,
    };
}

export function rentalUpdatedEventReceived(rental: IRental): RentalUpdatedEventReceivedAction {
    return {
        type: RentalActionType.EVENT_UPDATED,
        rental,
    };
}

export function rentalDeletedEventReceived(lookup: Partial<ILookup>): RentalDeletedEventReceivedAction {
    return {
        type: RentalActionType.EVENT_DELETED,
        lookup,
    };
}
