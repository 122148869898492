import React from 'react';
import type { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { BulbOutlined, CloudSyncOutlined, CreditCardOutlined, EditOutlined, FilePdfOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Empty, message, Tooltip, theme } from 'antd';
import type { ColumnProps } from 'antd/lib/table';

import { AccessControlledButton } from 'components/permissions';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { LongCurrency, SimpleDate } from 'utils/formatting';
import { displayErrorNotification } from 'utils/errors';
import { trackUmami } from 'utils/umami';

import { Currency } from 'models/currency';
import { ILoan, ILoanTransaction, LoanTransactionType, ILoanTransactionTo, LoanTermsInterestSchedule } from 'models/loan';
import { TransactionStatus, TransactionMethod } from 'models/transactions';
import type { ILoanSchedule } from 'models/loanSchedule';
import { PermissionFeature, PermissionAction } from 'models/permissions/features';
import type { IFeatureAccessPermissions } from 'models/permissions/features';

import { hasAccessToFeature } from 'store/selectors/permissions';

import { ReverseTransactionModal } from './reversePaymentModal';
import { ViewTransactionDetailsModal } from './viewTransactionDetailsModal';
import { TransactionStatusChangeModal } from './transactionStatusChangeModal';
import { loanActionsAreDisabled } from './loanActionsAreDisabled';
import { TransactionsList } from './transctionsList';
import { ReviseTransactionDateModal } from './reviseDateModal';

import { getLoanFile, getLoanTransactions, regenerateLoanTransactionLetter } from 'api/loans';

interface ILoanTransactionsTableProps {
    loan: Partial<ILoan>;
    schedule?: ILoanSchedule;
    addClick: () => void;
    refreshData: () => Promise<void>;
}

interface IInternalLoanTransactionsTableProps extends ILoanTransactionsTableProps {
    reversedColor: string;
    pendingColor: string;
    permissions: IFeatureAccessPermissions;
}

interface ILoanTransactionsTableState {
    loading: boolean;
    transactions: ILoanTransaction[];
    selectedTransaction?: ILoanTransaction;
    isReverseModalVisible: boolean;
    isDetailsModalVisible: boolean;
    isStatusModalVisible: boolean;
    isReviseDateVisible: boolean;
}

class LoanTransactionsTableBase extends React.PureComponent<IInternalLoanTransactionsTableProps, ILoanTransactionsTableState> {
    state: Readonly<ILoanTransactionsTableState> = {
        loading: true,
        transactions: [],
        isReverseModalVisible: false,
        isDetailsModalVisible: false,
        isStatusModalVisible: false,
        isReviseDateVisible: false,
    };

    loanScheduleColumns: ColumnProps<ILoanTransaction>[] = [
        {
            title: 'Date', key: 'date', dataIndex: 'date', defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (value: string) => <SimpleDate date={ value } />,
        },
        {
            title: <Tooltip overlay="Total collected, including fees">Total</Tooltip>, key: 'totalAmount', dataIndex: 'totalAmount',
            render: (value: Currency) => <LongCurrency value={value} />,
        },
        {
            title: 'To Interest', key: 'toInterest', dataIndex: 'totalToInterest',
            render: (value: Currency) => <LongCurrency value={value} tooltip />,
        },
        {
            title: 'To Principal', key: 'toPrincipal', dataIndex: 'totalToPrincipal',
            render: (value: Currency) => <LongCurrency value={value} tooltip />,
        },
        {
            title: 'To Fees', key: 'toFees', dataIndex: 'totalToFees',
            render: (value: Currency) => <LongCurrency value={value} />,
        },
        {
            title: 'Type', key: 'type', dataIndex: 'type', className: 'title-caps',
            filters: [
                { text: 'Regular Payment', value: LoanTransactionType.RegularPayment },
                { text: 'Principal Payment', value: LoanTransactionType.PrincipalPayment },
                { text: 'Late Fee', value: LoanTransactionType.LateFee },
                { text: 'Other Fee', value: LoanTransactionType.OtherFee },
                { text: 'Down Payment', value: LoanTransactionType.DownPayment },
            ],
            onFilter: (value, record: ILoanTransaction) => record.type === value,
            render: (value: string) => `${ value ? value.replaceAll('-', ' ') : '-' }`,
        },
        {
            title: 'Method', key: 'method', dataIndex: 'method', className: 'title-caps',
            filters: [
                { text: 'ACH', value: TransactionMethod.ACH },
                { text: 'Barter', value: TransactionMethod.Barter },
                { text: 'Card', value: TransactionMethod.Card },
                { text: 'Cash', value: TransactionMethod.Cash },
                { text: 'Check', value: TransactionMethod.Check },
                { text: 'Money Order', value: TransactionMethod.MoneyOrder },
                { text: 'Cashier Check', value: TransactionMethod.CashierCheck },
                { text: 'Wire', value: TransactionMethod.Wire },
                { text: 'Cash App', value: TransactionMethod.CashApp },
                { text: 'PayPal', value: TransactionMethod.PayPal },
                { text: 'Venmo', value: TransactionMethod.Venmo },
                { text: 'Zelle', value: TransactionMethod.Zelle },
                { text: 'Other', value: TransactionMethod.Other },
            ],
            onFilter: (value, record: ILoanTransaction) => record.method === value,
            render: (value: string) => `${ value ? value.replaceAll('-', ' ') : '-' }`,
        },
        {
            title: 'Status', key: 'status', dataIndex: 'status', className: 'title-caps',
            filters: [
                { text: 'Pending', value: TransactionStatus.Pending },
                { text: 'Success', value: TransactionStatus.Success },
                { text: 'Failure', value: TransactionStatus.Failure },
                { text: 'Reversed', value: TransactionStatus.Reversed },
            ],
            onFilter: (value, record: ILoanTransaction) => record.status === value,
            render: (value: string) => `${ value ? value.replaceAll('-', ' ') : '-' }`,
        },
        {
            title: 'Comment', key: 'comment', dataIndex: 'commentText', className: 'transaction-comment',
            render: (value: string, record: ILoanTransaction) => {
                if (record.status === TransactionStatus.Reversed) {
                    return `Reversal reason: ${ record.reversalReason.replace('-', ' ') }`;
                }

                if (record.status === TransactionStatus.Failure) {
                    return record.reversalCommentText ? record.reversalCommentText : '-';
                }

                return value ? value : '-';
            },
        },
        {
            title: '', dataIndex: '', key: 'action', fixed: 'right',
            render: (nothing: any, record: ILoanTransaction) => <ActionsMenu<ILoanTransaction> record={record} actions={this.actions} onClick={this.onActionMenuClick} />,
        },
    ]

    componentDidMount() {
        if (this.props.loan && this.props.loan.terms?.interestSchedule) {
            switch (this.props.loan.terms.interestSchedule) {
                case LoanTermsInterestSchedule.FollowsPayments:
                    this.loanScheduleColumns.splice(0, 0, {
                        title: 'For Payment(s)', key: 'toPayments', dataIndex: 'toPayments',
                        render: (value: ILoanTransactionTo[], transaction) => {
                            if (transaction.type !== LoanTransactionType.RegularPayment || !value) {
                                return '-';
                            }

                            return value.map((t) => t.paymentNumber).join(', ');
                        },
                    });
                    break;
                case LoanTermsInterestSchedule.AccruesDaily:
                    this.loanScheduleColumns.splice(2, 0, {
                        title: 'To Unpaid Int', key: 'toUnpaidInterest', dataIndex: 'toUnpaidInterest',
                        render: (value: Currency) => <LongCurrency value={value} tooltip />,
                    });
                    break;
            }
        }

        if (this.props.loan && typeof this.props.loan.escrow !== 'undefined') {
            this.loanScheduleColumns.splice(3, 0, {
                title: 'To Escrow', key: 'toEscrow', dataIndex: 'totalToEscrow',
                render: (value: Currency) => <LongCurrency value={value} tooltip />,
            });
        }

        this.loadTransactions();
    }

    componentDidUpdate(prevProps: ILoanTransactionsTableProps) {
        //since the transaction table is already loading, no need to have it load again
        if (this.state.loading) {
            return;
        }

        //no loan actually attached
        if (!this.props.loan.id) {
            return;
        }

        //no last transaction dates, so don't worry about updating
        if (!this.props.loan.lastTransactionDate || !prevProps.loan.lastTransactionDate || !this.props.loan.lastTransactionModifiedDate || !prevProps.loan.lastTransactionModifiedDate) {
            return;
        }

        const prevTransDate = new Date(prevProps.loan.lastTransactionDate);
        const curTransDate = new Date(this.props.loan.lastTransactionDate);

        const prevTransModified = new Date(prevProps.loan.lastTransactionModifiedDate);
        const curTransModified = new Date(this.props.loan.lastTransactionModifiedDate);


        //the last transaction dates are the same, so don't request an update
        if (curTransDate.getTime() <= prevTransDate.getTime() && curTransModified.getTime() <= prevTransModified.getTime()) {
            return;
        }

        this.loadTransactions();
    }

    loadTransactions = () => {
        if (!this.props.loan.organization || !this.props.loan.organization.id || !this.props.loan.id) {
            return;
        }

        this.setState({ loading: true }, async () => {
            try {
                const transactions = await getLoanTransactions(this.props.loan.organization!.id, this.props.loan.id!);

                this.setState({ transactions, loading: false });
            } catch (e) {
                displayErrorNotification(e);
            }
        });
    }

    rowStyling = (record: ILoanTransaction): React.HTMLAttributes<any> | React.TdHTMLAttributes<any> => {
        if (record.status === TransactionStatus.Reversed || record.status === TransactionStatus.Failure) {
            return {
                className: 'payment-reversed',
                style: {
                    backgroundColor: this.props.reversedColor,
                    textDecoration: 'line-through',
                },
            };
        }

        if (record.status === TransactionStatus.Pending) {
            return {
                style: {
                    backgroundColor: this.props.pendingColor,
                },
            };
        }

        return {};
    }

    //#region action menu
    actions: ActionMenuItemMap<ILoanTransaction> = {
        details: { icon: <InfoCircleOutlined />, text: 'View Details' },
        reverse: { icon: <UndoOutlined />, text: 'Reverse', hidden: (transaction) => transaction.status === TransactionStatus.Reversed || !this.props.permissions.update || transaction.type === LoanTransactionType.Payoff, disabled: (transaction) => transaction.status === TransactionStatus.Failure || loanActionsAreDisabled(this.props.loan.status) },
        'revise-date': { icon: <EditOutlined />, text: 'Revise Date', hidden: (transaction) => transaction.status !== TransactionStatus.Success || !this.props.permissions.update },
        'change-status': { icon: <BulbOutlined />, text: 'Change Status', hidden: (transaction) => transaction.status !== TransactionStatus.Pending || !this.props.permissions.update, disabled: (transaction) => !!transaction.processorTransactionId && transaction.processorTransactionId.length !== 0 },
        'generate-letter': { icon: <CloudSyncOutlined />, text: (transaction) => transaction.files.paymentSuccessId ? 'Regenerate Letter' : 'Generate Letter', hidden: (transaction) => transaction.type !== LoanTransactionType.RegularPayment || !transaction.forPayments || transaction.forPayments.length !== 1 || !this.props.permissions.update, disabled: (transaction) => transaction.status !== TransactionStatus.Success },
        'payment-success-letter': { icon: <FilePdfOutlined />, text: 'Download Success Letter', hidden: (transaction) => !(transaction.status === TransactionStatus.Pending || transaction.status === TransactionStatus.Success) || !!!transaction.files.paymentSuccessId },
    };

    onActionMenuClick = async (transaction: ILoanTransaction, actionKey: string) => {
        switch (actionKey) {
            case 'details':
                this.openDetailsModal(transaction);
                return;
            case 'reverse':
                this.openReverseModal(transaction);
                return;
            case 'change-status':
                this.openStatusModal(transaction);
                return;
            case 'generate-letter':
                await this.generateLoanLetter(transaction);
                trackUmami('Generate Loan Transaction Letter');
                return;
            case 'payment-success-letter':
                message.info('Download of the successful payment letter started...');
                await this.downloadFile(transaction.files.paymentSuccessId);
                trackUmami('Download Loan Transaction Letter');
                return;
            case 'revise-date':
                this.openReviseModal(transaction);
                return;
            default:
                console.log(actionKey, 'for', transaction);
                return;
        }
    }
    //#endregion action menu

    generateLoanLetter = async (transaction: ILoanTransaction) => {
        const { loan } = this.props;
        if (!loan || !loan.organization || !loan.id) {
            return;
        }

        message.info('Regenerating the successful payment letter...');
        try {
            await regenerateLoanTransactionLetter(loan.organization.id, loan.id, transaction.id);
            this.loadTransactions();
            message.info('...regeneration of the letter is complete.');
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    downloadFile = async (fileId: string) => {
        const { loan } = this.props;
        if (!loan || !loan.organization || !loan.id) {
            return;
        }

        const file = await getLoanFile(loan.organization.id, loan.id, fileId);
        window.open(file.shareableUrl, '_blank');
    }

    //#region modals
    openReverseModal = (transaction: ILoanTransaction) => {
        this.setState({ selectedTransaction: transaction, isReverseModalVisible: true });
    }

    closeReverseModal = async (saved: boolean) => {
        if (saved) {
            await this.props.refreshData();
        }

        this.setState({ isReverseModalVisible: false, selectedTransaction: undefined });
    }

    openDetailsModal = (transaction: ILoanTransaction) => {
        this.setState({ selectedTransaction: transaction, isDetailsModalVisible: true });
    }

    closeDetailsModal = () => {
        this.setState({ isDetailsModalVisible: false, selectedTransaction: undefined });
    }

    openStatusModal = (transaction: ILoanTransaction) => {
        this.setState({ selectedTransaction: transaction, isStatusModalVisible: true });
    }

    closeStatusModal = async (saved: boolean) => {
        if (saved) {
            await this.props.refreshData();
        }

        this.setState({ isStatusModalVisible: false, selectedTransaction: undefined });
    }

    openReviseModal = (transaction: ILoanTransaction) => {
        this.setState({ selectedTransaction: transaction, isReviseDateVisible: true });
    }

    closeReviseDateModal = async (saved: boolean) => {
        if (saved) {
            await this.props.refreshData();
        }

        this.setState({ isReviseDateVisible: false, selectedTransaction: undefined });
    }
    //#endregion modals

    get emptyArea() {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Transactions Recorded"
            >
                <AccessControlledButton
                    type="primary"
                    icon={<CreditCardOutlined />}
                    onClick={this.props.addClick}
                    disabled={loanActionsAreDisabled(this.props.loan.status)}
                    feature={PermissionFeature.LoanTransaction}
                    action={PermissionAction.Create}
                    prevent="tooltip"
                >Record Transaction</AccessControlledButton>
            </Empty>
        );
    }

    get listOrTable() {
        let transactions: ILoanTransaction[] = this.state.transactions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        if (isMobileOnly) {
            return (
                <TransactionsList
                    loading={this.state.loading}
                    empty={this.emptyArea}
                    transactions={transactions}
                    actionsDisabled={loanActionsAreDisabled(this.props.loan.status)}
                    openDetailsModal={this.openDetailsModal}
                    openStatusChangeModal={this.openStatusModal}
                    reversedColor={this.props.reversedColor}
                    pendingColor={this.props.pendingColor}
                />
            );
        }

        return (
            <Table<ILoanTransaction>
                loading={this.state.loading}
                rowKey="id"
                columns={this.loanScheduleColumns}
                dataSource={transactions}
                pagination={{ pageSize: 8, hideOnSinglePage: true }}
                locale={{ emptyText: this.emptyArea }}
                onRow={this.rowStyling}
                scroll={{ x: 'max-content' }}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.listOrTable}

                { this.props.permissions.update ?
                    <ReverseTransactionModal
                        loan={this.props.loan}
                        transaction={this.state.selectedTransaction}
                        isVisible={this.state.isReverseModalVisible}
                        close={this.closeReverseModal}
                    />
                : null }

                <ViewTransactionDetailsModal
                    loan={this.props.loan}
                    transaction={this.state.selectedTransaction}
                    isVisible={this.state.isDetailsModalVisible}
                    close={this.closeDetailsModal}
                />

                { this.props.permissions.update ?
                    <TransactionStatusChangeModal
                        loan={this.props.loan}
                        transaction={this.state.selectedTransaction}
                        isVisible={this.state.isStatusModalVisible}
                        close={this.closeStatusModal}
                    />
                : null }

                { this.props.permissions.update ?
                    <ReviseTransactionDateModal
                        loan={this.props.loan}
                        schedule={this.props.schedule}
                        transaction={this.state.selectedTransaction}
                        isVisible={this.state.isReviseDateVisible}
                        close={this.closeReviseDateModal}
                    />
                : null }
            </React.Fragment>
        );
    }
}

export const LoanTransactionsTable: FC<ILoanTransactionsTableProps> = (props) => {
    const { token } = theme.useToken();
    const transactionPermissions = useSelector(hasAccessToFeature(PermissionFeature.LoanTransaction));

    return (
        <LoanTransactionsTableBase
            reversedColor={token.colorErrorBg}
            pendingColor={token.colorWarningBg}
            loan={props.loan}
            schedule={props.schedule}
            addClick={props.addClick}
            refreshData={props.refreshData}
            permissions={transactionPermissions}
        />
    );
}
