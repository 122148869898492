import { Currency } from 'models/currency';
import { postForm, get } from './index';

import type { IMail } from 'models/mail';
import type { ISendMailToClientEntityCalculationPayload } from 'models/payloads/snailMail';
import type { IRestRequest } from 'models/common/restRequest';
import type { IRestResult } from 'models/common/restResult';

export const sendMail = (orgId: string, clientId: string, payload: FormData) => postForm<IMail>(`/organizations/${ orgId }/clients/${ clientId }/mail`, payload, true);
export const calculateMailPrice = (orgId: string, clientId: string, payload: ISendMailToClientEntityCalculationPayload) => get<{ cost: Currency }>(`/organizations/${ orgId }/clients/${ clientId }/mail/calc-pricing?${ new URLSearchParams(payload).toString() }`, true);
export const getMails = (orgId: string, req?: IRestRequest) => get<IRestResult<IMail>>(`/organizations/${ orgId }/mails`, true, req);
