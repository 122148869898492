import React, { Suspense, lazy } from 'react';
import currency from 'currency.js';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';

import { AuditOutlined, CalculatorOutlined, CopyOutlined, DeleteOutlined, DollarOutlined, EditOutlined, InfoCircleOutlined, PicRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Button, message, Tooltip, Empty } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { GetComponentProps } from 'rc-table/lib/interface';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';
import { displayErrorNotification } from 'utils/errors';

import { Currency } from 'models/currency';
import { IInventory } from 'models/inventory';
import { ITract } from 'models/tract';
import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';

import { EditTractDrawer } from './editTractDrawer';
import { TractStatusTag } from './tractStatusTag';
import { TractFinanceCalcModal } from './calculatorModal';

import { getTracts, deleteTractByID, duplicateTract } from 'api/tracts';

const TractAdjustCostModal = lazy(() => import('./adjustCost'));
const TractAdjustAcresModal = lazy(() => import('./adjustAcres'));

interface ITractsTableProps extends RouteComponentProps<IOrgIdPathParams>  {
    isLoading: boolean;
    inventory: IInventory;
    pageSize: number;
    enablePricing: boolean;
}

interface ITractsTableState {
    isLoading: boolean;
    tracts: ITract[];
    isCalcModalVisible: boolean;
    isNewTractDrawerVisible: boolean;
    isEditTractVisible: boolean;
    isEditCostVisible: boolean;
    isEditAcresVisible: boolean;
    editingTract?: ITract;
    selectedTract?: ITract;
}

class TractsTableBase extends React.PureComponent<ITractsTableProps, ITractsTableState> {
    state: Readonly<ITractsTableState> = {
        isLoading: true,
        tracts: [],
        isCalcModalVisible: false,
        isNewTractDrawerVisible: false,
        isEditTractVisible: false,
        isEditCostVisible: false,
        isEditAcresVisible: false,
    }

    constructor(props: ITractsTableProps) {
        super(props);

        this.setStatePromise = this.setStatePromise.bind(this);
    }

    setStatePromise<K extends keyof ITractsTableState>(partialState: Pick<ITractsTableState, K>): Promise<void> {
        return new Promise((resolve) => {
            this.setState(partialState, () => {
                resolve();
            });
        });
    }

    async componentDidMount() {
        this.loadTracts();
    }

    async loadTracts() {
        try {
            await this.setStatePromise({ isLoading: true });

            let tracts = await getTracts(this.props.match.params.orgId, this.props.inventory.id);
            tracts = tracts.sort((a, b) => a.number - b.number);

            this.setState({ tracts });
        } catch (e) {
            console.warn('error occurred while loading the tracts:', e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    addTractClick = () => {
        this.setState({ isNewTractDrawerVisible: true });
    }

    addTractDrawerClosed = async (saved: boolean) => {
        if (saved) {
            await this.loadTracts();
        }

        this.setState({ isNewTractDrawerVisible: false });
    }

    editTractClosed = async (saved: boolean) => {
        if (saved) {
            await this.loadTracts();
        }

        this.setState({ isEditTractVisible: false, editingTract: undefined });
    }

    //#region table columns
    columns: ColumnProps<ITract>[] = [
        {
            title: 'Label', dataIndex: 'label', key: 'label', width: '15%',
            render: (label: string, tract: ITract) => {
                return (
                    <span>
                        { label } <TractStatusTag status={tract.status} />
                    </span>
                );
            }
        },
        {
            title: 'Acres', dataIndex: 'acres', key: 'acres',
        },
        {
            title: 'Price Per Acre', dataIndex: ['paymentOption', 'pricePerAcre'], key: 'pricePerAcre',
            render: (value: Currency, record: ITract) => {
                return record.paymentOption.isFilledOut ? currency(value, { precision: 0 }).format(true) : '-';
            },
        },
        {
            title: 'Sales Price', dataIndex: ['paymentOption', 'salesPrice'], key: 'salesPrice',
            render: (value: Currency, record: ITract) => {
                return record.paymentOption.isFilledOut ? currency(value, { precision: 0 }).format(true) : '-';
            },
        },
        {
            title: 'Cost', dataIndex: 'costTotal', key: 'costTotal',
            render: (value: Currency) => {
                return value !== '0' ? currency(value, { precision: 2 }).format(true) : '-';
            },
        },
        {
            title: 'Down Payment', dataIndex: ['paymentOption', 'downPayment'], key: 'downPayment',
            render: (value: Currency, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? currency(value, { precision: 0 }).format(true) : '-'
            },
        },
        {
            title: 'Years', dataIndex: ['paymentOption', 'years'], key: 'years',
            render: (value: number, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? `${ value }` : '-';
            },
        },
        {
            title: 'Interest Rate', dataIndex: ['paymentOption', 'interestRate'], key: 'interestRate',
            render: (value: Currency, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? `${ value }%` : '-';
            },
        },
        {
            title: 'Amount Financed', dataIndex: '', key: 'amountFinanced',
            render: (_, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? currency(record.paymentOption.salesPrice, { precision: 0 }).subtract(currency(record.paymentOption.downPayment)).format(true) : '-';
            },
        },
        {
            title: 'Payment Amount', dataIndex: '', key: 'paymentAmount',
            render: (_, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? currency(record.paymentOption.monthlyPayment).format(true) : '-';
            },
        },
        {
            title: 'Total of Payments', dataIndex: '', key: 'totalOfPayments',
            render: (_, record: ITract) => {
                return record.paymentOption.isFilledOut && record.paymentOption.isFinanced ? currency(record.paymentOption.totalOfPayments, { precision: 2 }).format(true) : '-';
            },
        },
        {
            title: (
                <Button size="small" type="dashed" onClick={this.addTractClick} icon={<PlusOutlined />}> Add Tract</Button>
            ),
            dataIndex: '', key: 'action', fixed: 'right',
            render: (nothing: any, record: ITract) => <ActionsMenu<ITract> record={record} actions={this.actions} onClick={this.onActionMenuClick} />,
        },
    ];
    //#endregion table columns

    onTractCalculatorClick = (tract: ITract) => {
        this.setState({ isCalcModalVisible: true, selectedTract: cloneDeep(tract) });
    }

    onActionMenuClick = async (tract: ITract, actionKey: string) => {
        try {
            switch (actionKey) {
                case 'details':
                    if (!this.props.inventory) {
                        message.warning('Invalid props and state.');
                        return;
                    }

                    this.props.history.push(`/${ this.props.match.params.orgId }/inventories/${ this.props.inventory.id }/tracts/${ tract.id }`);
                    return;
                case 'paymentCalc':
                    this.onTractCalculatorClick(tract);
                    return;
                case 'quickEdit':
                    this.setState({ isEditTractVisible: true, editingTract: tract });
                    return;
                case 'details-loan':
                    this.props.history.push(`/${ this.props.match.params.orgId }/loans/${ tract.loan.id }`);
                    return;
                case 'delete':
                    await deleteTractByID(this.props.match.params.orgId, this.props.inventory.id, tract.id);
                    await this.loadTracts();
                    message.success(`${ tract.label } deleted successfully.`);
                    return;
                case 'duplicate':
                    await duplicateTract(this.props.match.params.orgId, this.props.inventory.id, tract);
                    await this.loadTracts();
                    message.success(`Successfully duplicated ${ tract.label }.`);
                    return;
                case 'adjust-cost':
                    this.setState({ isEditCostVisible: true, editingTract: tract });
                    return;
                case 'adjust-acres':
                    this.setState({ isEditAcresVisible: true, editingTract: tract });
                    return;
                default:
                    return;
            }
        } catch (err) {
            displayErrorNotification(err);
            console.warn('failed to take action via the menu click', actionKey, tract.id, 'error:', err);
        }
    }

    actions: ActionMenuItemMap<ITract> = {
        details: { icon: <InfoCircleOutlined />, text: 'View Details' },
        quickEdit: { icon: <EditOutlined />, text: 'Quick Edit' },
        'details-loan': { icon: <AuditOutlined />, text: 'View Loan', hidden: (tract) => !!!tract.loan.id },
        paymentCalc: { icon: <CalculatorOutlined />, text: 'Calculator' },
        'delete': { icon: <DeleteOutlined />, text: 'Delete', disabled: (tract) => !!tract.loan.id },
        duplicate: { icon: <CopyOutlined />, text: 'Duplicate' },
        'adjust-cost': { icon: <DollarOutlined />, text: 'Adjust Cost' },
        'adjust-acres': { icon: <PicRightOutlined />, text: 'Adjust Acres' },
    };

    modalDoneButtonClick = () => {
        this.setState({ isCalcModalVisible: false });
    }

    onRow = (record: ITract): ReturnType<GetComponentProps<ITract>> => {
        return {
            onClick: (event) => {
                if (event.isDefaultPrevented()) {
                    return;
                }

                if (record.id === 'SUMMARY') {
                    return;
                }

                event.preventDefault();

                this.onActionMenuClick(record, 'details');
            },
        };
    }

    summary = (tracts: readonly ITract[]) => {
        if (!Array.isArray(tracts) || tracts.length === 0) {
            return null;
        }

        let acres = 0;
        let salesPrice = currency(0, { precision: 0 });
        let totalCostOfDev = currency(0, { precision: 0 });
        let downPayment = currency(0, { precision: 0 });
        let amountFinanced = currency(0, { precision: 0 });
        let paymentAmount = currency(0, { precision: 0 });
        let totalOfPayments = currency(0, { precision: 0 });

        tracts.forEach((tract: ITract) => {
            acres += tract.acres;
            totalCostOfDev = totalCostOfDev.add(tract.costTotal ? tract.costTotal : '0');

            if (tract.paymentOption && tract.paymentOption.isFilledOut) {
                salesPrice = salesPrice.add(tract.paymentOption.salesPrice);

                if (tract.paymentOption.isFinanced) {
                    downPayment = downPayment.add(tract.paymentOption.downPayment);
                    amountFinanced = amountFinanced.add(currency(tract.paymentOption.salesPrice).subtract(tract.paymentOption.downPayment));
                    paymentAmount = paymentAmount.add(tract.paymentOption.monthlyPayment);
                    totalOfPayments = totalOfPayments.add(tract.paymentOption.totalOfPayments);
                }
            }
        });

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1}>
                    <strong>{ acres === 0 ? '-' : acres.toFixed(2) }</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <Tooltip overlay="Average Price per Acre">
                        <strong>{ acres === 0 ? '-' : salesPrice.divide(currency(acres)).format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <Tooltip overlay="Total Sales Price">
                        <strong>{ acres === 0 ? '-' : salesPrice.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                    <Tooltip overlay="Total Cost of Development">
                        <strong>{ acres === 0 || totalCostOfDev.intValue === 0 ? '-' : totalCostOfDev.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={3}>
                    <Tooltip overlay="Total Down Payment">
                        <strong>{ acres === 0 ? '-' : downPayment.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <Tooltip overlay="Total Amount Financed">
                        <strong>{ acres === 0 ? '-' : amountFinanced.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                    <Tooltip overlay="Total of the Monthly Payments">
                        <strong>{ acres === 0 ? '-' : paymentAmount.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                    <Tooltip overlay="Total of all Payments">
                        <strong>{ acres === 0 ? '-' : totalOfPayments.format(true) }</strong>
                    </Tooltip>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }

    get drawersAndModals() {
        return (
            <React.Fragment>
                <TractFinanceCalcModal tract={this.state.selectedTract} isVisible={this.state.isCalcModalVisible} close={this.modalDoneButtonClick} />
                <EditTractDrawer orgId={this.props.match.params.orgId} inventory={this.props.inventory} isVisible={this.state.isNewTractDrawerVisible} close={this.addTractDrawerClosed} />
                <Suspense fallback={null}>
                    <TractAdjustCostModal
                        orgId={this.props.match.params.orgId}
                        tract={this.state.editingTract}
                        open={this.state.isEditCostVisible}
                        close={() => {
                            this.setState({ isEditCostVisible: false, editingTract: undefined });
                            this.loadTracts();
                        }}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <TractAdjustAcresModal
                        orgId={this.props.match.params.orgId}
                        tract={this.state.editingTract}
                        open={this.state.isEditAcresVisible}
                        close={() => {
                            this.setState({ isEditAcresVisible: false, editingTract: undefined });
                            this.loadTracts();
                        }}
                    />
                </Suspense>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div>
                <Table<ITract>
                    loading={this.props.isLoading || this.state.isLoading}
                    className="tract-table"
                    rowKey="id"
                    dataSource={this.state.tracts}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: this.props.pageSize,
                    }}
                    rowClassName={(record, index) => 'pointer'}
                    scroll={{ x: 'max-content' }}
                    onRow={this.onRow}
                    summary={this.summary}
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={ `${ this.props.inventory.name } has no tracts currently` }
                                children={<Button type="primary" onClick={this.addTractClick} icon={<PlusOutlined />}>Add Tract</Button>}
                            />
                        ),
                    }}
                />

                <EditTractDrawer orgId={this.props.match.params.orgId} inventory={this.props.inventory} tract={this.state.editingTract} isVisible={this.state.isEditTractVisible} close={this.editTractClosed} />
                {this.drawersAndModals}
            </div>
        );
    }
}

export const TractsTable = withRouter(TractsTableBase);
