import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Table, Tag, message } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';

import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { IOrganization } from 'models/organization';
import { IClient } from 'models/client';
import { IPaymentMethod } from 'models/billing/paymentMethod';

import { ActionsMenu, ActionMenuItemMap } from 'components/actions';

import { setPaymentMethodAsDefault, deletePaymentMethod } from 'api/organizations';
import { displayErrorNotification } from 'utils/errors';

import { GlobalState } from 'store';
import { getSelectedOrg, isSelectedOrgAllowedToCreate } from 'store/selectors/org';
import { orgRefresh } from 'store/actions/org';
import { deleteClientPaymentMethod, setClientPaymentMethodAsDefault } from 'api/clients';

const mapStateToProps = (state: GlobalState) => ({
    selectedOrg: getSelectedOrg(state),
    canCreate: isSelectedOrgAllowedToCreate(state),
});

interface IPaymentMethodTableProps extends ReturnType<typeof mapStateToProps>, DispatchProp {
    for: IClient | IOrganization;
    size?: SizeType;
}

interface IPaymentMethodTableState {
    loading: boolean;
}

class PaymentMethodTableBase extends React.PureComponent<IPaymentMethodTableProps, IPaymentMethodTableState> {
    state: Readonly<IPaymentMethodTableState> = {
        loading: false,
    };

    columns: ColumnProps<IPaymentMethod>[] = [
        {
            title: 'Name', key: 'name', dataIndex: 'name',
            render: (name: string, record: IPaymentMethod) => {
                if (record.type !== 'card') {
                    return record.bankName;
                }

                return name;
            }
        },
        {
            title: 'Details', key: 'details',
            render: (nothing: any, record: IPaymentMethod) => (
                <span>
                    **** { record.last4 }&nbsp;
                    { record.default ? <Tag color="blue">Default</Tag> : null }
                    { record.disabled ? <Tag color="red">Disabled</Tag> : null }
                </span>
            ),
        },
        {
            title: 'Expiration', key: 'expiration',
            render: (nothing: any, record: IPaymentMethod) => {
                if (record.type !== 'card') {
                    return '-';
                }

                return `${ record.expirationMonth } / ${ record.expirationYear }`;
            }
        },
        {
            title: 'Action', key: 'actions',
            render: (nothing: any, record: IPaymentMethod) => (
                <ActionsMenu<IPaymentMethod>
                    record={record}
                    actions={this.actions}
                    onClick={this.onActionClick}
                    permission={{
                        feature: this.isForClient(this.props.for) ? PermissionFeature.Client : PermissionFeature.OrganizationBilling,
                        action: PermissionAction.Update,
                    }}
                />
            ),
        },
    ]

    actions: ActionMenuItemMap<IPaymentMethod> = {
        default: { icon: <CheckCircleOutlined />, text: 'Set as Default', disabled: (p) => p.default || p.disabled },
        remove: { icon: <MinusCircleOutlined />, text: 'Remove', disabled: (p) => p.default || this.props.for.billing.paymentMethods!.length === 1 },
    };

    componentDidMount(): void {
        if (!this.isForClient(this.props.for)) {
            return;
        }

        this.columns.splice(3, 0, {
            title: 'Source', key: 'source', dataIndex: 'source',
            className: 'title-caps',
        });
    }

    isForClient(arg: IClient | IOrganization): arg is IClient {
        return 'primaryEntity' in arg;
    }

    onActionClick = async (paymentMethod: IPaymentMethod, actionKey: string) => {
        try {
            this.setState({ loading: true });

            switch (actionKey) {
                case 'default':
                    if (this.isForClient(this.props.for)) {
                        await setClientPaymentMethodAsDefault(this.props.selectedOrg!.id, this.props.for.id, { paymentMethodId: paymentMethod.id });
                    } else {
                        await setPaymentMethodAsDefault(this.props.selectedOrg!.id, { paymentMethodId: paymentMethod.id });
                        await this.props.dispatch(orgRefresh(this.props.selectedOrg!.id) as any);
                    }
                    message.success(`Successfully changed **** ${ paymentMethod.last4 } to the default payment method.`);
                    break;
                case 'remove':
                    if (this.isForClient(this.props.for)) {
                        await deleteClientPaymentMethod(this.props.selectedOrg!.id, this.props.for.id, paymentMethod.id);
                        message.success(`Successfully removed **** ${ paymentMethod.last4 } from ${ this.props.for.displayName }'s account.`);
                    } else {
                        await deletePaymentMethod(this.props.selectedOrg!.id, paymentMethod.id);
                        await this.props.dispatch(orgRefresh(this.props.selectedOrg!.id) as any);
                        message.success(`Successfully removed **** ${ paymentMethod.last4 } from your account.`);
                    }
                    break;
            }
        } catch (e) {
            displayErrorNotification(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <Table<IPaymentMethod>
                size={this.props.size || 'middle'}
                rowKey="id"
                columns={this.columns}
                dataSource={this.props.for.billing.paymentMethods}
                pagination={{ hideOnSinglePage: true }}
                loading={this.state.loading}
                scroll={{ x: 'max-content' }}
            />
        );
    }
}

export const PaymentMethodTable = connect(mapStateToProps)(PaymentMethodTableBase);
