import React, { Suspense, lazy } from 'react';

import { connect, DispatchProp } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { AlertTwoTone, ApiOutlined, BankOutlined, BulbOutlined, CalculatorOutlined, CalendarOutlined, ClockCircleOutlined, CloudDownloadOutlined, ContactsOutlined, CreditCardOutlined, DeleteOutlined, DownOutlined, FileOutlined, FilePdfOutlined, FormOutlined, IssuesCloseOutlined, PhoneOutlined } from '@ant-design/icons';
import { Row, Col, Button, Collapse, Dropdown, Tag, Result, message, Skeleton } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import type { PageContainerProps } from '@ant-design/pro-layout';
import { MenuInfo } from 'rc-menu/lib/interface';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { isMobileOnly } from 'react-device-detect';
import { FaFileCsv } from "react-icons/fa";

import { displayConfirmModal } from 'utils/modals';
import { displayErrorNotification } from 'utils/errors';
import { breadCrumbItemRender } from 'utils/breadCrumbs';

import { IOrgIdPathParams } from 'models/props-or-state/orgPathProp';
import { ILoadingFailureState, ILoadingState } from 'models/props-or-state/states';

import type { ILoanSchedule } from 'models/loanSchedule';
import { LoanStatus, LoanType } from 'models/loan';
import { IClient } from 'models/client';
import { INote } from 'models/notes';
import { RelatedToType } from 'models/common/relatedTo';
import { FillablePDFFor } from 'models/fillablePDFs';
import { PermissionAction, PermissionFeature } from 'models/permissions/features';

import { Loading } from 'components/misc/loading';
import { LoanStatusTag } from 'components/loans/loanStatusTag';
import { NewTransactionModal } from 'components/loans/newTransactionModal';
import { LoanTransactionsTable } from 'components/loans/transactionsTable';
import { ViewPayOffModal } from 'components/loans/payOffModal';
import { ChangeDueDateModal } from 'components/loans/changeDueDateModal';
import { loanActionsAreDisabled } from 'components/loans/loanActionsAreDisabled';
import { LoanRenameModal } from 'components/loans/relabelModal';
import { NotesCard } from 'components/notes';
import { FilesCard } from 'components/files';
import { FillablePDFListModal } from 'components/fillablePdfs/pdfListModal';
import { AccessControlledOrComponent, AccessControlledWrapper } from 'components/permissions';

import { GlobalState } from 'store';
import { getSelectedOrg } from 'store/selectors/org';
import { getLoanById } from 'store/selectors/loans';
import { hasAccessTo } from 'store/selectors/permissions';
import { fetchLoanById } from 'store/actions/loans';
import { fetchClientById } from 'store/actions/clients';
import { LoanFetchSuccessAction } from 'store/types/loans';
import { ClientFetchSuccessAction } from 'store/types/clients';

import { setLoanStatus, getLoanNotes, deleteLoanByID, getLoanTransactions, getLoanAmortizationSchedule } from 'api/loans';
import { LoanTabOverview } from './loanTabOverview';
import { LoanTabDetails } from './loanTabDetails';

const ChangeLateFeeConfigDrawer = lazy(() => import('components/lateFees/changeLateFeeConfigDrawer'));
const ChangeCommunicationPreferencesModal = lazy(() => import('components/loans/changeCommunicationPreferencesModal'));
const MarkRepossessedModal = lazy(() => import('components/loans/markRepossessedModal'));
const OnlinePaymentModal = lazy(() => import('components/loans/onlinePaymentsModal'));
const ChangeDefaultingModal = lazy(() => import('components/loans/defaultingModal'));
const RecordPayOffModal = lazy(() => import('components/loans/recordPayOff'));
const LoanScheduleTable = lazy(() => import('components/loans/scheduleTable'));
const LoanEscrowTable = lazy(() => import('components/loans/escrow/escrowTable'));
const LoanTabActionArea = lazy(() => import('./loanTabAction'));
const LoanTabExisting = lazy(() => import('./loanTabExisting'));

type activeLoanTab = 'action' | 'overview' | 'details' | 'existing';

interface ILoanPathParams extends IOrgIdPathParams {
    loanId: string;
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<ILoanPathParams>) => ({
    selectedOrg: getSelectedOrg(state),
    loan: getLoanById(state, props.match.params.orgId, props.match.params.loanId),
    loanPermissions: {
        read: hasAccessTo(PermissionFeature.Loan, PermissionAction.Read)(state),
        update: hasAccessTo(PermissionFeature.Loan, PermissionAction.Update)(state),
        delete: hasAccessTo(PermissionFeature.Loan, PermissionAction.Delete)(state),
        escrow: hasAccessTo(PermissionFeature.LoanEscrow, PermissionAction.Read)(state),
    },
    canAccessTransactions: hasAccessTo(PermissionFeature.LoanTransaction, PermissionAction.Read)(state),
    canAccessNotes: hasAccessTo(PermissionFeature.LoanNotes, PermissionAction.Read)(state),
    canAccessFiles: hasAccessTo(PermissionFeature.LoanFiles, PermissionAction.Read)(state),
});

interface ILoanProps extends ReturnType<typeof mapStateToProps>, DispatchProp, RouteComponentProps<ILoanPathParams> {}

interface ILoanState extends ILoadingFailureState, ILoadingState {
    activeTab: activeLoanTab;
    schedule?: ILoanSchedule;
    client?: IClient;
    isAddTransVisible: boolean;
    isPayOffVisible: boolean;
    isRecordPayOffVisible: boolean;
    isOnlinePaymentsVisible: boolean;
    isChangeDueDateVisible: boolean;
    isChangeCommPrefVisible: boolean;
    isChangeLateFeeVisible: boolean;
    isChangeDefaultingVisible: boolean;
    isFillablePDFsVisible: boolean;
    isRenameLoanVisible: boolean;
    isRepossessedVisible: boolean;
    isDownloadingSchedule: boolean;
    notes: INote[];
}

class LoanBase extends React.PureComponent<ILoanProps, ILoanState> {
    state: Readonly<ILoanState> = {
        activeTab: 'overview',
        notFound: false,
        otherError: false,
        error: '',
        isLoading: true,
        isAddTransVisible: false,
        isOnlinePaymentsVisible: false,
        isPayOffVisible: false,
        isRecordPayOffVisible: false,
        isChangeDueDateVisible: false,
        isChangeCommPrefVisible: false,
        isChangeLateFeeVisible: false,
        isChangeDefaultingVisible: false,
        isFillablePDFsVisible: false,
        isRenameLoanVisible: false,
        isRepossessedVisible: false,
        isDownloadingSchedule: false,
        notes: [],
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: ILoanProps) {
        if (prevProps.loan?.modifiedAt !== this.props.loan?.modifiedAt) {
            this.loanSchedule();
        }

        if (this.props.match.params.orgId === prevProps.match.params.orgId) {
            return;
        }

        this.loadData();
    }

    loanSchedule = () => {
        return new Promise<void>(async (resolve) => {
            if (!this.props.loan?.hasSchedule) {
                return resolve();
            }

            const schedule = await getLoanAmortizationSchedule(this.props.match.params.orgId, this.props.match.params.loanId);

            this.setState((prevState) => {
                return {
                    ...prevState,
                    schedule: schedule as ILoanSchedule,
                    activeTab: this.hasActionNeeded() ? 'action' : 'overview',
                };
            }, resolve);
        });
    }

    loadData = async () => {
        if (!this.props.loanPermissions.read) {
            return;
        }

        this.setState({ isLoading: true, notFound: false, otherError: false });

        try {
            const loanResult: LoanFetchSuccessAction = await this.props.dispatch(fetchLoanById(this.props.match.params.loanId) as any);
            await this.loanSchedule();
            const clientResult: ClientFetchSuccessAction = await this.props.dispatch(fetchClientById(loanResult.loan.client.id) as any);

            let notes: INote[] = [];
            if (this.props.canAccessNotes) {
                notes = await getLoanNotes(this.props.match.params.orgId, this.props.match.params.loanId);
            }

            this.setState({
                isLoading: false,
                activeTab: this.hasActionNeeded() ? 'action' : 'overview',
                client: clientResult.client,
                notes,
            });
        } catch (e: any) {
            console.error('Error while loading the loan:', e);

            displayErrorNotification(e, this.loadData);

            if (e.code === 73) {
                this.setState({ notFound: true, isLoading: false });
            } else {
                this.setState({
                    notFound: false,
                    otherError: true,
                    error: typeof e.error === 'string' ? e.error : 'Unknown error',
                    isLoading: false,
                });
            }
        }
    }

    hasActionNeeded = () => {
        const { loan } = this.props;
        if (!loan) {
            return false;
        }

        switch (loan.status) {
            case LoanStatus.Draft:
            case LoanStatus.Late:
                return true;
            case LoanStatus.Pending:
            case LoanStatus.Current:
                if (loan.downPayment && !loan.downPayment.paid) {
                    return true;
                }

                // TODO: Enable this once the action is implemented
                // if (loan.balance.propertyTax !== '0') {
                //     return true;
                // }

                return false;
            case LoanStatus.InDefault:
            case LoanStatus.Defaulted:
            case LoanStatus.Repossessed:
                return true;
            default:
                return false;
        }
    }

    toggleTimeline = () => {
        // this.setState({ isTimelineVisible: !this.state.isTimelineVisible });
    }

    togglePayOffModal = () => {
        this.setState({ isPayOffVisible: !this.state.isPayOffVisible });
    }

    toggleRecordPayOffModal = () => {
        this.setState({ isRecordPayOffVisible: !this.state.isRecordPayOffVisible });
    }

    toggleOnlinePaymentsModal = async (changed?: boolean) => {
        if (changed) {
            await this.loadData();
        }

        this.setState({ isOnlinePaymentsVisible: !this.state.isOnlinePaymentsVisible });
    }

    toggleChangeDueDateModal = async (changed?: boolean) => {
        if (changed) {
            await this.loadData();
        }

        this.setState({ isChangeDueDateVisible: !this.state.isChangeDueDateVisible });
    }

    toggleChangeCommPrefModal = async (changed?: boolean) => {
        if (changed) {
            await this.loadData();
        }

        this.setState({ isChangeCommPrefVisible: !this.state.isChangeCommPrefVisible });
    }

    toggleChangeLateFeeModal = async (saved?: boolean) => {
        if (saved) {
            this.loadData();
        }

        this.setState({ isChangeLateFeeVisible: !this.state.isChangeLateFeeVisible });
    }

    toggleDefaultingConfigModal = async (saved?: boolean) => {
        if (saved) {
            this.loadData();
        }

        this.setState({ isChangeDefaultingVisible: !this.state.isChangeDefaultingVisible });
    }

    toggleRenameModal = () => {
        this.setState({ isRenameLoanVisible: !this.state.isRenameLoanVisible });
    }

    //#region menu
    onFillablePDFClose = () => {
        this.setState({ isFillablePDFsVisible: false });
    }

    onStatusChange = async (status: LoanStatus) => {
        const loan = this.props.loan!;

        if (loan.status === LoanStatus.Draft) {
            const moveForward = await displayConfirmModal("Convert from Draft", "Are you sure you want to convert this loan from a draft? Doing so will ensure the integrity, including marking it as late if applicable.", "Yes", "Whoops, no!");
            if (!moveForward) {
                return;
            }
        }

        //Double check they want to mark it as inactive.
        //We no longer allow the loan to be marked as inactive, it can only be repossessed.
        if (status === LoanStatus.Inactive || status === LoanStatus.Canceled) {
            let msg = "Are you sure you want to deactivate this loan?";
            if (loan.type === LoanType.Tract) {
                msg = `Deactivating the loan will disassociate the loan from the tract. In addition, marking it as inactive will exclude it from any reports. If you received the tract back from the buyer, we highly recommend marking the loan as repossessed. ${msg}`
            }

            const inactiveForward = await displayConfirmModal("Deactivate Loan", msg, "Yes", "Whoops, no!");
            if (!inactiveForward) {
                return;
            }
        }

        if (status === LoanStatus.Repossessed) {
            this.setState({ isRepossessedVisible: true });
            return;
        }

        try {
            await setLoanStatus(loan.organization.id, loan.id, status);
            this.loadData();
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    onDeleteClick = async () => {
        const loan = this.props.loan!;

        const moveForward = await displayConfirmModal("Delete Loan?", "Deleting this loan will remove all components of it, including files, transaction history, and comments. Are you 100% certain?", "Yes", "No!");
        if (!moveForward) {
            return;
        }

        try {
            await deleteLoanByID(loan.organization.id, loan.id);
            this.props.history.push(`/${loan.organization.shortId}/loans`);
        } catch (e) {
            displayErrorNotification(e);
        }
    }

    onActionMenuClick = async (e: MenuInfo) => {
        const { loan } = this.props;
        if (!loan) {
            return;
        }

        if (e.key.startsWith('status/')) {
            await this.onStatusChange(e.key.split('/')[1] as LoanStatus);
            return;
        }

        if (e.key.startsWith('details-tracts/')) {
            const split = e.key.split('/');
            this.props.history.push(`/${this.props.match.params.orgId}/inventories/${ split[1] }/tracts/${ split[2] }`);
            return;
        }

        switch (e.key) {
            case 'details-tract':
                if (!loan.tracts || loan.tracts.length === 0) {
                    return;
                }

                this.props.history.push(`/${this.props.match.params.orgId}/inventories/${loan.tracts[0].inventoryId}/tracts/${loan.tracts[0].tractId}`);
                return;
            case 'details-res-inv':
                this.props.history.push(`/${this.props.match.params.orgId}/inventories/${loan.residential?.inventoryId}`);
                return;
            case 'details-client':
                this.props.history.push(`/${this.props.match.params.orgId}/clients/${loan.client.id}`);
                return;
            case 'view-payoff':
                this.togglePayOffModal();
                return;
            case 'online-payments':
                this.toggleOnlinePaymentsModal();
                return;
            case 'change-due-date':
                this.toggleChangeDueDateModal();
                return;
            case 'change-comm-pref':
                this.toggleChangeCommPrefModal();
                return;
            case 'change-late-fee':
                this.toggleChangeLateFeeModal();
                return;
            case 'change-defaulting':
                this.toggleDefaultingConfigModal();
                return;
            case 'rename':
                this.toggleRenameModal();
                return;
            case 'delete':
                this.onDeleteClick();
                return;
            case 'fill-pdf':
                this.setState({ isFillablePDFsVisible: true });
                return;
            case 'repossess-inactive':
                await this.onStatusChange(LoanStatus.Repossessed);
                return;
            case 'record-payoff':
                this.toggleRecordPayOffModal();
                return;
            default:
                return;
        }
    }

    get dropdownMenuItems(): Array<ItemType> | null {
        const { loan } = this.props;

        if (!loan) {
            return null;
        }

        const disabled = loanActionsAreDisabled(loan.status) || this.state.isLoading;

        return [
            loan.type === LoanType.Tract && loan.tracts && loan.tracts.length === 1 ? { key: 'details-tract', icon: <FileOutlined />, label: 'View Tract' } : null,
            loan.type === LoanType.Tract && loan.tracts && loan.tracts.length > 1 ?
                {
                    key: 'details-tracts', icon: <FileOutlined />, label: 'View Tracts',
                    children: loan.tracts.map((t) => ({
                        key: `details-tracts/${ t.inventoryId }/${ t.tractId }`,
                        label: `Tract ${ t.number }`
                    })),
                }
            : null,
            loan.type === LoanType.Residential ? { key: 'details-res-inv', icon: <FileOutlined />, label: 'View Residential Property' } : null,
            { key: 'details-client', icon: <ContactsOutlined />, label: 'View Client' },
            { key: 'online-payments', icon: <ApiOutlined />, label: 'Online Payments', disabled },
            { key: 'change-due-date', icon: <CalendarOutlined />, label: 'Move Due Date', disabled: disabled || !loan.hasSchedule }, //disabled for loans that don't have a schedule
            { key: 'change-comm-pref', icon: <PhoneOutlined />, label: 'Communication Preferences', disabled },
            { key: 'change-late-fee', icon: <ClockCircleOutlined />, label: 'Late Fee Config', disabled },
            { key: 'change-defaulting', icon: <IssuesCloseOutlined />, label: 'Defaulting Config', disabled },
            { key: 'rename', icon: <FormOutlined />, label: 'Rename', disabled },
            { key: 'fill-pdf', icon: <FilePdfOutlined />, label: 'Fill a PDF', disabled },
            { key: 'view-payoff', icon: <CalculatorOutlined />, label: 'Calc Pay Off', disabled },
            { key: 'record-payoff', icon: <BankOutlined />, label: 'Record Pay Off', disabled },
            loan.status === LoanStatus.Draft ? { key: 'delete', icon: <DeleteOutlined />, label: 'Delete', disabled } : null,
            loan.type === LoanType.Tract && loan.status === LoanStatus.Inactive ? { key: 'repossess-inactive', icon: <DeleteOutlined />, label: 'Make Repossessed' } : null,
            {
                key: 'status',
                label: (
                    <React.Fragment>
                        <BulbOutlined /> Status
                    </React.Fragment>
                ),
                disabled,
                children: [
                    { key: 'status/' + LoanStatus.Draft, label: 'Draft', disabled: loan.status === LoanStatus.Draft || disabled },
                    { key: 'status/' + LoanStatus.Pending, label: 'Pending', disabled: loan.status === LoanStatus.Pending || disabled },
                    { key: 'status/' + LoanStatus.Current, label: 'Current', disabled: loan.status === LoanStatus.Current || disabled },
                    { key: 'status/' + LoanStatus.Late, label: 'Late', disabled: loan.status === LoanStatus.Late || disabled },
                    { key: 'status/' + LoanStatus.InDefault, label: 'In Default', disabled: loan.status === LoanStatus.InDefault || disabled },
                    { key: 'status/' + LoanStatus.Defaulted, label: 'Defaulted', disabled: loan.status === LoanStatus.Defaulted || disabled },
                    loan.type === LoanType.Tract
                        ? { key: 'status/' + LoanStatus.Repossessed, label: 'Repossessed', disabled: loan.status === LoanStatus.Repossessed }
                        : { key: 'status/' + LoanStatus.Inactive, label: 'Inactive', disabled: loan.status === LoanStatus.Inactive || disabled },
                    loan.status === LoanStatus.Draft ? { key: 'status/' + LoanStatus.Canceled, label: 'Canceled', disabled: loan.status !== LoanStatus.Draft } : null,
                ],
            },
        ];
    }

    closeAddTransaction = async (saved: boolean) => {
        if (saved) {
            this.setState({ isAddTransVisible: false });
            return;
        }

        this.setState({ isAddTransVisible: false });
    }

    openAddTransaction = () => {
        this.setState({ isAddTransVisible: true });
    }

    closeRepossessedModal = async (repossessed: boolean) => {
        if (repossessed) {
            await this.loadData();
        }

        this.setState({ isRepossessedVisible: false });
    }

    openClientPage = () => this.props.history.push(`/${this.props.match.params.orgId}/clients/${this.props.loan!.client.id}`);

    get headerButtons() {
        const loan = this.props.loan;
        if (!loan) {
            return null;
        }

        return (
            <Button.Group>
                <AccessControlledOrComponent
                    feature={PermissionFeature.LoanTransaction}
                    action={PermissionAction.Create}
                    can={<Button type="default" icon={<CreditCardOutlined />} onClick={this.openAddTransaction} disabled={loanActionsAreDisabled(loan.status)}>Record Transaction</Button>}
                    not={<Button type="default" icon={<ContactsOutlined />} onClick={this.openClientPage}>View Client</Button>}
                />

                { this.actionsButton }
            </Button.Group>
        );
    }

    get actionsButton() {
        const items = this.dropdownMenuItems;

        if (items === null || !this.props.loanPermissions.update) {
            return (
                <Button disabled>
                    Actions <DownOutlined />
                </Button>
            );
        }

        return (
            <Dropdown menu={{ items, onClick: this.onActionMenuClick }}>
                <Button>
                    Actions <DownOutlined />
                </Button>
            </Dropdown>
        );
    }
    //#endregion menu

    //#region download loan transactions
    downloadLoanTransactions = async (which: 'simple' | 'detailed') => {
        const loan = this.props.loan;
        if (!loan) {
            return;
        }

        await getLoanTransactions(loan.organization.id, loan.id, which);
    }

    onTransactionsDownloadClick = (e: MenuInfo) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();

        switch (e.key) {
            case 'simple':
                this.downloadLoanTransactions('simple');
                break;
            case 'detailed':
                this.downloadLoanTransactions('detailed');
                break;
            default:
                return;
        }
    }

    get transactionMenuItems(): Array<ItemType> {
        return [
            { key: 'simple', icon: <FaFileCsv />, label: 'Simple CSV' },
            { key: 'detailed', icon: <FaFileCsv />, label: 'Detailed CSV' },
        ];
    }

    onDownloadTransactionsClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        this.downloadLoanTransactions('simple');
    }

    get transactionButton() {
        if (isMobileOnly) {
            return null;
        }

        return (
            <Dropdown.Button
                size="small"
                onClick={this.onDownloadTransactionsClick}
                icon={<DownOutlined />}
                menu={{
                    items: this.transactionMenuItems,
                    onClick: this.onTransactionsDownloadClick,
                }}
            >
                Download Transactions
            </Dropdown.Button>
        );
    }
    //#endregion download loan transactions

    //#region download amortization schedule
    onDownloadAmortizationScheduleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDownloadingSchedule: true }, async () => {
            try {
                const loan = this.props.loan;
                if (!loan) {
                    return;
                }

                const res = await getLoanAmortizationSchedule(loan.organization.id, loan.id, true, true);
                if (res.fileUrl) {
                    message.success('Successfully generated the printable loan amortization schedule');
                    window.open(res.fileUrl, '_blank');
                } else {
                    message.error('Failed to generate the printable loan amortization schedule');
                }
            } catch (e) {
                displayErrorNotification(e);
            } finally {
                this.setState({ isDownloadingSchedule: false });
            }
        });
    }

    get amortizationScheduleButton() {
        if (isMobileOnly) {
            return null;
        }

        return (
            <Button
                size="small"
                icon={<CloudDownloadOutlined />}
                loading={this.state.isDownloadingSchedule}
                disabled={this.state.isDownloadingSchedule}
                onClick={this.onDownloadAmortizationScheduleClick}
            >
                Download Schedule
            </Button>
        );
    }
    //#endregion download amortization schedule

    get transactionsAndSchedule() {
        if (!this.props.loan) {
            return null;
        }

        return (
            <Collapse defaultActiveKey={['transactions']} bordered={false}>
                { this.props.canAccessTransactions ?
                    <Collapse.Panel key="transactions" header="Transactions" extra={this.transactionButton}>
                        <LoanTransactionsTable loan={this.props.loan} schedule={this.state.schedule} addClick={this.openAddTransaction} refreshData={this.loadData} />
                    </Collapse.Panel>
                : null}

                { this.state.schedule ?
                    <Collapse.Panel key="schedule" header="Amortization Schedule" extra={this.amortizationScheduleButton}>
                        <Suspense fallback={<Skeleton active />}>
                            <LoanScheduleTable
                                interestSchedule={this.props.loan.terms.interestSchedule}
                                schedule={this.state.schedule}
                                loanUpdatedAt={this.props.loan.modifiedAt}
                                showDate
                                showReceived
                                showEscrow={typeof this.props.loan.escrow !== 'undefined' && this.props.loanPermissions.escrow}
                            />
                        </Suspense>
                    </Collapse.Panel>
                : null }

                { typeof this.props.loan.escrow === 'object' && this.props.loanPermissions.escrow ?
                    <Collapse.Panel key="escrow" header="Escrow">
                        <Suspense fallback={<Skeleton active />}>
                            <LoanEscrowTable loan={this.props.loan} />
                        </Suspense>
                    </Collapse.Panel>
                : null}
            </Collapse>
        );
    }

    get notesAndFiles() {
        if (!this.props.canAccessNotes && !this.props.canAccessFiles) {
            return null;
        }

        if (!this.props.loan) {
            return null;
        }

        return (
            <Row gutter={16} style={{ marginTop: 24 }}>
                { this.props.canAccessNotes ?
                    <Col xs={24} lg={12}>
                        <NotesCard notes={this.state.notes} orgId={this.props.match.params.orgId} loan={this.props.loan} isLoading={this.state.isLoading} refresh={this.loadData} />
                    </Col>
                : null }
                { this.props.canAccessFiles ?
                    <Col xs={24} lg={12}>
                        <FilesCard
                            orgId={this.props.match.params.orgId}
                            relatedTo={{
                                type: RelatedToType.LOAN,
                                id: this.props.loan.id,
                            }}
                            relatedLabel={this.props.loan.label}
                        />
                    </Col>
                : null }
            </Row>
        );
    }

    //#region header
    get headerTags(): React.ReactElement | undefined {
        if (!this.props.loan) {
            return undefined;
        }

        return (
            <React.Fragment>
                <LoanStatusTag key="statusTag" status={this.props.loan.status} />
                <Tag key="typeTag" className="title-caps">{this.props.loan.type}</Tag>
            </React.Fragment>
        );
    }

    get breadcrumbProps(): BreadcrumbProps {
        if (!this.props.selectedOrg || !this.props.loan) {
            return {};
        }

        return {
            itemRender: breadCrumbItemRender,
            items: [
                {
                    path: `/${this.props.match.params.orgId}`,
                    breadcrumbName: 'Dashboard',
                },
                {
                    path: `/${this.props.match.params.orgId}/loans`,
                    breadcrumbName: `${this.props.selectedOrg.name}'s Loans`,
                },
                {
                    path: `/${this.props.match.params.orgId}/loans/${this.props.loan.id}`,
                    breadcrumbName: this.props.loan.label,
                },
            ],
        };
    }

    onBack = () => this.props.history.push(`/${this.props.match.params.orgId}/loans`);

    onTabChange = (activeTab: string) => this.setState({ activeTab: activeTab as activeLoanTab });
    //#endregion header

    render() {
        if (!this.props.loan && this.state.isLoading) {
            return <Loading />;
        }

        if (this.state.notFound || !this.props.loan) {
            console.log(`Not found?! state: ${this.state.notFound} or props ${!this.props.loan}`);
            if (!this.props.loan) {
                console.warn('No LOAN prop provided!');
            }

            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="This specific loan was not found in our system. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Back to Loans</Button>}
                />
            );
        }

        if (!this.props.loanPermissions.read) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="You do not have permission for this loan. 🤔"
                    extra={<Button type="primary" onClick={this.onBack}>Back to Loans</Button>}
                />
            );
        }

        const headerProps: PageContainerProps = {
            title: this.props.loan.label,
            tags: this.headerTags,
            onBack: this.onBack,
            extra: this.headerButtons,
            breadcrumb: this.breadcrumbProps,
            tabList: [
                { key: 'overview', tab: 'Overview' },
                { key: 'details', tab: 'Details' },
            ],
            onTabChange: this.onTabChange,
            tabActiveKey: this.state.activeTab,
            childrenContentStyle: {
                paddingTop: 0,
            },
        };

        if (this.props.loan.terms.wasExisting) {
            headerProps.tabList!.push({ key: 'existing', tab: 'Existing' });
        }

        if (this.hasActionNeeded()) {
            headerProps.tabList!.unshift({
                key: 'action',
                tab: (
                    <React.Fragment>
                        <AlertTwoTone twoToneColor="#f5222d" /> Action Center
                    </React.Fragment>
                ),
            })
        }

        return (
            <PageContainer {...headerProps}>
                <Row gutter={16}>
                    { this.state.activeTab === 'action' ? <Col span={24}><Suspense fallback={null}><LoanTabActionArea loan={this.props.loan} /></Suspense></Col> : null }
                    { this.state.activeTab === 'overview' ? <Col span={24}><LoanTabOverview loan={this.props.loan} /></Col> : null }
                    { this.state.activeTab === 'details' ? <Col span={24}><LoanTabDetails loan={this.props.loan} schedule={this.state.schedule} /></Col> : null }
                    { this.state.activeTab === 'existing' ? <Col span={24}><Suspense fallback={null}><LoanTabExisting loan={this.props.loan} /></Suspense></Col> : null}
                </Row>
                <Row gutter={16} style={{ marginTop: 24 }}>
                    <Col span={24}>
                        {this.transactionsAndSchedule}
                    </Col>
                </Row>
                { this.notesAndFiles }

                <NewTransactionModal loan={this.props.loan} schedule={this.state.schedule} isVisible={this.state.isAddTransVisible} close={this.closeAddTransaction} />
                <ViewPayOffModal loan={this.props.loan} isVisible={this.state.isPayOffVisible} close={this.togglePayOffModal} />
                <Suspense fallback={null}><OnlinePaymentModal org={this.props.selectedOrg} loan={this.props.loan} isVisible={this.state.isOnlinePaymentsVisible} close={this.toggleOnlinePaymentsModal} /></Suspense>
                <ChangeDueDateModal loan={this.props.loan} isVisible={this.state.isChangeDueDateVisible} close={this.toggleChangeDueDateModal} />
                <FillablePDFListModal orgId={this.props.loan.organization.id} isVisible={this.state.isFillablePDFsVisible} for={this.props.loan.type === LoanType.Tract ? FillablePDFFor.TractLoan : FillablePDFFor.CashLoan} forId={this.props.loan.id} close={this.onFillablePDFClose} />
                <Suspense fallback={null}>
                    <ChangeCommunicationPreferencesModal orgId={this.props.loan.organization.id} loan={this.props.loan} isVisible={this.state.isChangeCommPrefVisible} close={this.toggleChangeCommPrefModal} />
                </Suspense>
                <Suspense fallback={null}>
                    <ChangeLateFeeConfigDrawer type="loan" loan={this.props.loan} visible={this.state.isChangeLateFeeVisible} close={this.toggleChangeLateFeeModal} />
                </Suspense>
                { this.props.loan.status !== LoanStatus.Inactive ? <LoanRenameModal orgId={this.props.loan.organization.id} loanId={this.props.loan.id} initialLabel={this.props.loan.label} visible={this.state.isRenameLoanVisible} close={this.toggleRenameModal} /> : null}
                { this.props.loan.status !== LoanStatus.Repossessed ?
                    <Suspense fallback={null}>
                        <MarkRepossessedModal orgId={this.props.loan.organization.id} loan={this.props.loan} visible={this.state.isRepossessedVisible} close={this.closeRepossessedModal} />
                    </Suspense>
                : null }
                <Suspense fallback={null}>
                    <ChangeDefaultingModal loan={this.props.loan} isVisible={this.state.isChangeDefaultingVisible} close={this.toggleDefaultingConfigModal} />
                </Suspense>
                <Suspense fallback={null}>
                    <RecordPayOffModal loan={this.props.loan} isVisible={this.state.isRecordPayOffVisible} close={this.toggleRecordPayOffModal} />
                </Suspense>
            </PageContainer>
        );
    }
}

const LoanWithProps = withRouter(connect(mapStateToProps)(LoanBase));

export const Loan: React.FC = () => (
    <AccessControlledWrapper
        feature={PermissionFeature.Loan}
        action={PermissionAction.Read}
        children={<LoanWithProps />}
    />
);
